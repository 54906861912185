import React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'underscore';

import { Col, Row } from 'reactstrap';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Components
 */
import MessagingComponent, { MESSAGE_TYPE, STATUS_MESSAGE } from 'CommonComponents/messager';
import ActivityHeader from 'RootComponents/activity/components/header';
import ActivityDateGroupSectionComponent from 'RootComponents/activity/components/dateSection';

/**
 * New Filters
 */
import FilterContainer from 'CommonComponents/filters/filterContainer';
import AdvancedFilterComponent from 'FilterComponents/common/advanced';
import BooleanFilterComponent from 'FilterComponents/common/boolean';
import SchoolFilterComponent from 'FilterComponents/common/school';
import TextFilterComponent from 'FilterComponents/common/text';
import LocationFilterComponent from 'FilterComponents/common/location';
import DateFilterComponent from 'FilterComponents/specific/date';
import HighSchoolFilterComponent from 'FilterComponents/specific/high_school';
import LocationRadiusFilterComponent from 'FilterComponents/specific/location_radius';
import PositionsFilterComponent from 'FilterComponents/specific/positions';
import SchoolTypeFilterComponent from 'FilterComponents/specific/schoolType';
import RatingsFilterComponent from 'FilterComponents/specific/ratings';
import YearsFilterComponent from 'FilterComponents/specific/years';
import RecruitingActivitiesCoreFilterComponent from 'RootComponents/common/filters/filters/components/specific/recruitingActivitiesCore';

/**
 * Requests
 */
import ActivityRequest from 'Requests/activity';

/**
 * Services
 */
import ActivityService from 'Services/activity';

/**
 * Styles
 */
import 'RootComponents/activity/styles/_index.scss';

class ActivityComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    const activityRequest = new ActivityRequest();
    activityRequest.details.add('years', []);

    this.state = {
      activityRequest,
      collapse: false,
      data: {},
      defaultActive: false,
      errorMessage: '',
      errorThrown: false,
      filtersSaved: false,
      isLoading: true,
      nextPageLoading: true,
      originalRequest: JSON.parse(JSON.stringify(activityRequest)),
      page: 1,
      total: 0,
      totalPages: 0,
      totalCount: 0,
      activeFilterName: 'Activity',
    };
  }

  componentDidMount() {
    this.getActivity();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    /**
     * Check if filters are the same. If they're not the same, we can trigger
     * the get players call and return false.
     */
    if (isEqual(nextProps.activityRequest, this.props.activityRequest) === false) {
      this.clearFilters(nextProps.isClear);

      return false;
    }

    return true;
  }

  getActivity() {
    ActivityService.cancelCall('Restarting search');

    this.setState({
      errorThrown: false,
      nextPageLoading: true,
    });

    ActivityService.get(this.props.activityRequest, this.state.page)
      .then((data) => {
        const oldData = this.state.data;
        const totalCount = data.total;
        let total = 0;

        Object.keys(data.data).forEach((key) => {
          total += data.data[key].length;

          if (typeof oldData[key] === 'undefined') {
            oldData[key] = [];
          }

          oldData[key] = oldData[key].concat(data.data[key]);
        });

        this.setState({
          data: oldData,
          isLoading: false,
          nextPageLoading: false,
          page: (this.state.page += 1),
          totalPages: data.pageTotal,
          total,
          totalCount,
        });
      })
      .catch((error) => {
        if (error.message === 'Restarting search') {
          return;
        }

        this.setState({
          errorThrown: true,
          isLoading: false,
          totalCount: 0,
          errorMessage: 'There were no activity results found for the requested query',
        });
      });
  }

  renderGroups() {
    const html = [];

    Object.keys(this.state.data).forEach((key) => {
      html.push(
        <ActivityDateGroupSectionComponent data={this.state.data[key]} date={key} key={key} />
      );
    });

    if (this.state.page <= this.state.totalPages && this.state.nextPageLoading === false) {
      html.push(
        <Row key='submit'>
          <Col md={12} className='button-center'>
            <button className='btn btn-primary' onClick={this.getActivity.bind(this)} type='submit'>
              Load More
            </button>
          </Col>
        </Row>
      );
    } else if (this.state.nextPageLoading === true) {
      html.push(
        <Col md={12}>
          <MessagingComponent
            thrown={this.state.nextPageLoading}
            message={STATUS_MESSAGE.loading}
            type={MESSAGE_TYPE.warning}
          />
        </Col>
      );
    }

    return html;
  }

  saveHandler(name) {
    this.setState(
      {
        filtersSaved: true,
      },
      () => {
        this.setState({
          filtersSaved: false,
        });
      }
    );
  }

  clearFilters() {
    this.setState(
      {
        data: {},
        isLoading: true,
        page: 1,
        defaultActive: true,
        activeFilterName: 'Activity',
      },
      () => {
        this.getActivity();
      }
    );
  }

  render() {
    const closeFilter =
      typeof this.props.closeFilter === 'undefined' ? false : this.props.closeFilter;

    return (
      <Row className='activity' id='scroller'>
        <Col md={2} className='no-padding position-relative'>
          <FilterContainer
            addFilterToState={this.saveHandler.bind(this)}
            filter={this.props.activityRequest}
            type={1}>
            <div className='filter-group'>
              <h2>Player Details</h2>
              <TextFilterComponent
                activity_request_level='details'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                isOpen={true}
                id='name'
                label='Search'
                placeholder="Prospect's Name"
                type='name'
                value={this.props.activityRequest.details.name}
              />
              <YearsFilterComponent
                activity_request_level='details'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                values={this.props.activityRequest.details.years}
                closeFilter={closeFilter}
              />
              <RatingsFilterComponent
                id='rating'
                activity_request_level='details'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                closeFilter={closeFilter}
                values={this.props.activityRequest.details.rating}
              />
              <AdvancedFilterComponent
                id='advanced_filter_locations'
                closeFilter={closeFilter}
                values={this.props.activityRequest.details}
                label='Locations'>
                <LocationFilterComponent
                  id='location'
                  activity_request_level='details'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  closeFilter={closeFilter}
                  values={this.props.activityRequest.details.location}
                  label='Location Filter'
                />
                <LocationRadiusFilterComponent
                  activity_request_level='details'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  closeFilter={closeFilter}
                  id='location_radius'
                  label='Location Radius'
                  values={this.props.activityRequest.details.location_radius}
                  collapsible={false}
                  classes='transparent-background'
                />
                <HighSchoolFilterComponent
                  id='highschool_filter'
                  activity_request_level='details'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  closeFilter={closeFilter}
                  values={this.props.activityRequest.details.high_schools}
                />
                <SchoolFilterComponent
                  activity_request_level='details'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  closeFilter={closeFilter}
                  id='transfers'
                  label='Colleges'
                  collapsible={false}
                  classes='transparent-background'
                  values={this.props.activityRequest.details.transfers}
                />
                <SchoolTypeFilterComponent
                  activity_request_level='details'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  values={this.props.activityRequest.details.school_type}
                  collapsible={false}
                  classes='transparent-background'
                />
              </AdvancedFilterComponent>
              <PositionsFilterComponent
                activity_request_level='details'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                closeFilter={closeFilter}
                values={this.props.activityRequest.details.positions}
              />
              <RecruitingActivitiesCoreFilterComponent
                id='recruiting_activity'
                offers={this.props.activityRequest.details.offers}
                commits={this.props.activityRequest.details.commits}
                closeFilter={closeFilter}
              />
            </div>
            <div className='filter-group'>
              <h2>New Activity</h2>
              <DateFilterComponent
                activity_request_level='changes'
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='date_range'
                key='date_range'
                values={this.props.activityRequest.changes.date_range}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.offers.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='offers'
                label='Offers'
                values={this.props.activityRequest.changes.types.offers.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.first_offer.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='first_offer'
                label='First Offers'
                values={this.props.activityRequest.changes.types.first_offer.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.visits.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='visits'
                label='Visits'
                values={this.props.activityRequest.changes.types.visits.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.commits.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='commits'
                label='Commits'
                values={this.props.activityRequest.changes.types.commits.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.decommits.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='decommits'
                label='De-commits'
                values={this.props.activityRequest.changes.types.decommits.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.signings.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='signings'
                label='Signings'
                values={this.props.activityRequest.changes.types.signings.values}
              />
              <SchoolFilterComponent
                active={this.props.activityRequest.changes.types.twitter_follower.active}
                activity_request_level='changes'
                addActiveCheckbox={true}
                closeFilter={closeFilter}
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='twitter_follower'
                label='Twitter Followers'
                values={this.props.activityRequest.changes.types.twitter_follower.values}
              />
              {/* NOTE: We don't want to show track and field filter until
                * we fix https://ncsasports.atlassian.net/browse/CX-34
                <BooleanFilterComponent
                  activity_request_level='changes'
                  filter_action_type='ACTIVITY_FILTER_UPDATED'
                  id='track_and_field'
                  label='Track &amp; Field Results'
                  values={this.props.activityRequest.changes.types.track_and_field.active} />
                */}
              <BooleanFilterComponent
                activity_request_level='changes'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='rating_bump'
                label='Rating Bump'
                values={this.props.activityRequest.changes.types.rating_bump.active}
              />
              <BooleanFilterComponent
                activity_request_level='changes'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='new_prospects'
                label='New Prospects'
                values={this.props.activityRequest.changes.types.new_prospects.active}
              />
              <BooleanFilterComponent
                activity_request_level='changes'
                filter_action_type='ACTIVITY_FILTER_UPDATED'
                id='combine_results'
                label='Combine Results'
                values={this.props.activityRequest.changes.types.combine_results.active}
              />
            </div>
          </FilterContainer>
        </Col>
        <Col md={10}>
          <ActivityHeader
            activityRequest={this.props.activityRequest}
            filtersSaved={this.state.filtersSaved}
            activeFilterName={this.props.activeFilterName || 'Activity'}
            totalCount={this.state.totalCount}
          />
          <Row>
            {this.state.errorThrown === true && (
              <Col md={12} className='with-sidebar'>
                <MessagingComponent
                  thrown={this.state.errorThrown}
                  message={this.state.errorMessage}
                  type={MESSAGE_TYPE.danger}
                />
              </Col>
            )}
            {this.state.isLoading === true && (
              <Col md={12} className='with-sidebar'>
                <MessagingComponent
                  thrown={this.state.isLoading}
                  message={STATUS_MESSAGE.loading}
                  type={MESSAGE_TYPE.warning}
                />
              </Col>
            )}
            {this.state.errorThrown === false && this.state.isLoading === false && (
              <Col md={12} className='with-sidebar'>
                {this.renderGroups()}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

/**
 * This sends the store to me
 */
function mapStoreToProps(state) {
  return {
    activeFilterName: state.activityFilter.activeFilterName,
    activityRequest: state.activityFilter.filter,
    closeFilter: state.activityFilter.closeFilter,
    isClear: state.activityFilter.isClear,
  };
}

export default connect(mapStoreToProps)(ActivityComponent);
