import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { nanoid } from 'nanoid';
import DateTimeService from 'Services/date-time';

import {
  TimelineMessageLoading,
  TimelineMessageEmpty,
  TimelineShowMoreButton,
  TimelineMark,
  TimelineEntry,
  TimelineEntries,
  TimelineLegend,
} from './components';

import './styles/_index.scss';

class TimelineComponent extends Component {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.showMore = this.showMore.bind(this);

    this.state = {
      showFirst: this.props.showFirst || null,
      moreShowing: false,
    };
  }

  generateLegend() {
    const htmlNodes = [];

    this.props.values.forEach((key, index) => {
      Object.keys(key).forEach((value) => {
        if (this.isShowingMore(index)) {
          return;
        }

        htmlNodes.push(key[value].label);
      });
    });
    return htmlNodes;
  }

  generateTimeline() {
    const { totalBoxesPerRow } = this.getBoxValues();
    const htmlNodes = [];

    this.props.values.forEach((key, index) => {
      Object.keys(key).forEach((value) => {
        if (this.isShowingMore(index)) {
          return;
        }
        htmlNodes.push(
          <TimelineEntry
            key={`${value}`}
            entryKey={key}
            entryValue={value}
            totalBoxesPerRow={totalBoxesPerRow}>
            {this.generateBoxes(key, value)}
          </TimelineEntry>
        );
      });
    });

    return htmlNodes;
  }

  generateBoxes(key, value) {
    const { startDate } = this.props;
    const jsStartDate = new Date(startDate);
    const { totalBoxesPerRow, minutesPerBox } = this.getBoxValues();

    const htmlNodes = [];
    let curDate = this.dateTimeService.fromJSDate(jsStartDate);

    for (let i = 0; i < totalBoxesPerRow; i++) {
      const nextDate = curDate.plus({ minutes: minutesPerBox }).minus({ minutes: 1 });

      /**
       * Needs to be inverted since we're decrementing
       */
      const inRangeValues = this.getValidValues(curDate, nextDate, key[value].values);

      let markType = null;

      /**
       * See if there's any iconography to add; only run when there is one
       */
      if (inRangeValues.length === 1) {
        markType = this.getType(inRangeValues[0], key[value].values);
      }

      const markBgColor = inRangeValues.length > 0 ? key[value].color || 'grey' : 'none';
      const markId = nanoid();

      htmlNodes.push(
        <TimelineMark
          key={markId}
          markId={markId}
          markType={markType}
          markBgColor={markBgColor}
          markValues={inRangeValues}
        />
      );

      /**
       * Increment. We have to add a day as nextDate is the last day of the previous month.
       * Eg. curday = 4/1, nextDate = 3/31
       */
      curDate = nextDate.plus({ minutes: 1 });
    }

    return htmlNodes;
  }

  getBoxValues() {
    const { startDate, endDate, interval, intervalType } = this.props;
    const jsStartDate = new Date(startDate);
    const jsEndDate = new Date(endDate);

    const totalBoxesPerRow =
      this.dateTimeService
        .fromJSDate(jsEndDate)
        .diff(this.dateTimeService.fromJSDate(jsStartDate), intervalType)[intervalType] / interval;

    const minutesPerBox =
      this.dateTimeService
        .fromJSDate(jsEndDate)
        .diff(this.dateTimeService.fromJSDate(jsStartDate), 'minutes').minutes / totalBoxesPerRow;

    return { totalBoxesPerRow, minutesPerBox };
  }

  getType(value, values) {
    let type = '';

    values.forEach((single) => {
      if (single.label === value) {
        type = single.type;
      }
    });

    return type;
  }

  getValidValues(startDate, endDate, values) {
    const inRangeValues = [];

    values.forEach(({ value, label }) => {
      if (this.dateTimeService.isDateBetween(startDate, endDate, new Date(value))) {
        inRangeValues.push(label);
      }
    });

    return inRangeValues;
  }

  isShowingMore(index) {
    const { showFirst, moreShowing } = this.state;

    const max =
      typeof showFirst !== 'undefined' && parseInt(showFirst, 10) > 0
        ? parseInt(showFirst, 10)
        : null;

    return moreShowing === false && max !== null && index >= max;
  }

  showMore() {
    this.setState({
      moreShowing: !this.state.moreShowing,
    });
  }

  render() {
    const { timeLineDataLoaded, legendHeader, startLabel, endLabel, values, noResultsMessage } =
      this.props;
    const { moreShowing } = this.state;

    const { totalBoxesPerRow } = this.getBoxValues();

    if (!timeLineDataLoaded) {
      return <TimelineMessageLoading />;
    }

    if (values.length === 0) {
      return <TimelineMessageEmpty message={noResultsMessage} />;
    }

    setTimeout(() => {
      const el = document.getElementsByClassName('timeline-entries')[0];
      if (!el) {
        return;
      }
      el.scrollLeft = el.scrollWidth;
    }, 0);

    return (
      <Row className='timeline'>
        <Col md={2} className='timeline-legend'>
          <TimelineLegend legendHeader={legendHeader}>{this.generateLegend()}</TimelineLegend>
        </Col>
        <Col md={10} className='holder'>
          <TimelineEntries
            startLabel={startLabel}
            endLabel={endLabel}
            totalBoxesPerRow={totalBoxesPerRow}>
            {this.generateTimeline()}
          </TimelineEntries>
        </Col>
        {this.state.showFirst !== null && (
          <Col md={12} className='text-center'>
            <TimelineShowMoreButton onClick={this.showMore} isShowingMore={moreShowing} />
          </Col>
        )}
      </Row>
    );
  }
}

export default TimelineComponent;
