import PlayerSearchRequest from '../requests/playerSearch';

const filter = new PlayerSearchRequest();

const initialState = {
  filter,
};

/**
 * - Generate new instance of filter on page load based on page
 *   - activity
 *   - non-activity
 * - Accept new selected filter
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FILTER_UPDATED':
      const newFilter = {
        filter: Object.assign(new PlayerSearchRequest(), state.filter),
      };

      if (action.filter.type === 'name') {
        newFilter.filter = new PlayerSearchRequest();
        newFilter.filter.years = undefined;
      }

      newFilter.closeFilter = false;
      newFilter.filter[action.filter.type] = action.filter.values;
      newFilter.isClear = false;
      newFilter.activeFilterName = 'Players';
      newFilter.showOldYearsFilterMessage = false;

      return newFilter;
    case 'UPDATE_FROM_EXISTING_FILTER':
      /**
       * Update filter
       *
       * initialState = passed in filter
       */
      const updateFilter = {
        filter: {},
      };

      updateFilter.activeFilterName = action.name;
      updateFilter.closeFilter = true;
      updateFilter.filter = action.filter;
      updateFilter.isClear = true;
      updateFilter.showOldYearsFilterMessage = false;

      if (
        typeof updateFilter.filter.years !== 'undefined' &&
        (updateFilter.filter.years.indexOf('2020') >= 0 ||
          updateFilter.filter.years.indexOf(2020) >= 0)
      ) {
        updateFilter.showOldYearsFilterMessage = true;
      }

      return updateFilter;
    case 'CLEAR_FILTERS':
      return {
        activeFilterName: 'Players',
        closeFilter: true,
        filter: initialState.filter,
        isClear: true,
      };
    case 'CLEAR_ACCOUNT_FILTERS':
      const accountFilter = initialState.filter;

      accountFilter.years = [];

      return {
        activeFilterName: 'Players',
        closeFilter: true,
        filter: accountFilter,
        isClear: true,
      };
    case 'DELETE_SPECIFIC_FILTER':
      const newFilterDelete = {
        filter: Object.assign(new PlayerSearchRequest(), state.filter),
      };

      delete newFilterDelete.filter[action.filter.type];

      return newFilterDelete;
    case 'SET_CLOSE_FILTER':
      return Object.assign({}, state, {
        closeFilter: action.closeFilter,
      });
    default:
      return state;
  }
}
