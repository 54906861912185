import React from 'react';
import { Table, Collapse } from 'reactstrap';
import { useContactInfoContext } from '../contactInfo';
import {
  ContactInfoValue,
  ContactInfoIcon,
  ContactInfoDate,
  ContactInfoLocation,
} from '../contactInfoMisc';

function PlayerContactInfoCollapse() {
  const { isCollapsed, contact } = useContactInfoContext();

  return (
    <Collapse isOpen={isCollapsed}>
      <Table striped>
        <thead>
          <tr>
            <th scope='col'>Updated</th>
            <th scope='col'>Email</th>
            <th scope='col'>Cell Phone</th>
            <th scope='col'>Birth State</th>
            <th scope='col'>Address</th>
            <th scope='col'>Source</th>
          </tr>
        </thead>
        <tbody>
          {contact.contacts.map((player) => {
            return (
              <tr key={player.id} className='contact-table'>
                <td className='contact-date'>
                  <ContactInfoDate value={player.updated_at} />
                </td>
                <td className='contact-email'>
                  <ContactInfoValue value={player.email} dashNoValue />
                </td>
                <td className='contact-cell'>
                  <ContactInfoValue value={player.cell_phone} dashNoValue />
                </td>
                <td className='contact-birth-state'>
                  <ContactInfoValue
                    value={player.birth_state ? player.birth_state.name : null}
                    dashNoValue
                  />
                </td>
                <td contact='contact-address'>
                  <ContactInfoLocation
                    location={player.location}
                    address={player.address}
                    dashNoValue
                  />
                </td>
                <td className='contact-icon'>
                  <ContactInfoIcon source={player.source} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Collapse>
  );
}

export default PlayerContactInfoCollapse;
