import React from 'react';
import { Col, Row } from 'reactstrap';

import ZcruitComponent from 'CommonComponents/zcruit';
import ErrorPageComponent from 'PlayerPageComponents/pages/error';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';
import VideoPlayer from 'CommonComponents/videoPlayer';
import VideoDetailsComponent from 'CommonComponents/videoDetails';
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
  MESSAGE_TYPE,
} from 'CommonComponents/messager';

import PlayersService from 'Services/players';
import VideosService from 'Services/videos';
import generatePageTitles from 'RootComponents/utility/pageTitles';

import 'PlayerPageComponents/pages/videos/styles/_index.scss';

const _externalSources = ['ncsa', 'hudl'];

class VideoPlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this._externalLinkSources = ['hudl', 'ncsa'];

    this.state = {
      activeTab: 'videos',
      currentVideo: null,
      error: true,
      isLoading: true,
      originalPlayer: null,
      player: null,
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;
    const players = PlayersService.getPlayerAsPromise(playerId);
    const videos = VideosService.getVideosForPlayerAsPromise(playerId);

    Promise.all(
      [players, videos].map((p) => {
        return p.catch((e) => {});
      })
    ).then(
      (responses) => {
        if (typeof responses[0] === 'undefined') {
          throw new Error('We could not find the requested player');
        }

        document.title = generatePageTitles(`${responses[0].name} Film`);

        if (typeof responses[1] === 'undefined') {
          return this.setState({
            currentVideo: null,
            error: true,
            isLoading: false,
            player: responses[0],
            videos: [],
          });
        }

        const playerResponse = responses[0];
        const videosResponse = responses[1];

        this.setState({
          currentVideo: videosResponse[0],
          isLoading: false,
          originalPlayer: playerResponse,
          player: playerResponse,
          videos: videosResponse,
        });
      },
      () => {
        this.setState({
          currentVideo: null,
          error: true,
          isLoading: false,
        });
      }
    );
  }

  changeVideo(nextVideo) {
    if (this.state.currentVideo.id === nextVideo.id) {
      return;
    }

    this.setState({
      currentVideo: nextVideo,
    });
  }

  createPlaylistMembers() {
    return this.state.videos.map((member) => {
      if (
        typeof member.source !== 'undefined' &&
        _externalSources.indexOf(member.source.name) > -1
      ) {
        return (
          <a
            className='playlist-body-member'
            href={member.filename}
            key={member.id}
            target='_blank'
            rel='noopener noreferrer'>
            <IconComponent icon='play fas fa-play' label='Play Video' />
            <div className='info'>
              <div className='title'>
                <h5>{member.title}</h5>
                <i className='icon external_link white fas fa-external-link-alt' />
              </div>
              {typeof member.event !== 'undefined' && <p>{member.event.occurred_on}</p>}
            </div>
          </a>
        );
      }

      return (
        <div
          className='playlist-body-member'
          key={member.id}
          onClick={this.changeVideo.bind(this, member)}
          onKeyUp={this.changeVideo.bind(this, member)}
          role='button'
          tabIndex={0}>
          <IconComponent icon='play fas fa-play' label='Play Video' />
          <div className='info'>
            <h5>{member.title}</h5>
            {typeof member.event !== 'undefined' && <p>{member.event.occurred_on}</p>}
          </div>
        </div>
      );
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div className='modal-wrapper' style={{ width: '90%', margin: '50px auto' }}>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </div>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <div className='modal-wrapper'>
          <ErrorPageComponent
            activeTab='videos'
            message={NO_DATA_MESSAGE.film}
            page={this.props.page}
            player={this.state.player}
            title='Film'
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
          />
        </div>
      );
    }

    const url = `${window.location.protocol}//${window.location.host}/rivals-camp/video/${this.state.currentVideo.id}`;

    return (
      <div className='modal-wrapper'>
        <PlayerPageModalHeaderComponent
          player={this.state.player}
          page={this.props.page}
          activeTab={this.state.activeTab}
          toggleModal={this.props.toggleModal}
          setTab={this.props.setTab}
        />
        <div className='modal-body'>
          <Row className='secondary-page video-page'>
            <Col md={12} className='page-title-container'>
              <div className='page-title'>{`${this.state.player.name}'s Film`}</div>
            </Col>
            <Col md={12} className='box-holder'>
              <div className='box'>
                <div className='video'>
                  <h2>{this.state.currentVideo.title}</h2>
                  {this._externalLinkSources.indexOf(this.state.currentVideo.source.name) > -1 && (
                    <div className='external-source-bg'>
                      <p />
                    </div>
                  )}
                  {this._externalLinkSources.indexOf(this.state.currentVideo.source.name) ===
                    -1 && (
                    <div>
                      <VideoPlayer
                        source={this.state.currentVideo.source.name}
                        videoTitle={this.state.currentVideo.title}
                        videoUrl={this.state.currentVideo.filename}
                      />
                      <VideoDetailsComponent
                        players={[]}
                        videoCity={
                          this.state.currentVideo.event && this.state.currentVideo.event.city.name
                        }
                        videoDate={
                          this.state.currentVideo.event && this.state.currentVideo.event.occurred_on
                        }
                        videoId={this.state.currentVideo.id}
                        videoPageLink={url}
                        videoTitle={this.state.currentVideo.title}
                        videoUrl={this.state.currentVideo.filename}
                      />
                    </div>
                  )}
                </div>
                <div className='playlist'>
                  <div className='playerlist_header page-title-container'>
                    <h2>All Available Film</h2>
                  </div>
                  <div className='playlist-body'>{this.createPlaylistMembers()}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default VideoPlayerPageComponent;
