import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class TrackPlayerModal extends Component {
  render() {
    return (
      <Modal
        className='track-players hide-all-players'
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        aria-modal={true}
        labelledBy='hide-prospects'>
        <ModalBody>
          <ModalHeader id='hide-prospects' toggle={this.toggle}>
            Hide All Prospects
          </ModalHeader>
          <p>Hang tight! All signed recruits will be hidden shortly</p>
        </ModalBody>
      </Modal>
    );
  }
}

export default TrackPlayerModal;
