import React from 'react';
import { useContactInfoContext } from '../contactInfo';
import ContactInfoBody from '../contactInfoBody';
import {
  ContactInfoValue,
  ContactInfoPhone,
  ContactInfoState,
  ContactInfoAlmaMater,
} from '../contactInfoMisc';
import {
  ContactInfoBodyPhoneItem,
  ContactInfoBodyEmailItem,
  ContactInfoBodyBasicItem,
  ContactInfoBodyCollegeAlmaMaterItem,
} from '../contactInfoBodyItem';

function ParentContactInfoBody() {
  const { contact } = useContactInfoContext();
  const parentCollege = contact.parent_college_alma_mater;
  const parentCollegeState = contact.parent_college_alma_mater_state;

  return (
    <ContactInfoBody>
      <ContactInfoBodyBasicItem>
        <ContactInfoValue value={contact.name} />
        <p>Parent</p>
        <p>
          <ContactInfoState state={contact.parent_birth_state} />
        </p>
      </ContactInfoBodyBasicItem>
      <ContactInfoBodyCollegeAlmaMaterItem value={parentCollege || parentCollegeState}>
        <ContactInfoAlmaMater name={parentCollege} state={parentCollegeState} />
      </ContactInfoBodyCollegeAlmaMaterItem>
      <ContactInfoBodyEmailItem>
        <ContactInfoValue value={contact.email} />
      </ContactInfoBodyEmailItem>
      <ContactInfoBodyPhoneItem>
        <ContactInfoPhone value={contact.cell_phone} type='cellPhone' />
      </ContactInfoBodyPhoneItem>
    </ContactInfoBody>
  );
}

export default ParentContactInfoBody;
