import axios from 'axios';

import PlayersModel from '../models/player';
import PlayersTwitterModel from '../models/playerTwitter';

export const SEARCH_BY_NAME_PARAMS = {
  limit: 1000,
  'sort[sortBy]': 'name',
  'sort[sortDirection]': 'ASC',
};

class PlayersService {
  constructor() {
    this.cancelTokens = [];
    this.page = 0;
    this._playersList = [];
  }

  exportPlayers(params, data, page = 1) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/?limit=500&page=${page}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  get({ data, params = SEARCH_BY_NAME_PARAMS, isDropdown = false }) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        if (isDropdown) {
          return response.data.data.map((single) => {
            return {
              checked: false,
              id: single.id,
              label: single.name,
            };
          });
        }

        const players = response.data.data.map((player) => {
          return new PlayersModel(player);
        });

        return players;
      })
      .catch((error) => {
        console.warn('error');
        console.warn(error);

        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  getPlayers(params, data) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        const players = response.data.data.map((player) => {
          return new PlayersModel(player);
        });

        return {
          page: response.data.page,
          pageTotal: response.data.pageTotal,
          players,
          total: response.data.total,
        };
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  getPlayersTwitter(params, data) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/twitter/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        const players = response.data.data.map((player) => {
          return new PlayersTwitterModel(player);
        });

        return {
          players,
        };
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }
        console.log('had an error here', error);
        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  /**
   * @TODO: remove after the search is migrated to a the new API
   *
   * @param params
   * @param data
   * @returns {Promise<T | never>}
   */
  getPlayersNonRepeating(params, data) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-zcruit-school': window.localStorage.getItem('school'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        const players = response.data.data.map((player) => {
          return new PlayersModel(player);
        });

        return {
          page: response.data.page,
          pageTotal: response.data.pageTotal,
          players,
          total: response.data.total,
        };
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  cancelCall(message) {
    this.cancelTokens.forEach((token) => {
      token.cancel(message);
    });
  }

  getPlayer(id) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((response) => {
        const player = response.data.data;
        return new PlayersModel(player);
      })
      .catch((error) => {
        console.warn('error in get player');
        console.warn(error);

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  getPlayerAsPromise(id) {
    return new Promise((resolve, reject) => {
      return axios({
        method: 'GET',
        url: `${process.env.API_URL}players/${id}/`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
      })
        .then((response) => {
          const player = response.data.data;

          resolve(new PlayersModel(player));
        })
        .catch((error) => {
          reject(JSON.stringify(error));
        });
    });
  }

  editPlayer(id, data) {
    data = this.clean(data);

    return axios({
      method: 'PATCH',
      url: `${process.env.API_URL}players/${id}/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data,
    })
      .then((result) => {
        return new PlayersModel(result.data.data);
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  hidePlayer(id) {
    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/${id}/hide/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        return new PlayersModel(result.data.data);
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  hideAllPlayers() {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/hideall/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  unhidePlayer(id, withReturn) {
    return axios({
      method: 'DELETE',
      url: `${process.env.API_URL}players/${id}/hide/`,
      data: {
        withReturn,
      },
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        if (withReturn) {
          return new PlayersModel(result.data.data);
        }
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  changeStatus(id, data) {
    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/${id}/status/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data,
    })
      .then((result) => {
        return new PlayersModel(result.data.data);
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  trackPlayer(id, data) {
    return axios({
      method: 'POST',
      url: `${process.env.API_URL}players/${id}/track/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data,
    })
      .then((result) => {
        return new PlayersModel(result.data.data);
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  untrackPlayer(id, withReturn) {
    return axios({
      method: 'DELETE',
      url: `${process.env.API_URL}players/${id}/track/`,
      data: {
        withReturn,
      },
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        if (withReturn) {
          return new PlayersModel(result.data.data);
        }
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  clean(player) {
    const cloned = Object.assign({}, player);

    delete cloned.originalPlayer;
    delete cloned.visitsByCollege;

    return cloned;
  }

  getPlayerYearSources(id) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/sources/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }

  getPlayerYearsForPlayer(id) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/years/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw JSON.parse(JSON.stringify(error));
      });
  }
}

export default new PlayersService();
