import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import DateTimeService from '../../../../services/date-time';

/**
 * Components
 */
import ActivityDateGroupItemComponent from './components/activityItem';

/**
 * Styles
 */
import './styles/_index.scss';

class ActivityDateGroupSectionComponent extends Component {
  renderItems() {
    let count = 0;

    return this.props.data.map((item) => {
      let isEven = false;

      if (count + 1 === 3 || count + 1 === 4) {
        isEven = true;
      }

      count++;

      if (count === 4) {
        count = 0;
      }

      return <ActivityDateGroupItemComponent activity={item} key={item.id} odd={isEven} />;
    });
  }

  renderDate(date) {
    let dateTimeService;

    if (!dateTimeService) {
      dateTimeService = new DateTimeService();
    }

    const utcDate = dateTimeService.toUtcDate(new Date(date));
    return `${utcDate.toFormat('MMMM')} ${dateTimeService.getDayOrdinal(
      utcDate.day
    )}, ${utcDate.toFormat('yyyy')}`;
  }

  render() {
    return (
      <Row className='activity__date-group'>
        <Col md={12} className='activity__date-group__header'>
          <h2>
            <span>{this.renderDate(this.props.date)}</span>
          </h2>
        </Col>
        {this.renderItems()}
      </Row>
    );
  }
}
export default ActivityDateGroupSectionComponent;
