import React, { Component } from 'react';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import RatingService from 'Services/ratings';

/**
 * Styles
 */
import 'RootComponents/prospects/components/ratingPreview/styles/_index.scss';

class RatingPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      ratingsArr: [],
      errorThrown: false,
    };
  }

  componentDidMount() {
    RatingService.getRatingsForPlayer(this.props.playerId)
      .then((response) => {
        this.setState({
          isLoading: false,
          ratingsArr: response,
          errorThrown: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorThrown: true,
        });
      });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <ul className='tooltip-list text-left'>
          <MessagingComponent
            thrown={this.state.isLoading}
            message={STATUS_MESSAGE.loading}
            type={MESSAGE_TYPE.warning}
          />
        </ul>
      );
    }

    if (this.state.errorThrown === true) {
      return (
        <ul className='tooltip-list text-left'>
          <MessagingComponent
            thrown={this.state.errorThrown}
            message={NO_DATA_MESSAGE.ratings}
            type={MESSAGE_TYPE.danger}
          />
        </ul>
      );
    }

    return (
      <ul className='tooltip-list text-left'>
        <h3>{RatingService.getPlayerLevel(this.props.rating)}</h3>
        <hr className='rating-preview-rule' />
        {this.state.ratingsArr.map((rating) => {
          return (
            <li className='source-rating-item' key={`source-rating-logo-holder-${rating.id}`}>
              <IconComponent icon={rating.source.name} label={rating.source.label} />
              {rating.value}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default RatingPreview;
