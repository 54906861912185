import React, { Component } from 'react';
import { Modal } from 'reactstrap';

/**
 * Components
 */
import BasePlayerPageComponent from './components/pages/base';
import ContactPlayerPageComponent from './components/pages/contact';
import StatsPlayerPageComponent from './components/pages/stats';
import AcademicsPlayerPageComponent from './components/pages/academics';
import VideoPlayerPageComponent from './components/pages/videos';
import TwitterPlayerPageComponent from './components/pages/twitter';

/**
 * Utility functions
 */
import generatePageTitles from '../utility/pageTitles';

/**
 * Styles
 */
import './styles/_index.scss';

class ProspectsPlayerPageComponent extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentWillUnmount() {
    // User has closed the prospect modal so change the page title back
    document.title = generatePageTitles('Prospects');
  }

  getTemplate() {
    if (typeof this.props.match.params.param === 'undefined') {
      return (
        <BasePlayerPageComponent
          match={this.props.match}
          page='prospects'
          history={this.props.history}
          parentRef={this.modalRef}
        />
      );
    }

    switch (this.props.match.params.param) {
      case 'stats':
        return (
          <StatsPlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
          />
        );
      case 'videos':
        return (
          <VideoPlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
          />
        );
      case 'twitter':
        return (
          <TwitterPlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
          />
        );
      case 'contact':
        return (
          <ContactPlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
          />
        );
      case 'academics':
        return (
          <AcademicsPlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
          />
        );
      default:
        return (
          <BasePlayerPageComponent
            match={this.props.match}
            page='prospects'
            history={this.props.history}
            parentRef={this.modalRef}
          />
        );
    }
  }

  render() {
    return (
      <Modal
        isOpen={true}
        className='player-page'
        id='player-page'
        aria-modal={true}
        ref={this.modalRef}
        trapFocus={true}>
        {this.getTemplate()}
      </Modal>
    );
  }
}

export default ProspectsPlayerPageComponent;
