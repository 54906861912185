import React, { Component } from 'react';
import { Col, Table } from 'reactstrap';

/**
 * Components
 */
import OtherSportsStatsEventRowPlayerPageComponent from './components/event_row';

/**
 * Services
 */
import PlayersService from '../../../../../../../services/players';
import StatisticsService from '../../../../../../../services/stats';

/**
 * Styles
 */
import './styles/_index.scss';
import IconComponent from '../../../../../../common/icon';

const VALID_SPORTS = ['Track & Field'];

class OtherSportsStatsPlayerPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'Type',
      data: null,
      isLoading: true,
      player: this.props.player,
      stats: this.props.stats,
    };
  }

  componentDidMount() {
    PlayersService.getPlayerYearsForPlayer(this.state.player.id).then((response) => {
      const validSports = response.filter((playerYear) => {
        return VALID_SPORTS.indexOf(playerYear.sport.name) > -1;
      });

      if (validSports.length > 0) {
        StatisticsService.getStatsForPlayer(validSports[0].id, 'type', true).then((statRes) => {
          this.setState({
            data: statRes.metrics,
            isLoading: false,
          });
        });
      }
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.collapse === true) {
      classes += ' flip';
    }

    return classes;
  }

  generateRows() {
    return Object.keys(this.state.data).map((key) => {
      return <OtherSportsStatsEventRowPlayerPageComponent key={key} stats={this.state.data[key]} />;
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return null;
    }

    return (
      <Col md={12}>
        <div className='box less-padding other-sports'>
          <div className='stats-header other-sports__header'>
            <h2>Other Sports</h2>
          </div>
          <div className='stats-body other-sports__body'>
            <div className='other-sports__body__sport type'>
              <div className='other-sports__body__sport__header'>
                <IconComponent
                  color='purple'
                  icon='track_and_field fas fa-running'
                  label='Track and Field'
                  large={true}
                />
                Track &amp; Field
              </div>
              <div className='other-sports__body__sport__body'>
                <Table>
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Best</th>
                      <th>Date</th>
                      <th>Meet</th>
                      <th>Source</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>{this.generateRows()}</tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

export default OtherSportsStatsPlayerPageComponent;
