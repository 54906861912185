import React, { Component } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { connect } from 'react-redux';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import RivalsCampEventSingleVideoComponent from 'RootComponents/rivalsCamp/components/event-list/components/event-single/components/video';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Styles
 */
import 'RootComponents/rivalsCamp/components/event-list/styles/_index.scss';

class RivalsCampEventSingleComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: props.openList === props.event.name,
      isLoading: false,
      errorThrown: false,
    };
  }

  toggle(eventName) {
    const newCollapseState = !this.state.collapse;

    this.setState({
      collapse: newCollapseState,
    });

    if (newCollapseState === true) {
      store.dispatch({
        type: 'RIVALS_CAMP_LIST_OPENED',
        openList: eventName,
      });
    }
  }

  throwError() {
    this.setState({
      errorThrown: true,
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.collapse === true) {
      classes += ' flip';
    }

    return classes;
  }

  generateVideos() {
    if (this.state.errorThrown) {
      return <MessagingComponent message={NO_DATA_MESSAGE.videos} type={MESSAGE_TYPE.danger} />;
    }

    if (this.state.isLoading) {
      return <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />;
    }

    return this.props.event.videos.map((video) => {
      if (
        typeof video.title === 'undefined' ||
        video.title === null ||
        video.title === '' ||
        typeof video.players === 'undefined' ||
        video.players.length === 0
      ) {
        return false;
      }

      return <RivalsCampEventSingleVideoComponent video={video} key={video.id} />;
    });
  }

  render() {
    return (
      <Col className='event-single event-single__container' md={12}>
        <Button
          className='transparent'
          onClick={this.toggle.bind(this, this.props.event.name)}
          aria-expanded={this.state.collapse}>
          <span className='event-name'>{this.props.event.name}</span>
          <div className='right'>
            <IconComponent
              color='purple'
              icon='calendar fas fa-calendar-alt'
              label={this.props.event.occurred_on}
            />
            <span className='date'>{this.props.event.occurred_on}</span>
            <i className={this.getIconClasses()} />
          </div>
        </Button>
        <Collapse isOpen={this.state.collapse}>
          <Row>
            <Col md={12}>
              <Row className='box-holder'>{this.generateVideos()}</Row>
            </Col>
          </Row>
        </Collapse>
      </Col>
    );
  }
}

function mapStoreToProps(storeState) {
  return {
    openList: storeState.rivalsCamp.openList,
  };
}

export default connect(mapStoreToProps)(RivalsCampEventSingleComponent);
