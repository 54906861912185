import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FilterComponent from 'FilterComponents/common/generic';
import NestedDropdownFilterComponent from 'FilterComponents/common/nested_dropdown';
import TagListComponent from 'FilterComponents/common/tag_list';

import LocationService from 'Services/locations';
import useIsMounted from 'Utilities/hooks/useIsMounted';

const formatForDropdown = (data) => {
  return data.reduce((acc, state) => {
    const curState = {
      checked: false,
      label: state.name,
      id: state.id,
    };

    return [...acc, curState];
  }, []);
};

function StatesFilter({ id, isOpen, label, values, onChange }) {
  const [states, setStates] = useState([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    LocationService.getLocations().then((locations) => {
      const formattedStates = formatForDropdown(locations.usa);
      if (isMounted) {
        setStates(formattedStates);
      }
    });
  }, [isMounted]);

  const handleChange = (_, updated) => {
    const selected = updated.filter(({ checked }) => checked).map(({ id }) => id);
    onChange(selected);
  };

  const derivedStates = states.map((state) => {
    return {
      ...state,
      checked: values.includes(state.id),
    };
  });

  return (
    <FilterComponent id={id} label={label} collapsible={false} classes='transparent-background'>
      <NestedDropdownFilterComponent
        inputId={label}
        data={derivedStates}
        onChange={handleChange}
        placeholder='Select State'
        rootToggle='button'
        root={true}
        columns={1}
        depth={0}
      />

      <TagListComponent data={derivedStates} onChange={handleChange} />
    </FilterComponent>
  );
}

StatesFilter.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StatesFilter;
