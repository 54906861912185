import axios from 'axios';

class DivisionService {
  constructor() {
    this.currentCall = null;
  }

  getDivisions(params) {
    if (this.currentCall !== null) {
      return this.currentCall;
    }

    this.currentCall = axios({
      method: 'GET',
      url: `${process.env.API_URL}divisions/`,
      params,
      headers: {
        Authorization: JSON.stringify(JSON.parse(window.localStorage.getItem('access_token'))),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });

    return this.currentCall;
  }
}

export default new DivisionService();
