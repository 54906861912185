import axios from 'axios';

class UsersService {
  getUsers() {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}users/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.users;
    });
  }

  getUsersForAdmin(orgId, packageId, all) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}users/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params: {
        all,
        org: orgId,
        package: packageId,
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  getUser(cognitoId) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}users/${cognitoId}/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  async getLoggedInUserDBObject() {
    const accessToken = JSON.parse(window.localStorage.getItem('access_token'));

    const userInfo = await this.getUser(accessToken.payload.sub);

    return userInfo;
  }

  createUser(data) {
    // need email + role
    return axios({
      method: 'PUT',
      url: `${process.env.API_URL}users/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data,
    }).then((response) => {
      return response.data.user;
    });
  }

  deleteUser(id) {
    return axios({
      method: 'DELETE',
      url: `${process.env.API_URL}users/${id}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  async createUserOptIn() {
    const userObj = await this.getLoggedInUserDBObject();
    return axios({
      method: 'PUT',
      url: `${process.env.API_URL}users/${userObj.id}/optin`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export default new UsersService();
