import axios from 'axios';

import store from '../reducers/store';

class TwitterDataService {
  getTwitterData(id, type, filters = {}) {
    return new Promise((resolve, reject) => {
      let apiCall;
      if (typeof id !== 'undefined' && typeof type !== 'undefined') {
        apiCall = axios({
          method: 'POST',
          url: `${process.env.API_URL}players/${id}/twitter/`,
          headers: {
            Authorization: window.localStorage.getItem('access_token'),
            'X-Org': window.localStorage.getItem('organization_id'),
            'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
            'X-Username': window.localStorage.getItem('username'),
          },
          data: {
            filters,
            type,
          },
        });
      }
      apiCall
        .then((response) => {
          const recievedData = response.data.data;
          switch (type) {
            case 'twitterRankings':
              store.dispatch({
                type: 'UPDATE_TWITTER_RANKINGS',
                twitterRankings: recievedData,
              });
              break;
            case 'timeline':
              store.dispatch({
                type: 'UPDATE_TWITTER_TIMELINE',
                twitterTimeline: recievedData,
              });
              break;
            case 'likeTrends':
              store.dispatch({
                type: 'UPDATE_TWITTER_TRENDS',
                twitterTrends: recievedData,
              });
              break;
            default:
              console.log('FAILED WITH ', type);
          }

          resolve(recievedData);
        })
        .catch((err) => {
          return JSON.parse(JSON.stringify(err));
        });
    });
  }

  getTweets(twitterHandle) {
    return new Promise((resolve, reject) => {
      const apiCall = axios({
        method: 'GET',
        url: `${process.env.API_URL}twitter/tweets`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
        params: { twitterHandle: twitterHandle.toLowerCase() },
      });
      apiCall.then((response) => {
        resolve(response.data.data);
      });
    });
  }

  getStream(playerGuid) {
    return new Promise((resolve, reject) => {
      const apiCall = axios({
        method: 'GET',
        url: `${process.env.API_URL}twitter/stream`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
        params: { playerGuid },
      });
      apiCall
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err);
          resolve([]);
        });
    });
  }
}

export default new TwitterDataService();
