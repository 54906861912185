import React from 'react';

function Checkbox({
  id,
  value = '',
  className = '',
  label = '',
  checked = false,
  labelProps = {},
  parentProps = {},
  ...restCheckboxProps
}) {
  const { className: parentClassName, ...restParentProps } = parentProps;
  const { className: labelClassName, ...restLabelProps } = labelProps;

  return (
    <div className={`custom-checkbox ${parentClassName}`} {...restParentProps}>
      <label htmlFor={id} {...restLabelProps}>
        <input
          id={id}
          value={value}
          checked={checked}
          className={className}
          type='checkbox'
          {...restCheckboxProps}
        />
        <span className={labelClassName}>{label}</span>
      </label>
    </div>
  );
}

export default Checkbox;
