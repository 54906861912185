const initialState = {
  orgChanged: false,
};

export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case 'ORGANIZATION_CHANGED':
      return Object.assign({}, state, {
        orgChanged: true,
      });
    default:
      return state;
  }
}
