import React from 'react';
import ClickableTooltip from 'RootComponents/common/clickableTooltip';

function TimelineMark({ markValues, markType, markId, markBgColor }) {
  const getIconClassesByType = (type) => {
    switch (type) {
      case 'follower':
        return 'fas fa-level-down-alt fa-rotate-90';
      case 'following':
        return 'fas fa-level-up-alt fa-rotate-90';
      case 'like':
        return 'fas fa-heart';
      case 'mention':
        return 'fas fa-at';
      case 'retweet':
        return 'fas fa-retweet';
      default:
        return type;
    }
  };
  return (
    <div className='single' id={markId}>
      {markValues.length > 0 && (
        <ClickableTooltip
          targetId={`visit-${markId}`}
          key={`visit-${markId}-clickable-tooltip`}
          placement='bottom'
          target={
            <div key={`visit-${markId}-target`} style={{ backgroundColor: markBgColor }}>
              {markValues.length > 1 && <span>{markValues.length}</span>}
              {markValues.length === 1 && markType && (
                <i className={`icon white ${getIconClassesByType(markType)}`} />
              )}
            </div>
          }
          tooltip={markValues}
          parentDivStyle={{ backgroundColor: markBgColor }}
        />
      )}
    </div>
  );
}

export default TimelineMark;
