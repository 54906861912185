import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import ErrorPageComponent from 'PlayerPageComponents/pages/error';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
  MESSAGE_TYPE,
} from 'CommonComponents/messager';
import AcademicMetricViewPlayerPageComponent from 'PlayerPageComponents/pages/academics/components/singleMetric';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import PlayersService from 'Services/players';
import AcademicsService from 'Services/academics';

import generatePageTitles from 'RootComponents/utility/pageTitles';

/**
 * Styles
 */
import 'PlayerPageComponents/pages/academics/styles/_index.scss';

class AcademicsPlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: true,
      isLoading: true,
      format: 'type',
      activeTab: 'academics',
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;

    const players = PlayersService.getPlayerAsPromise(playerId);
    const academics = AcademicsService.getAcademicsForPlayer(playerId);

    Promise.all(
      [players, academics].map((p) => {
        return p.catch((e) => {});
      })
    ).then(
      (responses) => {
        if (typeof responses[0] === 'undefined') {
          throw new Error('We could not find the requested player');
        }

        document.title = generatePageTitles(`${responses[0].name} Academics`);

        if (typeof responses[1] === 'undefined') {
          return this.setState({
            error: true,
            isLoading: false,
            player: responses[0],
            academics: [],
          });
        }

        this.setState({
          isLoading: false,
          player: responses[0],
          academics: responses[1],
        });
      },
      (error) => {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    );
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div className='modal-wrapper' style={{ width: '90%', margin: '50px auto' }}>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </div>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <div className='modal-wrapper'>
          <ErrorPageComponent
            activeTab='academics'
            message={NO_DATA_MESSAGE.academics}
            page={this.props.page}
            player={this.state.player}
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
            title='Academics'
          />
        </div>
      );
    }

    return (
      <div className='modal-wrapper'>
        <PlayerPageModalHeaderComponent
          player={this.state.player}
          page={this.props.page}
          activeTab={this.state.activeTab}
          toggleModal={this.props.toggleModal}
          setTab={this.props.setTab}
        />
        <div className='modal-body'>
          <Row className='secondary-page academics-page'>
            <Col md={12} className='box-holder'>
              <Row>
                <AcademicMetricViewPlayerPageComponent
                  academics={this.state.academics}
                  type='gpa'
                />
                <AcademicMetricViewPlayerPageComponent
                  academics={this.state.academics}
                  type='sat'
                />
                <AcademicMetricViewPlayerPageComponent
                  academics={this.state.academics}
                  type='act'
                />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AcademicsPlayerPageComponent;
