import { Component } from 'react';
import * as _ from 'underscore';

/**
 * Stores
 */
import store from '../../../reducers/store';

class CommonComponent extends Component {
  /**
   * Compare incoming props and state to see if we should re-render
   *
   * @param instance
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shallowCompare(instance, nextProps, nextState) {
    return (
      _.isEqual(instance.props, nextProps) === false ||
      _.isEqual(instance.state, nextState) === false
    );
  }

  onChange(values) {
    store.dispatch({
      type: this.state.filter_action_type,
      filter: {
        activity_request_level: this.state.activity_request_level,
        type: this.state.id,
        values,
      },
    });
  }

  passIsOpenToParent(isOpen) {
    this.setState(
      {
        isOpen,
        toggleClicked: true,
      },
      () => {
        store.dispatch({
          type: 'SET_CLOSE_FILTER',
          closeFilter: false,
        });
      }
    );
  }
}

export default CommonComponent;
