import React from 'react';
import { DateTime } from 'luxon';

import MultiSelectFilterComponent from 'FilterComponents/common/multi_select';
import CommonComponent from 'CommonComponents/generic';
import store from 'Reducers/store';

import 'FilterComponents/specific/years/styles/_index.scss';

const CURRENT_YEAR = DateTime.utc().plus({ months: 4 }).year;

class YearsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      activity_request_level: this.props.activity_request_level || '',
      filter_action_type: this.props.filter_action_type || 'FILTER_UPDATED',
      years: this.getYears(CURRENT_YEAR),
      isOpen: this.getOpen(),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  getOpen() {
    if (this.props.isOpen || this.props.values?.length) return true;

    return false;
  }

  getYears(currentYear) {
    const doubleDigitYear = currentYear % 100;
    const years = [{ id: 'all', name: 'All' }];

    for (let i = currentYear; i < currentYear + 4; i++) {
      years.push({
        id: i,
        name: i,
      });
    }
    years.push({ id: `tr${doubleDigitYear}`, name: `TR${doubleDigitYear}` });

    return years;
  }

  onChange(values) {
    store.dispatch({
      type: this.state.filter_action_type,
      filter: {
        activity_request_level: this.state.activity_request_level,
        type: 'years',
        values,
      },
    });
  }

  render() {
    return (
      <MultiSelectFilterComponent
        id='years'
        label='Years'
        classes='years'
        collapseClass='third'
        isOpen={this.state.isOpen}
        values={this.state.years}
        activity_request_level={this.state.activity_request_level}
        filter_action_type={this.state.filter_action_type}
        selected={this.props.values}
        closeFilter={this.props.closeFilter}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

export default YearsFilterComponent;
