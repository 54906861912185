import React, { Component } from 'react';

/**
 * Import
 */
import IconComponent from '../../../../../../../../common/icon';

class OtherSportsStatsEventRowPlayerPageComponent extends Component {
  constructor(props) {
    super(props);

    this.convertToMinutes = ['4 x 200 Meter', '4 x 400 Meter'];

    if (typeof this.props.stats === 'undefined' || this.props.stats.length === 0) {
      return;
    }

    const sortLowestMarkers = ['m', 's'];

    this.state = {
      active: 'Type',
      isLoading: true,
      player: this.props.player,
      stats: this.props.stats.sort((a, b) => {
        if (a.value > b.value) {
          return sortLowestMarkers.indexOf(this.props.stats[0].unit) > -1 ? 1 : -1;
        }

        if (a.value < b.value) {
          return sortLowestMarkers.indexOf(this.props.stats[0].unit) > -1 ? -1 : 1;
        }

        return 0;
      }),
    };
  }

  toggle() {
    this.setState({
      opened: !this.state.opened,
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.opened === true) {
      classes += ' flip';
    }

    return classes;
  }

  convertSecondsToMinutes(value) {
    const valueFloat = parseFloat(value);
    const seconds = valueFloat % 60;
    const minutes = parseInt(valueFloat / 60, 10);
    return `${minutes}:${seconds.toFixed(2)}`;
  }

  generateRow() {
    let event = this.state.stats[0].event;

    const html = [
      <tr className='odd' key={this.state.stats[0].id}>
        <td>{this.state.stats[0].label}</td>
        <td>
          {this.convertToMinutes.includes(this.state.stats[0].label)
            ? this.convertSecondsToMinutes(this.state.stats[0].value)
            : this.state.stats[0].value}
        </td>
        <td>{event ? event.occurred_on : '-'}</td>
        <td>{event ? event.name : '-'}</td>
        <td>
          <IconComponent color='grey' icon='athletic_net' label='Athletic.net' />
        </td>
        <td>
          <span
            onClick={this.toggle.bind(this)}
            onKeyUp={this.toggle.bind(this)}
            role='button'
            tabIndex={0}>
            <i className={this.getIconClasses()} />
          </span>
        </td>
      </tr>,
    ];

    if (this.state.opened === true) {
      this.state.stats.forEach((stat, i) => {
        if (i === 0) {
          return;
        }

        event = stat.event;

        const evenOdd = i % 2 === 0 ? 'even no-border' : 'odd no-border';

        html.push(
          <tr className={evenOdd} key={stat.id}>
            <td>&nbsp;</td>
            <td>
              {this.convertToMinutes.includes(stat.label)
                ? this.convertSecondsToMinutes(stat.value)
                : stat.value}
            </td>
            <td>{event ? event.occurred_on : '-'}</td>
            <td>{event ? event.name : '-'}</td>
            <td>
              <IconComponent color='grey' icon='athletic_net' label='Athletic.net' />
            </td>
            <td>&nbsp;</td>
          </tr>
        );
      });
    }

    return html;
  }

  render() {
    return this.generateRow();
  }
}

export default OtherSportsStatsEventRowPlayerPageComponent;
