import React from 'react';
import { useContactInfoContext } from '../contactInfo';
import ContactInfoBody from '../contactInfoBody';
import { ContactInfoValue, ContactInfoPhoneNumbers, ContactInfoLocation } from '../contactInfoMisc';
import {
  ContactInfoBodyPhoneItem,
  ContactInfoBodyEmailItem,
  ContactInfoBodyAddressItem,
  ContactInfoBodyBasicItem,
} from '../contactInfoBodyItem';

function CoachContactInfoBody() {
  const { contact } = useContactInfoContext();
  return (
    <ContactInfoBody>
      <ContactInfoBodyBasicItem>
        <ContactInfoValue value={contact.email} />
      </ContactInfoBodyBasicItem>
      <ContactInfoBodyEmailItem>
        <ContactInfoValue value={contact.email} />
      </ContactInfoBodyEmailItem>
      <ContactInfoBodyPhoneItem>
        <ContactInfoPhoneNumbers cellPhone={contact.cell_phone} homePhone={contact.home_phone} />
      </ContactInfoBodyPhoneItem>
      <ContactInfoBodyAddressItem>
        <ContactInfoLocation location={contact.location} address={contact.address} />
      </ContactInfoBodyAddressItem>
    </ContactInfoBody>
  );
}

export default CoachContactInfoBody;
