import React, { Component } from 'react';

import DropdownCheckboxFilterComponent from 'CommonComponents/dropdownCheckbox';
import ConferenceService from 'Services/conferences';

class TwitterSchoolsFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { schools: [] };
  }

  componentDidMount() {
    ConferenceService.getConferences().then((conferences) => {
      this.setState({
        conferences: this._formatForDropdown(conferences),
        isLoading: false,
      });
    });
  }

  _formatForDropdown(conferences) {
    const final = [];

    conferences.forEach((conference) => {
      const curConf = {
        children: [
          {
            id: 'all',
            name: 'All',
            value: `all-${conference.id}`,
            highlight: true,
          },
        ],
        value: conference.name,
        name: conference.name,
        id: conference.id,
      };

      conference.colleges.forEach((child) => {
        const collegeEntry = {
          value: child.college_sport_id,
          name: child.name,
          id: child.college_sport_id,
        };
        curConf.children.push(collegeEntry);
      });
      if (curConf.children.length > 0) {
        final.push(curConf);
      }
    });

    this.setState({
      schools: [
        {
          id: 'all',
          name: 'All',
          value: 'all',
          checked: true,
        },
        {
          id: 'offered-only',
          highlight: true,
          name: 'Offered Only',
          value: 'offered-only',
          exclusive: true,
        },
      ].concat(final),
    });
    return final;
  }

  filterChanged(value) {
    this.props.handleFilterChange({
      type: 'school',
      values: value,
    });
  }

  render() {
    return (
      <div className='twitter-filters-filter'>
        <DropdownCheckboxFilterComponent
          columns={1}
          filterChanged={this.filterChanged.bind(this)}
          label='Schools'
          values={this.state.schools}
        />
      </div>
    );
  }
}

export default TwitterSchoolsFilterComponent;
