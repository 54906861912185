import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';

import reducer from './general';

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
  // this works with dev tools
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // or can npm install redux-devtools-extension
);

export default store;
