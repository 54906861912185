import React from 'react';

const METRIC_TYPES = {
  height: 'height',
  weight: 'weight',
  forty: 'forty',
  shuttle: 'shuttle',
  verticalJump: 'verticalJump',
  broadJump: 'broadJump',
  threeCone: 'threeCone',
  powerBall: 'powerBall',
  sparq: 'sparq',
  wingspan: 'wingspan',
  armLength: 'armLength',
  reach: 'reach',
  handSize: 'handSize',
  velocity: 'velocity',
  spinRate: 'spinRate',
  spiralEfficiency: 'spiralEfficiency',
  releaseTime: 'releaseTime',
  npRating: 'npRating',
  gpa: 'gpa',
  composite247: 'composite247',
  jersey: 'jersey',
};

const METRIC_TYPE_SUB_SOURCES = {
  wilson: 'wilson',
};

const METRIC_TYPE_SUB_SOURCES_INFO = {
  wilson: () => {
    return (
      <>
        The throw data was captured by Wilson Sporting Goods. Metrics were collected through a
        variety of drills during the Wilson QBX camps where players threw footballs using Wilson X
        Connected QB tracking technology embedded in each ball. More information can be found here -
        <a href='https://www.wilson.com/en-us/explore/labs/connected-football-system'>
          https://www.wilson.com/en-us/explore/labs/connected-football-system
        </a>
      </>
    );
  },
};

const IGNORABLE_METRIC_TYPES_LIST = [
  METRIC_TYPES.npRating,
  METRIC_TYPES.gpa,
  METRIC_TYPES.composite247,
  METRIC_TYPES.jersey,
];

const VALID_METRIC_TYPES_LIST = Object.values(METRIC_TYPES).filter((s) => {
  return !IGNORABLE_METRIC_TYPES_LIST.includes(s);
});

const IGNORABLE_METRIC_SOURCES = ['backfill', 'rivals'];

export {
  METRIC_TYPES,
  METRIC_TYPE_SUB_SOURCES,
  METRIC_TYPE_SUB_SOURCES_INFO,
  VALID_METRIC_TYPES_LIST,
  IGNORABLE_METRIC_TYPES_LIST,
  IGNORABLE_METRIC_SOURCES,
};
