import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from 'Reducers/store';

/**
 * Components
 */
import DropdownComponent from 'CommonComponents/dropdown';
import VerifiedIcon from 'CommonComponents/verifiedIcon';
import InfoSources from 'PlayerPageComponents/modal/basicInfo/components/infoSources';
import { isTimeValidVerifiedMetric } from 'RootComponents/utility/helpers';

/**
 * Services
 */
import PlayersService from 'Services/players';
import StatusesService from 'Services/statuses';

/**
 * Styles
 */
import 'PlayerPageComponents/modal/basicInfo/styles/_index.scss';

class PlayerPageModalBasicInfoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: this.props.player.hidden === true,
      sources: [],
      statusLoaded: false,
    };
  }

  componentDidMount() {
    StatusesService.get()
      .then((response) => {
        const statuses = response.map((status) => {
          return {
            id: status.name,
            label: status.name,
          };
        });

        this.setState({
          statuses,
          statusLoaded: true,
        });
      })
      .catch((error) => {});

    PlayersService.getPlayerYearSources(this.props.player.id)
      .then((results) => {
        this.setState({
          sources: results,
        });
      })
      .catch((error) => {});
  }

  trackPlayer(player, value) {
    const status = {
      status: {
        name: value.label,
      },
    };
    PlayersService.trackPlayer(player.id, status).then(
      (result) => {
        this.setState({
          hidden: false,
        });

        store.dispatch({
          type: 'TRACK_PLAYER',
          player: result,
        });

        this.props.updatePlayer(result);
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  valueSelected(player, value) {
    // this can be either changing a status
    // or tracking a player in discover from the modal
    if (!player.status) {
      this.trackPlayer(player, value);
      return;
    }
    player.status = {
      name: value.label,
    };

    const status = {
      status: {
        name: value.label,
      },
    };
    PlayersService.changeStatus(player.id, status).then(
      (result) => {
        this.setState({
          hidden: false,
        });

        this.props.updatePlayer(result);
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  hidePlayer() {
    PlayersService.hidePlayer(this.props.player.id).then(
      (result) => {
        this.setState({
          hidden: true,
        });

        store.dispatch({
          type: 'HIDE_PLAYER',
          player: result,
        });

        this.props.updatePlayer(result);
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  unhidePlayer() {
    PlayersService.unhidePlayer(this.props.player.id, true).then(
      (result) => {
        this.setState({
          hidden: false,
        });

        store.dispatch({
          type: 'UN_HIDE_PLAYER',
          player: result,
        });

        this.props.updatePlayer(result);
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  getHideOrUnhide() {
    if (this.state.hidden === false) {
      return (
        <li>
          <span
            onClick={this.hidePlayer.bind(this)}
            onKeyUp={this.hidePlayer.bind(this)}
            role='button'
            tabIndex={-1}>
            Hide Player
          </span>
        </li>
      );
    }

    return (
      <li>
        <span
          onClick={this.unhidePlayer.bind(this)}
          onKeyUp={this.unhidePlayer.bind(this)}
          role='button'
          tabIndex={-1}>
          Unhide Player
        </span>
      </li>
    );
  }

  getVerified(type) {
    const { verified, date } = this.props.player[type];
    const isVerified = verified && isTimeValidVerifiedMetric(date);
    return <VerifiedIcon verified={isVerified} />;
  }

  getImage(imageUrl) {
    if (typeof imageUrl === 'undefined' || imageUrl === null || imageUrl.indexOf('http') === -1) {
      imageUrl = 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
    }

    return imageUrl;
  }

  getYearTransferInfo() {
    if (this.props.player.transfer) {
      const { eligibility } = this.props.player.transfer;
      return `Transfer (${eligibility} Eligibility)`;
    }

    return `Class of ${this.props.player.year}`;
  }

  render() {
    return (
      <Row className='basic-info-row'>
        <Col md={12} className='basic-info'>
          <div className='no-skew'>
            <div className='image-holder'>
              <div className='image'>
                <img
                  src={this.getImage(this.props.player.image_url)}
                  alt={this.props.player.name}
                  title={this.props.player.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
                  }}
                />
              </div>
              <InfoSources player={this.props.player} sources={this.state.sources} />
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <div className='info' role='document' aria-label={this.props.player.name} tabIndex='0'>
              <div className='name-container'>
                <h2>{this.props.player.name}</h2>
              </div>
              <p>{this.getYearTransferInfo()}</p>
              {this.state.statusLoaded === true && (
                <ul className='actions'>
                  <li>
                    <DropdownComponent
                      values={this.state.statuses}
                      defaultLabel='Track Player'
                      labelPrefix='Tracked: '
                      value={this.props.player.getStatus()}
                      onValueSelection={this.valueSelected.bind(this, this.props.player)}
                    />
                  </li>
                  {this.getHideOrUnhide()}
                </ul>
              )}
              <ul className='secondaryInformation'>
                <li>
                  <h3>Position</h3>
                  <p>{this.props.player.getPosition()}</p>
                </li>
                <li>
                  <h3>Height</h3>
                  <p>
                    {this.props.player.getMeasurableStat('height')}
                    {this.getVerified('height')}
                  </p>
                </li>
                <li>
                  <h3>Weight</h3>
                  <p>
                    {this.props.player.getMeasurableStat('weight')}
                    {this.getVerified('weight')}
                  </p>
                </li>
                <li>
                  <h3>School</h3>
                  <p>
                    {this.props.player.getCorrectHighSchool()}
                    {this.props.player.getCorrectLocationForHighSchool()}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PlayerPageModalBasicInfoComponent;
