import React, { Component } from 'react';

import classNames from 'classnames';

class IconComponent extends Component {
  getParentHolderClasses() {
    const classes = {
      'icon-holder': true,
    };

    if (typeof this.props.color !== 'undefined') {
      classes[`${this.props.color}-bg`] = true;
    }

    if (typeof this.props.large !== 'undefined') {
      classes.large = this.props.large === true;
    }

    if (typeof this.props.shift_right !== 'undefined') {
      classes.right = this.props.shift_right === true;
    }

    return classNames(classes);
  }

  getIconClasses() {
    const classes = {
      icon: true,
    };

    if (typeof this.props.icon !== 'undefined') {
      classes[this.props.icon] = true;
    }

    return classNames(classes);
  }

  generateIcon() {
    if (typeof this.props.link !== 'undefined' && this.props.link !== null) {
      return (
        <a
          href={this.props.link}
          target='_blank'
          rel='noopener noreferrer'
          title={this.props.link_title}>
          <i className={this.getIconClasses()} />
        </a>
      );
    }

    return <i className={this.getIconClasses()} />;
  }

  generateImage() {
    return (
      <a
        href={this.props.link}
        target='_blank'
        rel='noopener noreferrer'
        title={this.props.link_title}>
        <img
          src='https://i0.wp.com/ras.football/wp-content/uploads/2022/10/cropped-ras-logo-full-white-1.png'
          alt='Relative Athletic Score'
          style={{ height: '8px', background: 'black' }}
        />
      </a>
    );
  }

  render() {
    if (typeof this.props.as_button !== 'undefined' && this.props.as_button === true) {
      return (
        <div
          className={this.getParentHolderClasses()}
          id={this.props.id}
          onClick={this.props.onClick}
          onKeyUp={this.props.onKeyUp}
          role='button'
          tabIndex={this.props.tabIndex}
          title={this.props.label}>
          {this?.props?.link?.includes('zcruit-ras-card')
            ? this.generateImage()
            : this.generateIcon()}
        </div>
      );
    }

    return (
      <div
        className={this.getParentHolderClasses()}
        id={this.props.id}
        title={this.props.label}
        style={this?.props?.link?.includes('zcruit-ras-card') ? { background: 'black' } : {}}>
        {this?.props?.link?.includes('zcruit-ras-card')
          ? this.generateImage()
          : this.generateIcon()}
      </div>
    );
  }
}

export default IconComponent;
