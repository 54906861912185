import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

/**
 * Components
 */
import MessagingComponent, { ERROR_MESSAGE, MESSAGE_TYPE } from 'CommonComponents/messager';
import PlayerListByPosition from 'RootComponents/rivalsCamp/components/singleVideo/components/playerListByPosition';
import NameFilterComponent from 'RootComponents/rivalsCamp/components/filters/name';

/**
 * Styles
 */
import 'RootComponents/rivalsCamp/components/singleVideo/components/videoFeaturedPlayers/styles/_index.scss';

class VideoFeaturedPlayersComponent extends Component {
  constructor(props) {
    super(props);

    this.shareLink = React.createRef();
    this.state = {
      errorThrown: false,
      isLoading: true,
      filterString: '',
    };
  }

  filterChanged(type, value) {
    this.setState({
      filterString: value,
    });
    this.splitPlayersByPosition(this.props.players);
  }

  filterPlayers(players) {
    const downcasedFilterString = this.state.filterString.toLowerCase();

    return players.filter((player) => {
      if (typeof downcasedFilterString === 'undefined' || downcasedFilterString === '') {
        return true;
      }

      if (
        typeof player.name !== 'undefined' &&
        player.name.toLowerCase().includes(downcasedFilterString)
      ) {
        return true;
      }

      if (
        typeof player.jersey !== 'undefined' &&
        player.jersey.value.includes(downcasedFilterString)
      ) {
        return true;
      }

      return false;
    });
  }

  splitPlayersByPosition(players, position) {
    const filteredPlayers = this.filterPlayers(players);

    return filteredPlayers.filter((player) => {
      return (
        typeof player.positions.find((pos) => {
          return (
            parseInt(pos.priority, 10) === 1 &&
            (position.indexOf(pos.id) > -1 ||
              pos.groupings.filter((grouping) => {
                return position.indexOf(grouping.id) > -1;
              }).length > 0)
          );
        }) !== 'undefined'
      );
    });
  }

  render() {
    if (this.state.errorThrown === true) {
      return <MessagingComponent message={ERROR_MESSAGE.filmDetails} type={MESSAGE_TYPE.danger} />;
    }

    if (this.props.players.length <= 1) {
      return null;
    }

    return (
      <Row className='video-multiplayer-list'>
        <Col md='12'>
          <Col className='jersey-filter-input' md={{ offset: 3, size: 6 }}>
            <NameFilterComponent
              filterChanged={this.filterChanged.bind(this, 'name-jersey')}
              placeholder='Search Jersey Number or Player Name'
              name={this.state.filterString}
            />
          </Col>
          <Row>
            <PlayerListByPosition
              players={this.splitPlayersByPosition(
                this.props.players,
                this.props.tiedPositions.firstPosition.ids
              )}
              title={this.props.tiedPositions.firstPosition.name}
              togglePlayerModal={this.props.togglePlayerModal}
            />
            {typeof this.props.tiedPositions.secondPosition !== 'undefined' && (
              <PlayerListByPosition
                players={this.splitPlayersByPosition(
                  this.props.players,
                  this.props.tiedPositions.secondPosition.ids
                )}
                title={this.props.tiedPositions.secondPosition.name}
                togglePlayerModal={this.props.togglePlayerModal}
              />
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default VideoFeaturedPlayersComponent;
