import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

import CommitsService from '../../../../../../../../services/commits';
import DateTimeService, { DATE_DEFAULTS } from '../../../../../../../../services/date-time';

import './styles/_index.scss';

function OfferClickableTooltip(props) {
  const dateTimeService = new DateTimeService();
  const [commitCount, setCommitCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (props.targetId !== event.target.id) {
        setIsOpen(false);
        setLoaded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
  });

  const loadOffer = () => {
    if (
      !isOpen &&
      typeof props.player.positions !== 'undefined' &&
      props.player.positions.length > 0
    ) {
      const query = {
        position: props.player.positions[0].id,
        year: props.player.year,
      };
      CommitsService.getCommitsForPlayerPosition(props.offer.college.college_sport_id, query)
        .then((response) => {
          setCommitCount(response);
          setLoaded(true);
          setIsOpen(true);
        })
        .catch(() => {
          setCommitCount(0);
          setLoaded(true);
          setIsOpen(true);
        });
    }
  };

  const handleClick = (event) => {
    if (
      props.targetId === event.target.id ||
      props.targetId === event.target.parentNode.id ||
      props.targetId === event.target.parentNode.parentNode.id
    ) {
      loadOffer();
    } else {
      setIsOpen(false);
      setLoaded(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && props.targetId === event.target.id) {
      loadOffer();
    } else {
      setIsOpen(false);
      setLoaded(false);
    }
  };

  let positionName = null;

  if (typeof props.player.positions !== 'undefined' && props.player.positions.length > 0) {
    positionName = props.player.positions[0].groupings[0]
      ? props.player.positions[0].groupings[0].label
      : props.player.positions[0].name;
  }

  return (
    <div
      className='offer-clickable-tooltip-container'
      tabIndex='0'
      id={props.targetId}
      key={`${props.targetId}-container`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='button'>
      {props.children}
      <Tooltip
        key={`${props.targetId}-tooltip`}
        autohide={true}
        placement='top'
        isOpen={isOpen}
        target={props.targetId}
        className='tooltip-content offer-tooltip'
        boundariesElement='viewport'>
        <div className='offer-clickable-tooltip'>
          <p className='school'>{props.offer.college.name}</p>
          <p>
            {`Offered on ${dateTimeService.formatUTCDate(
              new Date(props.offer.date),
              DATE_DEFAULTS.format.default
            )}`}
          </p>
          {positionName !== null && (
            <p>
              {loaded && `${commitCount} ${positionName} Commit${commitCount === 1 ? '' : 's'}`}
            </p>
          )}
        </div>
      </Tooltip>
    </div>
  );
}

OfferClickableTooltip.propTypes = {
  targetId: PropTypes.string.isRequired,
  player: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
};

export default OfferClickableTooltip;
