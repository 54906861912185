import React from 'react';
import { Button, Col, Row, Modal } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import store from 'Reducers/store';

import ZcruitComponent from 'CommonComponents/zcruit';
import PageContentHeader from 'CommonComponents/accountHeader';
import TableList from 'RootComponents/account/components/manageLists/components/tableList';
import SidebarFilters from 'CommonComponents/filters/sidebarFilters';
import FilterSlideContainer from 'CommonComponents/filterSlideContainer';
import PlayerSearchFilterComponent from 'FilterComponents/specific/player_search';
import MessagingComponent, {
  MESSAGE_TYPE,
  STATUS_MESSAGE,
  ERROR_MESSAGE,
} from 'CommonComponents/messager';

import FilterService from 'Services/filters';
import AuthenticationService from 'Services/authentication';

import 'RootComponents/account/components/manageLists/styles/_index.scss';

class AccountComponent extends ZcruitComponent {
  constructor(props) {
    super(props);
    this.addNewListButtonRef = React.createRef();

    this.state = {
      deleteModal: false,
      deleteTarget: {},
      drawerOpened: false,
      drawerOpening: false,
      editMode: false,
      error: false,
      filters: [],
      isLoading: true,
      selectedFilterGroup: '',
      listName: '',
      listId: null,
      editModalTrigger: null,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (
      typeof nextProps.filter.filter !== 'undefined' &&
      typeof nextProps.filter.filter.players !== 'undefined' &&
      nextProps.filter.filter.players.length > 0
    ) {
      state.selectedFilterGroup = 'prospects';
    } else {
      state.selectedFiterGroup = 'filters';
    }

    return state;
  }

  toggleEditModal(filter, filterType, triggerBtnRef) {
    this.setState(
      {
        drawerOpened: true,
        listName: filter.name,
        listId: filter.id,
        filterType,
        selectedFilterGroup: filter.filter.players ? 'prospects' : 'filters',
        editMode: true,
        editModalTrigger: triggerBtnRef,
      },
      () => {
        store.dispatch({
          type: 'UPDATE_FROM_EXISTING_FILTER',
          filter: filter.filter,
        });
        if (this.state.drawerOpened) {
          this.listNameInput.focus();
        }
      }
    );
  }

  changeListName(listName) {
    this.setState({
      listName,
    });
  }

  changeSelectedFilterGroup(selectedFilterGroup) {
    this.setState({
      selectedFilterGroup,
    });
  }

  toggleDeleteModal(filterId = null) {
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteTarget: {
        filterId,
      },
    });
  }

  deleteHandler() {
    const deletePromise = FilterService.deleteFilter(this.state.deleteTarget.filterId);
    deletePromise.then((response) => {
      if (response.status === 200) {
        this.removeFilterFromState(this.state.deleteTarget.filterId);

        this.toggleDeleteModal();
      } else {
        console.log(`${this.state.deleteTarget} has failed deletion`);
      }
    });
  }

  addFilterToState(filterObj) {
    // @TODO: update to not need refresh
    this.closeDrawer();

    this.getUserFilters();
  }

  removeFilterFromState(filterId) {
    const i = this.state.filters.findIndex((filterObj) => {
      return filterObj.id === filterId;
    });

    if (i === -1) {
      console.log('could not find the filter');
      return;
    }

    const updatedFilters = this.state.filters;
    updatedFilters.splice(i, 1);

    this.setState({
      filters: updatedFilters,
    });
  }

  getUserFilters() {
    this.setState({
      error: false,
      isLoading: true,
    });

    FilterService.getFilters(2)
      .then((response) => {
        const filters = response.data;
        this.setState({
          drawerOpened: false,
          isLoading: false,
          filters,
        });
      })
      .catch((err) => {
        if (err.code === 404) {
          this.setState({
            drawerOpened: false,
            isLoading: false,
            filters: [],
          });
        } else {
          this.setState({
            error: true,
            isLoading: false,
          });
        }
      });
  }

  componentDidMount() {
    this.getUserFilters();
  }

  openDrawer(type, editMode = false, clearList = false) {
    if (clearList) {
      store.dispatch({
        type: 'CLEAR_ACCOUNT_FILTERS',
      });
      this.setState({
        listName: '',
        selectedFilterGroup: '',
      });
    }
    this.setState(
      {
        drawerOpening: true,
        editMode,
        filterType: type,
        editModalTrigger: this.addNewListButtonRef,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              drawerOpening: false,
              drawerOpened: true,
            },
            () => {
              this.listNameInput.focus();
            }
          );
        }, 550);
      }
    );
  }

  closeDrawer() {
    this.setState(
      {
        drawerOpening: true,
        listName: '',
      },
      () => {
        this.setState(
          {
            drawerOpening: false,
            drawerOpened: false,
          },
          () => {
            store.dispatch({
              type: 'CLEAR_ACCOUNT_FILTERS',
            });
            this.state.editModalTrigger.current.focus(); // returns focus to triggering element on close
          }
        );
      }
    );
  }

  checkSelected(buttonName) {
    if (buttonName === this.state.selectedFilterGroup) {
      return true;
    }

    return false;
  }

  handleListNameChangeFromInput(e) {
    const newVal = e.target.value;
    this.setState({
      listName: newVal,
    });
  }

  getCorrectFilterSet() {
    return (
      <FilterSlideContainer
        addFilterToState={this.addFilterToState.bind(this)}
        drawerOpened={this.state.drawerOpened}
        drawerOpening={this.state.drawerOpening}
        openDrawer={this.openDrawer.bind(this)}
        closeDrawer={this.closeDrawer.bind(this)}
        editMode={this.state.editMode}
        location='account'
        listName={this.state.listName}
        listId={this.state.listId}
        filterType={this.state.filterType}>
        <div className='filter' aria-hidden={!this.state.drawerOpened}>
          <label className='label-header' htmlFor='list-name'>
            Name Your List
          </label>
          <div className='input'>
            <input
              value={this.state.listName}
              id='list-name'
              onChange={this.handleListNameChangeFromInput.bind(this)}
              ref={(input) => {
                this.listNameInput = input;
              }}
              type='text'
              required
              hidden={!this.state.drawerOpened}
              disabled={!this.state.drawerOpened}
            />
          </div>
        </div>
        {this.getFilterGroupSelectionButtons()}
        {this.getFilterInputs()}
      </FilterSlideContainer>
    );
  }

  getFilterGroupSelectionButtons() {
    const classes = classNames({
      btn: true,
      'type-selector': true,
    });

    const filterColor = this.checkSelected('filters') === true ? 'primary' : 'secondary';
    const prospectColor = this.checkSelected('prospects') === true ? 'primary' : 'secondary';
    return (
      <div className='filter' role='radiogroup' aria-labelledby='type-of-list'>
        <span className='label-header' id='type-of-list'>
          What type of list?
        </span>

        <Button
          className={classes}
          color={filterColor}
          onClick={this.changeSelectedFilterGroup.bind(this, 'filters')}
          role='radio'
          aria-checked={this.state.selectedFilterGroup === 'filters'}
          hidden={!this.state.drawerOpened}
          disabled={!this.state.drawerOpened}>
          Using Filters
        </Button>
        <Button
          className={classes}
          color={prospectColor}
          onClick={this.changeSelectedFilterGroup.bind(this, 'prospects')}
          role='radio'
          aria-checked={this.state.selectedFilterGroup === 'prospects'}
          hidden={!this.state.drawerOpened}
          disabled={!this.state.drawerOpened}>
          With Specific Prospects
        </Button>
      </div>
    );
  }

  getFilterInputs() {
    if (this.state.selectedFilterGroup === 'filters') {
      return (
        <SidebarFilters
          filter={this.props.filter}
          closeFilter={this.props.closeFilter}
          isRivalsOnly={AuthenticationService.isRivalsOnly()}
        />
      );
    }

    if (this.state.selectedFilterGroup === 'prospects') {
      return (
        <div>
          <PlayerSearchFilterComponent
            id='players'
            values={this.props.filter.players}
            closeFilter={this.props.closeFilter}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <Row>
          <PageContentHeader tab='tab_lists' />
          <Col md={{ size: 8, offset: 2 }} className='page-header-container box-container'>
            <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
          </Col>
        </Row>
      );
    }

    if (this.state.error === true) {
      return (
        <Row>
          <PageContentHeader tab='tab_lists' />
          <Col md={{ size: 8, offset: 2 }} className='page-header-container box-container'>
            <MessagingComponent message={ERROR_MESSAGE.lists} type={MESSAGE_TYPE.danger} />
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <PageContentHeader tab='tab_lists' />
        {this.getCorrectFilterSet()}
        <Col className='box-container' md={{ size: 8, offset: 2 }}>
          <TableList
            filters={this.state.filters}
            title='Lists'
            openDrawer={this.openDrawer.bind(this)}
            toggleEditModal={this.toggleEditModal.bind(this)}
            toggleDeleteModal={this.toggleDeleteModal.bind(this)}
            filterType='prospects'
            addNewListButtonRef={this.addNewListButtonRef}
          />
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeleteModal.bind(this)}
            modalClassName='confirm-delete-modal'
            centered={true}
            size='lg'
            aria-modal={true}
            labelledBy='delete-modal-title'>
            <Row>
              <Col md={{ size: 10, offset: 1 }} className='modal-query-container'>
                <p id='delete-modal-title'>Are you sure you want to delete this list?</p>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 10, offset: 1 }} className='modal-button-container'>
                <Button
                  className='cancel modal-button'
                  onClick={() => {
                    this.toggleDeleteModal();
                  }}>
                  Cancel
                </Button>

                <Button
                  className='btn confirm modal-button'
                  color='primary'
                  onClick={this.deleteHandler.bind(this)}>
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    );
  }
}

function mapStoreToProps(state) {
  return {
    filter: state.filter.filter,
    closeFilter: state.filter.closeFilter,
  };
}

export default connect(mapStoreToProps)(AccountComponent);
