import React, { Component } from 'react';
import { Col } from 'reactstrap';
import SchoolIcon from '../common/schoolIcon';

class PowerIcon extends Component {
  getColSize() {
    if (this.props.colSize) {
      return this.props.colSize;
    }

    return 2;
  }

  getContainerClass() {
    if (this.getColSize() === 6) {
      return 'preview';
    }

    return '';
  }

  getTotalFollowers(followData) {
    return followData.totalFollower + followData.totalFollowing;
  }

  render() {
    const total = this.props.followData ? this.getTotalFollowers(this.props.followData) : 0;

    return (
      <Col
        md={this.getColSize()}
        onClick={this.props.showSchoolDetails}
        className='carousel-rankings-item school-block'>
        <SchoolIcon highlight={this.props.offerFound} school={this.props.schoolName} />
        <div className={`rankings-icon-total-container ${this.getContainerClass()}`}>
          <div className='rankings-icon-total'>{total}</div>
        </div>
        {typeof this.props.schoolLabel !== 'undefined' && (
          <span className='rankings-icon-name'>{this.props.schoolLabel}</span>
        )}
      </Col>
    );
  }
}

export default PowerIcon;
