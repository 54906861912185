import React from 'react';
import PowerIcon from 'RootComponents/TwitterInsights/PowerIcon';

function TwitterSchoolList({ rankings }) {
  const schools = rankings.slice(0, 4);

  return schools.map((schoolObj) => {
    return (
      <PowerIcon
        schoolName={schoolObj.college_name}
        followData={schoolObj}
        key={`${schoolObj.college_name}-powerIcon`}
        colSize={6}
      />
    );
  });
}

export default TwitterSchoolList;
