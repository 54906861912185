import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import TwitterConnections from 'PlayerPageComponents/modal/boxes/components/twitter/components/twitterConnections';

function TwitterPreview({ player, page, rankings }) {
  const { id, name, twitterHandle } = player;

  return (
    <Col md={4} className='box twitter-preview'>
      <div className='d-flex'>
        <h2>Twitter Insights</h2>
        <a
          href={`https://www.twitter.com/${twitterHandle}`}
          target='_blank'
          rel='noopener noreferrer'
          className='smaller'
          aria-label={`Visit ${name}'s Twitter`}>
          @{twitterHandle}
        </a>
      </div>
      <Link to={`/${page}/${id}/twitter/`} aria-label={`Open ${name}'s Twitter Insights`}>
        <TwitterConnections rankings={rankings} />
      </Link>
    </Col>
  );
}

export default TwitterPreview;
