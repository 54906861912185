import React from 'react';

import TypeaheadFilterComponent from 'FilterComponents/common/typeahead';
import CommonComponent from 'CommonComponents/generic';
import HighSchoolsService from 'Services/high_schools';

import './styles/_index.scss';

const MIN_NAME_VALUE_LENGTH = 2;
const MIN_ETS_VALUE_LENGTH = 5;

class HighSchoolFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: Boolean(this.props.values?.length),
      toggleClicked: false,
      searchValue: '',
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.closeFilter === true && nextState.toggleClicked === false && nextProps.values) {
      nextState.isOpen = nextProps.values.length > 0;
    }

    nextState.toggleClicked = false;

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  getSearchCall = () => {
    const { searchValue } = this.state;

    if (!this.isReadyToSearch(searchValue)) return;

    if (this.isReadyToSearchByETS(searchValue)) {
      return this.searchByETS;
    }

    return this.searchByName;
  };

  searchByName = (searchValue) => {
    return HighSchoolsService.get({
      data: { name: searchValue },
      isDropdown: true,
    });
  };

  searchByETS = (searchValue) => {
    const parsedETS = parseInt(searchValue, 10);

    return HighSchoolsService.get({
      data: { ets_number: parsedETS },
      isDropdown: true,
    });
  };

  handleValueChange = (value) => {
    if (this.isPreventTyping(value)) return;

    const searchValue = this.isNumeric(value) ? value.trim() : value;

    this.setState({ searchValue });
  };

  handleCancelCall = () => {
    return HighSchoolsService.cancelCall();
  };

  isNumeric = (str) => {
    return /[0-9]/.test(str);
  };

  isCorrectLength(value, minLength) {
    return value.length > minLength;
  }

  isPreventTyping = (value) => {
    return this.isNumeric(value) && this.isCorrectLength(value, MIN_ETS_VALUE_LENGTH + 1);
  };

  isReadyToSearchByName = (value) => {
    return !this.isNumeric(value) && this.isCorrectLength(value, MIN_NAME_VALUE_LENGTH);
  };

  isReadyToSearchByETS = (value) => {
    return this.isNumeric(value) && this.isCorrectLength(value, MIN_ETS_VALUE_LENGTH);
  };

  isReadyToSearch = (value) => {
    return this.isReadyToSearchByName(value) || this.isReadyToSearchByETS(value);
  };

  render() {
    const { isOpen, searchValue } = this.state;
    const { values, filter_action_type, activity_request_level } = this.props;

    return (
      <TypeaheadFilterComponent
        collapsible={false}
        id='high_schools'
        label='High Schools'
        placeholder='Search for high schools'
        searchingMessage='Searching for Schools...'
        classes={`high_school_search player_search transparent-background`}
        isOpen={isOpen}
        values={values}
        searchValue={searchValue}
        filter_action_type={filter_action_type}
        activity_request_level={activity_request_level}
        onValueChange={this.handleValueChange}
        onCancelCall={this.handleCancelCall}
        searchCall={this.getSearchCall()}
      />
    );
  }
}

export default HighSchoolFilterComponent;
