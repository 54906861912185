const metricConfig = {
  combine: {
    forty: {
      active: false,
      id: 'forty',
      label: 'Forty Yd Dash',
      less_than: true,
      order: 1,
      unit: 'seconds',
    },
    shuttle: {
      active: false,
      id: 'shuttle',
      label: 'Shuttle',
      less_than: true,
      order: 2,
      unit: 'seconds',
    },
    verticalJump: {
      active: false,
      id: 'verticalJump',
      label: 'Vertical Jump',
      less_than: false,
      order: 3,
      unit: 'inches',
    },
    broadJump: {
      active: false,
      id: 'broadJump',
      label: 'Broad Jump',
      less_than: false,
      order: 4,
      unit: 'inches',
    },
    threeCone: {
      active: false,
      id: 'threeCone',
      label: 'Three Cone',
      less_than: true,
      order: 5,
      unit: 'seconds',
    },
    powerBall: {
      active: false,
      id: 'powerBall',
      label: 'Power Ball',
      less_than: false,
      order: 6,
      unit: 'feet',
    },
  },
  measurables: {
    height: {
      active: false,
      id: 'height',
      label: 'Height',
      less_than: false,
      order: 1,
      unit: 'feet-inches',
    },
    weight: {
      active: false,
      id: 'weight',
      label: 'Weight',
      less_than: false,
      order: 2,
      unit: 'lbs',
    },
    wingspan: {
      active: false,
      id: 'wingspan',
      label: 'Wingspan',
      less_than: false,
      order: 4,
      unit: 'inches',
    },
    armLength: {
      active: false,
      id: 'armLength',
      label: 'Arm Length',
      less_than: false,
      order: 3,
      unit: 'inches',
    },
    reach: {
      active: false,
      id: 'reach',
      label: 'Reach',
      less_than: false,
      order: 6,
      unit: 'inches',
    },
    handSize: {
      active: false,
      id: 'handSize',
      label: 'Hand Size',
      less_than: false,
      order: 5,
      unit: 'inches',
    },
  },
  track_and_field: {
    oneHundredMeter: {
      active: false,
      id: 'oneHundredMeter',
      label: '100m',
      less_than: true,
      order: 1,
      unit: 'seconds',
    },
    twoHundredMeter: {
      active: false,
      id: 'twoHundredMeter',
      label: '200m',
      less_than: true,
      order: 2,
      unit: 'seconds',
    },
    fourHundredMeter: {
      active: false,
      id: 'fourHundredMeter',
      label: '400m',
      less_than: true,
      order: 3,
      unit: 'seconds',
    },
    fourByOneHundredMeter: {
      active: false,
      id: 'fourByOne',
      label: '4 x 100m',
      less_than: true,
      order: 4,
      unit: 'seconds',
    },
    fourByTwoHundredMeter: {
      active: false,
      id: 'fourByTwo',
      label: '4 x 200m',
      less_than: true,
      order: 5,
      unit: 'minutes',
    },
    fourByFourHundredMeter: {
      active: false,
      id: 'fourByFour',
      label: '4 x 400m',
      less_than: true,
      order: 6,
      unit: 'minutes',
    },
    oneTenHurdles: {
      active: false,
      id: 'oneTenHurdle',
      label: '110m Hurdles',
      less_than: true,
      order: 7,
      unit: 'seconds',
    },
    threeHundredHurdles: {
      active: false,
      id: 'threeHundredHurdle',
      label: '300m Hurdles',
      less_than: true,
      order: 8,
      unit: 'seconds',
    },
    shotput: {
      active: false,
      id: 'shotput',
      label: 'Shotput',
      less_than: false,
      order: 9,
      unit: 'feet',
    },
    discus: {
      active: false,
      id: 'discus',
      label: 'Discus',
      less_than: false,
      order: 10,
      unit: 'feet',
    },
    javelin: {
      active: false,
      id: 'javelin',
      label: 'Javelin',
      less_than: false,
      order: 11,
      unit: 'feet',
    },
    hammer: {
      active: false,
      id: 'hammer',
      label: 'Hammer',
      less_than: false,
      order: 12,
      unit: 'feet',
    },
    highJump: {
      active: false,
      id: 'highJump',
      label: 'High Jump (HJ)',
      less_than: false,
      order: 13,
      unit: 'feet',
    },
    longJump: {
      active: false,
      id: 'longJump',
      label: 'Long Jump (LJ)',
      less_than: false,
      order: 14,
      unit: 'feet',
    },
    tripleJump: {
      active: false,
      id: 'tripleJump',
      label: 'Triple Jump',
      less_than: false,
      order: 15,
      unit: 'feet',
    },
    poleVault: {
      active: false,
      id: 'poleVault',
      label: 'Pole Vault (PV)',
      less_than: false,
      order: 16,
      unit: 'feet',
    },
  },
};

export default metricConfig;
