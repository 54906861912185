import React from 'react';
import { Col, Row } from 'reactstrap';
import PowerIcon from 'RootComponents/TwitterInsights/PowerIcon';
import TwitterSchoolList from 'PlayerPageComponents/modal/boxes/components/twitter/components/twitterSchoolList';

function TwitterConnections({ rankings }) {
  const currentSchoolName = window.localStorage.getItem('school');

  const currentSchool = rankings.find(({ college_name }) => {
    return college_name === currentSchoolName;
  });

  return (
    <Row className='block-inner' aria-label='School Connections'>
      <Col md={8} className='other-schools carousel-rankings no-padding'>
        <div className='carousel-rankings-content'>
          <TwitterSchoolList rankings={rankings} />
        </div>
      </Col>

      <Col md={4} className='my-school school-block'>
        <PowerIcon schoolName={currentSchoolName} followData={currentSchool} colSize={12} />
      </Col>
    </Row>
  );
}

export default TwitterConnections;
