import React, { Component } from 'react';

import { CSVLink } from 'react-csv';
import { Button } from 'reactstrap';

/**
 * Components
 */
import ExportPlayerModal from 'CommonComponents/exportPlayerModal';

/**
 * Services
 */
import PlayersService from 'Services/players';
import ActivityService from 'Services/activity';
import AuthenticationService from 'Services/authentication';

/**
 * Styles
 */
import 'CommonComponents/exportButton/styles/_index.scss';

class MyCsvLink extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      data: [],
      label: this.props.label || 'Export All Prospects',
      modalOpen: false,
      pageTotal: 1,
    };

    this.players = [];
    this.totalPlayers = 0;
  }

  exportData(page = 1) {
    const filter = JSON.parse(JSON.stringify(this.props.filter));

    filter.export = true;

    if (this.props.players) {
      filter.players = this.props.players.map((player) => {
        return player.id;
      });
    }

    const func = this.props.activity
      ? ActivityService.get(this.props.filter, page, 'excel')
      : PlayersService.exportPlayers(
          {
            hidden: false,
            tracked: this.props.tracked,
            'sort[sortBy]': this.props.sortBy,
            'sort[sortDirection]': this.props.sortDirection,
          },
          filter,
          page
        );

    func.then((data) => {
      this.players = this.players.concat(data.data);

      this.totalPlayers = data.total ? data.total : this.totalPlayers;

      this.setState({
        data: this.players,
        pageTotal: data.pageTotal ? data.pageTotal : this.state.pageTotal,
      });

      if (page < this.state.pageTotal) {
        return this.exportData(page + 1);
      }

      this.setState(
        {
          modalOpen: false,
        },
        () => {
          this.players = [];
          this.totalPlayers = 0;

          this.csvLink.current.link.click();
        }
      );
    });
  }

  triggerModal() {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => {
        this.exportData(1);
      }
    );
  }

  cancelRequests() {
    ActivityService.cancelCall('Cancelling prospect export');

    this.setState(
      {
        modalOpen: false,
      },
      () => {
        this.players = [];
        this.totalPlayers = 0;
      }
    );
  }

  render() {
    const isDemoOrg = AuthenticationService.getOrganization()?.is_demo;
    return (
      <div className='exporter'>
        <Button disabled={isDemoOrg} color='link' onClick={this.triggerModal.bind(this)}>
          <span className='downloader'>
            <i className='icon downloadWithBox fas fa-download' />
            {this.state.label}
          </span>
        </Button>
        <CSVLink
          data={this.state.data}
          filename='Zcruit Export.csv'
          className='hidden'
          ref={this.csvLink}
          target='_blank'
          uFEFF={false}
          aria-hidden={true}
        />
        <ExportPlayerModal
          completePlayers={this.players.length}
          cancelRequests={this.cancelRequests.bind(this)}
          modalOpen={this.state.modalOpen}
          toggleModal={this.triggerModal.bind(this)}
          totalPlayers={this.totalPlayers}
        />
      </div>
    );
  }
}

export default MyCsvLink;
