import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import PageContentHeader from '../../../common/accountHeader';

/**
 * Parent
 */
import ZcruitComponent from '../../../common/zcruit';

/**
 * Services
 */

/**
 * Styles
 */
import './styles/_index.scss';

class BasicInfoComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isLoading: true,
      email: '',
      orgName: '',
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo() {
    const orgName = window.localStorage.getItem('school');
    const email = window.localStorage.getItem('username');
    if (this.state.email !== email || this.state.orgName !== orgName) {
      this.setState({
        email,
        orgName,
      });
    }
  }

  render() {
    return (
      <Row>
        <PageContentHeader tab='tab_info' />
        <Col className='box-container basic-info' md={{ size: 8, offset: 2 }}>
          <Col md={8} className='account-box'>
            <h2 className='title'>Basic Info</h2>
            <div className='account-box__content'>
              <p className='account-box__text account-box__text--bold'>
                Email: <span className='account-box__text'>{this.state.email}</span>
              </p>
              <p className='account-box__text account-box__text--bold'>
                School: <span className='account-box__text'>{this.state.orgName}</span>
              </p>
            </div>
          </Col>
        </Col>
      </Row>
    );
  }
}

export default BasicInfoComponent;
