import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, { ERROR_MESSAGE, MESSAGE_TYPE } from 'CommonComponents/messager';

/**
 * Services
 */
import VideosService from 'Services/videos';
import AuthenticationService from 'Services/authentication';

/**
 * Styles
 */
import 'CommonComponents/videoDetails/styles/_index.scss';

class VideoDetailsComponent extends Component {
  constructor(props) {
    super(props);

    this.shareLink = React.createRef();
    this.state = {
      errorThrown: false,
      fileDownloading: false,
      fileLabel: 'Download',
      isLoading: true,
    };
  }

  copyToClipboard() {
    this.shareLink.current.select();

    document.execCommand('copy');
  }

  download() {
    this.setState({
      fileDownloading: true,
      fileLabel: 'Downloading...',
    });

    const file = this.props.videoUrl.split('/');
    const filename = file.at(-1).split('?')[0];
    const decodedURIFileName = decodeURI(filename).replace('%23', '#');

    VideosService.downloadVideo(this.props.videoId, decodedURIFileName).then(
      (response) => {
        this.setState({
          fileDownloading: false,
          fileLabel: 'Download',
        });
      },
      (error) => {
        console.warn('failed');
        console.warn(error);
      }
    );
  }

  renderViewDetailsButton() {
    if (this.props.players.length !== 1) {
      return null;
    }

    return (
      <Button
        color='primary'
        onClick={() => {
          this.props.togglePlayerModal(this.props.players[0].id);
        }}>
        View Profile
      </Button>
    );
  }

  renderVideoCaption() {
    if (this.props.players.length === 0) {
      return null;
    }

    if (this.props.players.length > 1) {
      return (
        <Col md='12'>
          <div className='video-details-upper video-details-row'>
            <h3 className='video-details-player'>{this.props.videoTitle}</h3>
            {this.renderViewDetailsButton()}
          </div>
        </Col>
      );
    }

    return (
      <Col md='12'>
        <div className='video-details-upper video-details-row'>
          <h3 className='video-details-player'>{this.props.players[0].name}</h3>
          {this.renderViewDetailsButton()}
        </div>
      </Col>
    );
  }

  render() {
    const isDemoOrg = AuthenticationService.getOrganization()?.is_demo;

    if (this.state.errorThrown === true) {
      return <MessagingComponent message={ERROR_MESSAGE.filmDetails} type={MESSAGE_TYPE.danger} />;
    }

    return (
      <Row className='video-details'>
        {this.renderVideoCaption()}
        <Col md={12}>
          <div className='video-details-lower video-details-row'>
            <div className='video-location-date-container'>
              <div className='location icon-info'>
                <IconComponent
                  color='purple'
                  icon='address fas fa-map-marker-alt'
                  label={this.props.videoCity}
                />
                {this.props.videoCity}
              </div>
              <div className='date icon-info'>
                <IconComponent
                  color='purple'
                  icon='calendar fas fa-calendar-alt'
                  label={this.props.videoDate}
                />
                {this.props.videoDate}
              </div>
            </div>
            <div className='video-link-container'>
              <Button
                outline
                disabled={isDemoOrg || this.state.fileDownloading === true}
                id={`download-button-${this.props.videoId}`}
                onClick={this.download.bind(this)}>
                {this.state.fileLabel}
              </Button>
              <Button
                outline
                id={`share-button-${this.props.videoId}`}
                onClick={this.copyToClipboard.bind(this)}>
                Share
              </Button>
              <UncontrolledTooltip target={`share-button-${this.props.videoId}`} trigger='legacy'>
                {this.props.videoPageLink || window.location.href}
              </UncontrolledTooltip>
              <textarea
                readOnly={true}
                value={this.props.videoPageLink || window.location.href}
                className='hidden-share-link'
                ref={this.shareLink}
                aria-hidden={true}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default VideoDetailsComponent;
