import React from 'react';
import { opensInNewTab } from '../../constants';

/**
 * Styles
 */
import './styles/_index.scss';

// eslint-disable-next-line max-len
export default function externalLinkScreenReaderMessage(visualCue = false) {
  // visualCue is used as a toggle to show a tooltip along with the screen-reader voice over
  return (
    <React.Fragment>
      {visualCue && (
        <span className='visual-cue'>
          <i className='fas fa-external-link-square-alt' />
        </span>
      )}
      <span className='screen-reader-only'>{opensInNewTab}</span>
    </React.Fragment>
  );
}
