import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import SchoolIconComponent from '../../../../common/schoolIcon';
import { DATE_DEFAULTS } from '../../../../../services/date-time';
import './styles/_index.scss';

class PlayerPageModalCommitInfoComponent extends Component {
  constructor(props) {
    super(props);

    let date = null;
    let school = null;
    let type = null;
    let unmount = false;

    if (this.props.player.hasSigning()) {
      date = this.props.player.getSignedDate(DATE_DEFAULTS.format.default);
      school = this.props.player.getSignedSchool();
      type = 'Signed';
    } else if (this.props.player.hasCommit()) {
      date = this.props.player.getCommitDate(DATE_DEFAULTS.format.default);
      school = this.props.player.getCommitSchool();
      type = 'Committed';
    } else if (this.props.player.hasTransferToSchool()) {
      date = this.props.player.getTransferDate(DATE_DEFAULTS.format.default);
      school = this.props.player.getTransferToSchool();
      type = 'Transferred';
    } else {
      unmount = true;
    }

    this.state = {
      date,
      school,
      type,
      unmount,
    };
  }

  render() {
    if (this.state.unmount === true) {
      return null;
    }

    return (
      <Row>
        <Col md={12} className='commit-info'>
          <div className='committed-info'>
            <SchoolIconComponent
              school={this.state.school}
              addCheck={this.state.type === 'Signed'}
              aria-hidden={true}
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <div tabIndex={0}>
              {this.state.type === 'Signed' && (
                <span>
                  {this.state.type} with {this.state.school}
                </span>
              )}
              {this.state.type === 'Committed' && (
                <span>
                  {this.state.type} to {this.state.school}
                </span>
              )}

              {this.state.type === 'Transferred' && (
                <span>
                  {this.state.type} to {this.state.school}
                </span>
              )}
              {this.state.date !== null && <span>&nbsp;on {this.state.date}</span>}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PlayerPageModalCommitInfoComponent;
