import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import VerifiedIcon from 'CommonComponents/verifiedIcon';

/**
 * Constants
 */
import { METRIC_TYPES } from 'PlayerPageComponents/pages/stats/constants';

/**
 * Styles
 */
import './styles/_index.scss';

class MeasurablesViewStatsPlayerPageComponent extends Component {
  buildStat(type) {
    return Object.keys(this.props.stats.metrics).map((statKey) => {
      const found = this.props.stats.metrics[statKey].filter((stat) => {
        return stat.type === type;
      });

      if (found.length === 0) {
        return null;
      }

      const usedMetric = this.selectBest(found);

      return (
        <Col md={6} className={type} key={type}>
          <h3>{usedMetric.label}</h3>
          <p>
            {usedMetric.raw_value ? (
              <span className='small'>
                {usedMetric.raw_value}&nbsp;
                <span className='extra-small'>{usedMetric.unit}</span>
              </span>
            ) : (
              <span className='small'>{usedMetric.value}</span>
            )}
            <VerifiedIcon verified={usedMetric.verified} subSource={usedMetric.subSource} />
          </p>
          <IconComponent
            color='grey'
            icon={usedMetric.source.name}
            label={usedMetric.source.label}
          />
        </Col>
      );
    });
  }

  selectBest(metrics) {
    return metrics[0];
  }

  generateHeight(inches) {
    const feet = Math.floor(inches / 12);

    inches = Math.round((inches % 12) * 100) / 100;

    return `${feet}' ${inches}"`;
  }

  render() {
    return (
      <Col md={4}>
        <div className='box measureables' tabIndex={0} role='tablist' aria-label='Measurables'>
          <h2>Measurables</h2>
          <Row>
            {this.buildStat(METRIC_TYPES.height)}
            {this.buildStat(METRIC_TYPES.weight)}
          </Row>
        </div>
      </Col>
    );
  }
}

export default MeasurablesViewStatsPlayerPageComponent;
