import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import ContactsService from 'Services/contacts';

import 'PlayerPageComponents/modal/boxes/components/contactInfo/styles/_index.scss';

class PlayerPageModalContactInfoBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      playerContactInfo: null,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    ContactsService.getContactsForPlayer(this.props.player.id).then(
      (response) => {
        this.setState({
          playerContactInfo: response,
          isLoading: false,
        });
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  generateSourceIcon(source) {
    if (typeof source !== 'undefined') {
      return <IconComponent color='grey' icon={source.name} label={source.label} />;
    }

    return <div />;
  }

  generateContactInfo() {
    let address = '';
    let location = '';
    let emptyAddress = true;
    let cellPhone = 'No data available';
    let email = 'No data available';
    if (typeof this.state.playerContactInfo.address !== 'undefined') {
      address = this.state.playerContactInfo.address.value;
      emptyAddress = false;
    }

    if (typeof this.state.playerContactInfo.location !== 'undefined') {
      location = `${this.state.playerContactInfo.location.value}`;
    }

    if (typeof this.state.playerContactInfo.cell_phone !== 'undefined') {
      cellPhone = this.state.playerContactInfo.cell_phone.value;
    }

    if (typeof this.state.playerContactInfo.email !== 'undefined') {
      email = this.state.playerContactInfo.email.value;
    }

    const addressSource =
      typeof this.state.playerContactInfo.address !== 'undefined'
        ? this.state.playerContactInfo.address.source
        : '';
    const cellSource =
      typeof this.state.playerContactInfo.cell_phone !== 'undefined'
        ? this.state.playerContactInfo.cell_phone.source
        : '';
    const emailSource =
      typeof this.state.playerContactInfo.email !== 'undefined'
        ? this.state.playerContactInfo.email.source
        : '';

    return (
      <Link
        to={`/${this.props.page}/${this.props.player.id}/contact/`}
        aria-label={`View ${this.props.player.name}'s contact info`}>
        <div className='contact_holder_contact'>
          <div
            className='contact_holder_contact_contact'
            key={`contact-${this.state.playerContactInfo.id}`}>
            <div className='contact-info-piece'>
              <IconComponent
                icon='email fas fa-envelope'
                label={`${this.props.player.name}'s Email`}
              />
              <p>{email}</p>
              {this.generateSourceIcon(emailSource)}
            </div>
            <div className='contact-info-piece'>
              <IconComponent
                icon='phone fas fa-mobile-alt'
                label={`${this.props.player.name}'s Phone`}
              />
              <p>{cellPhone}</p>
              {this.generateSourceIcon(cellSource)}
            </div>
            <div className='contact-info-piece'>
              <IconComponent
                icon='address fas fa-map-marker-alt'
                label={`${this.props.player.name}'s Address`}
              />
              <div>
                {emptyAddress && <p> No data available </p>}
                <p>{!emptyAddress && address}</p>
                <p>{!emptyAddress && location}</p>
              </div>
              {this.generateSourceIcon(addressSource)}
            </div>
          </div>
        </div>
      </Link>
    );
  }

  renderContent() {
    const { isLoading, playerContactInfo } = this.state;

    if (isLoading) {
      return <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />;
    }

    if (!playerContactInfo) {
      return <MessagingComponent message={NO_DATA_MESSAGE.contact} />;
    }

    return this.generateContactInfo();
  }

  render() {
    return (
      <Col md={4} className='box contact_holder'>
        <h2>Contact Info</h2>
        {this.renderContent()}
      </Col>
    );
  }
}

export default PlayerPageModalContactInfoBoxComponent;
