import axios from 'axios';

class FilterService {
  getFilters(type) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}filters/v2/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params: {
        type,
      },
    })
      .then((response) => {
        response.data.data = response.data.data.map((filter) => {
          if (typeof filter.filter.location_radius === 'undefined') {
            filter.filter.location_radius = {
              distance: 100,
              latitude: -1,
              label: null,
              longitude: -1,
            };
          }

          return filter;
        });

        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  saveFilter(filter, type) {
    filter.type = type;
    return axios({
      method: 'POST',
      url: `${process.env.API_URL}filters/v2/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data: filter,
    });
  }

  getFilterNew(type) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}filters/v2/?type=${type}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  deleteFilterNew(id) {
    return axios({
      method: 'DELETE',
      url: `${process.env.API_URL}filters/v2/${id}/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    });
  }

  editFilter(filter, name, id) {
    const activityFilter = {
      filter,
      name,
    };
    return axios({
      method: 'PATCH',
      url: `${process.env.API_URL}filters/v2/${id}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data: activityFilter,
    });
  }

  deleteFilter(id) {
    return axios({
      method: 'DELETE',
      url: `${process.env.API_URL}filters/v2/${id}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    });
  }
}

export default new FilterService();
