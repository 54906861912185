const initialState = {
  twitterLikesData: [],
  twitterRTData: [],
  twitterMentionData: [],
  twitterMentionDataLoaded: false,
  twitterRTDataLoaded: false,
  coachData: {},
  collegeData: {},
  twitterFollowData: { following: [], follower: [] },
  likeTrendsData: {},
  twitterRankings: [],
  twitterTimeline: [],
  twitterTrends: [],
  playerTweetsData: {},
  twitterFollowDataLoaded: false,
  twitterLikesDataLoaded: false,
  likeTrendsDataLoaded: false,
  twitterRankingsDataLoaded: false,
  twitterTimelineDataLoaded: false,
  twitterTrendsDataLoaded: false,
  twitterHandleCased: '',
  twitterPlayersLoaded: false,
  twitterAPICallFinished: false,
  twitterPlayers: [],
};

export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case 'UPDATE_TWITTER_LIKES_DATA': {
      return Object.assign({}, state, {
        twitterLikesData: action.twitterLikesData,
        twitterLikesDataLoaded: true,
      });
    }

    case 'UPDATE_PLAYER_TWEETS_DATA': {
      return Object.assign({}, state, {
        playerTweetsData: action.playerTweetsData,
      });
    }

    case 'UPDATE_COACH_DATA': {
      return Object.assign({}, state, {
        coachData: action.coachData,
      });
    }

    case 'UPDATE_COLLEGE_DATA': {
      return Object.assign({}, state, {
        collegeData: action.collegeData,
      });
    }

    case 'UPDATE_FOLLOW_DATA': {
      return Object.assign({}, state, {
        twitterFollowData: action.twitterFollowData,
        twitterFollowDataLoaded: true,
      });
    }

    case 'UPDATE_FOLLOW_DATA_LOADED': {
      return Object.assign({}, state, {
        twitterFollowDataLoaded: action.twitterFollowDataLoaded,
      });
    }

    case 'UPDATE_TWITTER_RT_DATA': {
      return Object.assign({}, state, {
        twitterRTData: action.retweetObject,
        twitterRTDataLoaded: true,
      });
    }
    case 'UPDATE_TWITTER_MENTIONS': {
      return Object.assign({}, state, {
        twitterMentionData: action.mentionObject,
        twitterMentionDataLoaded: true,
      });
    }

    case 'UPDATE_LIKE_TRENDS_DATA': {
      return Object.assign({}, state, {
        likeTrendsData: action.likeTrendsData,
        likeTrendsDataLoaded: true,
      });
    }

    case 'UPDATE_TWITTER_RANKINGS': {
      return Object.assign({}, state, {
        twitterRankings: action.twitterRankings,
        twitterRankingsDataLoaded: action.dataLoaded || true,
      });
    }

    case 'UPDATE_TWITTER_TIMELINE': {
      return Object.assign({}, state, {
        twitterTimeline: action.twitterTimeline,
        twitterTimelineDataLoaded: action.dataLoaded || true,
      });
    }

    case 'UPDATE_TWITTER_TRENDS': {
      return Object.assign({}, state, {
        twitterTrends: action.twitterTrends,
        twitterTrendsDataLoaded: action.dataLoaded || true,
      });
    }

    case 'TWITTER_DATA_RESET': {
      return Object.assign({}, state, {
        twitterTimelineDataLoaded: false,
        twitterTrendsDataLoaded: false,
        twitterRankingsDataLoaded: false,
      });
    }

    case 'UPDATE_CASED_HANDLE': {
      return Object.assign({}, state, {
        twitterHandleCased: action.twitterHandleCased,
      });
    }

    case 'TWITTER_PLAYERS_LOADED':
      return Object.assign({}, state, {
        twitterPlayersLoaded: action.twitterPlayersLoaded,
      });

    case 'TWITTER_PLAYERS':
      return Object.assign({}, state, {
        twitterPlayers: action.players,
        twitterPlayersLoaded: action.twitterPlayersLoaded,
      });

    case 'TWITTER_API_CALL': {
      return Object.assign({}, state, {
        twitterAPICallFinished: action.twitterAPICallFinished,
      });
    }

    default:
      return state;
  }
}
