import React from 'react';
import { Col, Row } from 'reactstrap';

import MessagingComponent, { MESSAGE_TYPE, STATUS_MESSAGE } from 'CommonComponents/messager';

function TimelineMessageLoading() {
  return (
    <Row className='timeline'>
      <Col md='12' className='timeline-legend'>
        <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
      </Col>
    </Row>
  );
}

function TimelineMessageEmpty({ message }) {
  return (
    <Row className='timeline'>
      <Col md='auto' className='timeline-legend'>
        <MessagingComponent
          type={MESSAGE_TYPE.danger}
          id='no_visits'
          message={message || 'No results were found'}
        />
      </Col>
    </Row>
  );
}

export { TimelineMessageLoading, TimelineMessageEmpty };
