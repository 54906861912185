import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import RangeButtonFilterComponent from 'FilterComponents/common/range_button';

import './styles/_index.scss';

class RatingsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    ) {
      isOpen = true;
    }

    this.state = {
      activity_request_level: this.props.activity_request_level,
      filter_action_type: this.props.filter_action_type,
      id: this.props.id,
      isOpen,
      label: 'Rating',
      ratings: [
        {
          label: 'All',
          lower: 0,
          selected: true,
          upper: 100,
        },
        {
          label: 'Elite',
          lower: 90,
          selected: false,
          upper: 100,
          sub_label: '100-90',
        },
        {
          label: 'P5',
          lower: 80,
          selected: false,
          upper: 89.999,
          sub_label: '90-80',
        },
        {
          label: 'P5/G5',
          lower: 70,
          selected: false,
          upper: 79.999,
          sub_label: '80-70',
        },
        {
          label: 'G5',
          lower: 60,
          selected: false,
          upper: 69.999,
          sub_label: '70-60',
        },
        {
          label: 'G5/FCS',
          lower: 50,
          selected: false,
          upper: 59.999,
          sub_label: '60-50',
        },
        {
          label: 'FCS',
          lower: 40,
          selected: false,
          upper: 49.999,
          sub_label: '50-40',
        },
        {
          label: 'FCS/D2',
          lower: 30,
          selected: false,
          upper: 39.999,
          sub_label: '40-30',
        },
        {
          label: 'D2',
          lower: 20,
          selected: false,
          upper: 29.999,
          sub_label: '30-20',
        },
        {
          label: 'D2/D3',
          lower: 10,
          selected: false,
          upper: 19.999,
          sub_label: '20-10',
        },
        {
          label: 'D3',
          lower: 0.1,
          selected: false,
          upper: 9.999,
          sub_label: '10-0',
        },
        {
          label: 'None',
          lower: null,
          selected: false,
          upper: null,
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      typeof nextProps.values !== 'undefined' &&
      typeof nextProps.values.max === 'number' &&
      typeof nextProps.values.min === 'number'
    ) {
      if (
        nextProps.values.min <= 0 &&
        (nextProps.values.max >= 100 || nextProps.values.max === -1)
      ) {
        nextState.ratings = nextState.ratings.map((rating) => {
          if (rating.lower === 0 && rating.upper === 100) {
            rating.selected = true;
          } else {
            rating.selected = false;
          }
          return rating;
        });
      } else {
        nextState.ratings = nextState.ratings.map((rating) => {
          if (
            (rating.lower >= nextProps.values.min && rating.upper <= nextProps.values.max) ||
            (rating.label === 'None' && rating.selected === true)
          ) {
            rating.selected = true;
          } else {
            rating.selected = false;
          }

          return rating;
        });
      }
    } else {
      nextState.ratings = nextState.ratings.map((rating) => {
        if (rating.lower === 0 && rating.upper === 100) {
          rating.selected = true;
        } else {
          rating.selected = false;
        }
        return rating;
      });
    }

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, label, closeFilter } = this.props;
    const { isLoading, isOpen, ratings } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <RangeButtonFilterComponent
        id={id}
        classes='rating'
        collapseClass='third'
        isOpen={isOpen}
        label={label}
        values={ratings}
        closeFilter={closeFilter}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

RatingsFilterComponent.defaultProps = {
  closeFilter: false,
  label: 'Rating',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

RatingsFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  label: PropTypes.string,
  closeFilter: PropTypes.bool,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default RatingsFilterComponent;
