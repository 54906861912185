import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';

/**
 * Components
 */
import AcademicsPlayerPageComponent from '../prospectsPlayerPage/components/pages/academics';
import BasePlayerPageComponent from '../prospectsPlayerPage/components/pages/base';
import ContactPlayerPageComponent from '../prospectsPlayerPage/components/pages/contact';
import StatsPlayerPageComponent from '../prospectsPlayerPage/components/pages/stats';
import VideoPlayerPageComponent from '../prospectsPlayerPage/components/pages/videos';
import TwitterPageComponent from '../prospectsPlayerPage/components/pages/twitter';

/**
 * Styles
 */
import './styles/_index.scss';

class ActivityPlayerPageComponent extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  getTemplate() {
    if (typeof this.props.match.params.param === 'undefined') {
      return (
        <BasePlayerPageComponent
          match={this.props.match}
          page='activity'
          history={this.props.history}
          parentRef={this.modalRef}
        />
      );
    }

    switch (this.props.match.params.param) {
      case 'stats':
        return (
          <StatsPlayerPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
          />
        );
      case 'videos':
        return (
          <VideoPlayerPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
          />
        );
      case 'twitter':
        return (
          <TwitterPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
          />
        );
      case 'contact':
        return (
          <ContactPlayerPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
          />
        );
      case 'academics':
        return (
          <AcademicsPlayerPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
          />
        );
      default:
        return (
          <BasePlayerPageComponent
            match={this.props.match}
            page='activity'
            history={this.props.history}
            parentRef={this.modalRef}
          />
        );
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={true}
          className='player-page'
          id='player-page'
          aria-modal={true}
          ref={this.modalRef}>
          {this.getTemplate()}
        </Modal>
      </div>
    );
  }
}

function mapStoreToProps(storeState) {
  return {
    twitterPlayersList: storeState.twitter.twitterPlayers,
    twitterPlayersLoaded: storeState.twitter.twitterPlayersLoaded,
  };
}

export default connect(mapStoreToProps)(ActivityPlayerPageComponent);
