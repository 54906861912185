import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';

import store from 'Reducers/store';

class TextFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      (typeof this.props.value !== 'undefined' &&
        this.props.value !== null &&
        this.props.value !== '') ||
      this.props.isOpen === true
    ) {
      isOpen = true;
    }

    this.state = { isOpen };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.values && nextProps.values.length > 0) {
      nextState.players = nextProps.values.map((player) => {
        player.checked = true;
        return player;
      });
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      nextState.isOpen = nextProps.values.length > 0;
    }

    nextState.toggleClicked = false;

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  onChange(event) {
    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type: id,
        values: event.target.value,
      },
    });
  }

  render() {
    const { id, value, label, placeholder } = this.props;
    const { isOpen } = this.state;

    return (
      <FilterComponent
        id={id}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        <div className='input'>
          <i className='icon search far fa-search' />
          <input
            type='text'
            value={value}
            placeholder={placeholder}
            onChange={this.onChange.bind(this)}
          />
        </div>
      </FilterComponent>
    );
  }
}

TextFilterComponent.defaultProps = {
  value: '',
  label: 'Label',
  placeholder: 'Enter a value',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
  closeFilter: false,
};

TextFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  closeFilter: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default TextFilterComponent;
