import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import ErrorPageComponent from 'PlayerPageComponents/pages/error';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';
import ContactTypeContactPlayerPageComponent from 'PlayerPageComponents/pages/contact/components/contactinfo';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import ContactsService from 'Services/contacts';
import SocialService from 'Services/social';
import PlayersService from 'Services/players';

import generatePageTitles from 'RootComponents/utility/pageTitles';

/**
 * Styles
 */
import 'PlayerPageComponents/pages/contact/styles/_index.scss';

class ContactPlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'contact',
      contacts: {},
      error: true,
      isLoading: true,
      player: null,
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;

    const contacts = ContactsService.getContactsForPlayer(playerId);
    const players = PlayersService.getPlayerAsPromise(playerId);
    const socialInfo = SocialService.getSocialForPlayer(playerId);
    Promise.all(
      [contacts, players, socialInfo].map((p) => {
        return p.catch((e) => {});
      })
    )
      .then(
        (responses) => {
          if (typeof responses[0] === 'undefined') {
            if (typeof responses[1] !== 'undefined') {
              document.title = generatePageTitles(`${responses[1].name} Contact Info`);
              return this.setState({
                error: true,
                isLoading: false,
                player: responses[1],
              });
            }

            throw new Error('We could not find the requested player');
          }

          document.title = generatePageTitles(`${responses[1].name} Contact Info`);

          return this.setState({
            contacts: responses[0],
            error: false,
            isLoading: false,
            player: responses[1],
            socialInfo: responses[2],
          });
        },
        (error) => {
          this.setState({
            error: true,
            isLoading: false,
          });
        }
      )
      .catch((finalError) => {
        /**
         * Go back to last page ONLY if there is no player and no contact info. This really
         * should never happen unless someone is jacking with the URI
         */
        this.props.history.goBack();
      });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div className='modal-wrapper' style={{ width: '90%', margin: '50px auto' }}>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </div>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <div className='modal-wrapper'>
          <ErrorPageComponent
            activeTab='contact'
            message={NO_DATA_MESSAGE.contact}
            page={this.props.page}
            player={this.state.player}
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
            title='Contact'
          />
        </div>
      );
    }

    return (
      <div className='modal-wrapper'>
        <PlayerPageModalHeaderComponent
          player={this.state.player}
          page={this.props.page}
          activeTab={this.state.activeTab}
          toggleModal={this.props.toggleModal}
          setTab={this.props.setTab}
        />
        <div className='modal-body'>
          <Row className='secondary-page contact-page'>
            <Col md={12} className='page-title-container'>
              <div className='page-title'>{`${this.state.player.name}'s Contact Info`}</div>
            </Col>
            <Col md={12}>
              <ContactTypeContactPlayerPageComponent
                contacts={this.state.contacts}
                player={this.state.player}
                socialInfo={this.state.socialInfo}
                type='Player'
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ContactPlayerPageComponent;
