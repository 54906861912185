import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import NestedDropdownFilterComponent from 'FilterComponents/common/nested_dropdown';
import TagListComponent from 'FilterComponents/common/tag_list';
import Checkbox from 'CommonComponents/checkbox';

import DivisionService from 'Services/divisions';
import store from 'Reducers/store';

import 'react-dropdown-tree-select/dist/styles.css';
import 'FilterComponents/specific/offers/styles/_index.scss';

class OffersFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      divisions: [],
      isLoading: true,
      toggleClicked: false,
      unsignedInteractedWith: false,
    };
  }

  componentDidMount() {
    DivisionService.getDivisions().then((divisions) => {
      const formattedDivision = this._formatForDropdown(divisions);

      this.setState({
        divisions: formattedDivision,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let divisions = Object.assign([], nextState.divisions);
    let isOpen = false;

    const selectedSchools = nextProps.values.values;

    if (Array.isArray(selectedSchools)) {
      divisions = divisions.map((division) => {
        let selectedConferenceCount = 0;

        /**
         * If user doesn't wanna see P5 schools, this will reset it
         */
        if (
          division.label === 'Power 5' &&
          typeof nextProps.values.no_p5 !== 'undefined' &&
          nextProps.values.no_p5 === true
        ) {
          division.checked = false;

          division.children = division.children.map((conference) => {
            conference.checked = false;

            conference.children = conference.children.map((school) => {
              school.checked = false;

              return school;
            });

            return conference;
          });
        }

        division.checked = selectedSchools.indexOf(division.id) > -1;

        /**
         * Read through all conferences and enable/disable them appropriately
         */
        division.children = division.children.map((conference) => {
          conference.checked =
            division.checked === true || selectedSchools.indexOf(conference.id) > -1;

          let selectedSchoolCount = 0;
          /**
           * Read through all schools and enable/disable them appropriately
           */
          conference.children = conference.children.map((school) => {
            if (conference.checked === true || selectedSchools.indexOf(school.id) > -1) {
              school.checked = true;

              selectedSchoolCount += 1;
            } else {
              school.checked = false;
            }

            return school;
          });

          if (selectedSchoolCount === conference.children.length) {
            conference.checked = true;
          }

          selectedConferenceCount =
            conference.checked === true ? selectedConferenceCount + 1 : selectedConferenceCount;

          return conference;
        });

        division.checked = division.children.length === selectedConferenceCount;
        division.expanded =
          selectedConferenceCount > 0 && division.children.length !== selectedConferenceCount;

        return division;
      });
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = Array.isArray(selectedSchools) && selectedSchools.length > 0;
    } else {
      isOpen = nextState.isOpen;
    }

    if (
      nextProps.closeFilter === true &&
      nextState.toggleClicked === false &&
      nextProps.defaultOpen === true
    ) {
      isOpen = true;
    }

    return {
      divisions,
      isOpen,
      toggleClicked: false,
      unsignedInteractedWith: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  _formatForDropdown(divisions) {
    const final = [];

    divisions.forEach((division) => {
      const curDivision = {
        checked: false,
        children: [],
        id: division.id,
        label: division.name,
      };

      division.conferences.forEach((conference) => {
        const conferenceEntry = {
          checked: false,
          children: [],
          id: conference.id,
          label: conference.name,
        };

        conference.colleges.forEach((college) => {
          const collegeEntry = {
            checked: false,
            id: college.college_sport_id,
            label: college.name,
          };

          conferenceEntry.children.push(collegeEntry);
        });

        curDivision.children.push(conferenceEntry);
      });

      final.push(curDivision);
    });

    return final;
  }

  /**
   * Trigger off values that should be sent to store
   *
   * values auto-open which can't happen
   *
   * @param clicked
   * @param selected
   * @param parent
   */
  onChange(clicked, selected, parent) {
    const values = {
      no_fbs: this.props.values.no_fbs,
      no_offers: this.props.values.no_offers,
      no_p5: this.props.values.no_p5,
      values: [],
    };

    const clickedLabel = clicked.label;

    const selectedDivision = this._getSelectedDivision(clickedLabel);

    if (selectedDivision.label === 'Power 5') {
      values.no_fbs = false;
      values.no_p5 = false;
    }

    if (selectedDivision.label === 'Group of 5') {
      values.no_fbs = false;
    }

    selected.forEach((option) => {
      if (typeof option.children !== 'undefined') {
        const selectedChildIdsNew = this.getCheckedIdsOfChildren(option).flat(Infinity);

        values.values = values.values.concat(selectedChildIdsNew);
      } else {
        values.values.push(option.id);
      }
    });

    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        active: values.length > 0,
        activity_request_level,
        type: id,
        values,
      },
    });
  }

  /**
   * Change value of one of the three checkboxes on the page
   *
   * @param key: string  - which checkbox/part of values to change
   */
  toggleCheckbox(key) {
    const values = Object.assign({}, this.props.values);

    values[key] = !values[key];

    if (key === 'no_fbs') {
      values.no_offers = false;
      values.no_p5 = false;

      this.state.divisions.forEach((division) => {
        if (division.label === 'Power 5' || division.label === 'Group of 5') {
          values.values = this._removeP5G5(values.values, division);
        }
      });
    } else if (key === 'no_p5') {
      values.no_fbs = false;
      values.no_offers = false;

      this.state.divisions.forEach((division) => {
        if (division.label === 'Power 5') {
          values.values = this._removeP5G5(values.values, division);
        }
      });
    } else if (key === 'no_offers') {
      values.no_fbs = false;
      values.no_p5 = false;
      values.values = [];
    }

    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type: id,
        values,
      },
    });
  }

  _removeP5G5(values, division) {
    division.children.forEach((conference) => {
      if (typeof conference.children !== 'undefined') {
        conference.children.forEach((school) => {
          const schoolIndex = values.indexOf(school.id);
          if (schoolIndex > -1) {
            values.splice(schoolIndex, 1);
          }
        });
      } else {
        const confIndex = values.indexOf(conference.id);
        if (confIndex > -1) {
          values.splice(confIndex, 1);
        }
      }
    });

    return values;
  }

  /**
   * Recursive function to dig through a tree and check if they are selected or not
   *
   * @param option
   * @return {*[]|*}
   */
  getCheckedIdsOfChildren(option) {
    if (Array.isArray(option.children)) {
      return option.children.map((child) => {
        return this.getCheckedIdsOfChildren(child);
      });
    }

    if (option.checked === true) {
      return [option.id];
    }

    return [];
  }

  _getSelectedDivision(clickedLabel) {
    return this.state.divisions.find((division) => {
      /**
       * If the clicked label is Power 5, just skip the next block of code and return
       */
      let divisionMatch = clickedLabel === division.label;
      if (divisionMatch === false && typeof division.children !== 'undefined') {
        /**
         * Go through the conferences and see if it's clicked. Useful for when P5 -> SEC is clicked
         */
        divisionMatch =
          division.children.find((conference) => {
            /**
             * Set conference is in P5 if clicked and current conference labels are the same
             */
            let conferenceInP5 = conference.label === clickedLabel;

            if (conferenceInP5 === false && typeof conference.children !== 'undefined') {
              /**
               * See if the school that was clicked matches
               */
              conferenceInP5 =
                conference.children.find((school) => {
                  return school.label === clickedLabel;
                }) !== undefined;
            }

            return conferenceInP5;
          }) !== undefined;
      }

      return divisionMatch;
    });
  }

  render() {
    const { id, values, label, classes, active, collapsible, addActiveCheckbox } = this.props;
    const { divisions, isOpen, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <FilterComponent
        id={id}
        active={active}
        addActiveCheckbox={addActiveCheckbox}
        classes={classes}
        collapsible={collapsible}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        <div className='nullable'>
          <Checkbox
            tabIndex={0}
            id='no_offers'
            name='no_offers'
            label='No Offers'
            checked={values.no_offers}
            onChange={this.toggleCheckbox.bind(this, 'no_offers')}
          />
        </div>
        <div className='nullable'>
          <Checkbox
            tabIndex={0}
            id='no_fbs'
            name='no_fbs'
            label='No FBS'
            checked={values.no_fbs}
            onChange={this.toggleCheckbox.bind(this, 'no_fbs')}
          />
        </div>
        <div className='nullable'>
          <Checkbox
            tabIndex={0}
            id='no_p5'
            name='no_p5'
            label='No P5'
            checked={values.no_p5}
            onChange={this.toggleCheckbox.bind(this, 'no_p5')}
          />
        </div>
        <NestedDropdownFilterComponent
          data={divisions}
          onChange={this.onChange.bind(this)}
          placeholder='School, Conference, or Division'
          parent={true}
          root={true}
          columns={1}
          rootExpanded={isOpen}
          depth={0}
          inputId={label}
          label={id}
        />
        <TagListComponent data={divisions} onChange={this.onChange.bind(this)} />
      </FilterComponent>
    );
  }
}

OffersFilterComponent.defaultProps = {
  values: {
    no_fbs: false,
    no_offers: false,
    no_p5: false,
    values: [],
  },
  isOpen: false,
  active: false,
  collapsible: false,
  closeFilter: false,
  defaultOpen: false,
  addActiveCheckbox: false,
  label: 'Label',
  classes: '',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

OffersFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  isOpen: PropTypes.bool,
  active: PropTypes.bool,
  collapsible: PropTypes.bool,
  closeFilter: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  addActiveCheckbox: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default OffersFilterComponent;
