import React, { Component } from 'react';

/**
 * Services
 */
import PlayersService from 'Services/players';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Styles
 */
import 'RootComponents/prospects/components/sourcesPreview/styles/_index.scss';

class RatingPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: true,
      linkableSources: ['hudl', 'ncsa', 'rivals', 'twoFourSeven', 'relative_athletic_score'],
      sourcesArr: [],
    };
  }

  componentDidMount() {
    PlayersService.getPlayerYearSources(this.props.playerId)
      .then((response) => {
        this.setState({
          isLoading: false,
          sourcesArr: response,
          errorThrown: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorThrown: true,
        });
      });
  }

  createPlayerSources(source) {
    let link = null;

    if (this.state.linkableSources.indexOf(source.source.name) > -1) {
      switch (source.source.name) {
        case 'hudl':
          link = `https://www.hudl.com${source.source_id}`;

          break;
        case 'ncsa':
          link = `http://recruit-match.ncsasports.org/clientrms/athletes/${source.source_id}`;

          break;
        case 'rivals':
          link = `https://n.rivals.com/content/prospects/${source.source_id}/`;

          break;
        case 'relative_athletic_score':
          link = `https://ras.football/zcruit-ras-card/?PlayerID=${source.source_id}`;

          break;
        case 'twoFourSeven':
          link = `https://247sports.com/player/${source.source_id}/`;

          break;
        default:
          break;
      }
    }

    return (
      <li className='source-rating-item' key={`source-rating-logo-holder-${source.id}`}>
        <IconComponent
          color={source.source.name}
          icon={source.source.name}
          label={source.source.label}
          link={link}
        />
        <a href={link} target='_blank' rel='noopener noreferrer'>
          {source.source.label}
        </a>
      </li>
    );
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <ul className='tooltip-list text-left'>
          <MessagingComponent
            thrown={this.state.isLoading}
            message={STATUS_MESSAGE.loading}
            type={MESSAGE_TYPE.warning}
          />
        </ul>
      );
    }

    if (this.state.errorThrown === true) {
      return (
        <ul className='tooltip-list text-left'>
          <MessagingComponent
            thrown={this.state.errorThrown}
            message={NO_DATA_MESSAGE.ratings}
            type={MESSAGE_TYPE.danger}
          />
        </ul>
      );
    }

    return (
      <ul className='tooltip-list text-left sources'>
        {this.state.sourcesArr.map((source) => {
          return this.createPlayerSources(source);
        })}
      </ul>
    );
  }
}

export default RatingPreview;
