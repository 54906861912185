import React, { Component } from 'react';
import { Col } from 'reactstrap';
import LikeTrendsListItem from './LikeTrendsListItem';

class LikeTrendsList extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.likeTrendsListData.length !== this.props.likeTrendsListData.length) {
      return true;
    }

    return true;
  }

  sortList(listData) {
    const sortedData = listData.sort((a, b) => {
      if (parseFloat(a.percentLiked) < parseFloat(b.percentLiked)) {
        return 1;
      }
      if (parseFloat(a.percentLiked) > parseFloat(b.percentLiked)) {
        return -1;
      }
      return 0;
    });
    return sortedData;
  }

  renderListItems() {
    let resultList;
    if (this.props.likeTrendsListData.length > 0) {
      resultList = this.sortList(this.props.likeTrendsListData).map((item) => {
        const keyId = `${item.userRole}-${item.schoolName}-${item.percentLiked}`;
        return <LikeTrendsListItem {...item} key={keyId} />;
      });
    } else {
      resultList = (
        <div className='item'>
          <p>No Data Available</p>
        </div>
      );
    }
    return resultList;
  }

  render() {
    return (
      <Col md={4} className='list-wrap no-padding'>
        {this.renderListItems()}
      </Col>
    );
  }
}

export default LikeTrendsList;
