import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

/**
 * Components
 */
import IconComponent from '../../../common/icon';

/**
 * Services
 */
import FilterService from '../../../../services/filters';

/**
 * Styles
 */
import './styles/_index.scss';

class DeleteFilterConfirmModalComponent extends Component {
  saveHandler() {
    this.props.saveHandler(this.state.name);
  }

  deleteFilter(event) {
    event.stopPropagation();

    FilterService.deleteFilterNew(this.props.filter.id).then((response) => {
      this.props.getFilters();

      this.props.toggle();
    });
  }

  render() {
    if (typeof this.props.filter === 'undefined') {
      return null;
    }

    return (
      <Modal
        className='delete-filter-confirm-modal'
        isOpen={this.props.modalOpen}
        modalClassName='delete-filter-confirm-modal'
        toggle={this.props.toggle}
        aria-modal={true}
        labelledBy='delete-filter-title'>
        <Row
          className='close-modal-container'
          onClick={this.props.toggle}
          onKeyUp={this.props.toggle}
          role='button'
          tabIndex={-1}>
          <Col md={12} className='close-modal'>
            <IconComponent icon='x fas fa-times' label='Close Filters' />
          </Col>
        </Row>
        <ModalBody>
          <h3 id='delete-filter-title'>Delete Filter</h3>
          <p>
            Are you sure you want to delete the {this.props.filter.name} filter from your saved?
          </p>
          <Button className='red' color='primary' onClick={this.deleteFilter.bind(this)}>
            Delete Filter
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default DeleteFilterConfirmModalComponent;
