import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import PowerDetailsRole from './PowerDetailsRole';

class PowerDetailsSpread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,
    };
  }

  render() {
    return (
      <Col md={12} className='power-details-spread-row-container'>
        <Row>
          <Col md={12}>
            <h4 className='power-details-spread-type'>
              {this.state.type} ({this.props.twitterData.total})
            </h4>
          </Col>
        </Row>
        <Container>
          <Row className='power-details-row d-flex justify-content-between'>
            <PowerDetailsRole
              accountType='School Accounts'
              followList={this.props.twitterData['School Accounts']}
            />
            <PowerDetailsRole accountType='Coaches' followList={this.props.twitterData.Coaches} />
            <PowerDetailsRole
              accountType='Support Staff'
              followList={this.props.twitterData['Support Staff']}
            />
            <PowerDetailsRole accountType='Roster' followList={this.props.twitterData.Roster} />
            <PowerDetailsRole accountType='Commits' followList={this.props.twitterData.Commits} />
          </Row>
        </Container>
      </Col>
    );
  }
}

export default PowerDetailsSpread;
