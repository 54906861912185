import axios from 'axios';

export const SEARCH_BY_NAME_PARAMS = {
  limit: 1000,
  'sort[sortBy]': 'name',
  'sort[sortDirection]': 'ASC',
};

class HighSchoolsService {
  constructor() {
    this.cancelTokens = [];
  }

  get({ data, params = SEARCH_BY_NAME_PARAMS, isDropdown = false }) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'POST',
      url: `${process.env.API_URL}highschool/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
      data,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        if (isDropdown) {
          return response.data.data.map((single) => {
            let name = single.name;

            if (typeof single.city !== 'undefined') {
              name = `${name} (${single.city.name}`;

              if (typeof single.state !== 'undefined') {
                name = `${name}, ${single.state.name})`;
              } else {
                name = `${name})`;
              }
            } else if (single.state !== 'undefined') {
              name = `${name} (${single.state.name})`;
            }

            return {
              checked: false,
              id: single.id,
              label: name,
            };
          });
        }

        return response.data.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  cancelCall(message) {
    this.cancelTokens.forEach((token) => {
      token.cancel(message);
    });
  }
}

export default new HighSchoolsService();
