import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FourOhFoComponent extends Component {
  render() {
    return (
      <div>
        <p>NOT FOUND!!! 404</p>
        <Link to='/' href='/'>
          Back
        </Link>
      </div>
    );
  }
}
export default FourOhFoComponent;
