/* eslint quote-props: 0 */

const schools = {
  'Arizona State': {
    forYou: {
      filters: {
        location: {
          state: [
            'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
            '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
            '3eba7bf3-6f54-42ed-b4cf-da29e25dd716',
            'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
            'c2eca9d8-8b9b-4340-a044-3f4b6a48298a',
            'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
            'd04587d7-0dd9-459d-84fe-f9cc1296eeb7',
            'aa7f7f3a-d264-416a-b610-ac18ac369283',
            'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
            '5d1e32b8-e846-4a5f-8565-571fec096a51',
            '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
            'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
            '6474741f-8744-494d-822d-6ca26b66e951',
            'a5c7e2ba-1e6a-4196-aa8b-3a2bf6ee87f4',
          ],
          counties: [],
        },
        counties: [],
        state: [
          'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
          '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
          '3eba7bf3-6f54-42ed-b4cf-da29e25dd716',
          'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
          'c2eca9d8-8b9b-4340-a044-3f4b6a48298a',
          'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
          'd04587d7-0dd9-459d-84fe-f9cc1296eeb7',
          'aa7f7f3a-d264-416a-b610-ac18ac369283',
          'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
          '5d1e32b8-e846-4a5f-8565-571fec096a51',
          '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
          'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
          '6474741f-8744-494d-822d-6ca26b66e951',
          'a5c7e2ba-1e6a-4196-aa8b-3a2bf6ee87f4',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  'Florida Atlantic': {
    forYou: {
      filters: {
        location: {
          state: [
            '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
            '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
            '90475874-8207-477d-b831-225bfe0858af',
          ],
          counties: [],
        },
        counties: [],
        state: [
          '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
          '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
          '90475874-8207-477d-b831-225bfe0858af',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  Nevada: {
    forYou: {
      filters: {
        location: {
          state: [
            '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
            'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
            '5d1e32b8-e846-4a5f-8565-571fec096a51',
            '6474741f-8744-494d-822d-6ca26b66e951',
            'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
            'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
            'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
          ],
          counties: [],
        },
        counties: [],
        state: [
          '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
          'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
          '5d1e32b8-e846-4a5f-8565-571fec096a51',
          '6474741f-8744-494d-822d-6ca26b66e951',
          'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
          'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
          'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  'Old Dominion': {
    forYou: {
      filters: {
        location: {
          state: [
            'cc259b0d-857b-4d7e-8bcf-b55e98044cee',
            '7a69f342-c703-43a3-bbaf-99e50a6231d5',
            '914f5c8c-4187-4405-883a-f4e4a4db6eb4',
            '8bfb92f9-b622-4c70-a5c5-ccd7199ace15',
            'dcc0d6d1-b20a-46a7-9ed9-87a3cbef1c5e',
            '3b3e9161-b99b-4b87-8057-9101f3a99ef7',
            'e16d2e1f-ecb3-4286-80fa-142c24714363',
            '039ce592-9c99-429e-96e9-95b32837a81d',
          ],
          counties: [],
        },
        counties: [],
        state: [
          'cc259b0d-857b-4d7e-8bcf-b55e98044cee',
          '7a69f342-c703-43a3-bbaf-99e50a6231d5',
          '914f5c8c-4187-4405-883a-f4e4a4db6eb4',
          '8bfb92f9-b622-4c70-a5c5-ccd7199ace15',
          'dcc0d6d1-b20a-46a7-9ed9-87a3cbef1c5e',
          '3b3e9161-b99b-4b87-8057-9101f3a99ef7',
          'e16d2e1f-ecb3-4286-80fa-142c24714363',
          '039ce592-9c99-429e-96e9-95b32837a81d',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  Samford: {
    forYou: {
      filters: {
        location: {
          state: [
            '90475874-8207-477d-b831-225bfe0858af',
            '5db6fc7d-804e-4e6f-ac6b-ae730b581052',
            '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
            'd6b342f5-e8ce-4cff-af7c-df1d294361fc',
            '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
          ],
          counties: [],
        },
        counties: [],
        state: [
          '90475874-8207-477d-b831-225bfe0858af',
          '5db6fc7d-804e-4e6f-ac6b-ae730b581052',
          '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
          'd6b342f5-e8ce-4cff-af7c-df1d294361fc',
          '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  'South Alabama': {
    forYou: {
      filters: {
        location: {
          state: [
            '90475874-8207-477d-b831-225bfe0858af',
            '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
            '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
            'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
            '5db6fc7d-804e-4e6f-ac6b-ae730b581052',
          ],
          counties: [],
        },
        counties: [],
        state: [
          '90475874-8207-477d-b831-225bfe0858af',
          '4c969ffa-ed3d-4549-86fb-2a76b82c601a',
          '9c2c60cc-13a6-431b-b14b-b7898225bcf1',
          'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
          '5db6fc7d-804e-4e6f-ac6b-ae730b581052',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 'discover',
    },
  },
  USF: {
    forYou: {
      filters: {
        location: {
          state: ['4c969ffa-ed3d-4549-86fb-2a76b82c601a', '9c2c60cc-13a6-431b-b14b-b7898225bcf1'],
          counties: [],
        },
        counties: [],
        state: ['4c969ffa-ed3d-4549-86fb-2a76b82c601a', '9c2c60cc-13a6-431b-b14b-b7898225bcf1'],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  UTEP: {
    forYou: {
      filters: {
        location: {
          state: [
            'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
            '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
            '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
            'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
          ],
          counties: [],
        },
        counties: [],
        state: [
          'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
          '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
          '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
          'f4b4ebb0-7afe-43a4-819b-2bc9fe35353c',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  Washington: {
    forYou: {
      filters: {
        location: {
          state: [
            'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
            '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
            '3eba7bf3-6f54-42ed-b4cf-da29e25dd716',
            'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
            'c2eca9d8-8b9b-4340-a044-3f4b6a48298a',
            'aa7f7f3a-d264-416a-b610-ac18ac369283',
            'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
            '5d1e32b8-e846-4a5f-8565-571fec096a51',
            '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
            'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
            '6474741f-8744-494d-822d-6ca26b66e951',
            'a5c7e2ba-1e6a-4196-aa8b-3a2bf6ee87f4',
          ],
          counties: [],
        },
        counties: [],
        state: [
          'a30114b2-f649-42aa-9b6e-3d22ed3fb4df',
          '936fcce6-ec37-4e6e-b473-7f1203ad3fc0',
          '3eba7bf3-6f54-42ed-b4cf-da29e25dd716',
          'e97e7ee5-7ae8-44ee-a35f-8e69827bc3f8',
          'c2eca9d8-8b9b-4340-a044-3f4b6a48298a',
          'aa7f7f3a-d264-416a-b610-ac18ac369283',
          'a63cccf6-f3b3-4086-81d7-e20b8122eed6',
          '5d1e32b8-e846-4a5f-8565-571fec096a51',
          '2b0d6f3b-3d86-44d1-bd8f-8962685b26f4',
          'e92d94e9-5a12-49b9-adad-6b953fa4cca7',
          '6474741f-8744-494d-822d-6ca26b66e951',
          'a5c7e2ba-1e6a-4196-aa8b-3a2bf6ee87f4',
        ],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
  default: {
    forYou: {
      filters: {
        location: { state: [], counties: [] },
        counties: [],
        state: [],
        years: [2022],
        position: ['all'],
        rating: {
          247: false,
          range: [0, 100],
          all: true,
          np: false,
          xos: false,
        },
        offers: [],
        sources: [],
        measureables: {
          height: {
            operator: 'greater',
            verified: false,
            feet: 0,
            inches: 0,
          },
          weight: {
            operator: 'greater',
            verified: false,
            pounds: 0,
          },
        },
        playing_level: {
          all: true,
          high_school: false,
          junior_college: false,
        },
        gpa: {
          range: [0, 4.0],
          no_gpa: true,
        },
        name: '',
      },
      name: 'For You',
      creator: 'default',
      type: 2,
    },
  },
};

export default schools;
