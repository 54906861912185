import DateTimeService from '../services/date-time';

export const DEFAULT_NULL_VALUE = '-';

export default class PlayersTwitterModel {
  constructor(player) {
    this.dateTimeService = new DateTimeService();
    this.originalPlayer = Object.assign({}, player);

    Object.assign(this, player);
  }

  getCommitDate(format) {
    if (typeof this.commit !== 'undefined') {
      return this.dateTimeService.formatUTCDate(new Date(this.commit.start_date), format);
    }

    return DEFAULT_NULL_VALUE;
  }

  getCommitSchool() {
    if (typeof this.commit !== 'undefined') {
      return this.commit.college.name;
    }
  }

  getSignedSchool() {
    if (typeof this.signed !== 'undefined') {
      return this.signed.college.name;
    }
  }

  getSignedDate(format) {
    if (typeof this.signed !== 'undefined') {
      return this.dateTimeService.formatUTCDate(new Date(this.signed.start_date), format);
    }

    return DEFAULT_NULL_VALUE;
  }

  hasCommit() {
    return typeof this.commit !== 'undefined';
  }

  hasSigning() {
    return typeof this.signed !== 'undefined';
  }
}
