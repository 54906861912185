import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import IconComponent from 'CommonComponents/icon';
import VerifiedIcon from 'CommonComponents/verifiedIcon';
import { METRIC_TYPES, VALID_METRIC_TYPES_LIST } from 'PlayerPageComponents/pages/stats/constants';

class OverviewViewStatsPlayerPageComponent extends Component {
  buildStat() {
    const validStats = VALID_METRIC_TYPES_LIST.filter(
      (stat) => stat !== METRIC_TYPES.height && stat !== METRIC_TYPES.weight
    );
    const maxStatElements = 4;
    const html = validStats
      .filter((stat) => !stat.toLowerCase().includes('rating') && this.props.stats.metrics[stat])
      .map((stat) => {
        const found = this.props.stats.metrics[stat].find(
          (single) => validStats.includes(single.type) && single
        );

        if (found) {
          return (
            <Col md={3} className={found.type} key={`${found.id}`}>
              <h3>{found.label}</h3>
              <p>
                {found.raw_value}&nbsp;
                <span className='small'>{found.unit}</span>
                <VerifiedIcon verified={found.verified} subSource={found.subSource} />
              </p>
              <IconComponent color='grey' icon={found.source.name} label={found.source.label} />
            </Col>
          );
        }

        return undefined;
      })
      .filter(Boolean)
      .slice(0, maxStatElements);

    if (html.length === 0) {
      html.push(
        <Col md={3} key='forty'>
          <h3>Forty</h3>
          <p>-</p>
        </Col>,
        <Col md={3} key='shuttle'>
          <h3>Shuttle</h3>
          <p>-</p>
        </Col>,
        <Col md={3} key='vertical-jump'>
          <h3>Vertical Jump</h3>
          <p>-</p>
        </Col>,
        <Col md={3} key='broad-jump'>
          <h3>Broad Jump</h3>
          <p>-</p>
        </Col>
      );
    }

    return html;
  }

  render() {
    return (
      <Col md={8}>
        <div className='box overview' tabIndex={0} role='tablist' aria-label='Statistics Overview'>
          <h2>Stat Overview</h2>
          <Row>{this.buildStat()}</Row>
        </div>
      </Col>
    );
  }
}

export default OverviewViewStatsPlayerPageComponent;
