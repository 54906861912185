import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import MessagingComponent, {
  ERROR_MESSAGE,
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import RivalsCampHeader from 'RootComponents/rivalsCamp/components/header';
import RivalsCampEventPage from 'RootComponents/rivalsCamp/components/event-list';
import SingleVideoComponent from 'RootComponents/rivalsCamp/components/singleVideo';
import EventsService from 'Services/events';
import PositionsService from 'Services/positions';
import VideosService from 'Services/videos';
import 'RootComponents/rivalsCamp/styles/_index.scss';

const RivalsCampComponent = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);
  const [positions, setPositions] = useState([]);
  const [filters, setFilters] = useState({ positions: null, name: null });

  useEffect(() => {
    const loadPositions = async () => {
      try {
        const positionsData = await PositionsService.get();
        setPositions([{ checked: true, id: 'all', name: 'All' }].concat(positionsData));
      } catch (error) {
        console.error('Failed to load positions:', error);
        setError(true);
      }
    };

    const loadEventsAndVideos = async () => {
      try {
        let eventsData = await EventsService.find();

        eventsData = eventsData.sort((a, b) => {
          const dateA = new Date(a.occurred_on);
          const dateB = new Date(b.occurred_on);
          return dateB - dateA;
        });

        for (let event of eventsData) {
          VideosService.getVideosForEvent({ event_id: event.id })
            .then((videos) => {
              const sortedVideos = videos.sort((a, b) => new Date(a.date) - new Date(b.date));

              setEvents((prevEvents) => {
                const existingEventIndex = prevEvents.findIndex((e) => e.id === event.id);
                if (existingEventIndex !== -1) {
                  const updatedEvents = [...prevEvents];
                  updatedEvents[existingEventIndex] = {
                    ...updatedEvents[existingEventIndex],
                    videos: sortedVideos,
                  };
                  return updatedEvents;
                } else {
                  return [...prevEvents, { ...event, videos: sortedVideos }];
                }
              });
            })
            .catch((err) => {
              console.error(`Failed to load videos for event ${event.id}:`, err);
            });
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load events:', error);
        setError(true);
      }
    };

    setIsLoading(true);
    loadPositions();
    loadEventsAndVideos();
  }, [match.params.id]);

  const filterChanged = React.useCallback((type, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [type]: value }));
  }, []);

  if (error) {
    return <MessagingComponent message={ERROR_MESSAGE.films} type={MESSAGE_TYPE.danger} />;
  }

  if (isLoading) {
    return <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />;
  }

  return (
    <Row className='rivals-camp'>
      <Col className='page-content' md={12}>
        <RivalsCampHeader
          filterChanged={filterChanged}
          events={[{ checked: true, id: 'all', name: 'All' }].concat(events)}
          positions={[{ checked: true, id: 'all', name: 'All' }].concat(positions)}
          filterTypes={Object.keys(filters)}
          name={filters.name}
        />
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            {match.params.id ? (
              <SingleVideoComponent videoId={match.params.id} history={history} />
            ) : events.length === 0 ? (
              <MessagingComponent message={NO_DATA_MESSAGE.events} type={MESSAGE_TYPE.danger} />
            ) : (
              <RivalsCampEventPage
                events={events.sort((a, b) => new Date(b.occurred_on) - new Date(a.occurred_on))}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RivalsCampComponent;
