import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'underscore';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import RatingsService from 'Services/ratings';

/**
 * Styles
 */
import 'PlayerPageComponents/modal/boxes/components/talent/styles/_index.scss';

class PlayerPageModalTalentBoxComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isLoading: true,
      stats: [],
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    RatingsService.getRatingsForPlayer(this.props.player.id, {
      format: 'type',
    }).then(
      (response) => {
        this.setState({
          isLoading: false,
          stats: response,
        });
      },
      () => {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    );
  }

  buildStats() {
    const validKeys = ['ncsa_rating', 'npRating', 'rating247', 'rivalsRating'];

    const labels = ['NCSA', 'National Preps', '247Sports', 'Rivals.com'];

    return _.compact(
      validKeys.map((key, index) => {
        const metricKeys = Object.keys(this.state.stats.metrics);

        if (metricKeys.indexOf(key) === -1 && key !== 'ncsa_rating') {
          return (
            <Col md={4} key={key}>
              <IconComponent color='grey' icon={key} label={labels[index]} />
              &mdash;
            </Col>
          );
        }

        if (typeof this.state.stats.metrics[key] === 'undefined' && key === 'ncsa_rating') {
          return null;
        }

        let value;

        if (/([^0-9])/g.test(this.state.stats.metrics[key][0].value) === false) {
          value = parseFloat(this.state.stats.metrics[key][0].value).toFixed(2);
        } else {
          value = this.state.stats.metrics[key][0].value;
          if (value === 'none') {
            value = '—';
          }
        }

        return (
          <Col md={4} key={key}>
            <IconComponent
              color='grey'
              icon={this.state.stats.metrics[key][0].source.name}
              label={this.state.stats.metrics[key][0].source.label}
            />
            {value}
          </Col>
        );
      })
    );
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <Col md={4} className='box talent'>
          <h2>Talent</h2>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </Col>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <Col md={4} className='box talent'>
          <h2>Talent</h2>
          <div className='talent_holder'>
            <MessagingComponent message={NO_DATA_MESSAGE.talent} />
          </div>
        </Col>
      );
    }

    const rating = this.props.player.getRating(1);

    return (
      <Col md={4} className='box talent' role='document' aria-label='Talent' tabIndex={0}>
        <h2>Talent</h2>
        <div className='talent_holder'>
          <Col md={12}>
            <h3>
              {rating} | {RatingsService.getPlayerLevel(rating)}
            </h3>
          </Col>
          <Row className='justify-content-center'>{this.buildStats()}</Row>
        </div>
      </Col>
    );
  }
}

export default PlayerPageModalTalentBoxComponent;
