import React from 'react';

/**
 * Common
 */
import MultiSelectFilterComponent from '../../common/multi_select';

/**
 * Parent
 */
import CommonComponent from '../../../../../generic';

/**
 * Store
 */
import store from '../../../../../../../reducers/store';

/**
 * Styles
 */
import './styles/_index.scss';

class SchoolTypeFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    ) {
      isOpen = true;
    }

    this.state = {
      activity_request_level: this.props.activity_request_level || '',
      classes: this.props.classes,
      filter_action_type: this.props.filter_action_type || 'FILTER_UPDATED',
      id: this.props.id || 'school_type',
      isOpen,
      label: this.props.label || 'SCHOOL TYPE',
      levels: [
        {
          id: 'all',
          name: 'All',
          selected: true,
        },
        {
          id: 'public',
          name: 'Public HS',
          selected: false,
        },
        {
          id: 'private',
          name: 'Private HS',
          selected: false,
        },
        {
          id: 'catholic',
          name: 'Catholic HS',
          selected: false,
        },
        {
          id: 'juco',
          name: 'JUCO',
          selected: false,
        },
      ],
      schoolTypes: {
        all: false,
        private: false,
        public: false,
        catholic: false,
        juco: false,
      },
      selected: ['all'],
      collapsible: typeof this.props.collapsible === 'undefined' ? true : this.props.collapsible,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    nextState.selected = [];
    const notSelected = [];
    if (typeof nextProps.values !== 'undefined' && Object.keys(nextProps.values).length > 0) {
      nextState.levels = nextState.levels.map((option) => {
        if (nextProps.values[option.id] === true) {
          option.selected = true;
          nextState.selected.push(option.id);
        } else {
          option.selected = false;
          notSelected.push(option.id);
        }
        return option;
      });
    }
    if (notSelected.length === 0 || (notSelected.length === 1 && notSelected.includes('all'))) {
      nextState.selected = [];
      nextState.levels = nextState.levels.map((option) => {
        option.selected = false;
        return option;
      });
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      nextState.isOpen = nextState.selected.length > 0;
    }

    nextState.toggleClicked = false;

    return nextState;
  }

  onChange(values) {
    const updatedValues = JSON.parse(JSON.stringify(this.state.schoolTypes));
    const keys = Object.keys(updatedValues);
    values.forEach((value) => {
      if (keys.includes(value)) {
        updatedValues[value] = true;
      }
    });

    store.dispatch({
      type: this.state.filter_action_type,
      filter: {
        activity_request_level: this.state.activity_request_level,
        type: this.state.id,
        values: updatedValues,
      },
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  render() {
    return (
      <MultiSelectFilterComponent
        activity_request_level={this.state.activity_request_level}
        filter_action_type={this.state.filter_action_type}
        classes={`school_type ${this.state.classes}`}
        collapsible={this.state.collapsible}
        collapseClass='third'
        id={this.state.id}
        isOpen={this.state.isOpen}
        selected={this.state.selected}
        label={this.state.label}
        onChange={this.onChange.bind(this)}
        values={this.state.levels}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}
        closeFilter={this.props.closeFilter}
      />
    );
  }
}

export default SchoolTypeFilterComponent;
