import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

/**
 * Components
 */
import VerifiedIcon from 'RootComponents/common/verifiedIcon';
import { isTimeValidVerifiedMetric } from 'RootComponents/utility/helpers';
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import StatisticsService from 'Services/stats';

/**
 * Styles
 */
import 'PlayerPageComponents/modal/boxes/components/stats/styles/_index.scss';

class PlayerPageModalStatsBoxComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      html: null,
      isLoading: true,
      stats: [],
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    StatisticsService.getStatsForPlayer(this.props.player.id, 'type').then(
      (response) => {
        this.setState(
          {
            isLoading: false,
            stats: response,
          },
          () => {
            this.buildStats();
          }
        );
      },
      () => {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    );
  }

  selectTab(url) {
    if (this.props.page === 'none') {
      this.props.setTab(url);
      return;
    }

    if (url === '') {
      return this.props.history.push(`/${this.props.page}/${this.props.player.id}/`);
    }

    this.props.history.push(`/${this.props.page}/${this.props.player.id}/${url}/`);
  }

  buildStats() {
    const validStatKeys = [
      'forty',
      'shuttle',
      'verticalJump',
      'broadJump',
      'threeCone',
      'powerBall',
      'sparq',
      'wingspan',
      'armLength',
      'reach',
      'handSize',
    ];

    const sortedMetricKeys = Object.keys(this.state.stats.metrics).sort((a, b) => {
      return validStatKeys.indexOf(a) - validStatKeys.indexOf(b);
    });

    let html = sortedMetricKeys.filter(Boolean).map((statKey) => {
      const stat = this.state.stats.metrics[statKey][0];

      const { id, verified, date, value, label, source, default: statDefault } = stat;
      const isVerified = verified && isTimeValidVerifiedMetric(date);

      return (
        <div className='stat_holder_stat' key={`video-${id}`}>
          <div className='stat_holder_stat_stat'>
            <div className='info'>
              <h4>{label}</h4>
              <div className='bottom'>
                <p>{value}</p>
                <div className='bottom_icons'>
                  <VerifiedIcon verified={isVerified} type='circle' />

                  {statDefault && (
                    <div className='star-holder'>
                      <i className='icon star fas fa-star' />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <IconComponent
              color='grey'
              icon={source.name}
              label={source.label}
              shift_right={true}
            />
          </div>
        </div>
      );
    });

    if (html.length === 0) {
      this.setState({
        error: true,
      });

      return;
    }
    // Display a max of 3 stats
    html = html.slice(0, 3);

    this.setState({
      error: false,
      html,
    });
  }

  renderStatBox() {
    const { isLoading, error } = this.state;

    if (isLoading) {
      return (
        <>
          <h2>Stats</h2>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </>
      );
    }

    if (error) {
      return (
        <>
          <h2>Stats</h2>
          <div className='stat_holder_stat'>
            <MessagingComponent message={NO_DATA_MESSAGE.stats} />
          </div>
        </>
      );
    }

    return (
      <>
        <h2>Stats</h2>
        <Link
          to={`/${this.props.page}/${this.props.player.id}/stats`}
          aria-label={`View ${this.props.player.name}'s stats`}>
          <div className='stat_holder'>{this.state.html}</div>
        </Link>
      </>
    );
  }

  render() {
    return (
      <Col md={4} className='box stat_holder'>
        {this.renderStatBox()}
      </Col>
    );
  }
}

export default withRouter(PlayerPageModalStatsBoxComponent);
