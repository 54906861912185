import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap';

class ExportPlayerModal extends Component {
  _calculateTotal() {
    if (this.props.totalPlayers === 0) {
      return 0;
    }

    return (this.props.completePlayers / this.props.totalPlayers) * 100;
  }

  renderingDocumentMessage() {
    const total = this._calculateTotal();
    if (total === 100) {
      return (
        <div>
          <p>All report data received!</p>
          <p>Your document is being built and will be downloaded shortly.</p>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Modal
        className='track-players'
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        aria-modal={true}
        labelledBy='export-prospects'>
        <ModalBody>
          <ModalHeader toggle={this.toggle} id='export-prospects'>
            Export All Prospects
          </ModalHeader>
          <p>
            Hang tight! We have to get all your prospects. In a few seconds, your download will
            start. Once finished, you&apos;ll receive a document with all requested players!
          </p>
          <div>
            <span className='bold'>Progress</span>: {this.props.completePlayers}/
            {this.props.totalPlayers}
            <Progress animated={true} color='success' value={this._calculateTotal()} />
          </div>
          {this.renderingDocumentMessage()}
          <ModalFooter>
            <Button color='secondary' onClick={this.props.cancelRequests}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

export default ExportPlayerModal;
