import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';

import AdvancedFilterComponent from 'FilterComponents/common/advanced';
import Checkbox from 'CommonComponents/checkbox';

import store from 'Reducers/store';

export const INITIAL_OFFERS = {
  no_fbs: false,
  no_offers: false,
  no_p5: false,
  values: [],
};

export const INITIAL_COMMITS = {
  uncommitted: false,
  values: [],
};

function RecruitingActivitiesCore({
  id,
  commits,
  offers,
  filter_action_type,
  activity_request_level,
  closeFilter,
}) {
  const onChangeHandler = (type) => (values) => {
    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type,
        values,
      },
    });
  };

  const handleOffersChange = onChangeHandler('offers');
  const handleCommitsChange = onChangeHandler('commits');

  const isInitiallyOpen = !isEqual(offers, INITIAL_OFFERS) || !isEqual(commits, INITIAL_COMMITS);

  return (
    <AdvancedFilterComponent
      id={id}
      label='Recruiting Activity'
      isOpen={isInitiallyOpen}
      closeFilter={closeFilter}>
      <div className='nullable'>
        <Checkbox
          tabIndex={0}
          id='no_offers'
          name='no_offers'
          label='No Offers'
          checked={offers.no_offers}
          onChange={() => {
            handleOffersChange({
              ...offers,
              no_offers: !offers.no_offers,
            });
          }}
        />
      </div>
      <div className='nullable'>
        <Checkbox
          tabIndex={0}
          id='uncommitted'
          name='uncommitted'
          label='Uncommitted'
          checked={commits.uncommitted}
          onChange={() => {
            handleCommitsChange({
              ...commits,
              uncommitted: !commits.uncommitted,
            });
          }}
        />
      </div>
    </AdvancedFilterComponent>
  );
}

RecruitingActivitiesCore.defaultProps = {
  offers: INITIAL_OFFERS,
  commits: INITIAL_COMMITS,
  closerFilter: false,
  activity_request_level: 'details',
  filter_action_type: 'ACTIVITY_FILTER_UPDATED',
};

RecruitingActivitiesCore.propTypes = {
  id: PropTypes.string.isRequired,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
  commits: PropTypes.object,
  offers: PropTypes.object,
  closeFilter: PropTypes.bool,
};

export default RecruitingActivitiesCore;
