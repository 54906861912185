import React from 'react';
import classNames from 'classnames';
import InfoTooltip from 'CommonComponents/infoTooltip';
import {
  METRIC_TYPE_SUB_SOURCES,
  METRIC_TYPE_SUB_SOURCES_INFO,
} from 'PlayerPageComponents/pages/stats/constants';

import 'CommonComponents/verifiedIcon/styles/_index.scss';

const getIconClasses = (verified, subSource) => {
  let subSourceClasses = {};

  const verifiedClasses = {
    'dash-circle': !verified,
    icon: verified,
    'fas fa-check-circle': verified && !subSource,
    verified,
  };

  if (subSource) {
    subSourceClasses = {
      wilson: subSource.name === METRIC_TYPE_SUB_SOURCES.wilson,
    };
  }

  return classNames({
    ...verifiedClasses,
    ...subSourceClasses,
  });
};

export function VerifiedIconCircle({ verified, subSource }) {
  return (
    <span className='stat-icon-holder'>
      <i
        role='img'
        aria-label={verified ? 'Verified Metric' : 'Unverified Metic'}
        className={getIconClasses(verified, subSource)}
      />
      {subSource && <InfoTooltip content={METRIC_TYPE_SUB_SOURCES_INFO[subSource.name]()} />}
    </span>
  );
}

export function VerifiedIconCheckmark({ verified }) {
  if (verified) {
    return (
      <span className='stat-icon-holder'>
        <i
          role='img'
          aria-label='Verified Metric'
          className='icon verified checkmark fas fa-check'
        />
      </span>
    );
  }

  return null;
}

function VerifiedIcon({ verified = false, subSource = null, type = 'circle' }) {
  if (type === 'circle') {
    return <VerifiedIconCircle verified={verified} subSource={subSource} />;
  } else if (type === 'checkmark') {
    return <VerifiedIconCheckmark verified={verified} />;
  } else {
    return null;
  }
}

export default VerifiedIcon;
