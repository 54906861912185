import React from 'react';
import ContactInfo from '../contactInfo';
import PlayerContactInfoBody from './playerContactInfoBody';
import PlayerContactInfoCollapse from './playerContactInfoCollapse';

function PlayerContactInfo({ contact }) {
  return (
    <ContactInfo contact={contact}>
      <PlayerContactInfoBody />
      <PlayerContactInfoCollapse />
    </ContactInfo>
  );
}

export default PlayerContactInfo;
