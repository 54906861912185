import React from 'react';

/**
 * Components
 */
import TagComponent from './components/tag';

/**
 * Styles
 */
import './styles/_index.scss';

/**
 * Parent
 */
import CommonComponent from '../../../../../generic';

class TagList extends CommonComponent {
  onDelete(tagData) {
    const updatedData = this.uncheckTargetValue(this.props.data, tagData);

    this.props.onChange(tagData, updatedData);
  }

  uncheckTargetValue(values, target) {
    return values.map((value) => {
      if (target.id === value.id) {
        value.checked = false;
        if (value.children) {
          value.children = this.uncheckAllValues(value.children);
        }
      } else if (value.children) {
        value.children = this.uncheckTargetValue(value.children, target);
      }
      return value;
    });
  }

  uncheckAllValues(values) {
    return values.map((value) => {
      value.checked = false;
      if (value.children) {
        value.children = this.uncheckAllValues(value.children);
      }
      return value;
    });
  }

  findCheckedValues(values) {
    let selectedValues = [];

    values.forEach((value) => {
      if (value.checked === true) {
        selectedValues.push(value);
      } else if (value.children) {
        selectedValues = selectedValues.concat(this.findCheckedValues(value.children));
      }
    });
    return selectedValues;
  }

  createTags() {
    const activeTags = this.findCheckedValues(this.props.data);
    const tagComponents = activeTags.map((tagData) => {
      return <TagComponent data={tagData} onDelete={this.onDelete.bind(this)} key={tagData.id} />;
    });
    return tagComponents;
  }

  render() {
    return <ul className='tag-list'>{this.createTags()}</ul>;
  }
}

export default TagList;
