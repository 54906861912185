import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScrollTo } from 'react-scroll-to';
import { Col, Row } from 'reactstrap';
import PowerIcon from 'RootComponents/TwitterInsights/PowerIcon';
import PowerDetails from 'RootComponents/TwitterInsights/PowerDetails';
import MessagingComponent, { MESSAGE_TYPE, STATUS_MESSAGE } from 'CommonComponents/messager';

import 'RootComponents/TwitterInsights/styles/_index.scss';

class PowerRankings extends Component {
  constructor(props) {
    super(props);
    this.scrollCarousel = this.scrollCarousel.bind(this);
    this.state = {
      playerId: this.props.playerId,
      schoolList: [],
      detailsVisible: false,
      detailsSchool: 'none',
      active: '',
      scrollableRef: React.createRef(),
    };
  }

  checkActive(schoolName) {
    return schoolName === this.state.detailsSchool;
  }

  showSchoolDetails(school) {
    let newState;
    let active;
    if (this.state.active === '') {
      active = 'is-active';
    } else {
      active = '';
    }

    if (school === this.state.detailsSchool) {
      newState = {
        detailsVisible: !this.state.detailsVisible,
      };
    } else {
      newState = {
        detailsVisible: true,
        detailsSchool: school,
        active,
      };
    }
    this.setState(newState);
  }

  detailsPane() {
    if (this.state.detailsVisible === true) {
      const school = this.state.detailsSchool;
      const schoolRankings = this.props.twitterRankingsData.find((rankings) => {
        return rankings.college_name === school;
      });
      return (
        <PowerDetails
          playerId={this.state.playerId}
          schoolRankingsData={schoolRankings}
          schoolName={school}
          player={this.props.player}
        />
      );
    }
  }

  scrollCarousel(scrollFunction, direction) {
    const currentPosition = this.state.scrollableRef.current.scrollLeft;
    const currentRef = this.state.scrollableRef.current;
    if (direction === 'right') {
      scrollFunction({
        ref: currentRef,
        x: currentPosition + 500,
        smooth: true,
      });
    } else {
      scrollFunction({
        ref: currentRef,
        x: currentPosition - 500,
        smooth: true,
      });
    }
  }

  renderSchoolList() {
    const schoolList = this.props.twitterRankingsData.map((school) => {
      return school.college_name;
    });

    if (this.state.schoolList.length !== schoolList.length) {
      this.setState({
        schoolList,
      });
    }

    if (schoolList.length === 0) {
      return <h2 className='rankings-no-data'>No Schools with Connection Data</h2>;
    }

    return schoolList.map((schoolName, i) => {
      const offerFound =
        typeof this.props.player.offers.find((single) => {
          return single.college.name === schoolName;
        }) !== 'undefined';

      const curSchool = this.props.twitterRankingsData.find((school) => {
        return school.college_name === schoolName;
      });

      return (
        <PowerIcon
          offerFound={offerFound}
          schoolName={schoolName}
          schoolLabel={schoolName}
          className={this.state.active}
          followData={curSchool}
          showSchoolDetails={this.showSchoolDetails.bind(this, schoolName)}
          active={this.checkActive(schoolName)}
          key={`${schoolName}-powerIcon`}
        />
      );
    });
  }

  render() {
    const currentSchool =
      window.localStorage.getItem('school') === 'TAMU'
        ? 'Texas A&M'
        : window.localStorage.getItem('school');

    if (this.props.twitterRankingsDataLoaded === false) {
      return (
        <Col md={12} className='no-padding power-rankings-container'>
          <Col md={12} className='box'>
            <h2>School Breakdown</h2>
            <Row className='block-inner'>
              <Col md='12'>
                <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
              </Col>
            </Row>
          </Col>
        </Col>
      );
    }

    return (
      <Col md={12} className='no-padding power-rankings-container'>
        <Col md={12} className='box'>
          <h2>School Breakdown</h2>
          <Row className='block-inner'>
            <Col md={10} className='carousel-rankings'>
              <ScrollTo>
                {({ scrollTo }) => {
                  return (
                    <button
                      className='carousel-rankings-nav previous'
                      onClick={() => {
                        return this.scrollCarousel(scrollTo, 'left');
                      }}
                      type='button'
                      aria-label='Show previous set of schools in power ranking'>
                      <i className='icon previous-arrow fal fa-chevron-left' />
                    </button>
                  );
                }}
              </ScrollTo>
              <div className='carousel-rankings-content' ref={this.state.scrollableRef}>
                {this.renderSchoolList()}
              </div>
              <ScrollTo>
                {({ scrollTo }) => {
                  return (
                    <button
                      className='carousel-rankings-nav next'
                      onClick={() => {
                        return this.scrollCarousel(scrollTo, 'right');
                      }}
                      type='button'
                      aria-label='Show next set of schools in power ranking'>
                      <i className='icon next-arrow fal fa-chevron-right' />
                    </button>
                  );
                }}
              </ScrollTo>
            </Col>

            <Col md={2} className='my-school school-block'>
              {this.state.schoolList.length > 0 && (
                <PowerIcon
                  offerFound={
                    typeof this.props.player.offers.find((single) => {
                      return single.college.name === currentSchool;
                    }) !== 'undefined'
                  }
                  schoolName={currentSchool}
                  schoolLabel={currentSchool}
                  followData={this.props.twitterRankingsData.find((rankings) => {
                    return currentSchool === rankings.college_name;
                  })}
                  showSchoolDetails={this.showSchoolDetails.bind(this, currentSchool)}
                />
              )}
            </Col>
          </Row>
        </Col>
        {this.detailsPane()}
      </Col>
    );
  }
}

function mapStoreToProps(storeState) {
  return {};
}

export default connect(mapStoreToProps)(PowerRankings);
