const getNoDataMessage = (str) => `Looks like we currently don't have this prospects ${str}.`;
const getErrorMessage = (str) => `Error getting ${str}. Please try again later.`;
const getShortNoDataMessage = (str) => `No ${str} were found.`;

const NO_DATA_MESSAGE = {
  academics: getNoDataMessage('academic info'),
  contact: getNoDataMessage('contact info'),
  stats: getNoDataMessage('stats info'),
  talent: getNoDataMessage('talent rating'),
  film: getNoDataMessage('film'),
  twitterHandle: getNoDataMessage('twitter handle'),
  twitterInfo: getNoDataMessage('twitter info'),
  prospects: getShortNoDataMessage('prospects'),
  ratings: getShortNoDataMessage('ratings'),
  visits: getShortNoDataMessage('visits'),
  offers: getShortNoDataMessage('offers'),
  events: getShortNoDataMessage('events'),
  videos: getShortNoDataMessage('videos'),
  page: getShortNoDataMessage('page'),
};

const ERROR_MESSAGE = {
  players: getErrorMessage('players'),
  users: getErrorMessage('users'),
  lists: getErrorMessage('lists'),
  films: getErrorMessage('films'),
  filmDetails: getErrorMessage('film details'),
  academics: getErrorMessage('academics'),
};

const STATUS_MESSAGE = {
  loading: 'Loading...',
  searching: 'Searching...',
};

const MESSAGE_TYPE = {
  warning: 'warning',
  danger: 'danger',
  success: 'success',
};

export { MESSAGE_TYPE, STATUS_MESSAGE, ERROR_MESSAGE, NO_DATA_MESSAGE };
