import React from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { DateTime } from 'luxon';

import DatePickerComponent from '../../../../../datePicker';
import CommonComponent from '../../../../../generic';
import FilterComponent from '../../common/generic';

/**
 * Reducer
 */
import store from '../../../../../../../reducers/store';

import './styles/_index.scss';

class DateFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this._datePickerRef = React.createRef();
    this.state = {
      activity_request_level: this.props.activity_request_level || '',
      isOpen: false,
      customInputOpen: false,
      customStart: undefined,
      customEnd: undefined,
      id: this.props.id || 'date_range',
      values: this.props.values || {
        buttonSelected: '1 Month',
      },
      dispatchUpdateNeeded: false,
      label: this.props.label || 'Time Occurred Within',
      collapseClass: this.props.collapseClass || 'half',
      classes: this.props.classes || 'date',
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let values = nextState.values;
    let dispatchUpdateNeeded = false;
    let customStart = nextState.customStart;
    let customEnd = nextState.customEnd;
    let isOpen = false;

    if (
      typeof nextProps.values !== 'undefined' &&
      typeof nextProps.values.buttonSelected !== 'undefined' &&
      nextProps.values.buttonSelected !== nextState.values.buttonSelected
    ) {
      values = nextProps.values;
      dispatchUpdateNeeded = true;
    }
    if (typeof nextProps.values !== 'undefined' && nextProps.values.buttonSelected === 'Custom') {
      customStart = nextProps.values.start;
      customEnd = nextProps.values.end;
    }
    if (typeof nextProps.values !== 'undefined' && nextProps.values.buttonSelected !== 'Custom') {
      customStart = undefined;
      customEnd = undefined;
    }

    if (
      typeof nextProps.values !== 'undefined' &&
      typeof nextProps.values.buttonSelected === 'undefined'
    ) {
      values = { buttonSelected: '1 Month' };
    }

    if (
      typeof nextProps.values.start === 'undefined' &&
      nextProps.values.buttonSelected !== 'Custom'
    ) {
      dispatchUpdateNeeded = true;
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = values.buttonSelected !== '1 Month';
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      values,
      dispatchUpdateNeeded,
      customStart,
      customEnd,
      toggleClicked: false,
      isOpen,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    if (this._datePickerRef.current && !this._datePickerRef.current.contains(event.target)) {
      this.setState({
        customInputOpen: false,
      });
    }
  }

  generateSelections() {
    const options = ['All Time', '1 Day', '1 Week', '1 Month', 'Custom'];

    return options.map((option) => {
      let state = 'muted';
      if (typeof this.state.values !== 'undefined' && this.state.values.buttonSelected === option) {
        state = 'primary';
      }
      const clickFunction =
        option === 'Custom'
          ? () => {
              this.toggleCustomInput.bind(this, option)();
              this.selectDate.bind(this, option)();
            }
          : this.selectDate.bind(this, option);

      const contents = (
        <Button
          color={state}
          key={`date-${option}`}
          onClick={clickFunction}
          role='checkbox'
          aria-checked={state === 'primary'}>
          {option}
        </Button>
      );

      return contents;
    });
  }

  toggleCustomInput() {
    this.setState({
      customInputOpen: !this.state.customInputOpen,
    });
  }

  updateCustomDates(startDate, endDate) {
    const dates = this.mapValueToDateObject({
      buttonSelected: 'Custom',
      start: startDate,
      end: endDate,
    });
    store.dispatch({
      type: 'ACTIVITY_FILTER_UPDATED',
      filter: {
        activity_request_level: this.state.activity_request_level,
        type: this.state.id,
        values: dates,
      },
    });
  }

  dispatchUpdatedDate() {
    if (this.state.dispatchUpdateNeeded === true) {
      const valueWithDate = this.mapValueToDateObject(this.state.values);
      store.dispatch({
        type: 'ACTIVITY_FILTER_UPDATED',
        filter: {
          activity_request_level: this.state.activity_request_level,
          type: this.state.id,
          values: valueWithDate,
        },
      });
    }
  }

  selectDate(option) {
    const dates = this.mapValueToDateObject({ buttonSelected: option });

    if (
      (typeof this.state.start !== 'undefined' && !this.states.start.isSame(dates.start)) ||
      (typeof this.state.end !== 'undefined' && !this.state.end.isSame(dates.end)) ||
      (this.state.values !== 'undefined' && this.state.values.buttonSelected !== option)
    ) {
      store.dispatch({
        type: 'ACTIVITY_FILTER_UPDATED',
        filter: {
          activity_request_level: this.state.activity_request_level,
          type: this.state.id,
          values: dates,
        },
      });
    }
  }

  mapValueToDateObject(values) {
    const currDate = DateTime.local().toUTC();

    const obj = {
      'All Time': {
        start: currDate.minus({ years: 4 }),
        buttonSelected: 'All Time',
      },
      '1 Day': {
        start: currDate.minus({ days: 1 }),
        buttonSelected: '1 Day',
      },
      '1 Week': {
        start: currDate.minus({ weeks: 1 }),
        buttonSelected: '1 Week',
      },
      '1 Month': {
        start: currDate.minus({ months: 1 }),
        buttonSelected: '1 Month',
      },
      Custom: {
        start:
          typeof values.start !== 'undefined'
            ? DateTime.fromISO(new Date(values.start).toISOString()).startOf('day')
            : undefined,
        end:
          typeof values.end !== 'undefined'
            ? DateTime.fromISO(new Date(values.end).toISOString()).endOf('day')
            : undefined,
        buttonSelected: 'Custom',
      },
    };

    return values.buttonSelected !== 'undefined' ? obj[values.buttonSelected] : values['1 Month'];
  }

  render() {
    this.dispatchUpdatedDate();
    const options = this.generateSelections();

    return (
      <FilterComponent
        classes={this.state.classes}
        collapseClass={this.state.collapseClass}
        className='filter date'
        id='date'
        isOpen={this.state.isOpen}
        key={this.state.id}
        label={this.state.label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {options}
        <Popover
          target='collapse-date'
          placement='left'
          isOpen={this.state.customInputOpen}
          boundariesElement='viewport'
          className='date-custom-input'
          modifiers={{
            offset: { enabled: true, offset: '0,20' },
          }}>
          <PopoverBody>
            {this.state.customInputOpen && (
              <DatePickerComponent
                startDate={this.state.customStart}
                endDate={this.state.customEnd}
                updateCustomDates={this.updateCustomDates.bind(this)}
                ref={this._datePickerRef}
              />
            )}
          </PopoverBody>
        </Popover>
      </FilterComponent>
    );
  }
}

export default DateFilterComponent;
