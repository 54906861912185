import React, { Component } from 'react';

import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class ActivityDateGroupItemComponent extends Component {
  getPosition() {
    if (
      typeof this.props.activity.player.positions !== 'undefined' &&
      this.props.activity.player.positions.length > 0
    ) {
      return <span>{this.props.activity.player.positions[0].name} -</span>;
    }
  }

  render() {
    let classes = 'activity__date-group__body';

    if (typeof this.props.odd !== 'undefined' && this.props.odd === true) {
      classes = 'activity__date-group__body--grey';
    }

    return (
      <Col md={6}>
        <Link to={`/activity/${this.props.activity.player.id}/`}>
          <div className={classes}>
            <div className='activity__date-group__body__image'>
              <img
                alt={this.props.activity.player.name}
                src={
                  this.props.activity.player.image_url ||
                  'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg'
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
                }}
                title={this.props.activity.player.name}
              />
            </div>
            <div className='activity__date-group__body__info'>
              <h3>{this.props.activity.player.name}</h3>
              <p>
                {this.props.activity.player.getYear()} -&nbsp;
                {this.getPosition()}&nbsp;
                {this.props.activity.player.getCorrectHighSchool()}
                {this.props.activity.player.getCorrectLocationForHighSchool()}
              </p>
              <p className='activity__date-group__body__activity'>{this.props.activity.label}</p>
            </div>
          </div>
        </Link>
      </Col>
    );
  }
}

export default ActivityDateGroupItemComponent;
