import React, { useState } from 'react';

export const ContactInfoContext = React.createContext();

function ContactInfo({ contact, ...props }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggle = () => {
    return setIsCollapsed(!isCollapsed);
  };

  const keyToggle = (e) => {
    // keyboard accessibility for enter and space
    if (e.key === 'Enter' || e.key === ' ') {
      return setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <ContactInfoContext.Provider value={{ isCollapsed, toggle, contact, keyToggle }} {...props} />
  );
}

export default ContactInfo;
