import { Component } from 'react';

import ReactGA from 'react-ga4';

/**
 * Services
 */
import AuthenticationService from '../../../services/authentication';

class ZcruitComponent extends Component {
  constructor(props) {
    super(props);

    if (typeof this.props.history === 'undefined') {
      return;
    }

    if (AuthenticationService.loggedIn === true) {
      ReactGA.set({
        userId: AuthenticationService.getUserId(),
      });
    }

    ReactGA.send({ hitType: 'pageview', page: this.props.history.location.pathname });
  }
}

export default ZcruitComponent;
