import React, { Component } from 'react';
import { Row } from 'reactstrap';

/**
 * Components
 */
import PlayerPageModalAcademicsBoxComponent from './components/academics';
import PlayerPageModalContactInfoBoxComponent from './components/contactInfo';
import PlayerPageModalOffersBoxComponent from './components/offers';
import PlayerPageModalStatsBoxComponent from './components/stats';
import PlayerPageModalTalentBoxComponent from './components/talent';
import PlayerPageModalTwitterBoxComponent from './components/twitter';
import PlayerPageModalVideoBoxComponent from './components/videos';

/**
 * Services
 */
import AuthenticationService from '../../../../../services/authentication';

/**
 * Styles
 */
import './styles/_index.scss';

class PlayerPageModalBoxesComponent extends Component {
  render() {
    return (
      <Row className='box-holder'>
        {AuthenticationService.isRivalsOnly() === false && (
          <PlayerPageModalOffersBoxComponent page={this.props.page} player={this.props.player} />
        )}
        <PlayerPageModalContactInfoBoxComponent
          page={this.props.page}
          player={this.props.player}
          setTab={this.props.setTab}
        />
        {AuthenticationService.isRivalsOnly() === false && (
          <PlayerPageModalTalentBoxComponent page={this.props.page} player={this.props.player} />
        )}
        {AuthenticationService.isRivalsOnly() === false && (
          <PlayerPageModalTwitterBoxComponent
            page={this.props.page}
            player={this.props.player}
            twitterFollowData={this.props.twitterFollowData}
            setTab={this.props.setTab}
          />
        )}
        <PlayerPageModalAcademicsBoxComponent
          page={this.props.page}
          player={this.props.player}
          setTab={this.props.setTab}
        />
        <PlayerPageModalVideoBoxComponent
          page={this.props.page}
          player={this.props.player}
          setTab={this.props.setTab}
        />
        <PlayerPageModalStatsBoxComponent
          page={this.props.page}
          player={this.props.player}
          setTab={this.props.setTab}
        />
      </Row>
    );
  }
}

export default PlayerPageModalBoxesComponent;
