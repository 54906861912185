import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

/**
 * Styles
 */
import './styles/_index.scss';

const OptinModal = (props) => {
  let continueButton = null;
  const { isOpen, continueButtonOnClick } = props;

  React.useEffect(() => {
    if (continueButton) {
      continueButton.focus();
    }
  });

  return (
    <div>
      <Modal
        scrollable
        isOpen={isOpen}
        className='opt-in-modal'
        backdrop={true}
        aria-modal={true}
        labelledBy='optin-heading'>
        <ModalHeader />
        <ModalBody>
          <>
            <h2 className='modal-heading' id='optin-heading'>
              Our Terms of Use
            </h2>
            <div className='modal-text'>
              <p>
                We’re committed to protecting your personal information and keeping you updated on
                any changes we make to online terms. Please review the updates to our&nbsp;
                <a rel='noopener noreferrer' target='_blank' href='/terms-of-use'>
                  Terms of Use.
                </a>
              </p>
              <p className='notice-of-agreement'>
                By clicking “Continue” below, or otherwise continuing to use our product and
                services, you acknowledge that you have read and agree to our Terms of Use.
              </p>
            </div>
            <Button
              innerRef={(button) => {
                continueButton = button;
              }}
              className='continue-button'
              onClick={continueButtonOnClick}>
              Continue
            </Button>
          </>
        </ModalBody>
        <ModalFooter />
      </Modal>
    </div>
  );
};

export default OptinModal;
