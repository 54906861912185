import React from 'react';

function TimelineEntries({ startLabel, endLabel, totalBoxesPerRow, children }) {
  return (
    <div className='timeline-entries'>
      <div className='header' style={{ width: `${totalBoxesPerRow * 30}px` }}>
        <h3>{startLabel}</h3>
        <h3>{endLabel}</h3>
      </div>
      {children}
    </div>
  );
}

export default TimelineEntries;
