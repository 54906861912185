import React, { Component } from 'react';

/**
 * Styles
 */
import './styles/_index.scss';

class NameFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.values,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      values: nextProps.values,
    });
  }

  onSearchChange(event) {
    this.props.filterChanged(event.currentTarget.value);
  }

  render() {
    return (
      <div className='input'>
        <i className='icon search far fa-search' />
        <input
          onChange={this.onSearchChange.bind(this)}
          placeholder={this.props.placeholder || 'Search Prospect'}
          type='text'
          value={this.props.name || ''}
        />
      </div>
    );
  }
}

export default NameFilterComponent;
