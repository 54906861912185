import React from 'react';

import AdvancedFilterComponent from 'FilterComponents/common/advanced';
import LocationFilterComponent from 'FilterComponents/common/location';
import TextFilterComponent from 'FilterComponents/common/text';

import AcademicsFilterComponent from 'FilterComponents/specific/academics';
import EventsFilterComponent from 'FilterComponents/specific/events';
import LocationRadiusFilterComponent from 'FilterComponents/specific/location_radius';
import MetricsFilterComponent from 'FilterComponents/specific/metrics';
import PositionsFilterComponent from 'FilterComponents/specific/positions';
import SourcesFilterComponent from 'FilterComponents/specific/sources';
import YearsFilterComponent from 'FilterComponents/specific/years';
import ConnectionsFilter from 'FilterComponents/specific/connections';

function SidebarRivalsOnlyFiltersGroup({ closeFilter, filter }) {
  return (
    <>
      <TextFilterComponent
        isOpen={true}
        id='name'
        label='Search'
        placeholder="Prospect's Name"
        type='name'
        value={filter.name}
      />
      <YearsFilterComponent isOpen={true} values={filter.years} closeFilter={closeFilter} />
      <AdvancedFilterComponent
        id='advanced_filter_locations'
        closeFilter={closeFilter}
        values={filter}
        label='Locations'>
        <LocationFilterComponent
          id='location'
          closeFilter={closeFilter}
          values={filter.location}
          label='Location Filter'
        />
        <LocationRadiusFilterComponent
          closeFilter={closeFilter}
          id='location_radius'
          label='Location Radius'
          values={filter.location_radius}
          collapsible={false}
          classes='transparent-background'
        />
      </AdvancedFilterComponent>
      <PositionsFilterComponent closeFilter={closeFilter} values={filter.positions} />
      <MetricsFilterComponent
        values={filter}
        closeFilter={closeFilter}
        id='measurables'
        label='Ht/Wt/Measurables'
        configType='measurables'
        dropdownLabel='Add Measurable'
      />
      <MetricsFilterComponent
        values={filter}
        closeFilter={closeFilter}
        id='combine'
        label='Combine Results'
        configType='combine'
        dropdownLabel='Add Event'
      />
      <AcademicsFilterComponent
        collapsible={true}
        values={filter}
        closeFilter={closeFilter}
        id='academics'
      />
      <EventsFilterComponent
        closeFilter={closeFilter}
        id='events'
        label='Rivals Camp Series'
        values={filter.events}
        collapsible={false}
      />
      <ConnectionsFilter id='connections' values={filter.connections} closeFilter={closeFilter} />
      <SourcesFilterComponent
        closeFilter={closeFilter}
        id='sources'
        label='Sources'
        values={filter.sources}
      />
    </>
  );
}

export default SidebarRivalsOnlyFiltersGroup;
