import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import IconComponent from '../../../../../../../../common/icon';
import DateTimeService, { DATE_DEFAULTS } from '../../../../../../../../../services/date-time';

const dateTimeService = new DateTimeService();

function ContactInfoDate({ value }) {
  const formattedDate = dateTimeService.formatUTCDate(
    new Date(value),
    DATE_DEFAULTS.format.default
  );

  return <p>{formattedDate}</p>;
}

function ContactInfoSocial({ socialInfo }) {
  if (!socialInfo) {
    return null;
  }

  const getSocial = (social) => {
    return socialInfo.find((s) => {
      return s.social_media_service.name === social;
    });
  };

  const twitter = getSocial('twitter');
  const instagram = getSocial('instagram');
  const snapchat = getSocial('snapchat');

  return (
    <div className='social'>
      <ContactInfoSocialItem service='twitter' social={twitter} />
      <ContactInfoSocialItem service='instagram' social={instagram} />
      <ContactInfoSocialItem service='snapchat' social={snapchat} />
    </div>
  );
}

function ContactInfoSocialItem({ social, service }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    return setIsOpen(!isOpen);
  };

  if (!social) {
    return null;
  }

  const SOCIAL_ICONS = {
    snapchat: 'fab fa-snapchat-ghost',
    twitter: 'fab fa-twitter',
    instagram: 'fab fa-instagram',
  };

  const link = service !== 'snapchat' ? `https://${service}.com/${social.account_handle}` : null;
  return (
    <span>
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        id={`${service}icon`}
        aria-label={`Visit ${service} profile`}>
        <i className={`icon ${service} ${SOCIAL_ICONS[service]}`} tabIndex={-1} />
        &nbsp;
      </a>
      <Tooltip
        placement='top'
        isOpen={isOpen}
        autohide={false}
        target={`${service}icon`}
        toggle={toggle}>
        {social.account_handle}
      </Tooltip>
    </span>
  );
}

function ContactInfoBirthDate({ value }) {
  if (!value) {
    return null;
  }

  const date = dateTimeService.toUtcDate(new Date(value));
  const age = dateTimeService.getUTCDate().diff(date, ['years', 'months', 'days']).years;
  const dateFormatted = date.toFormat(DATE_DEFAULTS.format.default);

  return <span>{`Age ${age}  |  DOB: ${dateFormatted}`}</span>;
}

function ContactInfoState({ state }) {
  if (!state) {
    return null;
  }

  const value = state ? `Birth State: ${state.name}` : null;

  return <span>{value}</span>;
}

function ContactInfoAlmaMater({ state, name }) {
  const parentCollege = name ? `Alma Mater: ${name}` : null;
  const parentCollegeState = state ? `Alma Mater State: ${state.name}` : null;

  return (
    <>
      {parentCollege ? <p>{parentCollege}</p> : null}
      {parentCollegeState ? <p>{parentCollegeState}</p> : null}
    </>
  );
}

function ContactInfoPhone({ value, type }) {
  const labels = {
    cellPhone: 'cell',
    homePhone: 'home',
  };

  return (
    <p>
      <ContactInfoValue value={value} /> <span>({labels[type]})</span>
    </p>
  );
}

function ContactInfoCollege({ name, state }) {
  if (name && state) {
    return (
      <p>
        {name} ({state})
      </p>
    );
  }

  if (name && !state) {
    return <p>{name} (&#8212;)</p>;
  }

  if (!name && state) {
    return <p>&#8212; ({state})</p>;
  }

  return <p>&#8212; (&#8212;)</p>;
}

function ContactInfoPhoneNumbers({ cellPhone, homePhone }) {
  return (
    <>
      <ContactInfoPhone value={cellPhone} type='cellPhone' />
      <ContactInfoPhone value={homePhone} type='homePhone' />
    </>
  );
}

function ContactInfoLocation({ location, address, dashNoValue }) {
  if (!address && !location) {
    return dashNoValue ? <p>&#8212;</p> : <p>No data available</p>;
  }

  return (
    <div>
      {address ? <p>{address}</p> : null}
      {location ? <p>{location}</p> : null}
    </div>
  );
}

function ContactInfoValue({ value, dashNoValue }) {
  if (dashNoValue) {
    return value || <>&#8212;</>;
  }

  return value || 'No data available';
}

function ContactInfoIcon({ source }) {
  return <IconComponent color='grey' icon={source.name} label={source.label} />;
}

export {
  ContactInfoLocation,
  ContactInfoValue,
  ContactInfoIcon,
  ContactInfoDate,
  ContactInfoBirthDate,
  ContactInfoPhone,
  ContactInfoPhoneNumbers,
  ContactInfoCollege,
  ContactInfoState,
  ContactInfoAlmaMater,
  ContactInfoSocial,
};
