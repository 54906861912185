import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import SchoolIconComponent from '../common/schoolIcon';
// import constants from '../../constants';

class LikeTrendsListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentLiked: this.props.percentLiked,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.percentLiked !== this.props.percentLiked) {
      return true;
    }

    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.percentLiked > 100) {
      nextState.percentLiked = 100;
    }
  }

  schoolLogo() {
    const imgUrl = 'undefined';
    return <img src={imgUrl} alt={this.props.schoolName} />;
  }

  render() {
    return (
      <Row className='list-item'>
        <Col md={3}>
          <SchoolIconComponent school={this.props.schoolName} />
        </Col>
        <Col md={6} className='list-item-text-container'>
          <p className='list-item-text'>
            <strong>{this.props.userName}</strong>
          </p>
          <p className='list-item-text'>{this.props.userRole}</p>
        </Col>

        <Col md={2} className='list-item-tweet-num-container'>
          <p className='list-item-percentage'>{this.state.percentLiked}%</p>
          <p className='list-item-total'>
            ({this.props.likedTweetsPostFollow.length}/{this.props.allTweetsPostFollow.length})
          </p>
        </Col>
      </Row>
    );
  }
}

export default LikeTrendsListItem;
