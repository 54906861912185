import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Checkbox from 'CommonComponents/checkbox';

import 'CommonComponents/dropdownCheckbox/styles/_index.scss';

class DropdownCheckboxFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.appRoot = document.getElementById('app');
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      dropdownOpen: false,
      values: this.props.values,
      subMenuValues: {},
      isChild: Boolean(this.props.parent),
    };
  }

  componentWillReceiveProps(nextProps) {
    // default all to selected
    if (this.props.values.length === 0 && nextProps.values.length > 0) {
      this.handleAllClick(nextProps.values);

      return;
    }

    this.setState({
      values: nextProps.values,
    });
  }

  toggleDropdown(e) {
    if (e.target.className.includes('no-toggle') || e.target.className.includes('custom-control')) {
      return;
    }
    const currentOpen = this.state.dropdownOpen;

    this.setState({
      dropdownOpen: !currentOpen,
    });
  }

  handleAllClick(values) {
    let newValuesState = values.map((value) => {
      if (value.id === 'all') {
        if (this.state.isChild) {
          value.checked = value.checked !== true;
          this.props.filterChanged([value.value], this.props.parent);
        } else {
          value.checked = true;
          this.props.filterChanged([]);
        }
      } else {
        value.checked = false;
      }

      return value;
    });
    newValuesState = this.uncheckAllChildren(newValuesState);

    this.setState({
      values: newValuesState,
    });
  }

  uncheckAllOption(values) {
    const newValuesState = values.map((value) => {
      if (value.id === 'all') {
        value.checked = false;
      }

      return value;
    });

    this.setState({
      values: newValuesState,
    });
  }

  uncheckExclusives(values) {
    const newValuesState = values.map((value) => {
      if (value.exclusive) {
        value.checked = false;
      }
      return value;
    });

    this.setState({
      values: newValuesState,
    });
  }

  setCheckedOfValue(clickedValue) {
    if (clickedValue.id === 'all') {
      this.handleAllClick(this.state.values);
      return;
    }

    let newValues = this.state.values;

    if (clickedValue.exclusive) {
      newValues = this.uncheckAllChildren(newValues);
    }

    let noneChecked = true;
    const newValuesState = newValues.map((value) => {
      if (this.props.radio) {
        if (value.id === clickedValue.id) {
          value.checked = value.checked !== true;
        } else {
          value.checked = false;
        }

        if (value.checked === true) {
          noneChecked = false;
        }

        return value;
      }
      if (value.id === clickedValue.id) {
        value.checked = value.checked !== true;
      }

      if (value.id === 'all' && clickedValue.checked !== true && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (clickedValue.exclusive && clickedValue.checked && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (value.exclusive && value.checked && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (value.checked === true) {
        noneChecked = false;
      }

      return value;
    });

    if (noneChecked) {
      this.handleAllClick(this.state.values);
    }

    this.setState(
      {
        values: newValuesState,
      },
      () => {
        const selectedFilters = this.state.values
          .filter((value) => {
            return value.checked === true && value.id !== 'all';
          })
          .map((value) => {
            return value.id;
          });
        if (this.state.isChild) {
          this.props.filterChanged(selectedFilters, this.props.parent);
        } else {
          this.props.filterChanged(selectedFilters);
        }
      }
    );
  }

  renderDropdownItems(values) {
    if (typeof values === 'undefined' || values.length === 0) {
      return <p>loading...</p>;
    }
    const highlightAll = typeof this.props.columns !== 'undefined' && this.props.columns === 1;

    const renderedInputs = values.map((value) => {
      const classes =
        highlightAll === true && (value.id.toLowerCase() === 'all' || value.highlight === true)
          ? 'highlight no-toggle'
          : 'no-toggle';

      return (
        <DropdownItem
          className={classes}
          key={`${this.props.label}-${value.id}`}
          toggle={false}
          tag='div'
          aria-label={value.name}>
          {this.renderCustomInput(value)}
        </DropdownItem>
      );
    });
    return renderedInputs;
  }

  getValuesOfChildren(parentValue) {
    const parentObj = this.props.values.find((value) => {
      return value.id === parentValue;
    });
    if (typeof parentObj !== 'undefined') {
      return parentObj.children.map((child) => {
        return child.value;
      });
    }
    return [];
  }

  uncheckAllChildren(values) {
    const updatedValues = values.map((value) => {
      if (typeof value.children !== 'undefined') {
        const uncheckedChildren = value.children.map((child) => {
          if (typeof child.children === 'undefined') {
            child.checked = false;
          }
          return child;
        });
        value.children = uncheckedChildren;
      }
      return value;
    });
    return updatedValues;
  }

  subMenuChanged(values, parent) {
    const updatedSubMenuValues = this.state.subMenuValues;
    if (values.length === 1 && values[0].includes('all-')) {
      const childrenValues = this.getValuesOfChildren(parent);
      updatedSubMenuValues[parent] = childrenValues;
    } else {
      updatedSubMenuValues[parent] = values;
    }

    this.setState({
      subMenuValues: updatedSubMenuValues,
    });

    let flattenedArray = [];
    Object.keys(updatedSubMenuValues).forEach((key) => {
      flattenedArray = flattenedArray.concat(updatedSubMenuValues[key]);
    });

    this.props.filterChanged(flattenedArray);

    if (flattenedArray.length > 0) {
      this.uncheckAllOption(this.state.values);
      this.uncheckExclusives(this.state.values);
    } else {
      this.handleAllClick(this.state.values);
    }
  }

  renderCustomInput(value) {
    const { label } = this.props;
    const { children, name, id, checked } = value;

    if (children?.length) {
      return (
        <DropdownCheckboxFilterComponent
          columns={1}
          label={name}
          values={children}
          parent={id}
          filterChanged={this.subMenuChanged.bind(this)}
        />
      );
    }

    const checkboxId = `${label}-dropdown-item-${id}`;
    return (
      <Checkbox
        id={checkboxId}
        value={id}
        checked={checked}
        label={name}
        className='custom-control-checkbox-input'
        parentProps={{
          className: 'dropdown-checkbox-input no-toggle custom-control',
        }}
        labelProps={{ className: 'custom-control-checkbox-label' }}
        onChange={() => this.setCheckedOfValue(value)}
      />
    );
  }

  render() {
    if (typeof this.props.parent === 'undefined') {
      return (
        <Dropdown
          className='dropdown-filter'
          isOpen={this.state.dropdownOpen}
          toggle={this.toggleDropdown}
          onKeyUp={(e) => {
            if (e.key === 'Escape' && this.state.dropdownOpen) {
              this.toggleDropdown(e);
            }
          }}>
          <DropdownToggle caret={false} className='dropdown-filter-button'>
            {this.props.label}
            <i className='icon caret-down fal fa-chevron-down' />
          </DropdownToggle>
          <DropdownMenu
            className='dropdown-checkbox-menu'
            modifiers={{
              offset: { offset: '8,28', enabled: true },
            }}
            right>
            <div className={`dropdown-inner-container column-count-${this.props.columns}`}>
              {this.renderDropdownItems(this.state.values, this.props.columns)}
            </div>
          </DropdownMenu>
        </Dropdown>
      );
    }
    return (
      <Dropdown
        direction='right'
        className='no-toggle parent-dropdown'
        isOpen={this.state.dropdownOpen}
        toggle={this.toggleDropdown}>
        <DropdownToggle className='parent-toggle'>
          {this.props.label}
          <i className='icon caret-right fal fa-chevron-right' />
        </DropdownToggle>
        {/* Wrap portal in a fragment */}
        {/* prevents proptype warning since react can not infer that a portal is a React Node */}
        <React.Fragment>
          {/* Portal attaches component to any node (App Root in this case) */}
          {/* Allows dropdown to appear outside of what would be the parent element */}
          {ReactDOM.createPortal(
            <DropdownMenu
              className='dropdown-checkbox-menu nested-dropdown no-toggle'
              modifiers={{
                offset: { offset: '0, 10%', enabled: true },
                flip: { enabled: false },
                preventOverflow: { enabled: false },
                hide: { enabled: false },
              }}>
              <div className='dropdown-inner-container'>
                {this.renderDropdownItems(this.props.values, this.props.columns)}
              </div>
            </DropdownMenu>,
            this.appRoot
          )}
        </React.Fragment>
      </Dropdown>
    );
  }
}

export default DropdownCheckboxFilterComponent;
