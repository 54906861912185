import React, { Component } from 'react';

/**
 * Third party
 */
import classNames from 'classnames';
import { debounce } from 'underscore';

/**
 * Internal components
 */
import NavigationSearchResultsComponent from './components/searchResults';

/**
 * Services
 */
import PlayersService from '../../../../services/players';

/**
 * Styles
 */
import './styles/_index.scss';

class NavigationSearchComponent extends Component {
  constructor(props) {
    super(props);

    this.search = debounce(this.search, 350);

    this.state = {
      expanded: false,
      hiddenPlayers: [],
      minLength: 2,
      searchValue: '',
      searchValueUppercased: '',
      trackedPlayers: [],
      untrackedPlayers: [],
    };
  }

  toggle(event) {
    this.setState({
      expanded: !this.state.expanded,
      hiddenPlayers: [],
      isLoading: false,
      searchValue: '',
      searchValueUppercased: '',
      trackedPlayers: [],
      untrackedPlayers: [],
    });

    if (this.state.expanded === false) {
      this.nameInput.focus();
    } else {
      this.nameInput.blur();
    }
  }

  triggerSearch(event) {
    this.setState({
      isLoading: true,
      searchValue: event.target.value,
    });

    /**
     * Cancel any previous bounces
     */
    this.search.cancel();
    PlayersService.cancelCall('Restarting search');

    if (event.target.value.length > this.state.minLength) {
      this.search();
    } else {
      this.setState({
        hiddenPlayers: [],
        isLoading: false,
        searchValueUppercased: '',
        trackedPlayers: [],
        untrackedPlayers: [],
      });
    }
  }

  search() {
    let value = this.state.searchValue.charAt(0).toUpperCase() + this.state.searchValue.slice(1);

    if (this.state.searchValue.indexOf(' ') > -1) {
      const split = value.split(' ');

      if (split[1] !== '') {
        value = `${split[0]} ${split[1].charAt(0).toUpperCase() + split[1].slice(1)}`;
      } else {
        value = this.state.searchValue.charAt(0).toUpperCase() + this.state.searchValue.slice(1);
      }
    }

    this.setState({
      searchValueUppercased: value.trim(),
    });

    const data = {
      tracked: {
        name: this.state.searchValue,
        prospect_type: { hidden: false, standard: false, tracked: true },
      },
      untracked: {
        name: this.state.searchValue,
        prospect_type: { hidden: false, standard: true, tracked: false },
      },
      hidden: {
        name: this.state.searchValue,
        prospect_type: { hidden: true, standard: false, tracked: false },
      },
    };

    const untrackedPlayersCall = PlayersService.getPlayers(
      {
        page: 1,
        'sort[sortBy]': 'name',
        'sort[sortDirection]': 'ASC',
      },
      data.untracked
    );
    const trackedPlayersCall = PlayersService.getPlayers(
      {
        page: 1,
        'sort[sortBy]': 'name',
        'sort[sortDirection]': 'ASC',
      },
      data.tracked
    );
    const hiddenPlayersCall = PlayersService.getPlayers(
      {
        page: 1,
        'sort[sortBy]': 'name',
        'sort[sortDirection]': 'ASC',
      },
      data.hidden
    );
    // what is going on here you may ask?
    // concurrently make each call, but dont exit out of Promise on error (this is what map does)
    // handle each in .then and set state
    Promise.all(
      [untrackedPlayersCall, trackedPlayersCall, hiddenPlayersCall].map((p) => {
        return p.catch((e) => {
          return e;
        });
      })
    ).then((results) => {
      const untracked = results[0].code === 404 ? [] : results[0].players;
      const tracked = results[1].code === 404 ? [] : results[1].players;
      const hidden = results[2].code === 404 ? [] : results[2].players;
      const isLoading =
        typeof untracked === 'undefined' ||
        typeof tracked === 'undefined' ||
        typeof hidden === 'undefined';
      this.setState({
        hiddenPlayers: hidden,
        isLoading,
        trackedPlayers: tracked,
        untrackedPlayers: untracked,
      });
    });
  }

  render() {
    const classes = classNames({
      expanded: this.state.expanded,
      'search-input-container': true,
    });

    return (
      <div
        className='navigation-search'
        onBlur={this.toggle.bind(this)}
        onFocus={this.toggle.bind(this)}>
        <div className='search-area'>
          <div
            className='click'
            onClick={this.toggle.bind(this)}
            onKeyUp={this.toggle.bind(this)}
            role='button'
            aria-label='Search'
            tabIndex={-1}>
            <i className='icon search far fa-search' />
          </div>
          <div className={classes}>
            <label htmlFor='search'>Search:</label>
            <input
              className='search-field'
              id='search'
              name='search'
              onChange={this.triggerSearch.bind(this)}
              placeholder='Search for prospects'
              ref={(input) => {
                this.nameInput = input;
              }}
              type='text'
              value={this.state.searchValue}
            />
          </div>
        </div>
        <NavigationSearchResultsComponent
          hiddenPlayers={this.state.hiddenPlayers}
          isLoading={this.state.isLoading}
          trackedPlayers={this.state.trackedPlayers}
          untrackedPlayers={this.state.untrackedPlayers}
          searchValue={this.state.searchValueUppercased}
        />
      </div>
    );
  }
}

export default NavigationSearchComponent;
