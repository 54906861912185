const AUTH_CONFIG = {
  region: process.env.AMAZON_REGION,
  identityPoolId: process.env.AMAZON_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.AMAZON_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.AMAZON_COGNITO_CLIENT_ID,
  cookieStorage: {
    domain: process.env.ZCRUIT_COOKIE_DOMAIN,
    path: process.env.ZCRUIT_COOKIE_PATH,
    expires: parseInt(process.env.ZCRUIT_COOKIE_EXPIRATION, 10),
    secure: process.env.HTTPS,
  },
};

export { AUTH_CONFIG };
