import DateTimeService from 'Services/date-time';

const isTimeValidVerifiedMetric = (date) => {
  if (!date) return false;

  const VALID_INTERVAL = 18;
  const _dateTimeService = new DateTimeService();
  const currentDate = _dateTimeService.getUTCDate();
  const latestValidVerifiedMetricDate = _dateTimeService.minusMonths(currentDate, VALID_INTERVAL);
  const metricCreatedDate = _dateTimeService.getStartOfDayForDate(new Date(date.toString()));

  return metricCreatedDate > latestValidVerifiedMetricDate;
};

export { isTimeValidVerifiedMetric };
