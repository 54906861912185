import React, { Component } from 'react';
import { Col, Row, Button, Collapse, Table } from 'reactstrap';
import classNames from 'classnames';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';

class AcademicMetricViewPlayerPageComponent extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.dateTimeService = new DateTimeService();

    this.state = {
      collapse: true,
    };
  }

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.collapse === true) {
      classes += ' flip';
    }

    return classes;
  }

  buildAcademics() {
    if (
      typeof this.props.academics.metrics === 'undefined' ||
      typeof this.props.academics.metrics[this.props.type] === 'undefined'
    ) {
      return (
        <Col md={12} className='academics-no-data'>
          no data available
        </Col>
      );
    }

    let value = this.props.academics.metrics[this.props.type][0].value;
    if (this.props.type === 'gpa') {
      value = parseFloat(value).toFixed(2);
    }

    const source = this.props.academics.metrics[this.props.type][0].source;

    return (
      <Col md={12} className='academics-preview'>
        {value}
        <IconComponent color='grey' icon={source.name} label={source.label} />
      </Col>
    );
  }

  buildTable() {
    const html = [];
    let count = 0;

    if (
      typeof this.props.academics.metrics !== 'undefined' &&
      typeof this.props.academics.metrics[this.props.type] !== 'undefined'
    ) {
      this.props.academics.metrics[this.props.type].forEach((metric, index) => {
        if (metric.source.name === 'backfill') {
          return;
        }

        const classes = classNames({
          even: index % 2 !== 0,
        });

        let value = this.props.academics.metrics[this.props.type][index].value;
        if (this.props.type === 'gpa') {
          value = parseFloat(value).toFixed(2);
        }

        html.push(
          <tr key={`${metric.id}-${metric.source.id}`} className={classes}>
            <td>
              {this.dateTimeService.formatUTCDate(
                new Date(metric.date),
                DATE_DEFAULTS.format.default
              )}
            </td>
            <td>{value}</td>
            <td>
              <IconComponent color='grey' icon={metric.source.name} label={metric.source.label} />
            </td>
          </tr>
        );

        count++;
      });

      if (count === 0) {
        return;
      }
    } else {
      return;
    }

    return (
      <Row>
        <Col md={12}>
          <Button
            aria-label={
              this.state.collapse
                ? `Hide ${this.props.type.toUpperCase()} Data`
                : `Show ${this.props.type.toUpperCase()} Data`
            }
            onClick={this.toggle}
            aria-expanded={this.state.collapse}>
            <i className={this.getIconClasses()} />
          </Button>
        </Col>
        <Col md={12} className='table-container'>
          <Collapse isOpen={this.state.collapse}>
            <Table>
              <thead>
                {' '}
                <tr>
                  <th scope='col'>Date</th>
                  <th scope='col'>{this.props.type.toUpperCase()}</th>
                  <th scope='col'>Source</th>
                </tr>
              </thead>
              <tbody>{html}</tbody>
            </Table>
          </Collapse>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Col md={4}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className='box academics' tabIndex={0} role='document' aria-label='Academics'>
          <h2>{this.props.type.toUpperCase()}</h2>
          <Row>{this.buildAcademics()}</Row>
          {this.buildTable()}
        </div>
      </Col>
    );
  }
}

export default AcademicMetricViewPlayerPageComponent;
