import React, { Component } from 'react';
import { Button, Collapse, Table } from 'reactstrap';
import classNames from 'classnames';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import VerifiedIcon from 'CommonComponents/verifiedIcon';

/**
 * Styles
 */
import './styles/_index.scss';

class SingleTypeViewStatsPlayerPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
  }

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.collapse === true) {
      classes += ' flip';
    }

    return classes;
  }

  getPreview() {
    const previewMetric = this.props.metric[0];

    return (
      <div className='preview'>
        {` : ${previewMetric.value} `}
        <div className='verified-holder'>
          <VerifiedIcon verified={previewMetric.verified} subSource={previewMetric.subSource} />
        </div>
      </div>
    );
  }

  buildStats() {
    const html = [];
    let count = 0;

    this.props.metric.forEach((metric, index) => {
      if (metric.source.name === 'backfill') {
        return;
      }

      const classes = classNames({
        even: index % 2 !== 0,
      });

      html.push(
        <tr key={`${metric.id}-${metric.source.id}`} className={classes}>
          <td>
            <div className='verified-holder'>
              <VerifiedIcon verified={metric.verified} subSource={metric.subSource} />
            </div>
          </td>
          <td>{metric.value}</td>
          <td>
            {typeof metric.event !== 'undefined' && metric.event.name}
            {typeof metric.event === 'undefined' && <span>&mdash;</span>}
          </td>
          <td>
            {typeof metric.event !== 'undefined' && metric.event.occurred_on}
            {typeof metric.event === 'undefined' && <span>&mdash;</span>}
          </td>
          <td>
            <IconComponent color='grey' icon={metric.source.name} label={metric.source.label} />
          </td>
        </tr>
      );

      count++;
    });

    if (count === 0) {
      return;
    }

    return (
      <Table>
        <thead>
          <tr>
            <th scope='col'>Verified?</th>
            <th scope='col'>Results</th>
            <th scope='col'>Events</th>
            <th scope='col'>Date</th>
            <th scope='col'>Source</th>
          </tr>
        </thead>
        <tbody>{html}</tbody>
      </Table>
    );
  }

  generateHeight(inches) {
    const feet = Math.floor(inches / 12);

    inches = Math.round((inches % 12) * 100) / 100;

    return `${feet}' ${inches}"`;
  }

  render() {
    return (
      <div className='type filter' key={this.props.metric[0].id}>
        <Button onClick={this.toggle.bind(this)} aria-expanded={this.state.collapse}>
          <h3>
            {this.props.metric[0].label}
            {this.getPreview()}
          </h3>
          <i className={this.getIconClasses()} />
        </Button>
        <Collapse isOpen={this.state.collapse}>{this.buildStats()}</Collapse>
      </div>
    );
  }
}

export default SingleTypeViewStatsPlayerPageComponent;
