import React from 'react';
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';

/**
 * Components
 */
import CommonComponent from 'CommonComponents/generic';
import MyCsvLink from 'CommonComponents/exportButton';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Styles
 */
import 'RootComponents/prospects/components/pageContentHeader/styles/_index.scss';

class PageContentHeader extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = { activeFilterName: 'Players' };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (typeof nextProps.activeFilterName !== 'undefined' && nextProps.activeFilterName !== '') {
      nextState.activeFilterName = nextProps.activeFilterName;
    }

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  setToList(list) {
    store.dispatch({
      type: 'UPDATE_FROM_EXISTING_FILTER',
      filter: list.filter,
      name: list.name,
    });
  }

  renderLists() {
    let lists = null;

    if (this.props.lists) {
      lists = this.props.lists.map((list) => {
        return (
          <DropdownItem key={`${list.name}-${list.id}`} onClick={this.setToList.bind(this, list)}>
            {list.name}
          </DropdownItem>
        );
      });
    }

    return lists;
  }

  /**
   * Clear filters
   */
  setToAllPlayers() {
    store.dispatch({
      type: 'CLEAR_FILTERS',
    });
  }

  render() {
    const lists = this.renderLists();

    return (
      <Col md='12' className='page-header-container'>
        <Row className='page-header'>
          <Col md={12}>
            <h1 className='page-header-text'>Prospects</h1>
            <div className='actions'>
              <MyCsvLink
                sortBy={this.props.sortBy}
                sortDirection={this.props.sortDirection}
                tracked={false}
                filter={this.props.filter}
              />
            </div>
          </Col>
        </Row>
        <Row className='utility-bar'>
          <Col md={12}>
            <UncontrolledDropdown className='dropdown-filter ml-auto'>
              <DropdownToggle caret={false} className='dropdown-filter-button'>
                {this.state.activeFilterName} ({this.props.totalCount || 0})
                <i className='fal fa-chevron-down' />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    this.setToAllPlayers();
                  }}>
                  All Players
                </DropdownItem>
                {lists}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Col>
    );
  }
}

function mapStoreToProps(state) {
  return {
    activeFilterName: state.filter.activeFilterName,
  };
}

export default connect(mapStoreToProps)(PageContentHeader);
