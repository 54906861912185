import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ZcruitComponent from 'CommonComponents/zcruit';
import PlayerPageModal from 'CommonComponents/playerPageModalNoUrl';
import VideoDetailsComponent from 'CommonComponents/videoDetails';
import VideoPlayer from 'CommonComponents/videoPlayer';
import VideoFeaturedPlayersComponent from 'RootComponents/rivalsCamp/components/singleVideo/components/videoFeaturedPlayers';
import MessagingComponent, {
  STATUS_MESSAGE,
  MESSAGE_TYPE,
  ERROR_MESSAGE,
} from 'CommonComponents/messager';

import VideosService from 'Services/videos';

import 'RootComponents/rivalsCamp/components/singleVideo/styles/_index.scss';

class SingleVideoComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      fileLabel: 'Download',
      isLoading: true,
      modalOpen: false,
    };
  }

  componentDidMount() {
    VideosService.getAsPromise(this.props.videoId)
      .then((response) => {
        this.setState({
          isLoading: false,
          videoUrl: response.filename,
          videoTitle: response.title,
          videoCity: response.event.city.name,
          players: response.players,
          videoDate: response.event.occurred_on,
          isMultiPlayer: response.players.length > 1,
          tiedPositions: response.positions,
        });
      })
      .catch((err) => {
        console.warn('errr');
        console.warn(err);
        this.setState({
          isLoading: false,
          errorThrown: true,
        });
      });
  }

  togglePlayerModal(playerId = null) {
    const modalOpen = !this.state.modalOpen;
    this.setState({
      modalPlayerId: playerId,
      modalOpen,
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />;
    }

    if (this.state.errorThrown === true) {
      return <MessagingComponent message={ERROR_MESSAGE.filmDetails} type={MESSAGE_TYPE.danger} />;
    }

    return (
      <Row className='single-player-video-container'>
        <Col md={12}>
          <Row className='title-row'>
            <i className='icon back fal fa-chevron-left' />
            <Link to='/rivals-camp' className='back-to-list-link'>
              Back to All
            </Link>
            <h2 className='video-title'>{this.state.videoTitle}</h2>
          </Row>
          <Row>
            <Col md={12} className='video-player-container'>
              <Row className='video-playing-component'>
                <Col md={12}>
                  <VideoPlayer videoUrl={this.state.videoUrl} />
                </Col>
              </Row>
              <VideoDetailsComponent
                videoTitle={this.state.videoTitle}
                videoUrl={this.state.videoUrl}
                videoId={this.props.videoId}
                players={this.state.players}
                videoCity={this.state.videoCity}
                videoDate={this.state.videoDate}
                togglePlayerModal={this.togglePlayerModal.bind(this)}
              />
            </Col>
          </Row>
          <VideoFeaturedPlayersComponent
            tiedPositions={this.state.tiedPositions}
            players={this.state.players}
            togglePlayerModal={this.togglePlayerModal.bind(this)}
          />
          <PlayerPageModal
            isOpen={this.state.modalOpen}
            playerId={this.state.modalPlayerId}
            tab='base'
            toggleModal={this.togglePlayerModal.bind(this)}
          />
        </Col>
      </Row>
    );
  }
}

export default SingleVideoComponent;
