import React from 'react';
import IconComponent from '../../../../../../../../common/icon';

function ContactInfoBodyItem({ item, icon, label, children }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={`info ${item}`} role='document' aria-label='Contact Information' tabIndex={0}>
      {icon && <IconComponent color='purple' icon={icon} label={label} />}
      <div className='values'>
        <div>{children}</div>
      </div>
    </div>
  );
}

function ContactInfoBodyEmailItem({ children }) {
  return (
    <ContactInfoBodyItem item='email' icon='fas fa-envelope' label='E-mail'>
      {children}
    </ContactInfoBodyItem>
  );
}

function ContactInfoBodyCollegeAlmaMaterItem({ value, children }) {
  if (!value) {
    return null;
  }

  return (
    <ContactInfoBodyItem item='college' icon='fas fa-university' label='College Alma Mater'>
      {children}
    </ContactInfoBodyItem>
  );
}

function ContactInfoBodyPhoneItem({ children }) {
  return (
    <ContactInfoBodyItem item='phone' icon='fas fa-mobile-alt' label='Phone Number'>
      {children}
    </ContactInfoBodyItem>
  );
}

function ContactInfoBodyAddressItem({ children }) {
  return (
    <ContactInfoBodyItem item='address' icon='fas fa-map-marker-alt' label='Address'>
      {children}
    </ContactInfoBodyItem>
  );
}

function ContactInfoBodyBasicItem({ children }) {
  return <ContactInfoBodyItem item='basic'>{children}</ContactInfoBodyItem>;
}

export {
  ContactInfoBodyEmailItem,
  ContactInfoBodyPhoneItem,
  ContactInfoBodyAddressItem,
  ContactInfoBodyBasicItem,
  ContactInfoBodyCollegeAlmaMaterItem,
};
