const config = {
  gpa: {
    active: false,
    id: 'gpa',
    label: 'GPA',
    less_than: false,
    order: 1,
    unit: '',
  },
  act: {
    active: false,
    id: 'act',
    label: 'ACT Score',
    less_than: false,
    order: 2,
    unit: '',
  },
  sat: {
    active: false,
    id: 'sat',
    label: 'SAT Score',
    less_than: false,
    order: 3,
    unit: '',
  },
};

export default config;
