import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import AuthenticationService from '../../../services/authentication';
import OptinModal from '../../optinModal';

import generatePageTitles from '../pageTitles';

class ProtectedRouterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserOptedIn: null,
    };
    this.continueButtonOnClick = this.continueButtonOnClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.pageName) {
      document.title = generatePageTitles(this.props.pageName);
    }

    const hasOptedIn = await AuthenticationService.isUserOptedIn();
    this.setState({
      isUserOptedIn: hasOptedIn,
    });
  }

  async updateUsersOptinStatus() {
    return '';
  }

  async continueButtonOnClick() {
    await AuthenticationService.createUserOptIn().then(() => {
      this.setState({
        isUserOptedIn: true,
      });
    });
  }

  render() {
    if (AuthenticationService.loggedIn) {
      if (window.localStorage.isZmailOnly === 'true') {
        AuthenticationService.redirectToZmail(false);
      }

      if (this.state.isUserOptedIn) {
        return <Route component={this.props.component} path={this.props.path} />;
      }

      return (
        <OptinModal
          isOpen={this.state.isUserOptedIn !== null && this.state.isUserOptedIn === false}
          continueButtonOnClick={this.continueButtonOnClick}
        />
      );
    }

    return <Redirect to={{ pathname: '/login' }} />;
  }
}
export default ProtectedRouterComponent;
