import React from 'react';
import { Table, Collapse } from 'reactstrap';
import { useContactInfoContext } from '../contactInfo';
import {
  ContactInfoValue,
  ContactInfoIcon,
  ContactInfoDate,
  ContactInfoCollege,
} from '../contactInfoMisc';

function ParentContactInfoCollapse() {
  const { isCollapsed, contact } = useContactInfoContext();

  return (
    <Collapse isOpen={isCollapsed}>
      <Table striped>
        <thead>
          <tr>
            <th>Updated</th>
            <th>Email</th>
            <th>Cell Phone</th>
            <th>Birth State</th>
            <th>College Alma Mater</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {contact.contacts.map((parent) => {
            const {
              parent_birth_state,
              parent_college_alma_mater,
              parent_college_alma_mater_state,
            } = parent;

            const parentBirthState = parent_birth_state ? parent_birth_state.name : null;
            const parentCollegeName = parent_college_alma_mater || null;
            const parentCollegeState = parent_college_alma_mater_state
              ? parent_college_alma_mater_state.name
              : null;

            return (
              <tr key={parent.id} className='contact-table'>
                <td className='contact-date'>
                  <ContactInfoDate value={parent.updated_at} />
                </td>
                <td className='contact-email'>
                  <ContactInfoValue value={parent.email} dashNoValue />
                </td>
                <td className='contact-cell'>
                  <ContactInfoValue value={parent.cell_phone} dashNoValue />
                </td>
                <td className='contact-birth-state'>
                  <ContactInfoValue value={parentBirthState} dashNoValue />
                </td>
                <td contact='contact-college-alma-mater'>
                  <ContactInfoCollege name={parentCollegeName} state={parentCollegeState} />
                </td>
                <td className='contact-icon'>
                  <ContactInfoIcon source={parent.source} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Collapse>
  );
}

export default ParentContactInfoCollapse;
