class ActivityChangesRequest {
  constructor() {
    this.date_range = {};
    this.types = {
      commits: {
        active: false,
        values: [],
      },
      combine_results: {
        active: false,
        values: [],
      },
      decommits: {
        active: false,
        values: [],
      },
      first_offer: {
        active: false,
        values: [],
      },
      gpa: {
        active: false,
        values: [],
      },
      new_prospects: {
        active: false,
        values: [],
      },
      offers: {
        active: false,
        values: [],
      },
      rating_bump: {
        active: false,
        values: [],
      },
      signings: {
        active: false,
        values: [],
      },
      track_and_field: {
        active: false,
        values: [],
      },
      twitter_follower: {
        active: false,
        values: [],
      },
      visits: {
        active: false,
        values: [],
      },
      /* NOTE: We don't want to apply track and field filter until
       * we fix https://ncsasports.atlassian.net/browse/CX-34
      track_and_field: {
        active: false,
        values: [],
      },
      */
    };
  }
}

export default ActivityChangesRequest;
