import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

/**
 * Styles
 */
import './styles/_index.scss';

class AccountHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: props.tab || 'info',
      isSuperUser: window.localStorage.getItem('superUser') === 'true',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  tabClass(tabTitle = 'info') {
    // TODO: change from year tab to setting tab
    let className = `tab_${tabTitle} year-tab`;
    if (this.state.activeTab === `tab_${tabTitle}`) {
      className = `${className} active_tab`;
    }

    return className;
  }

  render() {
    return (
      <Col md='12' className='page-header-container'>
        <Row className='page-header padding-left-header'>
          <Col md={{ size: 10, offset: 2 }}>
            <h1 className='page-header-text'>Account Settings</h1>
          </Col>
        </Row>
        <Row className='utility-bar'>
          <Col md={{ size: 10, offset: 2 }}>
            <Nav tabs className='nav-tab-links-container padding-left-header'>
              <NavItem>
                <NavLink
                  className={this.tabClass('info')}
                  onClick={() => {
                    this.toggle('tab_info');
                  }}
                  tag={Link}
                  to='/account/info'>
                  Basic Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.tabClass('password')}
                  onClick={() => {
                    this.toggle('tab_password');
                  }}
                  tag={Link}
                  to='/account/password'>
                  Change Password
                </NavLink>
              </NavItem>
              {this.state.isSuperUser === true && (
                <NavItem>
                  <NavLink
                    className={this.tabClass('users')}
                    onClick={() => {
                      this.toggle('tab_users');
                    }}
                    tag={Link}
                    to='/account/users'>
                    Manage Users
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={this.tabClass('lists')}
                  onClick={() => {
                    this.toggle('tab_lists');
                  }}
                  tag={Link}
                  to='/account/lists'>
                  Manage Lists
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default AccountHeader;
