import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

import AuthenticationService from 'Services/authentication';
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import generatePageTitles from 'RootComponents/utility/pageTitles';

import 'RootComponents/forgotpassword/styles/_index.scss';

class ForgotPasswordComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: false,
      message: '',
      successfullySent: false,
    };
  }

  componentWillMount() {
    if (AuthenticationService.loggedIn === true) {
      this.props.history.push('/prospects');
    }
  }

  componentDidMount() {
    document.title = generatePageTitles('Forgot Password');
  }

  startPasswordRecovery(...args) {
    const formValues = args[2];

    this.setState({
      isLoading: true,
    });

    AuthenticationService.startPasswordRecovery(formValues.username)
      .then(() => {
        this.props.history.push({
          pathname: '/resetpassword',
          state: formValues,
        });
      })
      .catch((error) => {
        this.setState({
          errorThrown: true,
          isLoading: false,
          message: error.message,
        });
      });
  }

  render() {
    if (this.state.successfullySent === true) {
      return this.showConfirmation();
    }

    return this.resetPasswordForm();
  }

  resetPasswordForm() {
    return (
      <div fluid={true}>
        <Row className='login-page forgot-password'>
          <Col md={6} className='left'>
            <Row className='overlay'>
              <Col md={12}>
                <h1 className='zcruit-by-ncsa-logo'>
                  <Link to='/login' href='/login'>
                    Zcruit
                  </Link>
                </h1>
                <h4>Recruit Smarter</h4>
                <h3>Perform Better</h3>
              </Col>
            </Row>
          </Col>
          <Col md={6} className='right'>
            <h3>Forgot Password</h3>
            <p>Enter your email below to receive your password reset instructions.</p>
            <AvForm onSubmit={this.startPasswordRecovery.bind(this)}>
              <AvGroup>
                <Label aria-hidden='true' for='username'>
                  Email
                </Label>
                <AvField
                  type='text'
                  id='username'
                  name='username'
                  placeholder='Enter your username'
                  errorMessage='Please provide a valid username'
                  required
                />
              </AvGroup>
              <FormGroup>
                <Button color='primary' disabled={this.state.isLoading}>
                  Continue
                </Button>
              </FormGroup>
            </AvForm>
            <MessagingComponent
              thrown={this.state.errorThrown}
              message={this.state.message}
              type={MESSAGE_TYPE.danger}
            />
          </Col>
        </Row>
      </div>
    );
  }

  showConfirmation() {
    return (
      <div>
        <Row className='login-page password-sent'>
          <Col md={6} className='left'>
            <Row className='overlay'>
              <Col md={12}>
                <h1 className='zcruit-by-ncsa-logo'>
                  <Link to='/login' href='/login'>
                    Zcruit
                  </Link>
                </h1>
                <h4>Recruit Smarter</h4>
                <h3>Perform Better</h3>
              </Col>
            </Row>
          </Col>
          <Col md={6} className='right'>
            <i className='icon verified fas fa-check-circle' />
            <h3>Reset password instructions sent!</h3>
            <p>A link to reset your password has been sent to your email address.</p>
            <p className='below'>
              Be sure you check your spam folder if you don’t receive an email.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}
export default ForgotPasswordComponent;
