import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';

class PowerDetailsLikes extends Component {
  getTotalLiked(posts) {
    const sum =
      posts.commit.liked +
      posts.coach.liked +
      posts.roster.liked +
      posts.school.liked +
      posts.support.liked;

    return sum;
  }

  render() {
    return (
      <Container className='power-details-likes'>
        <Row>
          <Col md={5} className='interaction-type-summary d-flex justify-content-between'>
            <Container>
              <Row>
                <Col md={2} className='icon-block-heart'>
                  <i className='icon twitter-like far fa-heart white' />
                </Col>
                <Col md='auto' className='total-likes-container'>
                  <p>
                    <span className='total-likes'>{this.getTotalLiked(this.props.posts)}</span>{' '}
                    {this.props.schoolName} Tweets Liked
                  </p>
                </Col>
                <svg height='50' width='15'>
                  <line x1='0' y1='0' x2='15' y2='25' stroke='rgb(212,212,212)' strokeWidth='1' />
                  <line x1='0' y1='50' x2='15' y2='25' stroke='rgb(212,212,212)' strokeWidth='1' />
                </svg>
              </Row>
            </Container>
          </Col>

          <Col md={7} className='account-breakdown'>
            <div className='account-type'>
              <h4>{this.props.posts.school.liked}</h4>
              <p>School Account</p>
            </div>
            <div className='account-type'>
              <h4>{this.props.posts.coach.liked}</h4>
              <p>Coaches</p>
            </div>
            <div className='account-type'>
              <h4>{this.props.posts.support.liked}</h4>
              <p>Support Staff</p>
            </div>
            <div className='account-type'>
              <h4>{this.props.posts.roster.liked}</h4>
              <p>Roster</p>
            </div>
            <div className='account-type'>
              <h4>{this.props.posts.commit.liked}</h4>
              <p>Commits</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PowerDetailsLikes;
