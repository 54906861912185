const initialState = {
  playerToHide: undefined,
  playerToUnHide: undefined,
  playerToTrack: undefined,
  players: [],
  getPlayersCalled: false,
  doneLoadingPlayers: false,
};

export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    // @TODO: Rename this bad boy
    case 'PLAYER_LIST_LOADED':
      return Object.assign({}, state, {
        players: action.players,
        getPlayersCalled: true,
      });

    case 'PLAYER_UPDATE': {
      const id = action.player.id;
      const players = state.players;
      for (let i = 0; i < players.length; i++) {
        if (players[i].id === id) {
          players[i] = action.player;
          break;
        }
      }
      return Object.assign({}, state, {
        players,
      });
    }

    case 'HIDE_PLAYER': {
      return Object.assign({}, state, {
        playerToHide: action.player,
      });
    }

    case 'UN_HIDE_PLAYER': {
      return Object.assign({}, state, {
        playerToUnHide: action.player,
      });
    }

    case 'TRACK_PLAYER': {
      return Object.assign({}, state, {
        playerToTrack: action.player,
      });
    }

    default:
      return state;
  }
}
