import React from 'react';

import IconComponent from 'CommonComponents/icon';

const LINKABLE_SOURCES = ['hudl', 'ncsa', 'rivals', 'twoFourSeven', 'relative_athletic_score'];

function InfoSources({ player, sources }) {
  const createPlayerSources = () => {
    return sources.map(({ source, source_id }) => {
      let link = null;

      if (LINKABLE_SOURCES.includes(source.name)) {
        switch (source.name) {
          case 'hudl':
            link = `https://www.hudl.com${source_id}`;

            break;
          case 'ncsa':
            link = `http://recruit-match.ncsasports.org/clientrms/athletes/${source_id}`;

            break;
          case 'rivals':
            link = `https://n.rivals.com/content/prospects/${source_id}/`;

            break;
          case 'twoFourSeven':
            link = `https://247sports.com/player/${source_id}/`;

            break;
          case 'relative_athletic_score':
            link = `https://ras.football/zcruit-ras-card/?PlayerID=${source_id}`;
            break;
          default:
            break;
        }
      }

      return (
        <IconComponent
          color={source.name}
          icon={source.name}
          label={source.label}
          link={link}
          link_title={`${player.name} - ${source.label}`}
          shift_right={true}
          key={source.name}
        />
      );
    });
  };

  return <div className='sources'>{createPlayerSources()}</div>;
}

export default InfoSources;
