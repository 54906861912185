import React from 'react';
import { Button, Col, Row, Table, Modal } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';

/**
 * Components
 */
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import PageContentHeader from 'CommonComponents/accountHeader';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import AuthenticationService from 'Services/authentication';
import UsersService from 'Services/users';

/**
 * Styles
 */
import 'RootComponents/account/components/manageUsers/styles/_index.scss';

class ManageUsersComponent extends ZcruitComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoading: false,
      message: '',
      success: false,
      users: [],
      deleteModal: false,
      targetId: null,
      isSuperUser: false,
      redirect: false,
    };
  }

  componentDidMount() {
    this.getUsers();
    AuthenticationService.isSuperUser()
      .then((result) => {
        this.setState({
          isSuperUser: result,
          redirect: !result,
        });
      })
      .catch((e) => {
        this.setState({
          isSuperUser: false,
          error: true,
          message: 'Your admin status could not be verified',
          redirect: true,
        });
      });
  }

  handleFormSubmit(event, errors, values) {
    if (this.state.isSuperUser === false) {
      this.setState({
        isSuperUser: false,
        error: true,
        message: 'Your admin status could not be verified',
      });

      return;
    }

    UsersService.createUser(values)
      .then((response) => {
        this.form.reset();

        const updatedUsers = this.state.users.concat([response]);
        this.setState({
          error: false,
          success: true,
          message: 'User succesfully created',
          users: updatedUsers,
        });
      })
      .catch((e) => {
        this.setState({
          error: true,
          success: false,
          message: e.message,
        });
      });
  }

  toggleEditModal() {
    // TODO: Add edit functionality
  }

  toggleDeleteModal(id = null) {
    const deleteModalState = !this.state.deleteModal;
    this.setState({
      deleteModal: deleteModalState,
      targetId: id,
    });
  }

  deleteHandler() {
    if (this.state.isSuperUser === false) {
      this.setState({
        isSuperUser: false,
        error: true,
        deleteModal: false,
        message: 'Your admin status could not be verified',
      });
      return;
    }
    if (this.state.targetId !== null) {
      UsersService.deleteUser(this.state.targetId)
        .then((response) => {
          console.log('response is here', response);
          const updatedUsers = this.state.users.filter((user) => {
            return user.id !== this.state.targetId;
          });
          this.setState({
            success: true,
            error: false,
            isLoading: false,
            message: 'Successfully deleted user',
            deleteModal: false,
            users: updatedUsers,
          });
        })
        .catch((e) => {
          console.log('error on delete', e);
          this.setState({
            error: true,
            message: e.message,
            success: false,
            deleteModal: false,
          });
        });
    } else {
      this.setState({
        success: false,
        error: true,
        isLoading: false,
        message: 'User to delete could not be found',
        deleteModal: false,
      });
    }
  }

  getUsers() {
    UsersService.getUsers()
      .then((result) => {
        this.setState({
          users: result,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: true,
          message: e.message,
        });
      });
  }

  createUserRows() {
    const rows = this.state.users.map((user) => {
      const role = user.super_user === 1 ? 'Admin' : 'Collaborator';
      return (
        <tr key={user.username} className='user-row'>
          <td className='user-email'>{user.username}</td>
          <td className='user-role'>{role}</td>
          <td>
            <div className='table-icon-container'>
              {/* TODO: add edit functionality */}
              {/* <span
                className='editor'
                onClick={this.toggleEditModal}
                role='button'
                onKeyPress={this.keyToggle}
                tabIndex={0}
              >
                <i className='icon edit' />
              </span> */}
              <button
                className='deleter'
                onClick={() => {
                  this.toggleDeleteModal(user.id);
                }}
                type='button'
                aria-label={`Delete User ${user.username}`}>
                <i className='icon x fas fa-times' />
              </button>
            </div>
          </td>
        </tr>
      );
    });

    return rows;
  }

  renderDeleteModal() {
    if (this.state.redirect === true) {
      return <Redirect to='/account/info' />;
    }
    return (
      <Modal
        isOpen={this.state.deleteModal}
        toggle={this.toggleDeleteModal.bind(this)}
        modalClassName='confirm-delete-modal'
        centered={true}
        size='lg'
        aria-modal={true}
        labelledBy='delete-modal-title'>
        <Row>
          <Col md={{ size: 10, offset: 1 }} className='modal-query-container'>
            <p id='delete-modal-title'>Are you sure you want to delete this user?</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 10, offset: 1 }} className='modal-button-container'>
            <Button
              className='cancel modal-button'
              onClick={() => {
                this.toggleDeleteModal();
              }}>
              Cancel
            </Button>

            <Button
              className='btn confirm modal-button'
              color='primary'
              onClick={this.deleteHandler.bind(this)}
              id='confirm-modal-button'>
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }

  render() {
    return (
      <Row>
        <PageContentHeader tab='tab_users' />
        <Col className='box-container manage-users' md={{ size: 10, offset: 2 }}>
          <Col className='account-messages' md={{ size: 9 }}>
            <MessagingComponent
              thrown={this.state.error}
              message={this.state.message}
              type={MESSAGE_TYPE.danger}
            />
            <MessagingComponent
              thrown={this.state.success}
              message={this.state.message}
              type={MESSAGE_TYPE.success}
            />
          </Col>
          <Row>
            <Col md={6} className='account-box users-table'>
              <h2 className='title'>Manage Users</h2>
              <Table className='account-box__content' aria-label='Manage Users' tabIndex={0}>
                <tbody>{this.createUserRows()}</tbody>
              </Table>
            </Col>
            <Col md={3} className='account-box new-user-input'>
              <h2 className='title'>Invite User</h2>
              <AvForm
                className='account-box__content'
                onSubmit={this.handleFormSubmit.bind(this)}
                ref={(r) => {
                  this.form = r;
                }}
                tabIndex={0}
                aria-label='Invite User Form'>
                <AvField
                  label='Email'
                  type='email'
                  name='email'
                  validate={{
                    required: { value: true, errorMessage: 'Field is required' },
                  }}
                />
                <AvField
                  label='Role'
                  type='select'
                  name='type'
                  validate={{
                    required: { value: true, errorMessage: 'Field is required' },
                  }}>
                  <option value='' defaultValue disabled>
                    Select
                  </option>
                  <option value='Administrator'>Admin</option>
                  <option value='Collaborator'>Collaborator</option>
                </AvField>
                <Button className='account-box__submit' disabled={this.state.isLoading}>
                  Invite User
                </Button>
              </AvForm>
            </Col>
          </Row>
          {this.renderDeleteModal()}
        </Col>
      </Row>
    );
  }
}

export default ManageUsersComponent;
