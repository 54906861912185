import ActivityChangesRequest from '../activityChanges';
import PlayerSearchRequest from '../playerSearch';

class ActivityRequest {
  constructor() {
    this.changes = new ActivityChangesRequest();
    this.details = new PlayerSearchRequest();

    this.details.years = [];
  }

  assignOut(target, source) {
    if (typeof source.changes !== 'undefined' && typeof source.changes.date_range !== 'undefined') {
      if (source.changes.date_range.buttonSelected === 'Custom') {
        Object.keys(source.changes.date_range).forEach((key) => {
          target.changes.date_range[key] = source.changes.date_range[key];
        });
      } else {
        target.changes.date_range.buttonSelected = source.changes.date_range.buttonSelected;
      }
    }

    if (typeof source.changes !== 'undefined' && typeof source.changes.types !== 'undefined') {
      Object.keys(source.changes.types).forEach((key) => {
        if (key === 'rating_bump' || key === 'gpa') {
          target.changes.types[key] = { active: false, values: [] };
        } else {
          target.changes.types[key] = source.changes.types[key];
        }
      });
    }

    if (typeof source.details !== 'undefined') {
      Object.keys(source.details).forEach((key) => {
        target.details[key] = source.details[key];
      });
    }

    return target;
  }
}

export default ActivityRequest;
