import React, { Component } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Col } from 'reactstrap';
import TimelineComponent from 'CommonComponents/timeline';
import SchoolIconComponent from 'CommonComponents/schoolIcon';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';
import CardHeader from './CardHeader';
import TimelineTweetLabel from './TimelineTweetLabel';

const filtersNeeded = ['Time', 'Interactions', 'Accounts', 'Schools'];

class Interactions extends Component {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.state = {
      filters: {
        component: 'timeline',
      },
      schools: [],
    };
  }

  generateStructure() {
    const objs = [];
    const currentSchool = window.localStorage.getItem('school');
    let sameSchoolFound = false;

    const colors = ['#f00028', '#002d65', '#dcc88a', '#241f20'];

    let keys;
    const twitterData = this.getLikesData(this.props.timelineData);
    if (twitterData) {
      keys = Object.keys(twitterData);
    } else {
      keys = [];
    }

    keys.forEach((key, index) => {
      if (twitterData[key].length === 0) {
        return;
      }

      const isSameSchool = key === currentSchool;
      if (isSameSchool) {
        sameSchoolFound = true;
      }
      const parsedValues = twitterData[key].map((tweetObj) => {
        const resultObj = {
          label: this.createTimelineTweetLabel(tweetObj),
          type: tweetObj.type,
          value: tweetObj.date,
        };
        return resultObj;
      });

      const singleObj = {};

      const classes = classNames({
        entry: true,
        main: isSameSchool,
      });

      const selectedColor = colors[index % colors.length];
      singleObj[key] = {
        label: this.createTimelineLegendLabel(key, selectedColor, classes, twitterData[key].length),
        main: isSameSchool,
        color: selectedColor,
        values: parsedValues,
      };

      if (isSameSchool === true) {
        objs.unshift(singleObj);
      } else {
        objs.push(singleObj);
      }
    });

    if (sameSchoolFound === false) {
      // if current logged in school does not have any twitter data to display
      // create an empty entry for the timeline
      const classes = classNames({ entry: true, main: true });
      const emptyMainSchool = {
        currentSchool: {
          label: this.createTimelineLegendLabel(currentSchool, colors[1], classes, 0),
          main: true,
          color: colors[1],
          values: [],
        },
      };
      objs.unshift(emptyMainSchool);
    }
    return objs;
  }

  createTimelineLegendLabel(school, color, classes, numEntries) {
    return (
      <div className={classes} key={`${school}-header`}>
        <div className='school-image-holder'>
          <SchoolIconComponent school={school} imageOnly={true} />
          <p>{school}</p>
        </div>
        <span className='visit-count' style={{ backgroundColor: color, color: 'white' }}>
          {numEntries}
        </span>
      </div>
    );
  }

  createTimelineTweetLabel(tweet) {
    return <TimelineTweetLabel playerName={this.props.player.name} tweet={tweet} />;
  }

  getLikesData(timelineTweetData) {
    const newSchoolTweetsObj = {};
    const schoolGuidArray = [];

    timelineTweetData.forEach((timelineObj) => {
      const school = timelineObj.college_name;
      schoolGuidArray.push(timelineObj.college_sport_guid);
      newSchoolTweetsObj[school] = timelineObj.interactions.map((interaction) => {
        return {
          id: interaction.id,
          handle: interaction.socialMediaAccount.handle,
          name: interaction.socialMediaAccount.name,
          image_url: interaction.socialMediaAccount.avatar_url,
          text: interaction.socialMediaPost ? interaction.socialMediaPost.message : '',
          date: interaction.date,
          type: interaction.type,
        };
      });
    });
    if (this.state.schools.length !== schoolGuidArray.length) {
      this.setState({
        schools: schoolGuidArray,
      });
    }
    return newSchoolTweetsObj;
  }

  handleFilterChange(updateObject) {
    const updatedFilters = Object.assign({}, this.state.filters);
    // filter type that is being updated
    const filterToUpdate = updateObject.type;

    updatedFilters[filterToUpdate] = updateObject.values;

    this.setState({
      filters: updatedFilters,
    });
    this.props.handleFilterChange(updatedFilters);
  }

  render() {
    const utcEndDate = DateTime.now();
    const utcStartDate = this.state.filters.startDate
      ? this.dateTimeService.formatUTCDate(
          new Date(this.state.filters.startDate),
          DATE_DEFAULTS.format.default
        )
      : this.dateTimeService.formatUTCDate(
          utcEndDate.minus({ years: 1 }),
          DATE_DEFAULTS.format.default
        );
    const startDate = utcStartDate;

    return (
      <Col md={12} className='box no-padding'>
        <CardHeader
          filtersNeeded={filtersNeeded}
          handleFilterChange={this.handleFilterChange}
          schools={this.state.schools}
          title='Interactions'
          defaults={{
            Time: ['3 Months'],
            Interactions: ['All'],
            Accounts: ['Coaches', 'School', 'Support Staff'],
          }}
        />
        <Col md={12} className='timeline-container timeline-holder'>
          <TimelineComponent
            player={this.props.player}
            endDate={this.dateTimeService.formatUTCDate(utcEndDate, DATE_DEFAULTS.format.default)}
            startDate={startDate}
            endLabel='Today'
            startLabel={startDate}
            interval={2}
            intervalType='weeks'
            showFirst={5}
            values={this.generateStructure()}
            timeLineDataLoaded={this.props.twitterTimelineDataLoaded}
          />
        </Col>
        <Col md={12} className='icon-legend'>
          <div className='single legend'>Legend:</div>
          <div className='single'>
            <i className='icon following fas fa-level-up-alt fa-rotate-90' />
            <span>Following</span>
          </div>
          <div className='single'>
            <i className='icon follower fas fa-level-down-alt fa-rotate-90' />
            <span>Follower</span>
          </div>
          <div className='single'>
            <i className='icon mention fas fa-at' />
            <span>Mentions</span>
          </div>
          <div className='single'>
            <i className='icon retweet fas fa-retweet' />
            <span>Rewteet</span>
          </div>
          <div className='single'>
            <i className='icon like fas fa-heart' />
            <span>Like</span>
          </div>
        </Col>
      </Col>
    );
  }
}

export default Interactions;
