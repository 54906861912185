import React, { Component } from 'react';

/**
 * Components
 */
import TwitterAccountsFilterComponent from './components/filters/accounts';
import TwitterDateFilterComponent from './components/filters/date';
import TwitterInteractionsFilterComponent from './components/filters/interactions';
import TwitterSchoolsFilterComponent from './components/filters/schools';

/**
 * Styles
 */
import './components/filters/styles/_index.scss';

class CardHeader extends Component {
  createFilters() {
    const filters = [];
    this.props.filtersNeeded.forEach((filterName) => {
      let defaultValue;
      if (this.props.defaults && this.props.defaults[filterName]) {
        defaultValue = this.props.defaults[filterName] ? this.props.defaults[filterName] : [];
      }
      switch (filterName) {
        case 'Time':
          filters.push(
            <TwitterDateFilterComponent
              handleFilterChange={this.props.handleFilterChange}
              key='filters-time'
              defaults={defaultValue}
            />
          );

          break;
        case 'Interactions':
          filters.push(
            <TwitterInteractionsFilterComponent
              handleFilterChange={this.props.handleFilterChange}
              key='filters-interactions'
              defaults={defaultValue}
            />
          );

          break;
        case 'Accounts':
          filters.push(
            <TwitterAccountsFilterComponent
              handleFilterChange={this.props.handleFilterChange}
              key='filters-accounts'
              defaults={defaultValue}
            />
          );

          break;
        case 'Schools':
          filters.push(
            <TwitterSchoolsFilterComponent
              handleFilterChange={this.props.handleFilterChange}
              schools={[]}
              key='filters-schools'
              defaults={defaultValue}
            />
          );

          break;
        default:
          break;
      }
    });

    return filters;
  }

  handleDropdownChange(newFilter) {
    const allFilters = this.state.twitterFilters;
    allFilters[newFilter.name] = newFilter;
    this.props.handleFilterChange(allFilters);
  }

  render() {
    return (
      <header className='card-header'>
        <h2>{this.props.title}</h2>
        <div className='twitter-filters'>{this.createFilters()}</div>
      </header>
    );
  }
}

export default CardHeader;
