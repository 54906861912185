import axios from 'axios';

class PackagesService {
  findPackages() {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}packages/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }
}

export default new PackagesService();
