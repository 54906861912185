import React from 'react';
import ContactInfo from '../contactInfo';
import ParentContactInfoBody from './parentContactInfoBody';
import ParentContactInfoCollapse from './parentContactInfoCollapse';

function ParentContactInfo({ contact }) {
  return (
    <ContactInfo contact={contact}>
      <ParentContactInfoBody />
      <ParentContactInfoCollapse />
    </ContactInfo>
  );
}

export default ParentContactInfo;
