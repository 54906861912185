import React from 'react';
import classNames from 'classnames';

function TimelineEntry({ entryKey, entryValue, totalBoxesPerRow, children }) {
  const classes = classNames({
    entry: true,
    main: entryKey[entryValue].main,
  });

  return (
    <div className={classes} style={{ width: `${totalBoxesPerRow * 30}px` }}>
      <div className='background'>{children}</div>
    </div>
  );
}

export default TimelineEntry;
