import React, { Component } from 'react';
import { Row } from 'reactstrap';

/**
 * Components
 */
import RivalsCampEventSingleComponent from './components/event-single';

/**
 * Styles
 */
import './styles/_index.scss';

class RivalsCampEventList extends Component {
  buildHtml() {
    return this.props.events.map((event) => {
      if (typeof event.videos === 'undefined' || event.videos.length === 0) {
        return null;
      }

      return <RivalsCampEventSingleComponent key={event.id} event={event} />;
    });
  }

  render() {
    return <Row className='event-list'>{this.buildHtml()}</Row>;
  }
}

export default RivalsCampEventList;
