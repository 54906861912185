import React from 'react';

/**
 * Common
 */
import MultiSelectFilterComponent from '../../common/multi_select';

/**
 * Parent
 */
import CommonComponent from '../../../../../generic';

/**
 * Services
 */
import PositionsService from '../../../../../../../services/positions';

/**
 * Store
 */
import store from '../../../../../../../reducers/store';

import './styles/_index.scss';

class PositionsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    ) {
      isOpen = true;
    }

    this.state = {
      activity_request_level: this.props.activity_request_level || '',
      filter_action_type: this.props.filter_action_type || 'FILTER_UPDATED',
      id: this.props.id || 'positions',
      isOpen,
      label: 'Positions',
      positions: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    PositionsService.get()
      .then((result) => {
        this.setState({
          isLoading: false,
          positions: [
            {
              id: 'all',
              name: 'All',
              selected: true,
            },
          ].concat(
            result.map((position) => {
              position.selected = false;

              return position;
            })
          ),
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  onChange(values) {
    store.dispatch({
      type: this.state.filter_action_type,
      filter: {
        activity_request_level: this.state.activity_request_level,
        type: this.state.id,
        values,
      },
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return null;
    }

    return (
      <MultiSelectFilterComponent
        activity_request_level={this.state.activity_request_level}
        filter_action_type={this.state.filter_action_type}
        classes='positions'
        collapseClass='quarter'
        id={this.state.id}
        isOpen={this.state.isOpen}
        label={this.state.label}
        onChange={this.onChange.bind(this)}
        selected={this.props.values}
        values={this.state.positions}
        closeFilter={this.props.closeFilter}
      />
    );
  }
}

export default PositionsFilterComponent;
