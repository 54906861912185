import React from 'react';

import SidebarRivalsOnlyFiltersGroup from 'FilterComponents/groups/sidebarRivalOnlyFiltersGroup';
import SidebarDefaultFiltersGroup from 'FilterComponents/groups/sidebarDefaultFiltersGroup';

function SidebarFilters({ filter, closeFilter, isRivalsOnly }) {
  if (isRivalsOnly) {
    return <SidebarRivalsOnlyFiltersGroup filter={filter} closeFilter={closeFilter} />;
  }

  return <SidebarDefaultFiltersGroup filter={filter} closeFilter={closeFilter} />;
}

export default SidebarFilters;
