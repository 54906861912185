import React from 'react';

import TypeaheadFilterComponent from 'FilterComponents/common/typeahead';
import CommonComponent from 'CommonComponents/generic';
import PlayerService from 'Services/players';

import './styles/_index.scss';

const MIN_NAME_VALUE_LENGTH = 2;

class PlayerSearchFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: Boolean(this.props.values?.length),
      toggleClicked: false,
      searchValue: '',
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.closeFilter === true && nextState.toggleClicked === false && nextProps.values) {
      nextState.isOpen = nextProps.values.length > 0;
    }

    nextState.toggleClicked = false;

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  getSearchCall = () => {
    const { searchValue } = this.state;

    if (!this.isReadyToSearch(searchValue)) return;

    return this.searchByName;
  };

  searchByName = (searchValue) => {
    return PlayerService.get({
      data: { name: searchValue },
      isDropdown: true,
    });
  };

  handleValueChange = (value) => this.setState({ searchValue: value });

  handleCancelCall = () => PlayerService.cancelCall();

  isReadyToSearch = (value) => {
    return value.length > MIN_NAME_VALUE_LENGTH;
  };

  render() {
    const { isOpen, searchValue } = this.state;
    const { values } = this.props;
    return (
      <TypeaheadFilterComponent
        classes='player_search'
        id='players'
        label='Search Players'
        placeholder='Search for prospects'
        searchingMessage='Searching for prospects...'
        isOpen={isOpen}
        values={values}
        searchValue={searchValue}
        onValueChange={this.handleValueChange}
        onCancelCall={this.handleCancelCall}
        searchCall={this.getSearchCall()}
      />
    );
  }
}

export default PlayerSearchFilterComponent;
