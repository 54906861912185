import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import store from '../../reducers/store';
import Interactions from './Interactions';
import LikeTrends from './LikeTrends';
import PowerRankings from './PowerRankings';
import TwitterStream from './TwitterStream';
import TwitterService from '../../services/twitter';

import './styles/_index.scss';

class TwitterInsights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerId: this.props.player.id,
      dataLoaded: false,
      masterPlayerList: this.props.masterPlayerList,
      twitterHandle: this.props.twitterHandle,
      filters: {
        interactions: {},
        likeTrends: {},
      },
    };
  }

  componentWillMount() {
    store.dispatch({
      type: 'TWITTER_DATA_RESET',
    });
    this.getTwitterData();
  }

  getTwitterData() {
    TwitterService.getTwitterData(this.props.player.id, 'timeline');
    TwitterService.getTwitterData(this.props.player.id, 'likeTrends');
    TwitterService.getTwitterData(this.props.player.id, 'twitterRankings');
  }

  handleFilterChange(newFilters) {
    const filterCopy = Object.assign({}, newFilters);
    const type = filterCopy.component;
    delete filterCopy.component;
    TwitterService.getTwitterData(this.props.player.id, type, filterCopy);
  }

  componentWillUnmount() {
    store.dispatch({
      type: 'TWITTER_DATA_RESET',
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.twitterTimelineDataLoaded ||
      nextProps.twitterRankingsDataLoaded ||
      nextProps.twitterTrendsDataLoaded
    ) {
      this.setState({
        dataLoaded: true,
      });
    }
  }

  render() {
    if (
      this.props.player.twitterHandle === 'none' ||
      this.props.player.twitterHandle === null ||
      typeof this.props.player.twitterHandle === 'undefined'
    ) {
      return (
        <Col md={12} className='box-holder'>
          <div className='box'>
            <h1 aria-live='polite'>No Twitter Handle For {this.props.player.name} </h1>
          </div>
        </Col>
      );
    }

    if (!this.state.dataLoaded) {
      return (
        <Col md={12} className='box-holder'>
          <div className='box'>
            <h1> Twitter Data Loading for {this.props.player.name} </h1>
          </div>
        </Col>
      );
    }
    return (
      <Col md={12} className='box-holder'>
        <PowerRankings
          twitterRankingsData={this.props.twitterRankings}
          handleFilterChange={this.handleFilterChange.bind(this)}
          player={this.props.player}
          playerId={this.state.playerId}
          twitterFollowData={this.props.twitterFollowData}
          twitterHandle={this.state.twitterHandle}
          twitterRankingsDataLoaded={this.props.twitterRankingsDataLoaded}
        />
        <Interactions
          timelineData={this.props.twitterTimeline}
          handleFilterChange={this.handleFilterChange.bind(this)}
          player={this.props.player}
          playerId={this.state.playerId}
          twitterHandle={this.state.twitterHandle}
          twitterTimelineDataLoaded={this.props.twitterTimelineDataLoaded}
        />
        <LikeTrends
          likeTrendsData={this.props.twitterTrends}
          handleFilterChange={this.handleFilterChange.bind(this)}
          player={this.props.player}
          twitterFollowData={this.props.twitterFollowData}
          twitterTrendsDataLoaded={this.props.twitterTrendsDataLoaded}
        />
        <TwitterStream
          className='relevantTweets'
          player={this.props.player}
          twitterHandle={this.state.twitterHandle}
        />
      </Col>
    );
  }
}

function mapStoreToProps(storeState) {
  return {
    likeTrendsData: storeState.twitter.likeTrendsData,
    twitterFollowData: storeState.twitter.twitterFollowData,
    twitterRankings: storeState.twitter.twitterRankings,
    twitterTimeline: storeState.twitter.twitterTimeline,
    twitterTrends: storeState.twitter.twitterTrends,
    twitterTimelineDataLoaded: storeState.twitter.twitterTimelineDataLoaded,
    twitterRankingsDataLoaded: storeState.twitter.twitterRankingsDataLoaded,
    twitterTrendsDataLoaded: storeState.twitter.twitterTrendsDataLoaded,
  };
}

export default connect(mapStoreToProps)(TwitterInsights);
