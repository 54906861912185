import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import TypeaheadSearchResultsComponent from 'FilterComponents/common/typeahead/components/results';
import GoogleService from 'Services/google';
import store from 'Reducers/store';
import { STATUSES } from 'Constants/loadingStatuses';

import './styles/_index.scss';

class LocationRadiusComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.search = debounce(this.search, 350);

    this.state = {
      data: [],
      minLength: 2,
      isOpen: false,
      lastTyped: false,
      toggleClicked: false,
      status: STATUSES.idle,
      distance: this.props.values.distance || 100,
      searchText: this.props.values.label || '',
    };
  }

  onChange(event) {
    let values = Object.assign({}, this.props.values);

    if (typeof values === 'undefined') {
      values = {
        distance: 0,
        search: '',
      };
    }

    values.search = event.target.value;

    store.dispatch({
      type: this.props.filter_action_type,
      filter: {
        activity_request_level: this.props.activity_request_level,
        type: this.props.id,
        values,
      },
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let isOpen = nextState.isOpen;

    if (nextState.lastTyped === false) {
      if (
        typeof nextProps.values === 'undefined' ||
        parseInt(nextProps.values.latitude, 10) === -1
      ) {
        nextState.distance = 100;
        nextState.searchText = '';
        if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
          isOpen = false;
        }
      } else if (
        typeof nextProps.values !== 'undefined' &&
        parseInt(nextProps.values.latitude, 10) > -1
      ) {
        nextState.distance = nextProps.values.distance;
        nextState.searchText = nextProps.values.label;
        if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
          isOpen = true;
        }
      }
    }
    return {
      isOpen,
      lastTyped: false,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  changeDistance(event) {
    this.setState(
      {
        distance: event.target.value,
        lastTyped: true,
      },
      () => {
        if (this.props.values.latitude > -1) {
          const values = Object.assign({}, this.props.values);

          values.distance = this.state.distance;

          store.dispatch({
            type: this.props.filter_action_type,
            filter: {
              activity_request_level: this.props.activity_request_level,
              type: this.props.id,
              values,
            },
          });
        }
      }
    );
  }

  triggerSearch(event) {
    this.search.cancel();

    const data =
      event.target.value.length >= 0 && event.target.value.length < this.state.minLength
        ? []
        : this.state.data;

    const status =
      event.target.value.length > this.state.minLength ? STATUSES.loading : STATUSES.idle;
    this.setState(
      {
        data,
        status,
        lastTyped: true,
        searchText: event.target.value,
      },
      () => {
        GoogleService.cancelCall('Restarting search');

        if (this.state.searchText.length > this.state.minLength) {
          this.search();
        } else if (this.state.searchText.length === 0) {
          store.dispatch({
            type: this.props.filter_action_type,
            filter: {
              activity_request_level: this.props.activity_request_level,
              type: this.props.id,
              values: {
                distance: this.state.distance,
                latitude: -1,
                label: null,
                longitude: -1,
              },
            },
          });
        }
      }
    );
  }

  search() {
    const data = {
      q: this.state.searchText,
    };

    const call = GoogleService.get(data);

    call
      .then((results) => {
        this.setState({
          data: results,
          status: STATUSES.completed,
          lastTyped: true,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          status: STATUSES.errored,
          lastTyped: true,
        });
      });
  }

  selectItem(item) {
    this.setState(
      {
        data: [],
        lastTyped: true,
        searchText: item.label,
      },
      () => {
        const values = {
          distance: this.state.distance,
          label: item.label,
          latitude: item.latitude,
          longitude: item.longitude,
        };

        store.dispatch({
          type: this.props.filter_action_type,
          filter: {
            activity_request_level: this.props.activity_request_level,
            type: this.props.id,
            values,
          },
        });
      }
    );
  }

  render() {
    const { id, values, label, classes, collapsible } = this.props;
    const { isOpen, data, status, distance, searchText } = this.state;

    const activeId = label.toLowerCase().replace(/([^a-z])/g, '_');

    return (
      <FilterComponent
        id={id}
        classes={`location-radius ${classes}`}
        collapsible={collapsible}
        isOpen={isOpen}
        key={id}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        <div className='input'>
          <i className='icon search far fa-search' />
          <input
            onChange={this.triggerSearch.bind(this)}
            placeholder='Search for a location'
            type='text'
            value={searchText}
            id={`active-${activeId}`}
          />
        </div>
        <div className='location-radius__holder'>
          <span>within</span>
          <input
            onChange={this.changeDistance.bind(this)}
            placeholder='Miles'
            type='text'
            value={distance}
          />
          <span>miles</span>
        </div>
        <TypeaheadSearchResultsComponent
          data={data}
          values={values}
          searchValue={searchText}
          errorMessage='No results found'
          searchingMessage='Searching...'
          selectItem={this.selectItem.bind(this)}
          isLoading={status === STATUSES.loading}
          isCompleted={status === STATUSES.completed}
          isIdle={status === STATUSES.idle}
          isErrored={status === STATUSES.errored}
        />
      </FilterComponent>
    );
  }
}

LocationRadiusComponent.defaultProps = {
  filter_action_type: 'FILTER_UPDATED',
  activity_request_level: '',
  closeFilter: false,
  label: 'Location Radius',
  collapsible: false,
  classes: '',
};

LocationRadiusComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
  closeFilter: PropTypes.bool,
  label: PropTypes.string,
  collapsible: PropTypes.bool,
  classes: PropTypes.string,
};

export default LocationRadiusComponent;
