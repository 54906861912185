import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PowerDetailsSpread from './PowerDetailsSpread';
import PowerDetailsLikes from './PowerDetailsLikes';

const defaultFollowObj = {
  'School Accounts': [],
  Coaches: [],
  Recruiting: [],
  'Support Staff': [],
  Roster: [],
  Commits: [],
  total: 0,
};

const defaultLikesObj = {
  coach: { liked: 0, total: 0 },
  commit: { liked: 0, total: 0 },
  roster: { liked: 0, total: 0 },
  school: { liked: 0, total: 0 },
  support: { liked: 0, total: 0 },
  total: { liked: 0, total: 0 },
};

class PowerDetails extends Component {
  componentWillMount() {
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolName !== this.props.schoolName) {
      this.setData();
    }
  }

  twitterDataExists(followData) {
    if (typeof followData === 'undefined' || typeof followData.followers === 'undefined') {
      return false;
    }

    return true;
  }

  mapHandlesToFollowObj(handleFollowObjs, total) {
    const updatedFollowObj = JSON.parse(JSON.stringify(defaultFollowObj));
    updatedFollowObj.Coaches = handleFollowObjs.coach;
    updatedFollowObj['School Accounts'] = handleFollowObjs.school;
    updatedFollowObj['Support Staff'] = handleFollowObjs.support;
    updatedFollowObj.Roster = handleFollowObjs.roster;
    updatedFollowObj.Commits = handleFollowObjs.commit;
    updatedFollowObj.total = total;
    return updatedFollowObj;
  }

  setData() {
    if (this.twitterDataExists(this.props.schoolRankingsData)) {
      this.setState({
        followingData: this.mapHandlesToFollowObj(
          this.props.schoolRankingsData.followings,
          this.props.schoolRankingsData.totalFollowing
        ),
        followerData: this.mapHandlesToFollowObj(
          this.props.schoolRankingsData.followers,
          this.props.schoolRankingsData.totalFollower
        ),
      });
    } else {
      this.setState({
        followingData: defaultFollowObj,
        followerData: defaultFollowObj,
      });
    }
  }

  getPostsData() {
    if (typeof this.props.schoolRankingsData === 'undefined') {
      return defaultLikesObj;
    }

    return this.props.schoolRankingsData.posts;
  }

  render() {
    return (
      <Col md={12} className='box box-dark' id='box-dark'>
        <Row className='power-details-header'>
          <h3>
            {this.props.player.name}
            <i style={{ margin: '0 5px' }} className='icon compare white fas fa-arrows-alt-h' />
            {this.props.schoolName}
          </h3>
        </Row>
        <Row className='power-details-content'>
          <PowerDetailsSpread type='Followers' twitterData={this.state.followingData} />
          <PowerDetailsSpread type='Following' twitterData={this.state.followerData} />
          <PowerDetailsLikes posts={this.getPostsData()} schoolName={this.props.schoolName} />
        </Row>
      </Col>
    );
  }
}

export default PowerDetails;
