import { Component } from 'react';

class RedirectComponent extends Component {
  constructor(props) {
    super(props);

    this.props.history.push('/prospects');
  }

  render() {
    return null;
  }
}

export default RedirectComponent;
