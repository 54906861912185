import React from 'react';
import { Link } from 'react-router-dom';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { Button, Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';

import AuthenticationService from 'Services/authentication';
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

import generatePageTitles from 'RootComponents/utility/pageTitles';

import 'RootComponents/login/styles/_index.scss';

class LoginComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: false,
      message: '',
    };
  }

  componentWillMount() {
    if (AuthenticationService.loggedIn === true) {
      this.props.history.push('/prospects');
    }
  }

  componentDidMount() {
    document.title = generatePageTitles('Login');
  }

  login(event, formValues) {
    this.setState({
      isLoading: true,
    });

    AuthenticationService.login(formValues.username, formValues.password)
      .then(() => {
        this.props.history.push('/prospects');
      })
      .catch((error) => {
        if (error.status === 403) {
          return this.props.history.push({
            pathname: '/forcereset',
            state: formValues,
          });
        }

        this.setState({
          errorThrown: true,
          isLoading: false,
          message: error.message,
        });
      });
  }

  render() {
    return (
      <Row className='login-page'>
        <Col md={6} className='left'>
          <Row className='overlay'>
            <Col md={12}>
              <h1 className='zcruit-by-ncsa-logo'>
                <Link to='/login' href='/login'>
                  Zcruit
                </Link>
              </h1>
              <p>Recruit Smarter</p>
              <p>Perform Better</p>
            </Col>
          </Row>
        </Col>
        <Col md={6} className='right'>
          <h2>Sign in </h2>
          <AvForm onValidSubmit={this.login.bind(this)}>
            <AvGroup>
              <Label aria-hidden='true' for='username'>
                Email
              </Label>
              <AvField
                type='text'
                id='username'
                name='username'
                placeholder='Enter your username'
                errorMessage='Please provide a valid username'
                required
              />
              <FormFeedback>{this.state.message}</FormFeedback>
            </AvGroup>
            <AvGroup>
              <Label aria-hidden='true' for='password'>
                Password
              </Label>
              <AvField
                type='password'
                id='password'
                name='password'
                placeholder='Enter your password'
                errorMessage='Please enter your password'
                required
              />
              <FormFeedback>{this.state.message}</FormFeedback>
            </AvGroup>
            <FormGroup>
              <Button color='primary' disabled={this.state.isLoading}>
                Sign In
              </Button>
              <Link to='/forgotpassword/' href='/login'>
                Forgot password?
              </Link>
            </FormGroup>
          </AvForm>
          <MessagingComponent
            thrown={this.state.errorThrown}
            message={this.state.message}
            type={MESSAGE_TYPE.danger}
          />
        </Col>
      </Row>
    );
  }
}
export default LoginComponent;
