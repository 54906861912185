import React, { Component } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from 'reactstrap';

import { DateTime } from 'luxon';

/**
 * Components
 */
import PdfDocument from 'RootComponents/activity/components/pdfDocument';
import ExportPlayerModal from 'CommonComponents/exportPlayerModal';

/**
 * Services
 */
import ActivityService from 'Services/activity';
import AuthenticationService from 'Services/authentication';

/**
 * Styles
 */

import 'CommonComponents/exportPdf/styles/_index.scss';

class MyPdfLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      label: this.props.label || 'Export All Prospects',
      modalOpen: false,
      triggerDownload: false,
    };

    this.players = {};
    this.totalPlayers = 0;
    this.completedPlayers = 0;
  }

  exportData(page = 1) {
    const filter = JSON.parse(JSON.stringify(this.props.filter));

    filter.export = true;

    if (this.props.players) {
      filter.players = this.props.players.map((player) => {
        return player.id;
      });
    }

    const func = ActivityService.get(this.props.filter, page, 'pdf');

    func.then((data) => {
      this.totalPlayers = data.total;
      Object.keys(data.data).forEach((key) => {
        this.completedPlayers += data.data[key].length;

        if (typeof this.players[key] === 'undefined') {
          this.players[key] = [];
        }

        this.players[key] = this.players[key].concat(data.data[key]);
      });

      this.setState({
        data: this.players,
      });

      if (page < data.pageTotal) {
        return this.exportData(page + 1);
      }

      this.setState(
        {
          triggerDownload: true,
        },
        () => {
          this.players = {};
          this.totalPlayers = 0;
          this.completedPlayers = 0;
        }
      );
    });
  }

  triggerModal() {
    this.setState(
      {
        modalOpen: !this.state.modalOpen,
      },
      () => {
        this.exportData(1);
      }
    );
  }

  cancelRequests() {
    ActivityService.cancelCall('Cancelling prospect export');

    this.setState(
      {
        modalOpen: false,
        data: {},
        triggerDownload: false,
      },
      () => {
        this.players = {};
        this.totalPlayers = 0;
        this.completedPlayers = 0;
      }
    );
  }

  renderLink() {
    if (this.state.triggerDownload === true) {
      return (
        <div id='pdf-holder'>
          <PDFDownloadLink
            document={<PdfDocument data={this.state.data} />}
            fileName={`Zcruit Report ${DateTime.utc().toFormat('MM-dd-yy')}`}>
            {({ blob, url, loading, error }) => {
              if (!loading && blob && url) {
                setTimeout(() => {
                  document.getElementById('pdf-holder').children[0].click();
                  this.setState({
                    triggerDownload: false,
                    modalOpen: false,
                  });
                }, 250);
              }
            }}
          </PDFDownloadLink>
        </div>
      );
    }
    return null;
  }

  render() {
    const isDemoOrg = AuthenticationService.getOrganization()?.is_demo;
    return (
      <div className='exporter'>
        <Button disabled={isDemoOrg} color='link' onClick={this.triggerModal.bind(this)}>
          <span className='downloader'>
            <i className='icon downloadWithBox white fas fa-download' />
            {this.state.label}
          </span>
        </Button>
        {this.renderLink()}
        <ExportPlayerModal
          completePlayers={this.completedPlayers}
          cancelRequests={this.cancelRequests.bind(this)}
          modalOpen={this.state.modalOpen}
          toggleModal={this.triggerModal.bind(this)}
          totalPlayers={this.totalPlayers}
        />
      </div>
    );
  }
}

export default MyPdfLink;
