/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import IconComponent from '../../../../common/icon';
import PlayerPageModalHeaderComponent from '../../modal/header';

/**
 * Styles
 */
import './styles/_index.scss';

class ErrorPageComponent extends Component {
  render() {
    return (
      <div className='modal-wrapper'>
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <PlayerPageModalHeaderComponent
            activeTab={this.props.activeTab}
            page={this.props.page}
            player={this.props.player}
            setTab={this.props.setTab}
            toggleModal={this.props.toggleModal}
          />
        </div>
        <div className='modal-body'>
          <Row className='secondary-page error-page'>
            <Col md={12} className='page-title-container'>
              <div className='page-title'>{this.props.title || 'Error'}</div>
            </Col>
            <Col md={12} className='box-holder'>
              <div className='box'>
                <div className='error'>
                  <IconComponent icon='play fas fa-exclamation' />
                  <h2 aria-live='polite'>{this.props.message}</h2>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ErrorPageComponent;
