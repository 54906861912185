import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import MetricFilterComponent from 'FilterComponents/common/metric';

import MinMaxRequest from 'Requests/min_max';
import AcademicConfig from './config';

const config = AcademicConfig;

class AcademicsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      isOpen: false,
      toggleClicked: false,
      academics: Object.keys(config).map((key) => {
        return config[key];
      }),
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let isOpen = false;
    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      const academicKeys = Object.keys(AcademicConfig);
      const filterKeys = Object.keys(nextProps.values);
      academicKeys.forEach((academicKey) => {
        if (filterKeys.includes(academicKey) && academicKey !== 'gpa') {
          isOpen = true;
        }
        if (academicKey === 'gpa') {
          isOpen = nextProps.values.gpa.min > -1;
        }
      });
    } else {
      isOpen = nextState.isOpen;
    }

    nextState.toggleClicked = false;
    nextState.isOpen = isOpen;

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @param clicked
   * @param selected
   */
  onChange(selected) {
    const academics = JSON.parse(JSON.stringify(this.state.academics));

    academics.forEach((academic) => {
      if (academic.id === selected.id) {
        academic.active = !academic.active;
      }
    });

    this.setState({
      academics,
    });
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @param clicked
   * @param selected
   */
  changeVerified(selected) {
    const academics = JSON.parse(JSON.stringify(this.state.academics));

    academics.forEach((academic) => {
      if (academic.id === selected.id) {
        academic.value.verified = selected.value.verified;
      }
    });

    this.setState({
      academics,
    });
  }

  getProperValues(metric) {
    if (typeof this.props.values[metric] === 'undefined') {
      return new MinMaxRequest();
    }

    return this.props.values[metric];
  }

  render() {
    const { id, label, classes, collapsible } = this.props;
    const { isOpen } = this.state;

    const act = this.state.academics.find((metric) => {
      return metric.id === 'act';
    });
    const sat = this.state.academics.find((metric) => {
      return metric.id === 'sat';
    });
    const gpa = this.state.academics.find((metric) => {
      return metric.id === 'gpa';
    });

    return (
      <FilterComponent
        id={id}
        className='academics'
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}
        collapsible={collapsible}
        classes={`academics ${classes}`}>
        <MetricFilterComponent
          active={gpa.active}
          id={gpa.id}
          key={gpa.id}
          label={gpa.label}
          lessThan={gpa.less_than}
          removable={false}
          unit={gpa.unit}
          value={this.getProperValues(gpa.id)}
          allow_null_checkbox={true}
        />
        <MetricFilterComponent
          active={act.active}
          id={act.id}
          key={act.id}
          label={act.label}
          lessThan={act.less_than}
          removable={false}
          unit={act.unit}
          value={this.getProperValues(act.id)}
        />
        <MetricFilterComponent
          active={sat.active}
          id={sat.id}
          key={sat.id}
          label={sat.label}
          lessThan={sat.less_than}
          removable={false}
          unit={sat.unit}
          value={this.getProperValues(sat.id)}
        />
      </FilterComponent>
    );
  }
}

AcademicsFilterComponent.defaultProps = {
  collapsible: false,
  closeFilter: false,
  label: 'Academics',
  classes: '',
};

AcademicsFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  collapsible: PropTypes.bool,
  closeFilter: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
};

export default AcademicsFilterComponent;
