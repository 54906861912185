import React from 'react';
import { Alert } from 'reactstrap';

import './styles/_index.scss';

function MessagingComponent({ message, thrown = true, id = null, type = null, className = null }) {
  if (thrown === false) {
    return null;
  }

  if (type) {
    return (
      <Alert color={type} id={id} className={className}>
        {message}
      </Alert>
    );
  }

  return (
    <div id={id} className={className}>
      <p>{message}</p>
    </div>
  );
}

export default MessagingComponent;
