/**
 * Third party includes
 */
import 'react-app-polyfill/ie9';
import 'core-js/es';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

/**
 * Zcruit includes
 */
import ZcruitRouter from './utilities/router';

/**
 * Store
 */
import store from './reducers/store';

/**
 * Services
 */
import AuthenticationService from './services/authentication';

/**
 * Style imports
 */
import './styles/_index.scss';
import { isProduction } from './constants/environment';

// --- Analytics ---
ReactGA.initialize(process.env.GA_TRACKING_CODE);

const tagManagerArgs = {
  gtmId: `${process.env.GTM_TRACKING_CODE}`,
};

TagManager.initialize(tagManagerArgs);

if (!isProduction() && process.env.ACCESSIBILITY_LOGGING) {
  // Accessibility checks while developing
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

const validPathsForUnauth = [
  '',
  'forcereset',
  'forgotpassword',
  'login',
  'redirect',
  'resetpassword',
  'terms-of-use',
  'privacy-policy',
  'do-not-sell-my-personal-info',
];

const doesPathNeedAuth = () => {
  return (
    validPathsForUnauth.indexOf(window.location.pathname.replace(/([^a-zA-Z0-9_-])/g, '')) === -1
  );
};

(async () => {
  try {
    await AuthenticationService.isLoggedIn();
  } catch (e) {
    if (doesPathNeedAuth()) {
      window.location = '/login';
    }
    // await AuthenticationService.logout();
  }

  ReactDOM.render(
    <Provider store={store}>
      <ZcruitRouter />
    </Provider>,
    document.getElementById('app')
  );
})();
