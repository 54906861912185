import React, { Component } from 'react';
import { Col } from 'reactstrap';

/**
 * Components
 */
import MessagingComponent, { STATUS_MESSAGE, MESSAGE_TYPE } from 'CommonComponents/messager';
import TogglerComponent from 'CommonComponents/toggler';
import SingleEventEventsViewStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/events/components/event';
import SingleTypeViewStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/events/components/type';

/**
 * Services
 */
import StatisticsService from 'Services/stats';

/**
 * Constants
 */
import {
  IGNORABLE_METRIC_TYPES_LIST,
  IGNORABLE_METRIC_SOURCES,
  VALID_METRIC_TYPES_LIST,
} from 'PlayerPageComponents/pages/stats/constants';

/**
 * Styles
 */
import 'PlayerPageComponents/pages/stats/components/events/styles/_index.scss';

class EventsViewStatsPlayerPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'Type',
      isLoading: false,
      player: this.props.player,
      stats: this.props.stats,
    };
  }

  toggle(active) {
    this.setState(
      {
        active,
        isLoading: true,
      },
      () => {
        this.loadData();
      }
    );
  }

  loadData() {
    StatisticsService.cancelCall('Restarting stats call');

    StatisticsService.getStatsForPlayer(this.state.player.id, this.state.active.toLowerCase())
      .then((success) => {
        this.setState({
          isLoading: false,
          stats: success,
        });
      })
      .catch((error) => {
        if (error.message === 'Restarting stats call') {
          return;
        }

        this.setState({
          errorThrown: true,
          isLoading: false,
          errorMessage: 'There were no stats results found for the requested type',
        });
      });
  }

  buildView() {
    if (this.state.active === 'Type') {
      const metricObj = this.state.stats.metrics;

      const sortedMetricKeys = Object.keys(metricObj)
        .sort((a, b) => VALID_METRIC_TYPES_LIST.indexOf(a) - VALID_METRIC_TYPES_LIST.indexOf(b))
        .filter((metricKey) => !IGNORABLE_METRIC_TYPES_LIST.includes(metricKey));

      const validMetrics = sortedMetricKeys.map((metricKey) => {
        const foundMetrics = this.props.stats.metrics[metricKey].filter(
          (metric) => !IGNORABLE_METRIC_SOURCES.includes(metric.source.name)
        );

        return (
          foundMetrics && (
            <SingleTypeViewStatsPlayerPageComponent
              key={metricKey}
              metric={this.props.stats.metrics[metricKey]}
            />
          )
        );
      });

      return validMetrics;
    }
    return this.state.stats.map((event, index) => {
      const collapse = index === 0;
      return (
        <SingleEventEventsViewStatsPlayerPageComponent
          collapse={collapse}
          event={event}
          key={event.id}
        />
      );
    });
  }

  render() {
    return (
      <Col md={12}>
        {this.state.isLoading === true && (
          <div className='box less-padding'>
            <div className='stats-header'>
              <h2>Stats/Combine Results</h2>
              <TogglerComponent
                active={this.state.active}
                defaultState='Type'
                leftText='Type'
                rightText='Event'
                toggle={this.toggle.bind(this)}
              />
            </div>
            <div className='stats-body'>
              <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
            </div>
          </div>
        )}
        {this.state.isLoading === false && (
          <div className='box less-padding'>
            <div className='stats-header'>
              <h2>Stats/Combine Results</h2>
              <TogglerComponent
                active={this.state.active}
                defaultState='Type'
                leftText='Type'
                rightText='Event'
                toggle={this.toggle.bind(this)}
              />
            </div>
            <div className='stats-body'>{this.buildView()}</div>
          </div>
        )}
      </Col>
    );
  }
}

export default EventsViewStatsPlayerPageComponent;
