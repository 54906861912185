import React, { Component } from 'react';
import { nanoid } from 'nanoid';

/**
 * Components
 */
import DropdownCheckboxFilterComponent from '../../../../common/dropdownCheckbox';

class TwitterInteractionsFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uuid: nanoid(),
      interactions: [
        {
          id: 'all',
          name: 'All',
          value: 'all',
        },
        {
          id: 'following',
          name: 'Following',
          value: 'following',
        },
        {
          id: 'follower',
          name: 'Follower',
          value: 'follower',
        },
        {
          id: 'mention',
          name: 'Mentions',
          value: 'mention',
        },
        {
          id: 'retweet',
          name: 'Retweets',
          value: 'retweet',
        },
        {
          id: 'like',
          name: 'Likes',
          value: 'like',
        },
      ],
    };
  }

  componentWillMount() {
    if (this.props.defaults && this.props.defaults.length > 0) {
      const newInteractions = this.state.interactions.map((interaction) => {
        if (this.props.defaults.includes(interaction.name)) {
          interaction.checked = true;
        }
        return interaction;
      });
      this.setState({
        interactions: newInteractions,
      });
      this.filterChanged(
        newInteractions
          .filter((interaction) => {
            return interaction.checked;
          })
          .map((interaction) => {
            return interaction.value;
          })
      );
    }
  }

  filterChanged(value) {
    this.props.handleFilterChange({
      type: 'interactions',
      values: value,
    });
  }

  render() {
    return (
      <div className='twitter-filters-filter' key={this.state.uuid}>
        <DropdownCheckboxFilterComponent
          addAll={true}
          columns={1}
          filterChanged={this.filterChanged.bind(this)}
          label='Interactions'
          values={this.state.interactions}
        />
      </div>
    );
  }
}

export default TwitterInteractionsFilterComponent;
