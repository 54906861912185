import React from 'react';

/**
 * Parent
 */
import CommonComponent from '../../../../../../../generic';

class PillsSearchResultsComponent extends CommonComponent {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  render() {
    if (typeof this.props.data === 'undefined' || this.props.data.length === 0) {
      return null;
    }

    return (
      <div className='selected-results'>
        {this.props.data.map((single) => {
          return (
            <div
              className='pill'
              key={single.id}
              onClick={() => {
                this.props.removeItem(single);
              }}
              onKeyUp={() => {
                this.props.removeItem(single);
              }}
              role='button'
              tabIndex={-1}>
              <p>{single.label}</p>
              <div className='circle white-bg'>
                <i className='icon fas fa-times' />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PillsSearchResultsComponent;
