import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';

import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import generatePageTitles from 'RootComponents/utility/pageTitles';
import AuthenticationService from 'Services/authentication';

import 'RootComponents/resetpassword/styles/_index.scss';

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: false,
      message: '',
    };
  }

  componentWillMount() {
    if (AuthenticationService.loggedIn === true) {
      this.props.history.push('/prospects');
    }
  }

  componentDidMount() {
    document.title = generatePageTitles('Reset Password');
  }

  resetPassword(event, formValues) {
    this.setState({
      isLoading: true,
    });

    AuthenticationService.resetPassword(formValues.username, formValues.pin, formValues.password)
      .then(() => {
        this.props.history.push('/prospects');
      })
      .catch((error) => {
        this.setState({
          errorThrown: true,
          isLoading: false,
          message: error.message,
        });
      });
  }

  render() {
    return (
      <Container fluid={true}>
        <Row className='login-page'>
          <Col md={6} className='left'>
            <Row className='overlay'>
              <Col md={12}>
                <h1 className='zcruit-by-ncsa-logo'>
                  <Link to='/login' href='/login'>
                    Zcruit
                  </Link>
                </h1>
                <h4>Recruit Smarter</h4>
                <h3>Perform Better</h3>
              </Col>
            </Row>
          </Col>
          <Col md={6} className='right'>
            <h3>Reset Password</h3>
            <AvForm onValidSubmit={this.resetPassword.bind(this)}>
              <AvGroup>
                <Label aria-hidden='true' for='username'>
                  Username *
                </Label>
                <AvField
                  type='text'
                  id='username'
                  name='username'
                  placeholder='Enter your username'
                  errorMessage='Please provide a valid username'
                  value={this.props.location.state.username}
                  required
                />
              </AvGroup>
              <AvGroup>
                <Label aria-hidden='true' for='pin'>
                  PIN (Sent To Email) *
                </Label>
                <AvField
                  type='text'
                  id='pin'
                  name='pin'
                  placeholder='Enter your PIN'
                  errorMessage='Please provide a PIN'
                  required
                />
              </AvGroup>
              <AvGroup>
                <Label aria-hidden='true' for='password'>
                  Password *
                </Label>
                <AvField
                  type='password'
                  id='password'
                  name='password'
                  placeholder='Enter your password'
                  errorMessage='Please provide a password'
                  required
                />
              </AvGroup>
              <AvGroup>
                <Label aria-hidden='true' for='password_confirm'>
                  Confirm Password *
                </Label>
                <AvField
                  type='password'
                  id='password_confirm'
                  name='password_confirm'
                  placeholder='Confirm your password'
                  errorMessage='Your passwords do not match'
                  validate={{ match: { value: 'password' } }}
                  required
                />
              </AvGroup>
              <FormGroup>
                <Button color='primary' disabled={this.state.isLoading}>
                  Reset Password
                </Button>
              </FormGroup>
            </AvForm>
            <MessagingComponent
              thrown={this.state.errorThrown}
              message={this.state.message}
              type={MESSAGE_TYPE.danger}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ResetPasswordComponent;
