import React, { Component } from 'react';
import videojs from 'video.js';

/**
 * Styles
 */
import 'video.js/dist/video-js.min.css';
import './styles/_index.scss';

class VideoPlayerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoplay: this.props.autoplay || false,
      controls: this.props.controls || true,
      fluid: true,
      sources: [
        {
          src: this.props.videoUrl,
          type: 'video/mp4',
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(
      {
        sources: [
          {
            src: nextProps.videoUrl,
            type: 'video/mp4',
          },
        ],
      },
      () => {
        this.player.src([
          {
            src: nextProps.videoUrl,
            type: 'video/mp4',
          },
        ]);
      }
    );
  }

  componentDidMount() {
    this.player = videojs(this.videoNode, this.state, () => {
      console.log('onPlayerReady', this);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div data-vjs-player>
        <video
          ref={(node) => {
            this.videoNode = node;
          }}
          className='video-js'>
          <track kind='captions' />
        </video>
      </div>
    );
  }
}

export default VideoPlayerComponent;
