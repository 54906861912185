import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import NestedDropdownFilterComponent from 'FilterComponents/common/nested_dropdown';
import TagListComponent from 'FilterComponents/common/tag_list';

import LocationService from 'Services/locations';
import store from 'Reducers/store';

class LocationFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpen: false,
      toggleClicked: false,
      locations: [],
    };
  }

  componentDidMount() {
    LocationService.getLocations().then((locations) => {
      const formattedLocation = this._formatForDropdown(locations);

      this.setState({
        locations: formattedLocation,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let locations = nextState.locations;
    let isOpen = false;

    if (typeof nextProps.values === 'undefined') {
      locations = locations.map((topLevel) => {
        topLevel.children = topLevel.children.map((secondLevel) => {
          secondLevel.checked = false;

          if (typeof secondLevel.children !== 'undefined') {
            secondLevel.children = secondLevel.children.map((thirdLevel) => {
              thirdLevel.checked = false;

              return thirdLevel;
            });
          }

          return secondLevel;
        });

        topLevel.checked = false;
        topLevel.expanded = false;

        return topLevel;
      });
    }

    if (typeof nextProps.values !== 'undefined') {
      if (
        (typeof nextProps.values.counties !== 'undefined' &&
          nextProps.values.counties.length > 0) ||
        (typeof nextProps.values.countries !== 'undefined' &&
          nextProps.values.countries.length > 0) ||
        (typeof nextProps.values.state !== 'undefined' && nextProps.values.state.length > 0)
      ) {
        locations = locations.map((location) => {
          switch (location.id) {
            case 'canada':
              let totalCanadianChecked = 0;

              location.children = location.children.map((canadianState) => {
                if (
                  typeof nextProps.values.state !== 'undefined' &&
                  nextProps.values.state.length > 0 &&
                  nextProps.values.state.indexOf(canadianState.id) > -1
                ) {
                  canadianState.checked = true;

                  totalCanadianChecked++;
                } else {
                  canadianState.checked = false;
                }

                return canadianState;
              });

              location.checked = totalCanadianChecked === location.children.length;

              break;
            case 'international':
              let totalInternationalChecked = 0;

              location.children = location.children.map((internationalState) => {
                if (
                  typeof nextProps.values.countries !== 'undefined' &&
                  nextProps.values.countries.length > 0 &&
                  nextProps.values.countries.indexOf(internationalState.id) > -1
                ) {
                  internationalState.checked = true;

                  totalInternationalChecked++;
                } else {
                  internationalState.checked = false;
                }

                return internationalState;
              });

              location.checked = totalInternationalChecked === location.children.length;

              break;
            case 'usa':
              let totalUSAChecked = 0;

              location.children = location.children.map((usState) => {
                let totalCountiesChecked = 0;

                usState.children = usState.children.map((county) => {
                  if (
                    typeof nextProps.values.counties !== 'undefined' &&
                    nextProps.values.counties.length > 0 &&
                    nextProps.values.counties.indexOf(county.id) > -1
                  ) {
                    county.checked = true;

                    totalCountiesChecked++;
                  } else {
                    county.checked = false;
                  }

                  return county;
                });

                usState.checked = totalCountiesChecked === usState.children.length;

                if (usState.checked === true) {
                  totalUSAChecked++;
                }

                return usState;
              });

              location.checked = totalUSAChecked === location.children.length;

              break;
            default:
              break;
          }

          return location;
        });
      } else {
        locations = locations.map((topLevel) => {
          topLevel.children = topLevel.children.map((secondLevel) => {
            secondLevel.checked = false;

            if (typeof secondLevel.children !== 'undefined') {
              secondLevel.children = secondLevel.children.map((thirdLevel) => {
                thirdLevel.checked = false;

                return thirdLevel;
              });
            }

            return secondLevel;
          });

          topLevel.checked = false;
          topLevel.expanded = false;

          return topLevel;
        });
      }
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      if (typeof nextProps.values !== 'undefined') {
        isOpen = nextProps.values.counties.length > 0 || nextProps.values.state.length > 0;
      } else {
        isOpen = false;
      }
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      isOpen,
      locations,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  _formatForDropdown(data) {
    const final = [];

    const canada = this._formatStatesForDropdown(data.canada, 'canada');
    const countries = this._formatCountriesForDropdown(data.international);
    const usa = this._formatStatesForDropdown(data.usa, 'usa');

    final.push({
      checked: false,
      children: usa,
      label: 'USA',
      id: 'usa',
      locIsParent: true,
    });
    final.push({
      checked: false,
      children: canada,
      label: 'Canada',
      id: 'canada',
      locIsParent: true,
    });
    final.push({
      checked: false,
      children: countries,
      label: 'International',
      id: 'international',
      locIsParent: true,
    });

    return final;
  }

  _formatCountriesForDropdown(countries) {
    const final = [];

    countries.forEach((country) => {
      const curCountry = {
        checked: false,
        children: [],
        label: country.name,
        id: country.id,
        locParent: 'international',
      };

      final.push(curCountry);
    });

    return final;
  }

  _formatStatesForDropdown(states, parent) {
    const final = [];

    states.forEach((state) => {
      const curState = {
        checked: false,
        children: [],
        label: state.name,
        id: state.id,
        locParent: parent,
      };

      if (typeof state.counties !== 'undefined' && state.counties.length > 0) {
        state.counties.forEach((child) => {
          const name = `${child.name} (${state.name})`;
          const collegeEntry = {
            checked: false,
            id: child.id,
            label: name,
            locParent: state.name,
            searchLabel: state.full_name,
            value: child.college_sport_id,
          };

          curState.children.push(collegeEntry);
        });
      }

      final.push(curState);
    });

    return final;
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @TODO: Add in logic to keep list of user opened states. Otherwise, preselected
   * values auto-open which can't happen
   *
   * @param clicked
   * @param selected
   */
  onChange(clicked, selected, parent) {
    let counties =
      typeof this.props.values !== 'undefined' && typeof this.props.values.counties !== 'undefined'
        ? this.props.values.counties
        : [];
    let countries =
      typeof this.props.values !== 'undefined' && typeof this.props.values.countries !== 'undefined'
        ? this.props.values.countries
        : [];
    let state =
      typeof this.props.values !== 'undefined' && typeof this.props.values.state !== 'undefined'
        ? this.props.values.state
        : [];

    const parentLabel =
      typeof clicked.locParent !== 'undefined'
        ? clicked.locParent
        : typeof clicked.id !== 'undefined'
          ? clicked.id
          : clicked.parent;

    switch (parentLabel) {
      case 'canada':
        state = [];

        state = this._selectCanadianSelections(
          selected.find((value) => {
            return value.id === 'canada';
          })
        );

        break;
      case 'international':
        countries = [];

        countries = this._selectCountries(
          selected.find((value) => {
            return value.id === 'international';
          })
        );

        break;
      case 'usa':
        counties = this._selectUSASecondarySelections(
          selected.find((value) => {
            return value.id === 'usa';
          }).children
        );

        break;
      default:
        counties = this._selectUSASecondarySelections(
          selected.find((value) => {
            return value.id === 'usa';
          }).children
        );

        break;
    }

    const { filter_action_type, activity_request_level, id } = this.props;
    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type: id,
        values: {
          counties,
          countries,
          state,
        },
      },
    });
  }

  _selectCanadianSelections(selected) {
    const states = [];

    selected.children.forEach((option) => {
      if (option.checked === true) {
        states.push(option.id);
      }
    });

    return states;
  }

  _selectUSASecondarySelections(selected) {
    let counties = [];

    selected.forEach((option) => {
      if (typeof option.children !== 'undefined') {
        const selectedChildren = option.children.filter((child) => {
          return child.checked === true;
        });

        const selectedChildIds = selectedChildren.map((child) => {
          return child.id;
        });

        counties = counties.concat(selectedChildIds);
      }
    });

    return counties;
  }

  _selectCountries(selected) {
    const countries = [];

    selected.children.forEach((option) => {
      if (option.checked === true) {
        countries.push(option.id);
      }
    });

    return countries;
  }

  render() {
    const { id, label, collapsible, classes } = this.props;
    const { locations, isOpen, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <FilterComponent
        id={id}
        classes={`location ${classes}`}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}
        collapsible={collapsible}>
        <NestedDropdownFilterComponent
          data={locations}
          onChange={this.onChange.bind(this)}
          placeholder='State or County'
          parent={true}
          root={true}
          columns={1}
          rootExpanded={isOpen}
          depth={0}
          inputId={label}
          label={id}
        />
        <TagListComponent data={locations} onChange={this.onChange.bind(this)} />
      </FilterComponent>
    );
  }
}

LocationFilterComponent.defaultProps = {
  label: 'State / County',
  collapsible: false,
  classes: 'transparent-background',
  closeFilter: false,
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

LocationFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  label: PropTypes.string,
  collapsible: PropTypes.bool,
  classes: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default LocationFilterComponent;
