import React, { Component } from 'react';

import DropdownCheckboxFilterComponent from 'CommonComponents/dropdownCheckbox';

class TwitterAccountsFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [
        {
          id: 'all',
          name: 'All',
          value: 'all',
          checked: true,
        },
        {
          id: 'school',
          name: 'School',
          value: 'school',
        },
        {
          id: 'coach',
          name: 'Coaches',
          value: 'coach',
        },
        {
          id: 'support',
          name: 'Support Staff',
          value: 'support',
        },
        {
          id: 'roster',
          name: 'Roster',
          value: 'roster',
        },
        {
          id: 'commit',
          name: 'Commits',
          value: 'commit',
        },
      ],
    };
  }

  filterChanged(value) {
    this.props.handleFilterChange({
      type: 'accounts',
      values: value,
    });
  }

  render() {
    return (
      <div className='twitter-filters-filter'>
        <DropdownCheckboxFilterComponent
          addAll={true}
          columns={1}
          filterChanged={this.filterChanged.bind(this)}
          label='Accounts'
          values={this.state.accounts}
        />
      </div>
    );
  }
}

export default TwitterAccountsFilterComponent;
