import React from 'react';
import ContactInfo from '../contactInfo';
import CoachContactInfoBody from './coachContactInfoBody';
import CoachContactInfoCollapse from './coachContactInfoCollapse';

function CoachContactInfo({ contact }) {
  return (
    <ContactInfo contact={contact}>
      <CoachContactInfoBody />
      <CoachContactInfoCollapse />
    </ContactInfo>
  );
}

export default CoachContactInfo;
