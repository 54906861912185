import React from 'react';

/**
 * Styles
 */
import './styles/_index.scss';

/**
 * Parent
 */
import CommonComponent from '../../../../../../../generic';

class Tag extends CommonComponent {
  handleClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.props.onDelete(this.props.data);
  }

  render() {
    const tagId = `${this.props.data.id}_id`;
    const buttonId = `${this.props.data.id}_button`;
    const className = 'tag-remove';
    const labelRemove = this.props.labelRemove || 'Remove';

    return (
      <li className='tag-item' key={tagId}>
        <span className='tag' id={tagId} aria-label={this.props.data.label}>
          {this.props.data.tagLabel ? this.props.data.tagLabel : this.props.data.label}
          <button
            id={buttonId}
            onClick={this.handleClick.bind(this)}
            className={className}
            type='button'
            aria-label={labelRemove}
            aria-labelledby={`${buttonId} ${tagId}`}>
            <i className='fas fa-times' />
          </button>
        </span>
      </li>
    );
  }
}

export default Tag;
