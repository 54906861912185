import React, { Component } from 'react';
import { Col } from 'reactstrap';

/**
 * Components
 */
import PlayerRowComponent from '../playerRow';

/**
 * Styles
 */
import './styles/_index.scss';

class PlayerListByPositionComponent extends Component {
  render() {
    return (
      <Col md={6} className='position-list'>
        <h2 className='position-list-title'>{this.props.title}</h2>
        {this.props.players.map((player) => {
          return (
            <PlayerRowComponent
              player={player}
              togglePlayerModal={this.props.togglePlayerModal}
              key={`${player.id}-row`}
            />
          );
        })}
      </Col>
    );
  }
}

export default PlayerListByPositionComponent;
