import React, { Component } from 'react';

import classNames from 'classnames';

import './styles/_index.scss';

class TogglerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active,
      defaultState: this.props.defaultState,
      leftText: this.props.leftText,
      rightText: this.props.rightText,
    };
  }

  toggle(clicked) {
    if (clicked === this.state.active) {
      return;
    }

    const active = clicked === this.state.defaultState ? this.state.leftText : this.state.rightText;

    this.setState(
      {
        active,
      },
      () => {
        this.props.toggle(active);
      }
    );
  }

  render() {
    const leftClasses = classNames({
      active: this.state.active === this.state.leftText,
      left: true,
    });

    const rightClasses = classNames({
      active: this.state.active === this.state.rightText,
      right: true,
    });

    return (
      <div className='toggler'>
        <button
          className={leftClasses}
          onClick={this.toggle.bind(this, this.state.leftText)}
          type='button'
          aria-label={`Show ${this.props.leftText} data`}>
          {this.props.leftText}
        </button>
        <button
          className={rightClasses}
          onClick={this.toggle.bind(this, this.state.rightText)}
          type='button'
          aria-label={`Show ${this.props.rightText} data`}>
          {this.props.rightText}
        </button>
      </div>
    );
  }
}

export default TogglerComponent;
