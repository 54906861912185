import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';

import AdvancedFilterComponent from 'FilterComponents/common/advanced';
import StatesFilterComponent from 'FilterComponents/common/states';

import store from 'Reducers/store';

export const DEFAULT_VALUES = {
  player_birth_states: [],
  parent_birth_states: [],
  parent_college_alma_mater_states: [],
};

export const CONNECTION_FILTERS = [
  { id: 'player_birth_states', label: 'Prospect Birth State' },
  { id: 'parent_birth_states', label: 'Parent Birth State' },
  { id: 'parent_college_alma_mater_states', label: 'Parent Alma Mater State' },
];

function ConnectionsFilter({
  id,
  label,
  values,
  closeFilter,
  filter_action_type,
  activity_request_level,
}) {
  const handleChange = (dataId) => (value) => {
    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type: id,
        values: {
          ...values,
          [dataId]: value,
        },
      },
    });
  };

  const isInitiallyOpen = !isEqual(values, DEFAULT_VALUES);

  return (
    <AdvancedFilterComponent
      isOpen={isInitiallyOpen}
      id={id}
      closeFilter={closeFilter}
      label={label}>
      {CONNECTION_FILTERS.map(({ id, label }) => (
        <StatesFilterComponent
          id={id}
          key={id}
          onChange={handleChange(id)}
          values={values[id]}
          label={label}
        />
      ))}
    </AdvancedFilterComponent>
  );
}

ConnectionsFilter.defaultProps = {
  values: DEFAULT_VALUES,
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
  closeFilter: false,
  label: 'Connections',
};

ConnectionsFilter.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  filter_action_type: PropTypes.string,
  activity_request_level: PropTypes.string,
  label: PropTypes.string,
};
export default ConnectionsFilter;
