import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import NestedDropdownFilterComponent from 'FilterComponents/common/nested_dropdown';
import TagListComponent from 'FilterComponents/common/tag_list';

import EventsService from 'Services/events';
import DateTimeService from 'Services/date-time';
import store from 'Reducers/store';

import 'react-dropdown-tree-select/dist/styles.css';
import './styles/_index.scss';

class EventsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.state = {
      events: [],
      isLoading: true,
      isOpen: false,
    };
  }

  componentDidMount() {
    const query = {
      sources: ['rivals_camp', 'rivals_combine'],
    };

    EventsService.find(query).then((event) => {
      const formattedEvent = this._formatForDropdown(event);

      this.setState({
        events: formattedEvent,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let events = nextState.events;
    let isOpen = false;

    if (typeof nextProps.values === 'undefined') {
      events = events.map((eventSource) => {
        eventSource.children = eventSource.children.map((eventYear) => {
          eventYear.checked = false;

          eventYear.children = eventYear.children.map((event) => {
            event.checked = false;

            return event;
          });

          return eventYear;
        });

        eventSource.checked = false;

        return eventSource;
      });
    }

    if (Array.isArray(nextProps.values)) {
      events = events.map((eventSource) => {
        let selectedYearsCount = 0;

        eventSource.checked = nextProps.values.indexOf(eventSource.id) > -1;

        eventSource.children = eventSource.children.map((eventYear) => {
          eventYear.checked =
            eventSource.checked === true || nextProps.values.indexOf(eventYear.id) > -1;

          let selectedEventsCount = 0;
          eventYear.children = eventYear.children.map((event) => {
            if (eventYear.checked === true || nextProps.values.indexOf(event.id) > -1) {
              event.checked = true;

              selectedEventsCount++;
            } else {
              event.checked = false;
            }

            return event;
          });

          if (selectedEventsCount === eventYear.children.length) {
            eventYear.checked = true;
          }

          selectedYearsCount =
            eventYear.checked === true ? selectedYearsCount + 1 : selectedYearsCount;

          return eventYear;
        });

        eventSource.checked = eventSource.children.length === selectedYearsCount;
        eventSource.expanded =
          selectedYearsCount > 0 && eventSource.children.length !== selectedYearsCount;

        return eventSource;
      });
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = Array.isArray(nextProps.values) && nextProps.values.length > 0;
    } else {
      isOpen = nextState.isOpen;
    }

    if (
      nextProps.closeFilter === true &&
      nextState.toggleClicked === false &&
      nextProps.defaultOpen === true
    ) {
      isOpen = true;
    }

    return {
      isOpen,
      events,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  _formatForDropdown(events) {
    events = events.sort((eventA, eventB) => {
      return eventA.source.label < eventB.source.label
        ? -1
        : eventA.source.label > eventB.source.label
          ? 1
          : 0;
    });

    const final = [];
    let currentSource;
    events.forEach((event, index) => {
      const nextEvent = events[index + 1];

      if (typeof currentSource === 'undefined' || event.source.id !== currentSource.id) {
        currentSource = {
          checked: false,
          children: [event],
          id: event.source.id,
          label: event.source.label,
        };
      } else {
        currentSource.children.push(event);
      }

      if (typeof nextEvent === 'undefined' || nextEvent.source.id !== currentSource.id) {
        currentSource.children = this._formatYearsForDropdown(currentSource.children);
        final.push(currentSource);
      }
    });

    return final;
  }

  doesYearMatchLabel(date, label) {
    const labelAsYear = parseInt(label, 10);
    const year = this.dateTimeService.toUtcDate(new Date(date)).year;
    return year === labelAsYear;
  }

  _formatYearsForDropdown(events) {
    events = events.sort((eventA, eventB) => {
      const dateA = new Date(eventA.occurred_on);
      const dateB = new Date(eventB.occurred_on);
      return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    });

    const final = [];
    let currentYear;
    events.forEach((event, index) => {
      const occurredOnEventYear = this.dateTimeService.toUtcDate(new Date(event.occurred_on)).year;
      const formattedEvent = this._formatEventForDropdown(event);
      const nextEvent = events[index + 1];

      if (
        typeof currentYear === 'undefined' ||
        !this.doesYearMatchLabel(event.occurred_on, currentYear.label)
      ) {
        currentYear = {
          checked: false,
          children: [formattedEvent],
          id: `${event.source.id}_${occurredOnEventYear}`,
          label: `${occurredOnEventYear}`,
          tagLabel: `${event.source.label} ${occurredOnEventYear}`,
          searchLabel: `${event.source.label} ${occurredOnEventYear}`,
        };
      } else {
        currentYear.children.push(formattedEvent);
      }

      if (
        typeof nextEvent === 'undefined' ||
        !this.doesYearMatchLabel(nextEvent.occurred_on, currentYear.label)
      ) {
        final.push(currentYear);
      }
    });

    return final.sort((yearA, yearB) => {
      return yearA.label < yearB.label ? 1 : yearA.label > yearB.label ? -1 : 0;
    });
  }

  _formatEventForDropdown(event) {
    const { year, month, day } = this.dateTimeService.fromJSDate(new Date(event.occurred_on));
    return {
      checked: false,
      id: event.id,
      label: `${event.city.name} (${month}/${day})`,
      tagLabel: `${event.source.label} ${year} ${event.city.name}`,
      searchLabel: `${event.source.label} ${year} ${event.city.name}`,
    };
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @param clicked
   * @param selected
   */

  onChange(clicked, selected, parent) {
    let values = [];

    selected.forEach((option) => {
      if (typeof option.children !== 'undefined') {
        const selectedChildIdsNew = this.getCheckedIdsOfChildren(option).flat(Infinity);

        values = values.concat(selectedChildIdsNew);
      } else {
        values.push(option.id);
      }
    });

    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        active: values.length > 0,
        activity_request_level,
        type: id,
        values,
      },
    });
  }

  getCheckedIdsOfChildren(option) {
    if (Array.isArray(option.children)) {
      return option.children.map((child) => {
        return this.getCheckedIdsOfChildren(child);
      });
    }
    if (option.checked === true) {
      return [option.id];
    }
    return [];
  }

  toggleActive(active) {
    const { id, activity_request_level } = this.props;

    store.dispatch({
      type: 'ACTIVITY_TOGGLE_ACTIVE',
      filter: {
        active,
        activity_request_level,
        type: id,
      },
    });
  }

  render() {
    const { id, label, classes, collapsible, active, addActiveCheckbox } = this.props;
    const { events, isOpen, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <FilterComponent
        id={id}
        active={active}
        addActiveCheckbox={addActiveCheckbox}
        classes={classes}
        collapsible={collapsible}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}
        toggleActive={this.toggleActive.bind(this)}>
        <NestedDropdownFilterComponent
          data={events}
          onChange={this.onChange.bind(this)}
          placeholder='Events'
          parent={true}
          root={true}
          columns={1}
          rootExpanded={isOpen}
          depth={0}
          inputId={label}
          label={id}
        />
        <TagListComponent data={events} onChange={this.onChange.bind(this)} />
      </FilterComponent>
    );
  }
}

EventsFilterComponent.defaultProps = {
  active: false,
  collapsible: false,
  closeFilter: false,
  defaultOpen: false,
  addActiveCheckbox: false,
  label: 'Events',
  classes: '',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

EventsFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.array,
  active: PropTypes.bool,
  collapsible: PropTypes.bool,
  closeFilter: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  addActiveCheckbox: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default EventsFilterComponent;
