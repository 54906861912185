import React from 'react';
import Navigation from '../components/navigation';

/**
 * Styles
 */
import './styles/navigationWrapper.scss';

export default function withNavigation(WrappedComponent) {
  return class extends React.Component {
    constructor() {
      super();
      this.skipContentAnchorLink = React.createRef();
      this.content = React.createRef();
    }

    handleOnClickForSkipNav() {
      this.content.current.focus();
    }

    render() {
      return (
        <>
          <a
            href='#main'
            id='skip-to-main-content'
            onClick={() => {
              this.handleOnClickForSkipNav();
            }}
            ref={this.skipContentAnchorLink}
            className='skip-to-content-link'>
            Skip to main content
          </a>
          <Navigation />
          <section tabIndex='-1' ref={this.content} className='p-0'>
            <WrappedComponent {...this.props} />
          </section>
        </>
      );
    }
  };
}
