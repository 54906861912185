import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import './_index.scss';

function InfoTooltip({ content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    return setIsOpen(!isOpen);
  };

  return (
    <span className='info-tooltip'>
      <span className='info-tooltip-icon' id='info-tooltip-target'>
        <i className='fas fa-info' />
      </span>

      <Tooltip
        placement='right'
        isOpen={isOpen}
        autohide={false}
        target='info-tooltip-target'
        toggle={toggle}>
        <div className='info-tooltip-content'>{content}</div>
      </Tooltip>
    </span>
  );
}

export default InfoTooltip;
