import React, { Component } from 'react';
import { Modal } from 'reactstrap';

/**
 * Components
 */
import AcademicsPlayerPageComponent from '../../prospectsPlayerPage/components/pages/academics';
import BasePlayerPageComponent from '../../prospectsPlayerPage/components/pages/base';
import ContactPlayerPageComponent from '../../prospectsPlayerPage/components/pages/contact';
import StatsPlayerPageComponent from '../../prospectsPlayerPage/components/pages/stats';
import VideoPlayerPageComponent from '../../prospectsPlayerPage/components/pages/videos';
import TwitterPlayerPageComponent from '../../prospectsPlayerPage/components/pages/twitter';

/**
 * Styles
 */
// import './styles/_index.scss';

class PlayerPageModalComponent extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      tab: this.props.tab,
    };
  }

  setTab(tab) {
    this.setState({
      tab,
    });
  }

  getTemplate() {
    if (typeof this.state.tab === 'undefined') {
      return (
        <BasePlayerPageComponent
          playerId={this.props.playerId}
          page='none'
          toggleModal={this.props.toggleModal}
          setTab={this.setTab.bind(this)}
          parentRef={this.modalRef}
        />
      );
    }

    switch (this.state.tab) {
      case 'stats':
        return (
          <StatsPlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
          />
        );
      case 'videos':
        return (
          <VideoPlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
          />
        );
      case 'twitter':
        return (
          <TwitterPlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
          />
        );
      case 'contact':
        return (
          <ContactPlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
          />
        );
      case 'academics':
        return (
          <AcademicsPlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
          />
        );
      default:
        return (
          <BasePlayerPageComponent
            playerId={this.props.playerId}
            page='none'
            toggleModal={this.props.toggleModal}
            setTab={this.setTab.bind(this)}
            parentRef={this.modalRef}
          />
        );
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className='player-page'
        id='player-page'
        aria-modal={true}
        ref={this.modalRef}>
        {this.getTemplate()}
      </Modal>
    );
  }
}

export default PlayerPageModalComponent;
