import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import PlayerContactInfo from './components/playerContactInfo';
import CoachContactInfo from './components/coachContactInfo';
import ParentContactInfo from './components/parentContactInfo';

function ContactTypeContactPlayerPageComponent({ contacts, socialInfo: social_info }) {
  const { coaches, players, parents, birth_date, birth_state } = contacts;

  const renderCoaches = () => {
    if (!coaches) {
      return null;
    }
    return (
      <Col md={12} className='box-holder'>
        <div className='box less-padding'>
          <div className='contact-header'>
            <h2>Coach Contact Info</h2>
          </div>
          {coaches.map((coach) => {
            return <CoachContactInfo key={coach.guid} contact={coach} />;
          })}
        </div>
      </Col>
    );
  };

  const renderParents = () => {
    if (!parents) {
      return null;
    }
    return (
      <Col md={12} className='box-holder'>
        <div className='box less-padding'>
          <div className='contact-header'>
            <h2>Family Contact Info</h2>
          </div>
          {parents.map((parent) => {
            return <ParentContactInfo key={parent.guid} contact={parent} />;
          })}
        </div>
      </Col>
    );
  };

  const renderPlayers = () => {
    if (!players) {
      return null;
    }
    return (
      <Col md={12} className='box-holder'>
        <div className='box less-padding'>
          <div className='contact-header'>
            <h2>Player Contact Info</h2>
          </div>
          {players.map((player) => {
            const extendPlayerContact = {
              ...player,
              birth_state,
              birth_date,
              social_info,
            };
            return <PlayerContactInfo key={player.guid} contact={extendPlayerContact} />;
          })}
        </div>
      </Col>
    );
  };

  return (
    <Row>
      {renderPlayers()}
      {renderCoaches()}
      {renderParents()}
    </Row>
  );
}

export default ContactTypeContactPlayerPageComponent;
