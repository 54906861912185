/**
 * Parents
 */
import MinMaxRequest from '../min_max';

class GPARequest extends MinMaxRequest {
  constructor() {
    super();

    this.allowNull = true;
    this.max = -1;
  }
}

export default GPARequest;
