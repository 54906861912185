import React, { Component } from 'react';
import { Col } from 'reactstrap';

/**
 * Services
 */
import TwitterDataService from 'Services/twitter';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';

/**
 * Components
 */
import CardHeader from './CardHeader';

class TwitterStream extends Component {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.state = {
      tweets: [],
      tweetsLoaded: false,
    };
  }

  componentDidMount() {
    TwitterDataService.getStream(this.props.player.id)
      .then((res) => {
        const tweets = res;
        this.loadTweets(tweets);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  loadTweets(tweets) {
    this.setState({
      tweets: tweets.map((tweet) => {
        return {
          name: tweet.user.name,
          handle: tweet.user.screen_name,
          text: tweet.text,
          date: this.dateTimeService.formatUTCDate(
            new Date(tweet.created_at),
            DATE_DEFAULTS.format.twitter
          ),
          favoriteCount: tweet.favorite_count,
          isRetweet: tweet.retweeted === true,
          conversationNum: null,
          profileImage: tweet.user.profile_image_url_https,
          url: `https://twitter.com/i/web/status/${tweet.id_str}`,
        };
      }),
      tweetsLoaded: true,
    });
  }

  tweetToHtml({ date, url, handle, text, favoriteCount, isRetweet, name, profileImage }) {
    return (
      <a
        className='single-tweet'
        href={url}
        key={`${date}-${url}`}
        target='_blank'
        rel='noopener noreferrer'>
        <div className='tweet'>
          {isRetweet === true && (
            <div className='tweet-header'>
              <i className='icon retweet fas fa-retweet' />
              Retweeted by {name}
            </div>
          )}
          <div className='twitter-user'>
            <div className='image'>
              <img src={profileImage} alt='Twitter Profile' className='twitter-profile-picture' />
            </div>
            <div className='name'>
              <p>
                <strong>{name}</strong>
              </p>
              <p>@{handle}</p>
            </div>
          </div>
          <div className='tweet-body'>
            <p>{text}</p>
            <p>{date}</p>
          </div>
          <div className='twitter-likes'>
            <i className='icon like fas fa-heart' />
            <span>{favoriteCount}</span>
          </div>
        </div>
      </a>
    );
  }

  render() {
    const tweets = this.state.tweets.map((tweet) => {
      return this.tweetToHtml(tweet);
    });
    if (!this.state.tweetsLoaded) {
      return (
        <Col md={12} className='box no-padding'>
          <CardHeader title='Twitter Feed' filtersNeeded={[]} />
          <div className='twitter-stream-container'>Loading...</div>
        </Col>
      );
    }
    if (this.state.tweetsLoaded && this.state.tweets.length === 0) {
      return (
        <Col md={12} className='box no-padding'>
          <CardHeader title='Twitter Feed' filtersNeeded={[]} />
          <div className='twitter-stream-container'>
            <span className='twitter-message'>No relevant tweets found.</span>
          </div>
        </Col>
      );
    }
    if (this.props.twitterHandle !== 'none') {
      return (
        <Col md={12} className='box no-padding'>
          <CardHeader title='Twitter Feed' filtersNeeded={[]} />
          <div className='twitter-stream-container'>{tweets}</div>
        </Col>
      );
    }

    return null;
  }
}

export default TwitterStream;
