import axios from 'axios';

class LocationService {
  getLocations(params) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}locations/`,
      params,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  queryLocations(query) {
    return axios({
      method: 'POST',
      url: `${process.env.API_URL}locations/query/`,
      data: query,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }
}

export default new LocationService();
