import React from 'react';
import { Table, Collapse } from 'reactstrap';
import { useContactInfoContext } from '../contactInfo';
import {
  ContactInfoValue,
  ContactInfoLocation,
  ContactInfoIcon,
  ContactInfoDate,
} from '../contactInfoMisc';

function CoachContactInfoCollapse() {
  const { isCollapsed, contact } = useContactInfoContext();
  return (
    <Collapse isOpen={isCollapsed}>
      <Table striped>
        <thead>
          <tr>
            <th>Updated</th>
            <th>Email</th>
            <th>Cell Phone</th>
            <th>Home Phone</th>
            <th>Address</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {contact.contacts.map((coach) => {
            return (
              <tr key={coach.id} className='contact-table'>
                <td className='contact-date'>
                  <ContactInfoDate value={coach.updated_at} />
                </td>
                <td className='contact-email'>
                  <ContactInfoValue value={coach.email} dashNoValue />
                </td>
                <td className='contact-cell'>
                  <ContactInfoValue value={coach.cell_phone} dashNoValue />
                </td>
                <td className='contact-home'>
                  <ContactInfoValue value={coach.home_phone} dashNoValue />
                </td>
                <td contact='contact-address'>
                  <ContactInfoLocation
                    location={coach.location}
                    address={coach.address}
                    dashNoValue
                  />
                </td>
                <td className='contact-icon'>
                  <ContactInfoIcon source={coach.source} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Collapse>
  );
}

export default CoachContactInfoCollapse;
