import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';

import Checkbox from 'CommonComponents/checkbox';
import 'FilterComponents/common/generic/styles/_index.scss';

class FilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active || false,
      addActiveCheckbox: this.props.addActiveCheckbox || false,
      canCollapse: this.props.canCollapse || true,
      classes: this.props.classes || '',
      clicked: false,
      collapseClass: this.props.collapseClass || '',
      isOpen: this.props.isOpen || false,
      label: this.props.label || 'Filter',
      collapsible: typeof this.props.collapsible === 'undefined' ? true : this.props.collapsible,
    };
  }

  toggle(e) {
    /**
     * Hack to get child element clicked.
     *
     * @TODO: maybe figure out how this was working before and isn't now.
     * Might be due to better pattern.
     */
    if (e.target.tagName.toLowerCase() === 'label') {
      return this.toggleActiveCheckbox();
    }

    this.props.passIsOpenToParent(!this.props.isOpen);
  }

  toggleActiveCheckbox() {
    this.props.toggleActive(!this.props.active);
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.props.isOpen === true) {
      classes += ' flip';
    }

    return classes;
  }

  addActiveCheckbox() {
    if (this.state.addActiveCheckbox === true) {
      const id = this.state.label.toLowerCase().replace(/([^a-z])/g, '_');

      return (
        <Checkbox
          id={`active-${id}`}
          checked={this.props.active}
          onChange={this.toggleActiveCheckbox.bind(this)}
        />
      );
    }

    return null;
  }

  renderFilter() {
    if (this.state.collapsible === false) {
      return (
        <Collapse
          isOpen={this.state.classes !== 'boolean'}
          className={this.state.collapseClass}
          id={`collapse-${this.props.id}`}>
          {this.props.children}
        </Collapse>
      );
    }

    return (
      <Collapse
        isOpen={this.props.isOpen}
        className={this.state.collapseClass}
        id={`collapse-${this.props.id}`}>
        {this.props.children}
      </Collapse>
    );
  }

  renderLabel() {
    if (this.state.collapsible === false) {
      const id = this.state.label.toLowerCase().replace(/([^a-z])/g, '_');
      return (
        <label className='transparent' htmlFor={`active-${id}`}>
          {this.addActiveCheckbox()}
          {this.state.label}
        </label>
      );
    }

    return (
      <Button
        className={this.state.addActiveCheckbox ? 'transparent school-filter-btn' : 'transparent'}
        onClick={this.toggle.bind(this)}
        aria-label={this.state.label}
        aria-expanded={this.props.isOpen}>
        <span>{this.state.label}</span>
        <i className={this.getIconClasses()} />
      </Button>
    );
  }

  render() {
    return (
      <div className={`filter ${this.state.classes}`}>
        {this.renderLabel()}
        {this.renderFilter()}
      </div>
    );
  }
}

export default FilterComponent;
