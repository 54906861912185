import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

/**
 * Common
 */
import FilterComponent from 'FilterComponents/common/generic';

/**
 * Parent
 */
import CommonComponent from 'CommonComponents/generic';

/**
 * Services
 */
import SourceService from 'Services/sources';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Styles
 */
import 'react-dropdown-tree-select/dist/styles.css';
import 'FilterComponents/specific/sources/styles/_index.scss';

class SourcesFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpen: false,
      sources: [],
    };
  }

  /**
   * Sources aren't labelled so we map with this function
   *
   * @param source
   * @return {*}
   */
  getValidSourceLabel(source) {
    const nameLabelMap = {
      athleticnet: 'Athletic.net',
      rivals: 'Rivals',
      rivals_camp: 'Rivals Camp',
      rivals_combine: 'Rivals Combine',
      national_preps: 'National Preps',
      ncsa: 'NCSA',
      nike_opening: 'Nike Opening',
      twoFourSeven: '247',
      hudl: 'Hudl',
      national_combine: 'National Combine',
      reigning_champs_experiences: 'RCX Showcase',
      polynesian_bowl_combine: 'Polynesian Bowl Combine',
      relative_athletic_score: 'Relative Athletic Score',
    };

    return nameLabelMap[source];
  }

  componentDidMount() {
    SourceService.getSources().then((conferences) => {
      const formattedConf = this._formatForDropdown(conferences);

      this.setState({
        sources: formattedConf,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let isOpen = false;
    let sources = nextState.sources;

    if (typeof nextProps.values === 'undefined' || nextProps.values.length === 0) {
      sources = sources.map((source) => {
        source.checked = false;

        return source;
      });
    }

    if (Array.isArray(nextProps.values) && nextProps.values.length > 0) {
      sources = sources.map((source) => {
        source.checked = nextProps.values.indexOf(source.id) > -1;
        return source;
      });
    }
    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = Array.isArray(nextProps.values) && nextProps.values.length > 0;
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      sources,
      isOpen,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  _formatForDropdown(sources) {
    const final = [];

    sources.forEach((source) => {
      const name = this.getValidSourceLabel(source.name);

      if (typeof name === 'undefined' || name === null || name === '') {
        return;
      }

      const curSource = {
        checked: false,
        children: [],
        id: source.id,
        label: name,
      };

      final.push(curSource);
    });

    return final;
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @TODO: Add in logic to keep list of user opened conferences. Otherwise, preselected
   * values auto-open which can't happen
   *
   * @param clicked
   * @param selected
   */
  onChange(clicked, selected) {
    let values = [];

    selected.forEach((option) => {
      if (typeof option._children !== 'undefined' && option._children.length > 0) {
        values = values.concat(option._children);
      } else {
        values.push(option.id);
      }
    });

    store.dispatch({
      type: 'FILTER_UPDATED',
      filter: {
        type: this.props.id,
        values,
      },
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return null;
    }

    return (
      <FilterComponent
        isOpen={this.state.isOpen}
        key={this.props.id}
        label={this.props.label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        <div className='custom-checkbox'>
          <DropdownTreeSelect
            clearSearchOnChange={false}
            data={this.state.sources}
            keepChildrenOnSearch={true}
            keepOpenOnSelect={true}
            keepTreeOnSearch={true}
            onChange={this.onChange.bind(this)}
            showPartiallySelected={true}
            texts={{
              placeholder: 'Sources',
            }}
          />
        </div>
      </FilterComponent>
    );
  }
}

export default SourcesFilterComponent;
