/**
 * Request structures
 */
import GPARequest from '../gpa';
import MinMaxRequest from '../min_max';
import { CURRENT_YEAR, isReadyToFlipYear } from './helpers';

class PlayerSearchRequest {
  constructor() {
    this.commits = {
      uncommitted: false,
      values: [],
    };
    this.events = [];
    this.gpa = new GPARequest();
    this.height = new MinMaxRequest();
    this.high_schools = [];
    this.transfers = [];
    this.location_radius = {
      distance: 100,
      latitude: -1,
      label: null,
      longitude: -1,
    };
    this.name = '';
    this.offers = {
      no_fbs: false,
      no_offers: false,
      no_p5: false,
      values: [],
    };
    this.school_type = {
      all: true,
      public: false,
      private: false,
      catholic: false,
      juco: false,
    };
    this.positions = [];
    this.prospect_type = {
      all: true,
      tracked: false,
      hidden: false,
      standard: false,
    };
    this.rating = new MinMaxRequest();
    this.signings = {
      unsigned: false,
      values: [],
    };
    this.sources = [];
    this.visits = [];
    this.weight = new MinMaxRequest();
    this.years = this._getDefaultYears();
  }

  add(key, value) {
    if (typeof this[key] !== 'undefined') {
      if (value === null || value === '' || value.length === 0) {
        delete this[key];
      } else {
        this[key] = value;
      }
    } else {
      this[key] = value;
    }
  }

  _getDefaultYears() {
    if (isReadyToFlipYear()) {
      return [CURRENT_YEAR + 1];
    }

    return [CURRENT_YEAR];
  }
}

export default PlayerSearchRequest;
