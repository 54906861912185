import React, { Component } from 'react';
import { DateTime } from 'luxon';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './styles/_index.scss';
import { DATE_DEFAULTS } from '../../../services/date-time';

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFormatDate = this.handleFormatDate.bind(this);
    this.handleParseDate = this.handleParseDate.bind(this);
    this.state = {
      from:
        typeof this.props.startDate !== 'undefined'
          ? DateTime.fromISO(this.props.startDate).toJSDate()
          : undefined,
      to:
        typeof this.props.endDate !== 'undefined'
          ? DateTime.fromISO(this.props.endDate).toJSDate()
          : undefined,
    };
  }

  showFromMonth() {
    const { from } = this.state;
    if (!from) {
      return;
    }
    this.to.getDayPicker().showMonth(from);
  }

  handleFormatDate(date, format) {
    const jsDate = new Date(date);
    const formattedDate = DateTime.fromJSDate(jsDate).toFormat(format);

    return formattedDate;
  }

  handleParseDate(date) {
    const jsDate = new Date(date);
    const parsedDate = DateTime.fromJSDate(jsDate);

    if (parsedDate.isValid) {
      return parsedDate.toJSDate();
    }

    return undefined;
  }

  handleFromChange(from) {
    if (typeof from !== 'undefined') {
      this.setState({ from }, () => {
        this.props.updateCustomDates(this.state.from, this.state.to);
      });
    }
  }

  handleToChange(to) {
    if (typeof to !== 'undefined') {
      this.setState({ to }, () => {
        this.showFromMonth();
        this.props.updateCustomDates(this.state.from, this.state.to);
      });
    }
  }

  createNavbar({ onPreviousClick, onNextClick, className, localeUtils }) {
    return (
      <div className={className}>
        <div
          className='DayPicker-NavButton DayPicker-NavButton--prev'
          onClick={() => {
            onPreviousClick();
          }}
          onKeyUp={() => {
            onPreviousClick();
          }}
          tabIndex={0}
          role='button'>
          <i className='icon caret-down prev-caret fal fa-chevron-down' />
        </div>
        <div
          className='DayPicker-NavButton DayPicker-NavButton--next'
          onClick={() => {
            onNextClick();
          }}
          onKeyUp={() => {
            onNextClick();
          }}
          tabIndex={0}
          role='button'>
          <i className='icon caret-down next-caret fal fa-chevron-down' />
        </div>
      </div>
    );
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className='date-picker' ref={this.props.innerRef}>
        <div className='date-picker__input date-picker__input--from'>
          <span className='date-picker__input-label'>Start Date</span>
          <DayPickerInput
            value={from}
            placeholder={`${DATE_DEFAULTS.format.default.toUpperCase()}`}
            format={`${DATE_DEFAULTS.format.default}`}
            formatDate={this.handleFormatDate}
            parseDate={this.handleParseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              navbarElement: this.createNavbar,
              onDayClick: () => {
                return this.to.getInput().focus();
              },
            }}
            onDayChange={this.handleFromChange}
          />
        </div>
        <div className='date-picker__input date-picker__input--to'>
          <span className='date-picker__input-label'>End Date</span>
          <DayPickerInput
            /* eslint-disable no-return-assign */
            ref={(el) => {
              return (this.to = el);
            }}
            /* eslint-enable no-return-assign */
            value={to}
            placeholder={`${DATE_DEFAULTS.format.default.toUpperCase()}`}
            format={`${DATE_DEFAULTS.format.default}`}
            formatDate={this.handleFormatDate}
            parseDate={this.handleParseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              toMonth: to,
              numberOfMonths: 1,
              navbarElement: this.createNavbar,
            }}
            onDayChange={this.handleToChange}
          />
        </div>
      </div>
    );
  }
}

// Necessary to forward ref from parent https://stackoverflow.com/questions/51526461/how-to-use-react-forwardref-in-a-class-based-component
export default React.forwardRef((props, ref) => {
  return <DatePickerComponent innerRef={ref} {...props} />;
});
