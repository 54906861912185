import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';

import AccountComponent from '../components/account';
import ActivityComponent from '../components/activity';
import ActivityPlayerPageComponent from '../components/activityPlayerPage';
import ProspectsComponent from '../components/prospects';
import ProspectsPlayerPageComponent from '../components/prospectsPlayerPage';
import ForcePasswordResetComponent from '../components/forceresetpassword';
import ForgotPasswordComponent from '../components/forgotpassword';
import FourOhFoComponent from '../components/errors/404';
import IndexComponent from '../components/landingPage';
import LoginComponent from '../components/login';
import ProtectedRouterComponent from '../components/utility/protected.router';
import RedirectComponent from '../components/redirect';
import ResetPasswordComponent from '../components/resetpassword';
import RivalsCampComponent from '../components/rivalsCamp';
import UsersComponent from '../components/users';

/** HOC navigation wrapper */
import withNavigation from '../utilities/navigationWrapper';

class ZcruitRouter extends Component {
  constructor() {
    super();

    this.history = createBrowserHistory();
  }

  render() {
    return (
      <Router history={this.history}>
        <div>
          <Switch>
            <Redirect exact from='/discover' to='/prospects' history={this.history} />
            <Redirect exact from='/dashboard' to='/prospects' history={this.history} />

            <Route exact path='/' component={IndexComponent} history={this.history} />
            <Route
              exact
              path='/forgotpassword'
              component={ForgotPasswordComponent}
              history={this.history}
            />
            <Route exact path='/login' component={LoginComponent} history={this.history} />
            <Route
              exact
              path='/resetpassword'
              component={ResetPasswordComponent}
              history={this.history}
            />
            <Route
              exact
              path='/forcereset'
              component={ForcePasswordResetComponent}
              history={this.history}
            />
            <Route exact path='/redirect' component={RedirectComponent} history={this.history} />

            <ProtectedRouterComponent
              exact
              path='/account'
              component={withNavigation(AccountComponent)}
              history={this.history}
              pageName='Account'
            />
            <ProtectedRouterComponent
              exact
              path='/account/:param'
              component={withNavigation(AccountComponent)}
              history={this.history}
              pageName='Account Settings'
            />
            <ProtectedRouterComponent
              path='/activity'
              component={withNavigation(ActivityComponent)}
              history={this.history}
              pageName='Activity Report'
            />
            <ProtectedRouterComponent
              path='/prospects'
              component={withNavigation(ProspectsComponent)}
              history={this.history}
              pageName='Prospects'
            />
            <ProtectedRouterComponent
              exact
              path='/rivals-camp/'
              component={withNavigation(RivalsCampComponent)}
              history={this.history}
              pageName='Rivals Camp'
            />
            <ProtectedRouterComponent
              exact
              path='/rivals-camp/video/:id'
              component={withNavigation(RivalsCampComponent)}
              history={this.history}
              pageName='Rivals Camp Videos'
            />
            <ProtectedRouterComponent
              exact
              path='/users/'
              component={withNavigation(UsersComponent)}
              pageName='Account Users'
            />
            <Route component={FourOhFoComponent} />
          </Switch>
          <Switch>
            <Redirect exact from='/discover/:id' to='/prospects/:id' history={this.history} />
            <Redirect
              exact
              from='/discover/:id/:param'
              to='/prospects/:id/:param'
              history={this.history}
            />
            <Redirect exact from='/dashboard/:id' to='/prospects/:id' history={this.history} />
            <Redirect
              exact
              from='/dashboard/:id/:param'
              to='/prospects/:id/:param'
              history={this.history}
            />

            <ProtectedRouterComponent
              exact
              path='/activity/:id'
              component={ActivityPlayerPageComponent}
              history={this.history}
              pageName='Activity Report'
            />
            <ProtectedRouterComponent
              exact
              path='/activity/:id/:param'
              component={ActivityPlayerPageComponent}
              history={this.history}
              pageName='Activity Report'
            />
            <ProtectedRouterComponent
              exact
              path='/prospects/:id'
              component={ProspectsPlayerPageComponent}
              history={this.history}
            />
            <ProtectedRouterComponent
              exact
              path='/prospects/:id/:param'
              component={ProspectsPlayerPageComponent}
              history={this.history}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default ZcruitRouter;
