import React from 'react';

function TimelineShowMoreButton({ onClick, isShowingMore }) {
  return (
    <button
      onClick={onClick}
      type='button'
      className='showMore'
      aria-label={
        isShowingMore ? 'Show Less Twitter Interactions' : 'Show More Twitter Interactions'
      }>
      {isShowingMore ? '- Show less' : '+ Show More'}
    </button>
  );
}

export default TimelineShowMoreButton;
