import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import EventsService from 'Services/events';
import FilterRow from 'AccountComponents/manageLists/components/filterRow';

import './styles/_index.scss';

class TableListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setEvents();
  }

  setEvents() {
    const query = {
      sources: ['rivals_camp', 'rivals_combine'],
    };

    EventsService.find(query)
      .then((events) => {
        const formattedEvents = this._formatListToDictionary(events);

        this.setState({
          events: formattedEvents,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  _formatListToDictionary(list) {
    if (typeof list === 'undefined' || list.length === 0) {
      return [];
    }

    return list.reduce((listMap, item) => {
      listMap[item.id] = item.name;
      return listMap;
    }, {});
  }

  render() {
    return (
      <Table className='table-list'>
        <thead className='list-heading'>
          <tr>
            <th className='title'>
              <h2>{this.props.title}</h2>
            </th>
            <th className='add-button'>
              <Button
                color='primary'
                onClick={() => {
                  this.props.openDrawer(this.props.filterType, false, true);
                }}
                aria-label='Add New List'
                className='add-new-list'
                innerRef={this.props.addNewListButtonRef}>
                + Add New List
              </Button>
            </th>
          </tr>
        </thead>
        {!this.state.isLoading &&
          this.props.filters.filter(Boolean).map((filter) => {
            return (
              <FilterRow
                editType={this.props.editType}
                filter={JSON.parse(JSON.stringify(filter))} // pass it a copy of the filter
                filterType={this.props.filterType}
                key={filter.id}
                toggleDeleteModal={this.props.toggleDeleteModal}
                toggleEditModal={this.props.toggleEditModal}
                events={this.state.events}
              />
            );
          })}
      </Table>
    );
  }
}

export default TableListComponent;
