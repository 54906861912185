import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store from 'Reducers/store';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import { TwitterPreview } from 'PlayerPageComponents/modal/boxes/components/twitter/components';

import 'PlayerPageComponents/modal/boxes/components/twitter/styles/_index.scss';

function TwitterPreviewBox({ children }) {
  return (
    <Col md={4} className='box twitter-preview'>
      <h2>Twitter Insights</h2>
      {children}
    </Col>
  );
}

function TwitterBox({ player, page, twitterRankingsData, twitterRankingsDataLoaded }) {
  useEffect(() => {
    store.dispatch({
      type: 'TWITTER_DATA_RESET',
    });
  }, []);

  if (!player.twitterHandle) {
    return (
      <TwitterPreviewBox>
        <MessagingComponent message={NO_DATA_MESSAGE.twitterHandle} />
      </TwitterPreviewBox>
    );
  }

  if (!twitterRankingsDataLoaded) {
    return (
      <TwitterPreviewBox>
        <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
      </TwitterPreviewBox>
    );
  }

  if (!twitterRankingsData.length) {
    return (
      <TwitterPreviewBox>
        <MessagingComponent message={NO_DATA_MESSAGE.twitterInfo} />
      </TwitterPreviewBox>
    );
  }

  return <TwitterPreview player={player} page={page} rankings={twitterRankingsData} />;
}

function mapStoreToProps({ twitter }) {
  return {
    twitterRankingsData: twitter.twitterRankings,
    twitterRankingsDataLoaded: twitter.twitterRankingsDataLoaded,
  };
}

export { TwitterBox };
export default withRouter(connect(mapStoreToProps)(TwitterBox));
