import React, { Component } from 'react';
import DateTimeService, { DATE_DEFAULTS } from '../../../../../services/date-time';

/**
 * Components
 */
import DropdownCheckboxFilterComponent from '../../../../common/dropdownCheckbox';

class TwitterDateFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();

    const currDate = this.props.currDate || this.dateTimeService.getDate();
    const thirtyDaysAgo = this.dateTimeService.formatUTCDate(
      currDate.minus({ days: 30 }),
      DATE_DEFAULTS.format.extended
    );
    const ninetyDaysAgo = this.dateTimeService.formatUTCDate(
      currDate.minus({ days: 90 }),
      DATE_DEFAULTS.format.extended
    );
    const oneHundredAndEightyDaysAgo = this.dateTimeService.formatUTCDate(
      currDate.minus({ days: 180 }),
      DATE_DEFAULTS.format.extended
    );
    const oneYearAgo = this.dateTimeService.formatUTCDate(
      currDate.minus({ years: 1 }),
      DATE_DEFAULTS.format.extended
    );

    this.state = {
      dates: [
        {
          id: thirtyDaysAgo,
          name: '1 Month',
          value: thirtyDaysAgo,
        },
        {
          id: ninetyDaysAgo,
          name: '3 Months',
          value: ninetyDaysAgo,
        },
        {
          id: oneHundredAndEightyDaysAgo,
          name: '6 Months',
          value: oneHundredAndEightyDaysAgo,
        },
        {
          id: oneYearAgo,
          name: '1 Year',
          value: oneYearAgo,
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.defaults && this.props.defaults.length > 0) {
      const newDates = this.state.dates.map((date) => {
        if (this.props.defaults.includes(date.name)) {
          date.checked = true;
        }
        return date;
      });
      this.setState({
        dates: newDates,
      });
      this.filterChanged(
        newDates
          .filter((date) => {
            return date.checked;
          })
          .map((date) => {
            return date.value;
          })
      );
    }
  }

  filterChanged(value) {
    this.props.handleFilterChange({ type: 'startDate', values: value[0] });
  }

  render() {
    return (
      <div className='twitter-filters-filter'>
        <DropdownCheckboxFilterComponent
          addAll={true}
          columns={1}
          filterChanged={this.filterChanged.bind(this)}
          label='Time'
          values={this.state.dates}
          radio={true}
        />
      </div>
    );
  }
}

export default TwitterDateFilterComponent;
