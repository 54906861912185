import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import './styles/_index.scss';

class SaveFilterModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      saveButtonName: this.props.saveButtonName || 'Create New List',
    };
  }

  saveHandler() {
    this.props.saveHandler(this.state.name);
  }

  updateName(event) {
    this.setState({
      name: event.target.value,
    });
  }

  disableSaveButton() {
    return (
      typeof this.state.name === 'undefined' ||
      this.state.name.length === 0 ||
      this.state.name.toLowerCase() === 'for you'
    );
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modalOpen}
        toggle={this.props.toggle}
        modalClassName='save-filter-modal'
        className='save-filter-modal-content'
        labelledBy='save-filter-modal'
        aria-modal={true}>
        <ModalBody>
          <h3 id='save-filter-modal'>Save Filters into New List</h3>
          <label htmlFor='filter-name'>List Name</label>
          <div className='input white'>
            <input
              className=''
              type='text'
              id='filter-name'
              onChange={this.updateName.bind(this)}
              required
            />
          </div>
          <Button
            color='primary'
            disabled={this.disableSaveButton()}
            title={this.disableSaveButton() ? 'List Name cannot be empty' : null}
            onClick={() => {
              this.saveHandler(this.state.name);
            }}>
            {this.state.saveButtonName}
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default SaveFilterModalComponent;
