import axios from 'axios';
import PlayersModel from '../models/player';

class ActivityService {
  constructor() {
    this.cancelTokens = [];
    this.page = 0;
  }

  get(params = {}, page = 1, download) {
    params = this._updateDefaultParams(params);
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    let url = `${process.env.API_URL}activity/?page=${page}`;
    if (download === 'excel') {
      url = `${process.env.API_URL}activity/excel?page=${page}`;
    } else if (download === 'pdf') {
      url = `${process.env.API_URL}activity/pdf?page=${page}`;
    }

    return axios({
      method: 'POST',
      url,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      cancelToken: this.cancelTokens[tokenLength - 1].token,
      data: params,
    })
      .then((response) => {
        if (download === 'excel') {
          return response.data;
        }

        Object.keys(response.data.data).forEach((key) => {
          response.data.data[key].forEach((item) => {
            item.player = new PlayersModel(item.player);
          });
        });

        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  cancelCall(message) {
    this.cancelTokens.forEach((token) => {
      token.cancel(message);
    });
  }

  _updateDefaultParams(params) {
    if (!params.changes && !params.changes.types) {
      return params;
    }
    const newParams = JSON.parse(JSON.stringify(params));
    const types = newParams.changes.types;
    const typesKeys = Object.keys(newParams.changes.types);
    // if any of the actives are true, the user has changed them
    // we dont need to change anything and can handle the params normally
    for (let keyIndex = 0; keyIndex < typesKeys.length; keyIndex++) {
      if (types[typesKeys[keyIndex]].active === true) {
        return newParams;
      }
    }
    // if we get here, the actives are all at the default state (false)
    // and we want to see all data
    // loop through and change all actives to true
    // some keys should stay default false (for business reasons)
    const skipKeys = ['twitter_follower', 'rating_bump', 'gpa'];
    for (let keyIndex = 0; keyIndex < typesKeys.length; keyIndex++) {
      if (skipKeys.indexOf(typesKeys[keyIndex]) === -1) {
        types[typesKeys[keyIndex]].active = true;
      }
    }
    return newParams;
  }
}

export default new ActivityService();
