import React, { Component } from 'react';
import { Button } from 'reactstrap';

/**
 * Components
 */
import MessagingComponent, { ERROR_MESSAGE, MESSAGE_TYPE } from 'CommonComponents/messager';

/**
 * Styles
 */
import 'RootComponents/rivalsCamp/components/singleVideo/components/playerRow/styles/_index.scss';

class PlayerRowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: true,
    };
  }

  renderViewDetailsButton(playerId) {
    return (
      <Button
        color='primary'
        onClick={() => {
          this.props.togglePlayerModal(playerId);
        }}
        className='position-list-profile-button'>
        View Profile
      </Button>
    );
  }

  getImage(player) {
    if (
      typeof player.image_url === 'undefined' ||
      player.image_url === null ||
      player.image_url.indexOf('http') === -1
    ) {
      return 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
    }
    return player.image_url;
  }

  getPosition(player) {
    console.log(player);
    if (player.positions.length > 0) {
      return player.positions.reduce((highestPosition, currentPosition) => {
        return currentPosition.priority > highestPosition ? currentPosition : highestPosition;
      }).name;
    }
    return 'No data available';
  }

  getJersey(player) {
    if (player.jersey) {
      return `#${player.jersey.value}`;
    }
    return 'No data available';
  }

  render() {
    if (this.state.errorThrown === true) {
      return <MessagingComponent message={ERROR_MESSAGE.players} type={MESSAGE_TYPE.danger} />;
    }

    return (
      <div className='position-list-player-row-wrapper'>
        <div className='position-list-player-row'>
          <div className='position-list-player-info'>
            <div className='headshot'>
              <div className='image'>
                <img alt={this.props.player.name} src={this.getImage(this.props.player)} />
              </div>
            </div>
            <div className='position-list-player-info-text'>
              <p className='position-list-player-name'>{this.props.player.name}</p>
              <p className='position-list-player-data'>
                {this.getJersey(this.props.player)} |{this.props.player.year} |
                {this.getPosition(this.props.player)}
              </p>
            </div>
          </div>

          <div className='position-list-button-container'>
            {this.renderViewDetailsButton(this.props.player.id)}
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerRowComponent;
