import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Services
 */
import AuthenticationService from '../../../../../services/authentication';

import './styles/_index.scss';

class PlayerPageModalHeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.props.page,
    };
  }

  goBack() {
    if (this.state.page === 'none') {
      this.props.toggleModal();

      return;
    }
    this.props.history.push(`/${this.state.page}`);
  }

  selectTab(url) {
    if (this.state.page === 'none') {
      this.props.setTab(url);
      return;
    }

    if (url === '') {
      return this.props.history.push(`/${this.state.page}/${this.props.player.id}/`);
    }

    this.props.history.push(`/${this.state.page}/${this.props.player.id}/${url}/`);
  }

  isActive(tabName) {
    if (
      (typeof this.props.activeTab === 'undefined' && tabName === 'overview') ||
      this.props.activeTab === tabName
    ) {
      return 'active';
    }
    return '';
  }

  render() {
    return (
      <div className='modal-header'>
        <h1>{this.props.player.name}</h1>
        <div className='player-page-nav'>
          <button
            onClick={() => {
              this.selectTab('');
            }}
            tabIndex={0}
            className={this.isActive('overview')}
            type='button'
            aria-label={`${this.props.player.name} Profile Overview`}
            role='link'>
            <span>Overview</span>
          </button>
          <button
            onClick={() => {
              this.selectTab('contact');
            }}
            tabIndex={0}
            type='button'
            className={this.isActive('contact')}
            role='link'>
            <span>Contact Info</span>
          </button>
          {AuthenticationService.isRivalsOnly() === false && (
            <button
              onClick={() => {
                this.selectTab('twitter');
              }}
              type='button'
              tabIndex={0}
              className={this.isActive('twitter')}
              role='link'>
              <span>Twitter Insights</span>
            </button>
          )}
          <button
            onClick={() => {
              this.selectTab('academics');
            }}
            type='button'
            tabIndex={0}
            className={this.isActive('academics')}
            role='link'>
            <span>Academics</span>
          </button>
          <button
            onClick={() => {
              this.selectTab('videos');
            }}
            type='button'
            tabIndex={0}
            className={this.isActive('videos')}
            role='link'>
            <span>Film</span>
          </button>
          <button
            onClick={() => {
              this.selectTab('stats');
            }}
            type='button'
            tabIndex={0}
            className={this.isActive('stats')}
            role='link'>
            <span>Stats</span>
          </button>
        </div>
        <div
          className='closer'
          onClick={this.goBack.bind(this)}
          onKeyUp={this.goBack.bind(this)}
          role='button'
          aria-label='Close player modal'
          tabIndex={-1}>
          <i className='icon x fas fa-times' />
        </div>
      </div>
    );
  }
}

export default withRouter(PlayerPageModalHeaderComponent);
