import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import CommonComponent from 'CommonComponents/generic';

class TypeaheadSearchResultsComponent extends CommonComponent {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  generateRows() {
    const results = [];

    this.props.data.forEach((single, index) => {
      results.push(this.generateRow(single, index));
    });

    return <div className='results'>{results}</div>;
  }

  generateRow(item, index) {
    if (item.error === true) {
      return (
        <MessagingComponent type={MESSAGE_TYPE.danger} id='missing_results' message={item.label} />
      );
    }

    const classes = classNames({
      result: true,
      selected: item.checked,
    });

    return (
      <div
        className={classes}
        key={item.id || index}
        onClick={() => {
          this.props.selectItem(item);
        }}
        onKeyUp={() => {
          this.props.selectItem(item);
        }}
        role='button'
        tabIndex={-1}>
        <p className='name'>
          {item.checked === true && <i className='icon checkmark fas fa-check' />}
          <span>{item.label}</span>
        </p>
      </div>
    );
  }

  render() {
    const {
      isIdle,
      isLoading,
      isCompleted,
      isErrored,
      searchValue,
      errorMessage,
      searchingMessage,
    } = this.props;

    if (isIdle) {
      return null;
    }

    if (isErrored && searchValue) {
      return (
        <div className='search-results'>
          <div className='results'>
            <MessagingComponent
              id='no_search_results'
              message={errorMessage}
              type={MESSAGE_TYPE.danger}
            />
          </div>
        </div>
      );
    }

    return (
      <div className='search-results'>
        {isCompleted && this.generateRows()}
        {isLoading && (
          <MessagingComponent
            id='looking_for_search_results'
            message={searchingMessage}
            type={MESSAGE_TYPE.warning}
          />
        )}
      </div>
    );
  }
}

TypeaheadSearchResultsComponent.defaultProps = {
  errorMessage: 'No results were found',
  searchingMessage: 'Searching...',
  data: [],
  isLoading: false,
  isCompleted: false,
  isIdle: false,
  isErrored: false,
};

TypeaheadSearchResultsComponent.propTypes = {
  searchValue: PropTypes.string,
  selectItem: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  searchingMessage: PropTypes.string,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isIdle: PropTypes.bool,
  isErrored: PropTypes.bool,
};

export default TypeaheadSearchResultsComponent;
