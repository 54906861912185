import axios from 'axios';

class RatingsService {
  getRatingsForPlayer(id, params = {}) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/ratings/`,
      params,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  getRatingsForPlayerAsPromise(id, format) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.API_URL}players/${id}/ratings/?format=${format}`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
      }).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPlayerLevel(rating) {
    /**
     * 90-100 = Elite P5 / National (Ntl) or (Elite)
     80-90 = P5
     70-80 = P5/G5
     60-70 = G5
     50-60 = G5/FCS
     40-50 = FCS
     30-40 = FCS/D2
     20-30 = D2
     10-20 = D2/D3
     0-10 = D3
     All
     Unrated
     */

    if (rating >= 90) {
      return 'Elite';
    }

    if (rating < 90 && rating >= 80) {
      return 'P5';
    }

    if (rating < 80 && rating >= 70) {
      return 'P5/G5';
    }

    if (rating < 70 && rating >= 60) {
      return 'G5';
    }
    if (rating < 60 && rating >= 50) {
      return 'G5/FCS';
    }

    if (rating < 50 && rating >= 40) {
      return 'FCS';
    }

    if (rating < 40 && rating >= 30) {
      return 'FCS/D2';
    }

    if (rating < 30 && rating >= 20) {
      return 'D2';
    }

    if (rating < 20 && rating >= 10) {
      return 'D2/D3';
    }

    return 'D3';
  }
}

export default new RatingsService();
