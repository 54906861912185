import React from 'react';

import AdvancedFilterComponent from 'FilterComponents/common/advanced';
import LocationFilterComponent from 'FilterComponents/common/location';
import SchoolFilterComponent from 'FilterComponents/common/school';
import TextFilterComponent from 'FilterComponents/common/text';

import AcademicsFilterComponent from 'FilterComponents/specific/academics';
import EventsFilterComponent from 'FilterComponents/specific/events';
import HighSchoolFilterComponent from 'FilterComponents/specific/high_school';
import LocationRadiusFilterComponent from 'FilterComponents/specific/location_radius';
import MetricsFilterComponent from 'FilterComponents/specific/metrics';
import OffersFilterComponent from 'FilterComponents/specific/offers';
import PositionsFilterComponent from 'FilterComponents/specific/positions';
import SchoolTypeFilterComponent from 'FilterComponents/specific/schoolType';
import RatingsFilterComponent from 'FilterComponents/specific/ratings';
import SchoolWithNullableFilterComponent from 'FilterComponents/specific/schoolWithNullable';
import SourcesFilterComponent from 'FilterComponents/specific/sources';
import YearsFilterComponent from 'FilterComponents/specific/years';
import ConnectionsFilter from 'FilterComponents/specific/connections';

function SidebarDefaultFiltersGroup({ closeFilter, filter }) {
  return (
    <>
      <TextFilterComponent
        isOpen={true}
        id='name'
        label='Search'
        placeholder="Prospect's Name"
        type='name'
        value={filter.name}
      />
      <YearsFilterComponent isOpen={true} values={filter.years} closeFilter={closeFilter} />
      <RatingsFilterComponent id='rating' closeFilter={closeFilter} values={filter.rating} />
      <AdvancedFilterComponent
        id='advanced_filter_locations'
        closeFilter={closeFilter}
        values={filter}
        label='Locations'>
        <LocationFilterComponent
          id='location'
          closeFilter={closeFilter}
          values={filter.location}
          label='Location Filter'
        />
        <LocationRadiusFilterComponent
          closeFilter={closeFilter}
          id='location_radius'
          label='Location Radius'
          values={filter.location_radius}
          collapsible={false}
          classes='transparent-background'
        />
        <HighSchoolFilterComponent
          id='high_schools'
          closeFilter={closeFilter}
          values={filter.high_schools}
        />
        <SchoolFilterComponent
          filter_action_type='FILTER_UPDATED'
          closeFilter={closeFilter}
          id='transfers'
          label='Colleges'
          collapsible={false}
          classes='transparent-background'
          values={filter.transfers}
        />
        <SchoolTypeFilterComponent
          values={filter.school_type}
          classes='transparent-background'
          collapsible={false}
        />
      </AdvancedFilterComponent>
      <PositionsFilterComponent closeFilter={closeFilter} values={filter.positions} />
      <AdvancedFilterComponent
        id='advanced_filters_recruiting_activity'
        closeFilter={closeFilter}
        values={filter}
        label='Recruiting Activity'>
        <OffersFilterComponent
          closeFilter={closeFilter}
          isOpen={true}
          defaultOpen={true}
          collapsible={true}
          id='offers'
          label='Offers'
          values={filter.offers}
        />
        <SchoolWithNullableFilterComponent
          collapsible={true}
          closeFilter={closeFilter}
          id='commits'
          label='Commits'
          withoutKey='uncommitted'
          values={
            filter.commits || {
              uncommitted: false,
              values: [],
            }
          }
        />
        <SchoolWithNullableFilterComponent
          collapsible={true}
          closeFilter={closeFilter}
          id='signings'
          label='Signees'
          withoutKey='unsigned'
          values={
            filter.signings || {
              unsigned: false,
              values: [],
            }
          }
        />
        <SchoolFilterComponent
          closeFilter={closeFilter}
          id='visits'
          label='Visits'
          values={filter.visits}
        />
      </AdvancedFilterComponent>
      <MetricsFilterComponent
        values={filter}
        closeFilter={closeFilter}
        id='measurables'
        label='Ht/Wt/Measurables'
        configType='measurables'
        dropdownLabel='Add Measurable'
      />
      <MetricsFilterComponent
        values={filter}
        closeFilter={closeFilter}
        id='track_results'
        label='Track &amp; Field Results'
        configType='track_and_field'
        dropdownLabel='Add Event'
      />
      <MetricsFilterComponent
        values={filter}
        closeFilter={closeFilter}
        id='combine'
        label='Combine Results'
        configType='combine'
        dropdownLabel='Add Event'
      />
      <AcademicsFilterComponent
        collapsible={true}
        values={filter}
        closeFilter={closeFilter}
        id='academics'
      />
      <EventsFilterComponent
        closeFilter={closeFilter}
        id='events'
        label='Rivals Camp Series'
        values={filter.events}
        collapsible={true}
      />
      <ConnectionsFilter id='connections' values={filter.connections} closeFilter={closeFilter} />
      <SourcesFilterComponent
        closeFilter={closeFilter}
        id='sources'
        label='Sources'
        values={filter.sources}
      />
    </>
  );
}

export default SidebarDefaultFiltersGroup;
