import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';

class MultiSelectFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      (typeof this.props.value !== 'undefined' &&
        this.props.value !== null &&
        this.props.value !== '') ||
      this.props.isOpen === true
    ) {
      isOpen = true;
    }

    this.state = {
      isOpen,
      toggleClicked: false,
      values: this.props.values,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let values = nextState.values;
    let isOpen = false;
    let allSelected = false;

    let numSelected = 0;
    if (Array.isArray(values) && values.length > 0) {
      if (Array.isArray(nextProps.selected) && nextProps.selected.length > 0) {
        values = values.map((value) => {
          value.selected = nextProps.selected.indexOf(value.id) > -1;
          if (value.selected === true) {
            numSelected++;
          }
          return value;
        });
      } else {
        values[0].selected = true;
      }
      if (numSelected >= values.length - 1 || numSelected === 0) {
        allSelected = true;
        values = values.map((value) => {
          value.selected = value.id === 'all';
          return value;
        });
      }
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = Array.isArray(nextProps.selected) && nextProps.selected.length > 0 && !allSelected;
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      isOpen,
      values,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  selectValue(button) {
    const values = this.props.values;
    const selected = [];

    if (button.id === 'all') {
      values.forEach((value) => {
        value.selected = value.id === 'all';
      });
    } else {
      values[0].selected = false;

      /**
       * Toggle current selection
       */
      values.forEach((value) => {
        if (value.id !== 'all' && value.id === button.id) {
          value.selected = !value.selected;
        }
      });

      values.forEach((single) => {
        if (single.id !== 'all' && single.selected === true) {
          selected.push(single.id);
        }
      });
    }

    this.props.onChange(selected);
  }

  generateButtons() {
    return this.state.values.map((button) => {
      const state = button.selected === true ? 'primary' : 'muted';

      const contents = (
        <Button
          key={`${this.props.id}-${button.id}`}
          color={state}
          onClick={this.selectValue.bind(this, button)}
          role='checkbox'
          aria-checked={button.selected}>
          {button.name}
        </Button>
      );

      return contents;
    });
  }

  render() {
    const { id, label, collapsible, classes, collapseClass } = this.props;
    const { isOpen } = this.state;

    return (
      <FilterComponent
        id={id}
        classes={classes}
        collapseClass={collapseClass}
        isOpen={isOpen}
        collapsible={collapsible}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {this.generateButtons()}
      </FilterComponent>
    );
  }
}

MultiSelectFilterComponent.defaultProps = {
  isOpen: false,
  collapsible: true,
  closeFilter: false,
  label: 'Label',
  classes: '',
  collapseClass: '',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

MultiSelectFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  closeFilter: PropTypes.bool,
  collapsible: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  collapseClass: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default MultiSelectFilterComponent;
