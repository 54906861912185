import React, { Component } from 'react';

import classNames from 'classnames';

import './styles/_index.scss';

class SchoolIconComponent extends Component {
  addDefaultSrc(ev) {
    if (ev.target.src !== 'https://s3.amazonaws.com/zcruit-assets/school_logos/Zcruitest.png') {
      ev.target.src = 'https://s3.amazonaws.com/zcruit-assets/school_logos/Zcruitest.png';
    }
  }

  getHoverText() {
    if (this.props.highlight === true) {
      return `Offer from ${this.props.school}`;
    }
    return this.props.school;
  }

  render() {
    let school = this.props.school;
    if (school.includes('Demo')) {
      school = 'Zcruitest';
    }

    switch (school) {
      case 'Abilene Christian University':
        school = 'Abilene Christian';

        break;
      case 'Brigham-Young':
        school = 'BYU';

        break;
      case 'Brigham Young':
        school = 'BYU';

        break;
      case 'Middle Tennessee State':
        school = 'Middle Tennessee';

        break;
      case 'Texas A&M':
        school = 'Texas A&M';

        break;
      case 'USF':
        school = 'South Florida';

        break;
      case 'UT-San Antonio':
        school = 'Texas San Antonio';

        break;
      case 'ZcruitLiteTest':
        school = 'Zcruitest';

        break;
      case 'Appalachian St':
        school = 'Appalachian State';

        break;
      default:
        break;
    }

    let classes = 'school-image';

    if (this.props.highlight === true) {
      classes = classNames({
        highlighted: true,
        'school-image': true,
      });
    }

    if (this.props.imageOnly === true) {
      classes = classNames({
        'image-only': true,
        'school-image': true,
      });
    }

    if (typeof this.props.size !== 'undefined' && this.props.size !== '') {
      switch (this.props.size) {
        case 'small':
          classes = `${classes} tiny-logo`;

          break;
        default:
          break;
      }
    }

    return (
      <div className={classes} id={this.props.id}>
        <img
          src={`https://s3.amazonaws.com/zcruit-assets/school_logos/${school.replace(
            /\s/g,
            '_'
          )}.png`}
          alt={`${this.getHoverText()} Logo`}
          title={this.getHoverText()}
          onError={this.addDefaultSrc}
        />
        {this.props.addCheck === true && (
          <div className='check-holder'>
            <i
              role='img'
              aria-label='Verified School'
              className='icon verified fas fa-check-circle'
            />
          </div>
        )}
      </div>
    );
  }
}

export default SchoolIconComponent;
