import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

/**
 * Components
 */
import DropdownCheckboxFilterComponent from '../../../common/dropdownCheckbox';
import NameFilterComponent from '../filters/name';

/**
 * Assets
 */
import ncsaLogo from 'Assets/images/ncsa-full.png';

/**
 * Styles
 */
import './styles/_index.scss';

class RivalsCampHeader extends Component {
  renderFilter(type) {
    let filterElement;
    switch (type) {
      case 'positions':
        filterElement = (
          <DropdownCheckboxFilterComponent
            addAll={true}
            columns={2}
            filterChanged={this.props.filterChanged.bind(this, 'positions')}
            label='Position'
            values={this.props.positions}
            key='positions-filter'
          />
        );
        break;
      case 'name':
        filterElement = (
          <NameFilterComponent
            filterChanged={this.props.filterChanged.bind(this, 'name')}
            key='name-filter'
            name={this.props.name}
          />
        );
        break;
      default:
        filterElement = null;
        break;
    }
    return filterElement;
  }

  render() {
    return (
      <Col md='12' className='page-header-container'>
        <Row className='page-header padding-left-header'>
          <Col md={{ size: 10, offset: 2 }}>
            <h1 className='page-header-text'>Rivals Film</h1>
            <img src={ncsaLogo} className='ncsa-logo' alt='NCSA logo' />
          </Col>
        </Row>
        <Row className='utility-bar'>
          <Col md={{ size: 8, offset: 2 }}>
            <div className='nav-tab-links-container rivals-camp-nav-filters'>
              {this.props.filterTypes.map((filterType) => {
                return this.renderFilter(filterType);
              })}
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default RivalsCampHeader;
