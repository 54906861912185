import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { nanoid } from 'nanoid';
import { debounce } from 'underscore';

import CommonComponent from 'CommonComponents/generic';
import Checkbox from 'RootComponents/common/checkbox';
import './styles/_index.scss';

class NestedDropdownFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.appRoot = document.getElementById('app');
    this.updateStateSearchValue = debounce(this.updateStateSearchValue, 350);
    this.searchInputRef = React.createRef();

    this.state = {
      uuid: nanoid(),
      values: this.props.data,
      childOpen: null,
      userManualClose: false,
      dropdownOpen: this.props.isOpen,
      searchValue: this.props.searchValue,
      isChild: Boolean(this.props.parent),
      uniqueClass: this.props.uniqueClass || `nested-dropdown-${nanoid()}`,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const values = nextProps.data;
    let dropdownOpen = nextState.dropdownOpen;
    let searchValue = nextState.searchValue;
    let childOpen = nextState.childOpen;
    let userManualClose = nextState.userManualClose;

    if (!nextProps.root) {
      dropdownOpen = nextProps.isOpen;
      searchValue = nextProps.searchValue;
      if (nextState.searchValue !== searchValue) {
        userManualClose = false;
      }
      if (dropdownOpen === false) {
        childOpen = null;
      }
    }

    return {
      values,
      dropdownOpen,
      searchValue,
      childOpen,
      userManualClose,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  componentDidMount() {
    if (this.state.searchValue.length > 2) {
      this.setState({
        userManualClose: false,
      });
    }
  }

  clearSearchValue() {
    this.setState(
      {
        searchValue: '',
        userManualClose: true,
      },
      () => {
        this.setOpenChild(null);
        this.searchInputRef.current.focus();
        this.searchInputRef.current.value = '';
      }
    );
  }

  toggleDropdown(e) {
    if (e.target.className.includes('no-toggle') || e.target.className.includes('custom-control')) {
      return;
    }

    if (
      !e.target.className.includes(this.state.uniqueClass) &&
      !e.target.id.includes(`${this.state.uuid}`) &&
      (this.state.dropdownOpen !== false || this.state.childOpen !== null)
    ) {
      this.setState({
        dropdownOpen: false,
        childOpen: null,
        userManualClose: true,
      });
      return;
    }

    if (
      !e.target.className.includes(`${this.state.uuid}-${this.props.label}`) &&
      !e.target.className.includes('search')
    ) {
      return;
    }

    if (
      e.target.className.includes(this.state.uniqueClass) &&
      this.state.dropdownOpen === true &&
      e.target.className.includes('search')
    ) {
      return;
    }

    const updatedOpen = !this.state.dropdownOpen;

    if (this.props.root) {
      this.setState({
        dropdownOpen: updatedOpen,
        childOpen: updatedOpen ? this.state.childOpen : null,
      });
    } else {
      this.props.toggleOpenFromParent(this.props.parent);
    }
  }

  setCheckedOfValue(clickedValue) {
    const newValues = JSON.parse(JSON.stringify(this.state.values));
    const newValuesState = newValues.map((value) => {
      if (clickedValue.parent) {
        value.checked = clickedValue.checked !== true;
        if (value.checked === true && this.state.searchValue.length > 2) {
          this.props.clearSearchValue();
        }

        this.setCheckOfChild(value, value.checked);
        return value;
      }

      if (value.id === clickedValue.id) {
        value.checked = value.checked !== true;
      }

      if (value.id === 'all' && clickedValue.checked !== true && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (clickedValue.exclusive && clickedValue.checked && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (value.exclusive && value.checked && clickedValue.id !== value.id) {
        value.checked = false;
      }

      if (value.checked === true && this.state.searchValue.length > 2) {
        this.props.clearSearchValue();
      }
      return value;
    });
    this.setState(
      {
        values: newValuesState,
      },
      () => {
        if (this.state.isChild) {
          this.props.onChange(clickedValue, newValuesState, this.props.parent);
        } else {
          this.props.onChange(clickedValue, newValuesState);
        }
      }
    );
  }

  getValuesOfChildren(parentValue) {
    const parentObj = this.props.values.find((value) => {
      return value.id === parentValue;
    });
    if (typeof parentObj !== 'undefined') {
      return parentObj.children.map((child) => {
        return child.id;
      });
    }
    return [];
  }

  setCheckOfChild(child, checked) {
    if (typeof child.children !== 'undefined') {
      child.children = child.children.map((grandChild) => {
        return this.setCheckOfChild(grandChild, checked);
      });
    }
    child.checked = checked;

    return child;
  }

  subMenuChanged(clicked, selected, parent) {
    const curValues = JSON.parse(JSON.stringify(this.state.values));
    const updatedValues = curValues.map((value) => {
      if (value.id === parent) {
        value.children = selected;
      }

      if (value.children) {
        const match = value.children.find((child) => {
          return child.id === parent;
        });
        if (match) {
          value.children = selected;
        }
      }

      const selectedChildren = value.children.filter((child) => {
        return child.checked === true;
      });

      value.checked = selectedChildren.length === value.children.length;

      return value;
    });

    this.props.onChange(clicked, updatedValues, parent);
  }

  recursiveChildLabelMatch(option, searchValue) {
    if (
      option.label.toLowerCase().includes(searchValue) ||
      (typeof option.searchLabel !== 'undefined' &&
        option.searchLabel.toLowerCase().includes(searchValue))
    ) {
      return true;
    }
    if (typeof option.children !== 'undefined') {
      return option.children.some((child) => {
        return this.recursiveChildLabelMatch(child, searchValue);
      });
    }
    return false;
  }

  renderDropdownItems(values) {
    let firstOpened = this.state.childOpen !== null;
    const renderedInputs = values.map((value) => {
      const isOpen = value.id === this.state.childOpen;
      let classes = `no-toggle no-root ${this.state.uniqueClass}`;
      let searchMatched = false;
      if (
        this.state.searchValue &&
        this.state.searchValue.length > 2 &&
        !value.label.toLowerCase().includes(this.state.searchValue.toLowerCase()) &&
        this.props.parentSearchMatched !== true
      ) {
        const childMatched = this.recursiveChildLabelMatch(
          value,
          this.state.searchValue.toLowerCase()
        );
        if (childMatched === false) {
          return null;
        }
      } else if (
        this.state.searchValue &&
        this.state.searchValue.length > 2 &&
        value.label.toLowerCase().includes(this.state.searchValue.toLowerCase())
      ) {
        searchMatched = true;
      }

      if (
        this.state.searchValue.length > 2 &&
        this.state.childOpen === null &&
        firstOpened === false &&
        this.state.userManualClose === false
      ) {
        this.setState({
          childOpen: value.id,
          userManualClose: false,
        });
        firstOpened = true;
      }

      if (isOpen === true) {
        classes += ' highlight';
      }

      if (typeof value.children === 'undefined' || value.children.length === 0) {
        classes += ' last-child';
      }

      return (
        <DropdownItem className={classes} key={`${this.state.uuid}-${value.id}`}>
          {this.renderCustomInput(value, isOpen, searchMatched)}
        </DropdownItem>
      );
    });
    return renderedInputs;
  }

  handleSearchChange(e) {
    this.updateStateSearchValue(e.target.value);
  }

  updateStateSearchValue(value) {
    this.setState({
      searchValue: value,
      childOpen: null,
      userManualClose: false,
    });
  }

  setOpenChild(id) {
    let value = id;
    if (this.state.childOpen === value) {
      value = null;
    }
    this.setState({
      childOpen: value,
      userManualClose: true,
    });
  }

  inputClicked(e) {
    console.log('Input was clicked', e.target);
  }

  renderCustomInput(value, isOpen, searchMatched) {
    if (typeof value.children !== 'undefined' && value.children.length > 0) {
      let clearSearchValue = this.props.clearSearchValue;

      if (typeof this.props.clearSearchValue === 'undefined') {
        clearSearchValue = this.clearSearchValue.bind(this);
      }

      return (
        <NestedDropdownFilterComponent
          columns={1}
          onChange={this.subMenuChanged.bind(this)}
          checked={value.checked}
          label={value.label}
          data={value.children}
          parent={value.id}
          root={false}
          searchValue={this.state.searchValue}
          isOpen={isOpen}
          parentSearchMatched={searchMatched}
          toggleOpenFromParent={this.setOpenChild.bind(this)}
          uniqueClass={this.state.uniqueClass}
          clearSearchValue={clearSearchValue}
          childOpen={this.state.childOpen}
          depth={this.props.depth + 1}
          locParent={value.locParent}
          inputId={value.label}
        />
      );
    }

    return this.createCustomInput(value, value);
  }

  createCustomInput(value) {
    return (
      <Checkbox
        id={value.label}
        label={value.label}
        checked={value.checked}
        tabIndex={-1}
        className={`custom-control-checkbox-input ${this.state.uniqueClass}`}
        onChange={this.setCheckedOfValue.bind(this, value)}
        parentProps={{
          className: `dropdown-checkbox-input ${this.state.uniqueClass}`,
        }}
        labelProps={{
          className: `custom-control-checkbox-label ${this.state.uniqueClass}`,
        }}
      />
    );
  }

  scrollHandler(e) {
    const correctDepth = e.target.className.includes(`depth-${this.props.depth}`);
    if (this.state.childOpen !== null && correctDepth) {
      this.setState({
        childOpen: null,
        userManualClose: true,
      });
    }
  }

  renderRootToggle() {
    const { rootToggle, placeholder, inputId } = this.props;
    const { uniqueClass } = this.state;

    const id = inputId?.toLowerCase().replace(/([^a-z])/g, '_');
    const elementId = `active-${id}`;
    const elementClassName = `search ${uniqueClass}`;
    if (rootToggle === 'button') {
      return (
        <Dropdown toggle={this.toggleDropdown.bind(this)}>
          <DropdownToggle id={elementId} className={elementClassName}>
            {placeholder}
          </DropdownToggle>
        </Dropdown>
      );
    }

    return (
      <input
        type='text'
        autoComplete='off'
        id={elementId}
        className={elementClassName}
        placeholder={placeholder}
        ref={this.searchInputRef}
        onClick={this.toggleDropdown.bind(this)}
        onChange={this.handleSearchChange.bind(this)}
        onFocus={this.toggleDropdown.bind(this)}
      />
    );
  }

  render() {
    const direction = this.props.root ? 'down' : 'right';
    const toggleClass = this.props.root ? 'hidden' : '';
    return (
      <div className={`parent-dropdown-container ${this.state.uniqueClass}`}>
        {this.props.root && this.renderRootToggle()}
        <Dropdown
          direction={direction}
          className={`no-toggle parent-dropdown ${this.state.uuid}-${this.props.label} ${this.state.uniqueClass}`}
          isOpen={this.state.dropdownOpen}
          toggle={this.toggleDropdown.bind(this)}>
          <DropdownToggle
            className={`parent-toggle ${this.state.uuid}-${this.props.label} ${toggleClass} ${this.state.uniqueClass}`}
            disabled={this.props.root}
            tag='div'>
            {this.createCustomInput(this.props)}
            <i className={`icon caret-right fal fa-chevron-right ${this.state.uniqueClass}`} />
          </DropdownToggle>
          {/* Wrap portal in a fragment */}
          {/* prevents proptype warning since react can not infer that a portal is a React Node */}
          <React.Fragment>
            {/* Portal attaches component to any node (App Root in this case) */}
            {/* Allows dropdown to appear outside of what would be the parent element */}
            {this.state.dropdownOpen &&
              ReactDOM.createPortal(
                <DropdownMenu
                  className={`dropdown-checkbox-menu nested-dropdown no-toggle filter-dropdown ${this.state.uniqueClass}`}
                  modifiers={{
                    offset: { offset: '-8%', enabled: true },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'viewport',
                    },
                    hide: { enabled: true },
                  }}
                  onScroll={this.scrollHandler.bind(this)}>
                  <div
                    className={`dropdown-inner-container ${this.state.uniqueClass} depth-${this.props.depth}`}>
                    {this.renderDropdownItems(this.props.data, this.props.columns)}
                  </div>
                </DropdownMenu>,
                this.appRoot
              )}
          </React.Fragment>
        </Dropdown>
      </div>
    );
  }
}

NestedDropdownFilterComponent.defaultProps = {
  isOpen: false,
  parent: false,
  searchValue: '',
  uniqueClass: '',
  rootToggle: 'input',
};

NestedDropdownFilterComponent.propTypes = {
  values: PropTypes.array,
  isOpen: PropTypes.bool,
  searchValue: PropTypes.string,
  uniqueClass: PropTypes.string,
  parent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  rootToggle: PropTypes.oneOf(['input', 'button']),
};

export default NestedDropdownFilterComponent;
