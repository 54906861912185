class MinMaxRequest {
  constructor() {
    this.allowNull = true;
    this.min = -1;
    this.max = -1;
    this.verified = false;
  }

  add(key, value) {
    if (typeof this[key] !== 'undefined') {
      if (value === null || value === '' || value.length === 0) {
        delete this[key];
      } else {
        this[key] = value;
      }
    } else {
      this[key] = value;
    }
  }
}

export default MinMaxRequest;
