import React, { Component } from 'react';
import { Col, Button } from 'reactstrap';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Services
 */
import FilterService from 'Services/filters';

/**
 * Components
 */
import SaveFilterModal from 'CommonComponents/saveFiltersModal';
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';

/**
 * Styles
 */
import 'CommonComponents/filters/filterContainer/styles/_index.scss';

class FiltersContainerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelButtonLabel: this.props.cancelButtonLabel || 'Clear Filters',
      errorThrown: false,
      errorMessage: '',
      saveButtonLabel: this.props.saveButtonLabel || 'Save Filters',
      saveModalOpen: false,
      type: this.props.type || 2,
    };
  }

  throwError(message) {
    this.setState({
      errorThrown: true,
      errorMessage: message,
    });
  }

  saveHandler(name) {
    if (this.props.location !== 'manageLists') {
      this.toggleSaveModal();
    }

    const filterObj = {};

    filterObj.filter = Object.assign({}, this.props.filter);
    filterObj.name = name;

    filterObj.filter.name = '';

    if (typeof filterObj.name === 'undefined' || filterObj.name.length === 0) {
      this.throwError('Filter must have a name');

      return;
    }

    if (this.props.filterType) {
      filterObj.type = this.props.filterType;
    }

    if (this.props.apiRoute === 'edit') {
      FilterService.editFilter(this.props.filter, name, this.props.filterId)
        .then((response) => {
          if (response.status === 200 && typeof this.props.addFilterToState !== 'undefined') {
            this.props.addFilterToState(filterObj);
          }
        })
        .catch((err) => {
          this.throwError(err.response.data.error.message);
        });

      return;
    }

    FilterService.saveFilter(filterObj, this.state.type)
      .then((response) => {
        if (response.status === 200 && typeof this.props.addFilterToState !== 'undefined') {
          this.props.addFilterToState(response.data.data);
        }
      })
      .catch((err) => {
        console.error(err);

        this.throwError(err.response.data.error.message);
      });

    if (this.props.location === 'manageLists') {
      this.props.resetFiltersToDefault();
    }
  }

  toggleSaveModal() {
    if (this.props.location === 'manageLists') {
      this.saveHandler(this.props.listName);
    } else {
      this.setState({
        saveModalOpen: !this.state.saveModalOpen,
      });
    }
  }

  clearFilters() {
    store.dispatch({
      type: 'CLEAR_FILTERS',
    });
  }

  render() {
    return (
      <aside className='filters sticky-top'>
        {this.props.children}
        <Col md={2} className='button-filter-col'>
          <div className='button-filters'>
            <Button type='submit' className='save-button' onClick={this.toggleSaveModal.bind(this)}>
              {this.state.saveButtonLabel}
            </Button>
            <a href='#none' className='link-white' onClick={this.clearFilters.bind(this)}>
              {this.state.cancelButtonLabel}
            </a>
          </div>
        </Col>

        <SaveFilterModal
          modalOpen={this.state.saveModalOpen}
          toggle={this.toggleSaveModal.bind(this)}
          saveHandler={this.saveHandler.bind(this)}
        />
        <MessagingComponent
          thrown={this.state.errorThrown}
          message={this.state.errorMessage}
          type={MESSAGE_TYPE.danger}
        />
      </aside>
    );
  }
}

export default FiltersContainerComponent;
