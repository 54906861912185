import { Cookies } from 'react-cookie';
import { Amplify, Auth } from 'aws-amplify';

import axios from 'axios';
import { AUTH_CONFIG } from '../constants/amplify.config';
import schoolDefaults from '../constants/schoolDefaults';
import store from '../reducers/store';
import { isProduction } from '../constants/environment';
import ReactHeap from 'reactjs-heap';

/**
 * Services
 */
import UsersService from './users';
import DateTimeService, { DATE_DEFAULTS } from './date-time';

/**
 * Service to auth and verify authentication of a user
 */
class AuthenticationService {
  constructor() {
    this.authToken = null;
    this.loggedIn = false;
    this._dateTimeService = new DateTimeService();

    this.configureAmplify();
  }

  async isLoggedIn() {
    let loggedIn = true;

    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      console.log('failedin in is logging');
      console.log(e);

      await this.logout();

      throw e;
    }

    const cookies = new Cookies();
    const org = cookies.get('organization');
    /**
     * Ensures we reset the auth_token fully, as it will be auto-refreshed after an hour
     */

    window.localStorage.setItem('access_token', JSON.stringify(user.signInUserSession.accessToken));
    window.localStorage.setItem(
      'refresh_token',
      JSON.stringify(user.signInUserSession.refreshToken)
    );
    window.localStorage.setItem('username', user.signInUserSession.accessToken.payload.username);

    /**
     * Always get the user to ensure we got the right one. If there is no cookie, assume logged out
     */
    if (typeof org === 'undefined' || org === null || org === '') {
      loggedIn = false;

      await this.logout();
    } else {
      window.localStorage.setItem('organization_id', org.id);
      window.localStorage.setItem('organization', JSON.stringify(org));

      user = await UsersService.getUser(user.signInUserSession.accessToken.payload.sub);

      this.resetOrgThroughHeaders(JSON.stringify(user.organization));

      window.localStorage.setItem('user_id', user.id);
      window.localStorage.setItem('superUser', user.super_user === 1);
    }

    this.loggedIn = loggedIn;

    if (isProduction()) {
      const userId = this.getUserId();
      this.setUserGuidingIdentifier(userId);

      if (this.loggedIn) {
        try {
          ReactHeap.initialize(process.env.HEAP_TRACKING_ID);
          this.setHeapUserIdentifier(userId);
          this.setHeapUserProperties(user);
          await this.setHeapAccountProperties(user.organization);
        } catch (err) {
          console.log('Error sending request');
        }
      }
    }

    return this.loggedIn;
  }

  async setUserGuidingIdentifier(userId) {
    window.userGuiding.identify(userId);
  }

  async setHeapUserIdentifier(userId) {
    const heap = window.heap;
    try {
      heap.identify(userId);
    } catch (err) {
      console.log('Error identifying user');
    }
  }

  setHeapUserProperties({ organization: orgData, username, super_user, created_at }) {
    const heap = window.heap;
    const { id: orgId, name: orgName } = orgData;
    const createdDateFormatted = this._dateTimeService.formatUTCDate(
      new Date(created_at),
      DATE_DEFAULTS.format.default
    );
    const accDefaultProp = {
      account_id: orgId,
    };
    const userProps = Object.assign(
      {
        Name: orgName,
        Email: username,
        'User Type': super_user === 1 ? 'Admin' : 'Collaborator',
        'User Created Date': createdDateFormatted,
      },
      accDefaultProp
    );

    heap.addUserProperties(userProps);
  }

  async setHeapAccountProperties({
    id: orgId,
    created_at: orgCreatedDate,
    college: collegeData,
    package: packageData,
  }) {
    const { name: pkgName } = packageData;
    const {
      name: collName,
      city: cityData,
      state: stateData,
      conference: conferenceData,
    } = collegeData;
    const { name: collCity } = cityData;
    const { name: collState } = stateData;
    const orgCreatedDateFormatted = this._dateTimeService.formatUTCDate(
      new Date(orgCreatedDate),
      DATE_DEFAULTS.format.default
    );
    const accProps = {
      app_id: process.env.HEAP_TRACKING_ID,
      account_id: orgId,
      properties: {
        Subscription: pkgName,
        'University Name': collName,
        'University City': collCity,
        'University State': collState,
        'Organization Created Date': orgCreatedDateFormatted,
      },
    };

    if (conferenceData) {
      const {
        name: confName,
        division: { name: divisionName },
      } = conferenceData;

      accProps.properties = Object.assign(
        {
          Division: divisionName,
          Conference: confName,
        },
        accProps.properties
      );
    }

    const API_OPTS = {
      method: 'POST',
      url: `${process.env.API_URL}analytics/heap-aap`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      data: accProps,
    };

    await axios.request(API_OPTS).catch((error) => {
      return error;
    });
  }

  async isSuperUser() {
    const userInfo = await UsersService.getLoggedInUserDBObject();
    return userInfo.super_user === 1;
  }

  async login(username, password) {
    const user = await Auth.signIn(username, password);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const error = {
        message: 'A new password is required',
        status: 403,
      };

      throw error;
    }

    window.localStorage.setItem('access_token', JSON.stringify(user.signInUserSession.accessToken));
    window.localStorage.setItem(
      'refresh_token',
      JSON.stringify(user.signInUserSession.refreshToken)
    );

    /**
     * Fetch the user and org
     */
    const userInfo = await UsersService.getUser(user.signInUserSession.accessToken.payload.sub);

    this.resetOrgThroughHeaders(JSON.stringify(userInfo.organization));

    window.localStorage.setItem('username', userInfo.username);
    window.localStorage.setItem('user_id', userInfo.id);
    window.localStorage.setItem('superUser', userInfo.super_user === 1);

    if (isProduction()) {
      this.setUserGuidingIdentifier(this.getUserId());

      try {
        await this.setHeapUserIdentifier(userInfo);
      } catch (err) {
        console.log(err);
      }
    }

    this.loggedIn = true;
  }

  async changePassword(password, newPassword) {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, password, newPassword);
    });
  }

  async startPasswordRecovery(username) {
    const reset = await Auth.forgotPassword(username);

    return reset;
  }

  async resetPasswordForNewUser(username, password, newPassword) {
    const userOld = await Auth.signIn(username, password);

    const user = await Auth.completeNewPassword(userOld, newPassword, []);

    window.localStorage.setItem('access_token', JSON.stringify(user.signInUserSession.accessToken));
    window.localStorage.setItem(
      'refresh_token',
      JSON.stringify(user.signInUserSession.refreshToken)
    );

    /**
     * Fetch the user and org
     */
    const userInfo = await UsersService.getUser(user.signInUserSession.accessToken.payload.sub);

    this.resetOrgThroughHeaders(JSON.stringify(userInfo.organization));

    window.localStorage.setItem('username', userInfo.username);

    this.loggedIn = true;
  }

  async resetPassword(username, code, password) {
    await Auth.forgotPasswordSubmit(username, code, password);

    await this.login(username, password);
  }

  async logout() {
    await Auth.signOut();

    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('idi');
    window.localStorage.removeItem('hasRivalsCampData');
    window.localStorage.removeItem('isRivalsOnly');
    window.localStorage.removeItem('isZmailOnly');
    window.localStorage.removeItem('isZcruitLite');
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('school');
    window.localStorage.removeItem('superUser');
    window.localStorage.removeItem('organization_id');
    window.localStorage.removeItem('organization');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('user_id');
    window.localStorage.removeItem('isUserOptedIn');
    window.localStorage.removeItem('is_demo');

    this.removeAuthCookies();

    return true;
  }

  getSchool() {
    return window.localStorage.getItem('school');
  }

  getOrganization() {
    return JSON.parse(window.localStorage.getItem('organization'));
  }

  getSchoolForYou() {
    return schoolDefaults[this.getSchool()]
      ? schoolDefaults[this.getSchool()].forYou
      : schoolDefaults.default.forYou;
  }

  getCustomVariableTypes() {
    const customVars = JSON.parse(window.localStorage.getItem('organization'));

    if (customVars === null) {
      console.log('no custom vars in org');

      this.logout();

      return null;
    }

    return customVars.customTypes;
  }

  hasRivalsData() {
    return window.localStorage.getItem('hasRivalsCampData') === 'true';
  }

  isRivalsOnly() {
    return window.localStorage.getItem('isRivalsOnly') === 'true';
  }

  isTrialOrg() {
    return window.localStorage.getItem('is_demo') === 'true';
  }

  isZcruitLite() {
    return window.localStorage.isZcruitLite === 'true';
  }

  redirectToZmail(newTab) {
    if (newTab) {
      window.open(`${process.env.ZCRUIT_ZMAIL_URL}redirect/`, '_blank');
    } else {
      window.location.assign(`${process.env.ZCRUIT_ZMAIL_URL}redirect/`);
    }
  }

  removeAuthCookies() {
    const cookies = new Cookies();

    cookies.remove('organization');

    const allCookieKeys = Object.keys(cookies.getAll());
    allCookieKeys.forEach((cooKey) => {
      if (cooKey.includes('CognitoIdentityServiceProvider')) {
        cookies.remove(cooKey);
      }
    });
  }

  resetOrgThroughHeaders(org) {
    const fullOrg = JSON.parse(org);

    window.localStorage.setItem(
      'hasRivalsCampData',
      fullOrg.package.has_rivals_camp === true ? 'true' : 'false'
    );
    window.localStorage.setItem(
      'isRivalsOnly',
      fullOrg.package.is_rivals_only === true ? 'true' : 'false'
    );
    window.localStorage.setItem('isZcruitLite', fullOrg.package.is_lite ? 'true' : 'false');
    window.localStorage.setItem('isZmailOnly', fullOrg.package.is_zmail);
    window.localStorage.setItem('organization_id', fullOrg.id);
    window.localStorage.setItem('organization', org);
    window.localStorage.setItem('school', fullOrg.name);
    window.localStorage.setItem('is_demo', fullOrg.is_demo);

    const cookies = new Cookies();

    const date = new Date();

    const temp = JSON.parse(window.localStorage.getItem('organization'));

    if (typeof temp.customTypes !== 'undefined') {
      delete temp.customTypes;
    }

    delete temp.package.packageSources;

    cookies.set('organization', JSON.stringify(temp), {
      domain: process.env.ZCRUIT_COOKIE_DOMAIN,
      expires: new Date(date.setSeconds(date.getSeconds() + 2628000)),
      path: '/',
    });

    store.dispatch({
      type: 'ORGANIZATION_CHANGED',
    });
  }

  getUserId() {
    return window.localStorage.getItem('user_id');
  }

  setUserOptedInLocalValue(isOptedInValue) {
    window.localStorage.setItem('isUserOptedIn', isOptedInValue);
  }

  async isUserOptedIn() {
    const localOptedInValue = window.localStorage.getItem('isUserOptedIn');
    if (localOptedInValue) {
      return localOptedInValue === 'true';
    }

    const userObj = await UsersService.getLoggedInUserDBObject();

    this.setUserOptedInLocalValue(userObj.isUserOptedIn);

    return userObj.isUserOptedIn;
  }

  async createUserOptIn() {
    const res = await UsersService.createUserOptIn();
    this.setUserOptedInLocalValue(res);
    return res;
  }

  configureAmplify() {
    Amplify.configure(AUTH_CONFIG);

    // You can get the current config object
    this._currentConfig = Auth.configure();
  }
}

export default new AuthenticationService();
