import React from 'react';
import * as _ from 'underscore';

/**
 * Parent
 */
import CommonComponent from '../generic';

/**
 * Styles
 */
import './styles/_index.scss';

class DropdownComponent extends CommonComponent {
  constructor(props) {
    super(props);
    this.dropdownButtonRef = React.createRef();
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleDropdownCloseOnTabOrEscape = this.handleDropdownCloseOnTabOrEscape.bind(this);

    const prefix = typeof this.props.labelPrefix !== 'undefined' ? this.props.labelPrefix : '';
    const selectedValueLabel = this.getSelectedValueForLabel(this.props.value, prefix);

    this.state = {
      dropdownOpen: false,
      labelPrefix: prefix,
      openUp: this.props.openUp || false,
      selectedValueLabel,
      values: this.props.values,
      valuesHtml: [],
    };
  }

  componentWillReceiveProps(incomingProps, incomingState) {
    if (
      (typeof incomingProps.value === 'undefined' ||
        incomingProps.value === null ||
        incomingProps.value === '') &&
      (typeof incomingProps.values === 'undefined' ||
        incomingProps.values === null ||
        incomingProps.values === '')
    ) {
      return;
    }

    let values = JSON.parse(JSON.stringify(this.state.values));
    if (_.isEqual(values, incomingProps) === false) {
      values = incomingProps.values;
    }

    this.setState({
      selectedValueLabel: this.getSelectedValueForLabel(
        incomingProps.value,
        this.state.labelPrefix
      ),
      values,
    });
  }

  getSelectedValueForLabel(value, prefix) {
    let selectedValue = '';

    if (typeof value !== 'undefined' && value !== null && value !== 'none') {
      const foundValue = this.props.values.find((val) => {
        return val.id === value;
      });

      if (typeof foundValue !== 'undefined') {
        selectedValue = `${prefix}${foundValue.label}`;
      }
    }

    return selectedValue;
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleDropdownCloseOnTabOrEscape(e) {
    if (e.key === 'Escape') {
      this.toggleDropdown();
      this.dropdownButtonRef.current.focus();
    }

    if (
      e.key === 'Tab' &&
      ((e.target.parentNode === e.target.closest('ul').lastElementChild && !e.shiftKey) ||
        (e.target.parentNode === e.target.closest('ul').firstElementChild && e.shiftKey))
    ) {
      this.toggleDropdown();
    }
  }

  buildDropdownValues() {
    const valuesHtml = [];

    if (this.state.valuesHtml.length > 0) {
      return this.state.valuesHtml;
    }

    this.state.values.forEach((value) => {
      const html = (
        <li key={`dd-value-${value.id}`}>
          <button
            type='button'
            onClick={this.selectValue.bind(this, value)}
            onKeyDown={this.handleDropdownCloseOnTabOrEscape}>
            {value.label}
          </button>
        </li>
      );

      valuesHtml.push(html);
    });

    return valuesHtml;
  }

  selectValue(value, event) {
    this.props.onValueSelection(value, event);

    this.toggleDropdown();

    this.setState({
      selectedValueLabel: `${this.state.labelPrefix}${value.label}`,
    });
  }

  render() {
    const valuesHtml = this.buildDropdownValues();

    let classes = 'zc-dropdown-results';

    if (this.state.dropdownOpen === true) {
      classes += ' opened';
    }

    if (this.state.openUp === true) {
      classes += ' up';
    }

    return (
      <div className='zc-dropdown'>
        <div className='zc-dropdown-opener'>
          <button type='button' onClick={this.toggleDropdown} ref={this.dropdownButtonRef}>
            {this.state.selectedValueLabel !== ''
              ? this.state.selectedValueLabel
              : this.props.defaultLabel}
            <i className='icon caret-down fal fa-chevron-down' />
          </button>
        </div>
        <ul className={classes}>{valuesHtml}</ul>
      </div>
    );
  }
}

export default DropdownComponent;
