import React, { Component } from 'react';
import { Button, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import * as _ from 'underscore';

import DropdownComponent from '../../../common/dropdown';

/**
 * Styles
 */
import './styles/_index.scss';

class TrackPlayerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmedPlayersTotal: 0,
      totalPlayers: 0,
      submitClicked: false,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const updatedState = {
      totalPlayers: nextProps.players.length,
    };
    if (nextProps.players.length > nextState.totalPlayers) {
      updatedState.submitClicked = false;
      updatedState.confirmedPlayersTotal = 0;
    }

    return updatedState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return _.isEqual(this.props, nextProps) === false || _.isEqual(this.state, nextState) === false;
  }

  valueSelected(player, value) {
    player.status = value.label;

    const confirmedPlayers = this.props.players.filter((filterPlayer) => {
      return ![undefined, '', null].includes(filterPlayer.status);
    });

    this.setState({
      confirmedPlayersTotal: confirmedPlayers.length,
    });
  }

  trackProspects() {
    this.setState({
      submitClicked: true,
    });
    this.props.players.forEach((player) => {
      this.props.trackProspect(player);
    });
  }

  buildPlayerList() {
    const htmlPieces = [];
    const subtract = this.props.players.length === 1 ? 0 : 1;

    this.props.players.forEach((player, index) => {
      const playerStatus = player.status && player.status.name ? player.status.name : player.status;
      const html = (
        <Row key={player.id}>
          <Col md={12} className='track-player-row'>
            <div className='info'>
              <img
                src={player.image_url || 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg'}
                alt={player.name}
                title={player.name}
              />
              <div>
                <p className='bold'>{player.name}</p>
                <p>
                  {player.year} - {player.getPosition()} - {player.getLocation()}
                </p>
              </div>
            </div>
            <DropdownComponent
              id={`status-dd-${player.id}`}
              defaultLabel='Select a Status'
              values={this.props.dropdownValues}
              value={playerStatus}
              onValueSelection={this.valueSelected.bind(this, player)}
              openUp={this.props.players.length - subtract <= index}
            />
          </Col>
        </Row>
      );

      htmlPieces.push(html);
    });

    return htmlPieces;
  }

  render() {
    return (
      <Modal
        className='track-players'
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
        aria-modal={true}
        labelledBy='track-prospects'>
        <ModalBody>
          <ModalHeader toggle={this.toggle} id='track-prospects'>
            Track Prospects
          </ModalHeader>
          <p>
            Before tracking a prospect, we require you to set a prospects status (only viewable by
            your school).
          </p>
          {this.buildPlayerList()}
          <ModalFooter>
            <Button color='secondary' onClick={this.props.toggleModal}>
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={this.trackProspects.bind(this)}
              disabled={
                this.state.confirmedPlayersTotal < this.state.totalPlayers ||
                this.state.submitClicked
              }>
              Track Player
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    );
  }
}

export default TrackPlayerModal;
