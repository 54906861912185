import React from 'react';
import { useContactInfoContext } from '../contactInfo';
import ContactInfoBody from '../contactInfoBody';
import {
  ContactInfoValue,
  ContactInfoPhone,
  ContactInfoLocation,
  ContactInfoBirthDate,
  ContactInfoState,
  ContactInfoSocial,
} from '../contactInfoMisc';
import {
  ContactInfoBodyPhoneItem,
  ContactInfoBodyEmailItem,
  ContactInfoBodyAddressItem,
  ContactInfoBodyBasicItem,
} from '../contactInfoBodyItem';

function PlayerContactInfoBody() {
  const { contact } = useContactInfoContext();

  return (
    <ContactInfoBody>
      <ContactInfoBodyBasicItem>
        <div style={{ display: 'flex' }}>
          <ContactInfoValue value={contact.name} />
          <ContactInfoSocial socialInfo={contact.social_info} />
        </div>
        <ContactInfoBirthDate value={contact.birth_date} />
        <div>
          <ContactInfoState state={contact.birth_state} />
        </div>
      </ContactInfoBodyBasicItem>
      <ContactInfoBodyEmailItem>
        <ContactInfoValue value={contact.email} />
      </ContactInfoBodyEmailItem>
      <ContactInfoBodyPhoneItem>
        <ContactInfoPhone value={contact.cell_phone} type='cellPhone' />
      </ContactInfoBodyPhoneItem>
      <ContactInfoBodyAddressItem>
        <ContactInfoLocation location={contact.location} address={contact.address} />
      </ContactInfoBodyAddressItem>
    </ContactInfoBody>
  );
}

export default PlayerContactInfoBody;
