import React from 'react';
import { useContactInfoContext } from '../contactInfo';

function ContactInfoBody({ children }) {
  const { toggle, isCollapsed, contact, keyToggle } = useContactInfoContext();

  const canToggle = Boolean(contact.contacts.length);
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,
    <div
      className='contact-body'
      aria-label={`${contact.type} contact info`}
      tabIndex={0}
      aria-expanded={isCollapsed}
      onClick={toggle}
      onKeyUp={keyToggle}
      role='button'>
      <div className='box main'>
        {children}
        {canToggle ? (
          <div className='info icon'>
            <i className={`icon caret-down fal fa-chevron-down ${isCollapsed ? 'flip' : ''}`} />
          </div>
        ) : (
          <div className='info icon' />
        )}
      </div>
    </div>
  );
}

export default ContactInfoBody;
