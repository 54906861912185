import axios from 'axios';

class VideosService {
  getAsPromise(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.API_URL}videos/${id}`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
      }).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  downloadVideo(id, filename) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}videos/${id}/download`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      responseType: 'blob',
    }).then(
      (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.data.type,
          })
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();

        link.remove();
      },
      (error) => {
        return error;
      }
    );
  }

  getVideosForPlayer(id) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/videos/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  getVideosForEvent(params) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}videos/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      params,
    }).then((response) => {
      return response.data.data;
    });
  }

  getVideosForPlayerAsPromise(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.API_URL}players/${id}/videos/`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
      }).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default new VideosService();
