import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import BasicInfoComponent from './components/basicInfo';
import ManageListsComponent from './components/manageLists';
import ManageUsersComponent from './components/manageUsers';
import UpdatePasswordComponent from './components/updatePassword';

/**
 * Parent
 */
import ZcruitComponent from '../common/zcruit';

/**
 * Styles
 */
import './styles/_index.scss';

class AccountComponent extends ZcruitComponent {
  getTemplate() {
    if (typeof this.props.match.params.param === 'undefined') {
      return <BasicInfoComponent />;
    }

    switch (this.props.match.params.param) {
      case 'password':
        return <UpdatePasswordComponent />;
      case 'lists':
        return <ManageListsComponent />;
      case 'users':
        return <ManageUsersComponent />;
      default:
        return <BasicInfoComponent />;
    }
  }

  render() {
    return (
      <Row className='account'>
        <Col className='page-content' md={12}>
          {this.getTemplate()}
        </Col>
      </Row>
    );
  }
}

export default AccountComponent;
