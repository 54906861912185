import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import classNames from 'classnames';

import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';
import MessagingComponent, { MESSAGE_TYPE, NO_DATA_MESSAGE } from 'CommonComponents/messager';
import SchoolIconComponent from 'CommonComponents/schoolIcon';
import TimelineComponent from 'CommonComponents/timeline';
import OfferClickableTooltip from 'PlayerPageComponents/modal/boxes/components/offers/clickableTooltip';
import ClickableTooltip from 'CommonComponents/clickableTooltip';

import 'PlayerPageComponents/modal/boxes/components/offers/styles/_index.scss';

class PlayerPageModalOffersBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.dateTimeService = new DateTimeService();
    this.state = {
      schoolHtml: this.generateSchoolOffers(),
      tab: 'offer',
    };
  }

  componentDidMount() {
    const offers = this.generateSchoolOffers();

    this.setState({
      schoolHtml: offers,
    });
  }

  generateSchoolOffers() {
    const htmlPortions = [];

    this.props.player.offers.forEach((offer) => {
      const html = (
        <Col md={1} key={offer.id}>
          <OfferClickableTooltip
            targetId={`offer-from-${offer.college.id}`}
            player={this.props.player}
            offer={offer}>
            <SchoolIconComponent school={offer.college.name} />
          </OfferClickableTooltip>
        </Col>
      );

      htmlPortions.push(html);
    });

    return htmlPortions;
  }

  toggleTab(tab) {
    this.setState({
      tab,
    });
  }

  generateStructure() {
    if (this.props.player.hasVisits() === false) {
      return (
        <MessagingComponent
          id='no_visits'
          message={NO_DATA_MESSAGE.visits}
          type={MESSAGE_TYPE.danger}
        />
      );
    }

    const objs = [];

    Object.keys(this.props.player.visitsByCollege).forEach((key, index) => {
      const isSameSchool = key === window.localStorage.getItem('school');
      const singleObj = {};

      const classes = classNames({
        entry: true,
        main: isSameSchool,
      });

      singleObj[key] = {
        label: (
          <div className={classes} key={`${key}-header`}>
            <div className='school-image-holder'>
              <SchoolIconComponent school={key} imageOnly={true} />
              <p>{key}</p>
            </div>
            <span className='visit-count'>{this.props.player.visitsByCollege[key].length}</span>
          </div>
        ),
        main: isSameSchool,
        values: this.props.player.visitsByCollege[key].map((vbc) => {
          return vbc.value;
        }),
      };

      objs.push(singleObj);
    });

    return objs;
  }

  generateOfficialVisitsSection() {
    if (!this.props.player.visits) {
      return null;
    }

    const officialVisits = this.props.player.visits.filter((visit) => {
      return visit.type.name === 'Official Visit';
    });

    if (officialVisits.length === 0) {
      return null;
    }

    const label = `${officialVisits.length} Official ${
      officialVisits.length === 1 ? 'Visit' : 'Visits'
    }:`;

    return (
      <Row className='official-visits'>
        <Col md={2} className='boldest'>
          <span>{label}</span>
        </Col>
        <Col md={10}>
          <Row>{this.generateOfficialVisitsByBadge(officialVisits)}</Row>
        </Col>
      </Row>
    );
  }

  generateOfficialVisitsByBadge(visits) {
    const finalHtml = [];

    let counter = 0;

    const sortedVisits = visits.sort((a, b) => {
      const firstDate = this.dateTimeService.toUtcDate(new Date(a));
      const secondDate = this.dateTimeService.toUtcDate(new Date(b));

      return firstDate < secondDate ? -1 : 1;
    });

    sortedVisits.sort().forEach((visit) => {
      if (counter >= 5) {
        return;
      }

      const visitId = visit.id;

      const html = (
        <Col md='auto' className='single-official-visit' key={`official-visit-at-${visitId}`}>
          <ClickableTooltip
            targetId={`official-visit-at-${visitId}`}
            target={<SchoolIconComponent school={visit.college.name} />}
            tooltip={
              <span>
                {this.dateTimeService.formatUTCDate(
                  new Date(visit.date),
                  DATE_DEFAULTS.format.default
                )}
              </span>
            }
            placement='top'
          />
        </Col>
      );

      finalHtml.push(html);

      counter++;
    });

    const lgth = finalHtml.length;

    // This represents the remaining spaces where official visits have not happened
    // Since we currently only show a maximum of 5 spaces, using index is small-impact
    // as a unique key
    if (lgth < 5) {
      for (let i = 0; i < 5 - lgth; i++) {
        const uniqueKey = `empty-visit-${i}`;
        finalHtml.push(
          <Col md='auto' className='single-official-visit' key={uniqueKey}>
            <div className='empty-icon' />
          </Col>
        );
      }
    }

    return finalHtml;
  }

  getScreen() {
    if (this.state.tab === 'visit') {
      if (this.props.player.hasVisits() === false) {
        return (
          <MessagingComponent
            id='no_visits'
            message={NO_DATA_MESSAGE.visits}
            type={MESSAGE_TYPE.danger}
          />
        );
      }

      const lastIndex = this.props.player.visits.length - 1;

      const startDate = this.dateTimeService
        .toUtcDate(new Date(this.props.player.visits[lastIndex].date))
        .startOf('month');
      const endDate = this.dateTimeService.getUTCDate();

      const visitsOrVisit = this.props.player.totalVisits === 1 ? 'Visit' : 'Visits';

      return (
        <div className='timeline-holder'>
          <TimelineComponent
            timeLineDataLoaded={true}
            player={this.props.player}
            endDate={endDate}
            startDate={startDate}
            endLabel='Today'
            startLabel={startDate.toFormat(DATE_DEFAULTS.format.default)}
            interval={1}
            intervalType='months'
            legendHeader={`${this.props.player.visits.length} ${visitsOrVisit}:`}
            noResultsMessage={NO_DATA_MESSAGE.visits}
            values={this.generateStructure()}
          />
          {this.generateOfficialVisitsSection()}
        </div>
      );
    }

    return <Row className='offers-list'>{this.state.schoolHtml}</Row>;
  }

  getOfferTab() {
    return (
      <Col
        md='auto'
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            return this.toggleTab('offer');
          }
        }}
        onClick={() => {
          return this.toggleTab('offer');
        }}
        className={this.state.tab === 'offer' ? 'active' : 'inactive'}
        role='tab'
        tabIndex={0}
        aria-selected={this.state.tab === 'offer'}>
        {this.props.player.offers.length} Offers
      </Col>
    );
  }

  getVisitTab() {
    const visitCount = this.props.player.getVisitCount();

    const visitsOrVisit = visitCount === 1 ? 'Visit' : 'Visits';

    return (
      <Col
        md='auto'
        className={this.state.tab === 'visit' ? 'active' : 'inactive'}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            return this.toggleTab('visit');
          }
        }}
        onClick={() => {
          return this.toggleTab('visit');
        }}
        role='tab'
        tabIndex={0}
        aria-selected={this.state.tab === 'visit'}>
        {visitCount} {visitsOrVisit}
      </Col>
    );
  }

  render() {
    return (
      <Col md={12} className='box offerVisits'>
        <Row className='nav-header' role='tablist'>
          {this.getOfferTab()}
          {this.getVisitTab()}
        </Row>
        {this.getScreen()}
      </Col>
    );
  }
}

export default PlayerPageModalOffersBoxComponent;
