import { combineReducers } from 'redux';

/**
 * Reducers
 */
import activityFilter from './activity.filters';
import authentication from './authentication';
import filter from './filters';
import players from './players';
import rivalsCamp from './rivalsCamp';
import twitter from './twitter';

export default combineReducers({
  activityFilter,
  authentication,
  filter,
  players,
  rivalsCamp,
  twitter,
});
