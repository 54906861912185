import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import CardHeader from 'RootComponents/TwitterInsights/CardHeader';
import LikeTrendsList from 'RootComponents/TwitterInsights/LikeTrendsList';
import LikeTrendsChart from 'RootComponents/TwitterInsights/LikeTrendsChart';
import MessagingComponent, { MESSAGE_TYPE, STATUS_MESSAGE } from 'CommonComponents/messager';
import DateTimeService from 'Services/date-time';

const filtersNeeded = ['Time', 'Accounts', 'Schools'];

class LikeTrends extends Component {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.state = {
      startDate: new Date().setFullYear(new Date().getFullYear() - 1),
      filters: {
        component: 'likeTrends',
      },
      listData: [],
      chartRanges: [],
      schools: [],
    };
  }

  getDisplayedRole(sma) {
    if (typeof sma.position !== 'undefined') {
      return sma.position;
    }
    if (sma.role === 'roster') {
      return 'Roster Player';
    }
    return sma.role.charAt(0).toUpperCase() + sma.role.slice(1);
  }

  getListData() {
    const likeTrendsData = this.props.likeTrendsData;
    const resultListData = [];
    const schoolGuidArray = [];

    likeTrendsData.forEach((collegeObj) => {
      schoolGuidArray.push(collegeObj.college_sport_guid);
      collegeObj.socialMediaAccounts.forEach((sma) => {
        const handleObj = sma;
        const likedCountPostFollow = handleObj.twitterLikes.length;
        const totalCountPostFollow = handleObj.socialMediaPosts.length;
        const likedTweetPostFollowList = handleObj.twitterLikes;
        const allTweetPostFollowList = handleObj.socialMediaPosts;
        let percentCalculation = ((handleObj.likedCount / handleObj.totalCount) * 100).toFixed(1);

        let listDataObject;
        if (likedCountPostFollow > 0) {
          percentCalculation = ((likedCountPostFollow / totalCountPostFollow) * 100).toFixed(1);
          if (percentCalculation >= 100) {
            percentCalculation = 100;
          }
          listDataObject = {
            userRole: this.getDisplayedRole(handleObj),
            schoolName: collegeObj.college_name,
            percentLiked: percentCalculation,
            likedTweetsPostFollow: likedTweetPostFollowList,
            allTweetsPostFollow: allTweetPostFollowList,
          };

          if (handleObj.name) {
            listDataObject.userName = handleObj.name;
          } else {
            listDataObject.userName = handleObj.accountHandle;
          }
          resultListData.push(listDataObject);
        }
      });
    });
    if (this.state.listData.length !== resultListData.length) {
      this.setState({
        listData: resultListData,
        schools: schoolGuidArray,
      });
    }

    return resultListData;
  }

  getChartData() {
    const resultObject = {};
    const listData = this.state.listData;
    const resultChartDataPostFollowTweets = {};

    listData.forEach((twitterAccountRecord) => {
      const school = twitterAccountRecord.schoolName;
      if (!resultChartDataPostFollowTweets[school]) {
        resultChartDataPostFollowTweets[school] = {
          all: twitterAccountRecord.allTweetsPostFollow,
          liked: twitterAccountRecord.likedTweetsPostFollow,
        };
      } else {
        resultChartDataPostFollowTweets[school].all = resultChartDataPostFollowTweets[
          school
        ].all.concat(twitterAccountRecord.allTweetsPostFollow);
        resultChartDataPostFollowTweets[school].liked = resultChartDataPostFollowTweets[
          school
        ].liked.concat(twitterAccountRecord.likedTweetsPostFollow);
      }
    });

    const postFollowColleges = Object.keys(resultChartDataPostFollowTweets);

    postFollowColleges.forEach((college) => {
      const allTweetCount = [0, 0, 0, 0, 0, 0];
      const likedTweetCount = [0, 0, 0, 0, 0, 0];
      const likePercentageResults = this.state.chartRanges.map((range, i) => {
        resultChartDataPostFollowTweets[college].all.forEach((tweet) => {
          const isDateInRange = this.dateTimeService.isDateBetween(
            new Date(range.start),
            new Date(range.end),
            new Date(tweet.date)
          );

          if (isDateInRange) {
            allTweetCount[i] += 1;
          }
        });

        resultChartDataPostFollowTweets[college].liked.forEach((tweet) => {
          const isDateInRange = this.dateTimeService.isDateBetween(
            new Date(range.start),
            new Date(range.end),
            new Date(tweet.date)
          );

          if (isDateInRange) {
            likedTweetCount[i] += 1;
          }
        });

        let result;
        if (i > 0) {
          likedTweetCount[i] += likedTweetCount[i - 1];
          allTweetCount[i] += allTweetCount[i - 1];
          result = ((likedTweetCount[i] / allTweetCount[i]) * 100).toFixed(1);
        } else {
          result = ((likedTweetCount[i] / allTweetCount[i]) * 100).toFixed(1);
        }

        if (result > 100) {
          result = 100;
        }

        if (result === 'NaN') {
          result = 0;
        }

        return result;
      });

      resultObject[college] = likePercentageResults;
    });

    return resultObject;
  }

  getChartLabels() {
    // Defaulting to past month for now. Will extend when we have more data.
    let chartRanges = this.state.chartRanges;
    let startDate = this.dateTimeService.getUTCDate().minus({ days: 90 });

    if (
      typeof this.state.filters !== 'undefined' &&
      typeof this.state.filters.startDate !== 'undefined'
    ) {
      startDate = this.dateTimeService.toUtcDate(new Date(this.state.filters.startDate));
    }

    let yLabels = [];
    const dayInterval = (startDate.diffNow('days').toObject().days / 4) * -1;

    yLabels = [
      startDate,
      startDate.plus({ days: dayInterval }),
      startDate.plus({ days: dayInterval * 2 }),
      startDate.plus({ days: dayInterval * 3 }),
      startDate.plus({ days: dayInterval * 4 }),
    ];

    chartRanges = yLabels.map((utcTime) => {
      const start = utcTime.minus({ days: dayInterval });
      const end = utcTime;
      return this.dateTimeService.getDateRange(start, end);
    });

    yLabels = yLabels.map((utcTime) => {
      const label = `${utcTime.toFormat('MMM')} ${this.dateTimeService.getDayOrdinal(utcTime.day)}`;
      return label;
    });

    if (
      this.state.chartRanges.length < 1 ||
      !this.state.chartRanges[0].start.hasSame(chartRanges[0].start, 'day')
    ) {
      this.setState({
        chartRanges,
        startDate: chartRanges[0].start,
      });
    }

    return yLabels;
  }

  handleFilterChange(updateObject) {
    const updatedFilters = Object.assign({}, this.state.filters);
    // filter type that is being updated
    const filterToUpdate = updateObject.type;

    updatedFilters[filterToUpdate] = updateObject.values;

    if (typeof updateObject !== 'undefined' && updateObject.type === 'startDate') {
      this.setState({
        filters: updatedFilters,
        startDate: updateObject.values,
      });
    } else {
      this.setState({
        filters: updatedFilters,
      });
    }
    this.props.handleFilterChange(updatedFilters);
  }

  handleDayChange(newDay) {
    this.setState({
      startDate: newDay,
    });
  }

  render() {
    if (this.props.twitterTrendsDataLoaded === false) {
      return (
        <Col md={12} className='box no-padding'>
          <CardHeader
            title='Like Trends'
            filtersNeeded={filtersNeeded}
            handleFilterChange={this.handleFilterChange.bind(this)}
            schools={this.state.schools}
            defaults={{
              Time: ['3 Months'],
              Interactions: ['All'],
              Accounts: ['Coaches', 'School', 'Support Staff'],
            }}
          />
          <Col md={12} className='like-trends-container'>
            <Row>
              <Col md='12'>
                <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
              </Col>
            </Row>
          </Col>
        </Col>
      );
    }

    return (
      <Col md={12} className='box no-padding'>
        <CardHeader
          title='Like Trends'
          handleDayChange={this.handleDayChange.bind(this)}
          filtersNeeded={filtersNeeded}
          handleFilterChange={this.handleFilterChange.bind(this)}
          schools={this.state.schools}
          defaults={{
            Time: ['3 Months'],
            Interactions: ['All'],
            Accounts: ['Coaches', 'School', 'Support Staff'],
          }}
        />
        <Col md={12} className='like-trends-container'>
          <Row>
            <LikeTrendsChart
              likeTrendsChartData={this.getChartData()}
              chartLabels={this.getChartLabels()}
              startDate={this.state.startDate}
            />
            <LikeTrendsList likeTrendsListData={this.getListData()} />
          </Row>
        </Col>
      </Col>
    );
  }
}

export default LikeTrends;
