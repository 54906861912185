import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

/**
 * Components
 */
import IconComponent from '../../../../../../../common/icon';

/**
 * Styles
 */
import './styles/_index.scss';

class RivalsCampEventSingleVideoComponent extends Component {
  render() {
    return (
      <Col md={6} className='video'>
        <div className='box'>
          <Link to={`/rivals-camp/video/${this.props.video.id}`} className='back-to-list-link'>
            <p>{this.props.video.title}</p>
            <IconComponent icon='play fas fa-play' label={`Play ${this.props.video.id}`} />
          </Link>
        </div>
      </Col>
    );
  }
}

export default RivalsCampEventSingleVideoComponent;
