import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';

import './styles/_index.scss';

class AdvancedFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      (typeof this.props.value !== 'undefined' &&
        this.props.value !== null &&
        this.props.value !== '') ||
      this.props.isOpen === true
    ) {
      isOpen = true;
    }

    this.state = {
      isOpen,
      toggleClicked: false,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let filterIds = [];
    // Get a list of ids from children filters
    if (Array.isArray(nextProps.children)) {
      filterIds = nextProps.children.map((child) => {
        if (child === false) {
          return null;
        }
        return child.props.id;
      });
    } else {
      filterIds.push(nextProps.children.props.id);
    }

    let isOpen = false;

    if (nextProps.closeFilter && !nextState.toggleClicked) {
      if (nextProps.isOpen) {
        return {
          isOpen: true,
          toggleClicked: false,
        };
      }

      const valueKeys = Object.keys(nextProps.values);
      filterIds.forEach((id) => {
        if (valueKeys.includes(id) === false) {
          return;
        }
        // If any child has a value, open it
        const values = nextProps.values[id];
        // console.log(values);
        switch (id) {
          case 'commits':
            if (
              (typeof values.values !== 'undefined' && values.values.length > 0) ||
              (typeof values.uncommitted !== 'undefined' && values.uncommitted === true)
            ) {
              isOpen = true;
            }
            break;
          case 'high_schools':
            if (values.length > 0) {
              isOpen = true;
            }

            break;
          case 'location_radius':
            if (values.latitude !== -1) {
              isOpen = true;
            }

            break;
          case 'location':
            if (values.counties.length > 0 || values.state.length > 0) {
              isOpen = true;
            }

            break;
          case 'offers':
            if (
              (typeof values.values !== 'undefined' && values.values.length > 0) ||
              (typeof values.no_fbs !== 'undefined' && values.no_fbs === true) ||
              (typeof values.no_offers !== 'undefined' && values.no_offers === true) ||
              (typeof values.no_p5 !== 'undefined' && values.no_p5 === true)
            ) {
              isOpen = true;
            }

            break;
          case 'signings':
            if (
              (typeof values.values !== 'undefined' && values.values.length > 0) ||
              (typeof values.unsigned !== 'undefined' && values.unsigned === true)
            ) {
              isOpen = true;
            }

            break;
          case 'visits':
            if (values.length > 0) {
              isOpen = true;
            }
            break;
          default:
            if (valueKeys.includes(id)) {
              isOpen = false;
            }
        }
      });
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      isOpen,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { id, label, children } = this.props;
    const { isOpen } = this.state;
    return (
      <FilterComponent
        id={id}
        classes='advanced'
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {children}
      </FilterComponent>
    );
  }
}
AdvancedFilterComponent.defaultProps = {
  values: {},
  closeFilter: false,
  label: 'Advanced Filters',
};

AdvancedFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
  closeFilter: PropTypes.bool,
  label: PropTypes.string,
};

export default AdvancedFilterComponent;
