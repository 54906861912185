import React, { Component } from 'react';
import { Col, Row, Table } from 'reactstrap';

/**
 * Components
 */
import MessagingComponent, {
  STATUS_MESSAGE,
  MESSAGE_TYPE,
  ERROR_MESSAGE,
} from 'CommonComponents/messager';
import PageContentHeader from 'RootComponents/users/components/header';

/**
 * Services
 */
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';
import OrganizationsService from 'Services/organizations';
import PackagesService from 'Services/packages';
import UsersService from 'Services/users';

/**
 * Styles
 */
import 'RootComponents/users/styles/_index.scss';

class UsersComponent extends Component {
  constructor(props) {
    super(props);

    this.dateTimeService = new DateTimeService();
    this.state = {
      error: false,
      isLoading: true,
      organziations: null,
      packages: null,
      users: [],
    };
  }

  componentWillMount() {
    const promises = Promise.all([
      OrganizationsService.findOrganizations(),
      PackagesService.findPackages(),
    ]);

    promises.then((success) => {
      this.setState({
        organizations: success[0],
        packages: success[1],
      });
    });

    UsersService.getUsersForAdmin(null, null, true).then(
      (users) => {
        this.setState({
          isLoading: false,
          users,
        });
      },
      () => {
        this.setState({
          error: true,
        });
      }
    );
  }

  buildTable() {
    return this.state.users.map((user) => {
      return (
        <tr key={user.id}>
          <td>{user.id}</td>
          <td>{user.username}</td>
          <td>{user.organization.name}</td>
          <td>{user.organization.package.name}</td>
          <td>
            {this.dateTimeService.formatUTCDate(
              new Date(user.created_at),
              DATE_DEFAULTS.format.default
            )}
          </td>
          <td>
            {this.dateTimeService.formatUTCDate(
              new Date(user.updated_at),
              DATE_DEFAULTS.format.default
            )}
          </td>
        </tr>
      );
    });
  }

  filterChanged(type, value) {
    this.setState({
      error: false,
    });

    const orgQuery = type === 'organizations' ? value : null;
    const packageQuery = type === 'packages' ? value : null;

    UsersService.getUsersForAdmin(orgQuery, packageQuery, true).then(
      (users) => {
        this.setState({
          isLoading: false,
          users,
        });
      },
      () => {
        this.setState({
          error: true,
        });
      }
    );
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <Row className='account'>
          <Col className='page-content' md={12}>
            <Row>
              <PageContentHeader />
              <Col md={{ size: 8, offset: 2 }} className='page-header-container box-container'>
                <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    if (this.state.error === true) {
      return (
        <Row className='account rivals-camp'>
          <Col className='page-content' md={12}>
            <Row>
              <PageContentHeader
                filterChanged={this.filterChanged.bind(this)}
                organizations={this.state.organizations}
                packages={this.state.packages}
              />
              <Col md={{ size: 8, offset: 2 }} className='page-header-container box-container'>
                <MessagingComponent message={ERROR_MESSAGE.users} type={MESSAGE_TYPE.danger} />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    return (
      <Row className='account rivals-camp'>
        <Col className='page-content' md={12}>
          <Row>
            <PageContentHeader
              filterChanged={this.filterChanged.bind(this)}
              organizations={this.state.organizations}
              packages={this.state.packages}
            />
            <Col md={{ size: 8, offset: 2 }} className='box-container'>
              <Table className='table-list'>
                <thead className='list-heading'>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Organization</th>
                    <th>Package</th>
                    <th>Created On</th>
                    <th>Last Action</th>
                  </tr>
                </thead>
                <tbody>{this.buildTable()}</tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default UsersComponent;
