import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
  ERROR_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import AcademicsService from 'Services/academics';

/**
 * Styles
 */
import 'PlayerPageComponents/modal/boxes/components/academics/styles/_index.scss';

class PlayerPageModalAcademicsBoxComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isLoading: true,
      academics: [],
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    AcademicsService.getAcademicsForPlayer(this.props.player.id).then(
      (response) => {
        this.setState({
          isLoading: false,
          academics: response,
        });
      },
      () => {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    );
  }

  buildGPA() {
    if (typeof this.state.academics.metrics.gpa === 'undefined') {
      return <MessagingComponent message={ERROR_MESSAGE.academics} type={MESSAGE_TYPE.danger} />;
    }

    const gpa = this.state.academics.metrics.gpa[0];

    return (
      <div className='top gpa'>
        <p>{parseFloat(gpa.value).toFixed(2)}</p>
        <IconComponent color='grey' icon={gpa.source.name} label={gpa.source.label} />
      </div>
    );
  }

  buildStats() {
    const academicMetrics = this.state.academics.metrics;

    const academicKeys = Object.keys(academicMetrics).filter((key) => {
      return key !== 'gpa';
    });

    if (academicKeys.length === 0) {
      return null;
    }

    const html = academicKeys.map((academicKey) => {
      return (
        <div className='academic'>
          <div className='left'>
            <p>{academicMetrics[academicKey][0].label}</p>
            <p className='bold'>{academicMetrics[academicKey][0].value}</p>
          </div>
          <IconComponent
            color='grey'
            icon={academicMetrics[academicKey][0].source.name}
            label={academicMetrics[academicKey][0].source.label}
          />
        </div>
      );
    });

    return <div className='bottom'>{html}</div>;
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <Col md={4} className='box academics'>
          <h2>Academics</h2>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </Col>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <Col md={4} className='box academics'>
          <h2>Academics</h2>
          <div className='academics_holder'>
            <MessagingComponent message={NO_DATA_MESSAGE.academics} />
          </div>
        </Col>
      );
    }

    if (this.props.page !== 'none' && typeof this.props.page !== 'undefined') {
      return (
        <Col md={4} className='box academics'>
          <h2>Academics</h2>
          <Link
            to={`/${this.props.page}/${this.props.player.id}/academics`}
            aria-label={`View ${this.props.player.name}'s academic info`}>
            <div className='academics_holder'>
              <h3>GPA</h3>
              {this.buildGPA()}
              {this.buildStats()}
            </div>
          </Link>
        </Col>
      );
    }
    return (
      <Col md={4} className='box academics'>
        <div
          onClick={() => {
            this.props.setTab('academics');
          }}
          onKeyUp={() => {
            this.props.setTab('academics');
          }}
          role='link'
          tabIndex={0}
          className='tab-link-container'>
          <h2>Academics</h2>
          <div className='academics_holder'>
            <h3>GPA</h3>
            {this.buildGPA()}
            {this.buildStats()}
          </div>
        </div>
      </Col>
    );
  }
}

export default PlayerPageModalAcademicsBoxComponent;
