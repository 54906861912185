const initialState = {
  orgChanged: false,
};

export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action) {
  switch (action.type) {
    case 'RIVALS_CAMP_LIST_OPENED':
      return Object.assign({}, state, {
        openList: action.openList,
      });
    default:
      return state;
  }
}
