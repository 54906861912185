import { DateTime, Interval } from 'luxon';

export const DATE_DEFAULTS = {
  format: {
    // Date => 01/31/2022
    default: `MM/dd/yyyy`,
    // Date => 01/31/2022 09:55:00
    long: `MM/dd/yyyy HH:mm:ss`,
    // Date => 2022-01-31 09:55:00
    extended: `yyyy-MM-dd HH:mm:ss`,
    // Date => 09:55 AM - January, 31 2022
    twitter: `hh:mm a - MMM d, yyyy`,
  },
};
/**
 * @class DateTimeService
 * @classdesc Manages and modifies dates for consistent presentation and date storage purposes
 */
export default class DateTimeService {
  /**
   * Gets the current date based on local timezone
   * @returns {DateTime} the current date
   */
  getDate() {
    return DateTime.local();
  }

  /**
   * Gets the current date based on UTC time
   * @returns {DateTime} The current date
   */
  getUTCDate() {
    return DateTime.utc();
  }

  /**
   * Gets the year from the current date
   * @returns {number} The current year
   */
  getYear() {
    return this.getDate().year;
  }

  /**
   * Gets the current date with the time set to the beginning of the day
   * @returns {DateTime} The current date at the beginning of the day
   */
  getStartOfDay() {
    return this.getDate().startOf('day');
  }

  /**
   * Gets the given date with the time set to the beginning of the day
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @returns {DateTime} The given date at the beginning of the day; else the current date at the beginning of the day
   */
  getStartOfDayForDate(date) {
    if (date instanceof Date) {
      return this.getStartOfDayForDate(this.fromJSDate(date));
    }

    return date.startOf('day');
  }

  /**
   * Gets the current date and removes days to return a new date with time set to the beginning of the day
   * @param {number} daysFromNow Represents the number of days from a given date
   * @returns {DateTime} The new date from the current date at the beginning of the day
   */
  getStartOfDayMinusDays(daysFromNow) {
    const date = this.getDate();
    const newDate = this.minusDays(date, daysFromNow);
    return this.getStartOfDayForDate(newDate);
  }

  /**
   * Gets the current date with the time set to the end of the day
   * @returns {DateTime} The current date at the end of the day
   */
  getEndOfDay() {
    return this.getDate().endOf('day');
  }

  /**
   * Gets the given date with the time set to the end of the day
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @returns {DateTime} The given date at the end of the day; else the current date at the end of the day
   */
  getEndOfDayForDate(date) {
    if (date instanceof Date) {
      return this.getEndOfDayForDate(this.fromJSDate(date));
    }

    return date.endOf('day');
  }

  /**
   * Gets the current date and adds days to return a new date with time set to the end of the day
   * @param {number} daysFromNow Represents the number of days from a given date
   * @returns {DateTime} The new date from the current date at the end of the day
   */
  getEndOfDayPlusDays(daysFromNow) {
    const date = this.getDate();
    const newDate = this.plusDays(date, daysFromNow);
    return this.getEndOfDayForDate(newDate);
  }

  /**
   * Gets the current date and removes days to return a new date with time set to the end of the day
   * @param {number} daysFromNow Represents the number of days from a given date
   * @returns {DateTime} The new date from the current date at the end of the day
   */
  getEndOfDayMinusDays(daysFromNow) {
    const date = this.getDate();
    const newDate = this.minusDays(date, daysFromNow);
    return this.getEndOfDayForDate(newDate);
  }

  /**
   * Gets a new date from adding days to a provided date or the current date if no date is provided
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @param {number} dayCount Represents the number of days to add to the provided date
   * @returns {DateTime} The new date
   */
  plusDays(date, dayCount) {
    if (date instanceof Date) {
      return this.plusDays(this.fromJSDate(date), dayCount);
    }

    return date.plus({ days: dayCount });
  }

  /**
   * Gets a new date from adding seconds to a provided date or the current date if no date is provided
   * @param {DateTime} date Represents a DateTime instance
   * @param {number} seconds Represents the number of seconnds to add to the provided date
   * @returns {DateTime} The new date; else the current date
   */
  plusSeconds(date, seconds) {
    if (date instanceof Date) {
      return this.plusSeconds(this.fromJSDate(date), seconds);
    }

    return date.plus({ seconds });
  }

  /**
   * Gets a new date from removing days to a provided date or the current date if no date is provided
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @param {number} dayCount Represents the number of days to add to the provided date
   * @returns {DateTime} The new date
   */
  minusDays(date, dayCount) {
    if (date instanceof Date) {
      return this.minusDays(this.fromJSDate(date), dayCount);
    }

    return date.minus({ days: dayCount });
  }

  /**
   * Gets a new date from removing months to a provided date or the current date if no date is provided
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @param {number} monthCount Represents the number of months to subtract from the provided date
   * @returns {DateTime} The new date
   */
  minusMonths(date, monthCount) {
    if (date instanceof Date) {
      return this.minusMonths(this.fromJSDate(date), monthCount);
    }

    return date.minus({ months: monthCount });
  }

  /**
   * Gets a new date from the provided ISO date string
   * @param {string} date Represents an ISO date string
   * @returns {DateTime} The new date
   */
  fromISODateString(date) {
    const sanitizeDate = new Date(date).toISOString();
    return DateTime.fromISO(sanitizeDate);
  }

  /**
   * Gets a new date from the provided ISO date string in a specified format
   * @param {string} date Represents an ISO date string
   * @param {string} format Represents the ISO format for the date to be represented in
   * @returns {string} The new ISO date string
   */
  formatISODateString(dateText, format) {
    const date = this.fromISODateString(dateText);
    return date.toLocaleString(format);
  }

  /**
   * Gets a new date from the provided JS Date instance in a specified format
   * @param {Date} jsDate Represents a JS Date instance
   * @param {string} format Represents the ISO format for the date to be represented in
   * @returns {string} The new ISO date string
   */
  formatJSDate(jsDate, format) {
    const date = DateTime.fromJSDate(jsDate);
    return date.toLocaleString(format);
  }

  /**
   * Gets a new date from the provided JS Date instance as UTC in a specified format
   * @param {DateTime|Date} date Represents a DateTime or JS Date instance
   * @param {string} format Represents the ISO format for the date to be represented in
   * @returns {string} The new ISO date string
   */
  formatUTCDate(date, format) {
    if (date instanceof Date) {
      return this.formatUTCDate(this.fromJSDate(date), format);
    }

    const utcDate = date.toUTC();
    const formattedUTCDate = utcDate.toFormat(format);

    return formattedUTCDate;
  }

  /**
   * Gets a new date from the provided DateTime or JS Date instance in a specified format
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @param {string} format Represents the ISO format for the date to be represented in
   * @returns {string} The new ISO date string
   */
  formatDate(date, format) {
    if (date instanceof Date) {
      return this.formatDate(this.fromJSDate(date), format);
    }

    return date.toLocaleString(format);
  }

  /**
   * Gets the difference in seconds between two dates
   * @param {DateTime|Date} maxDate Represents the end date of a date range
   * @param {DateTime|Date} minDate Represents the beginning of a date range
   * @returns {Duration} The difference in seconds between the given dates
   */
  getDiffInSeconds(maxDate, minDate) {
    if (maxDate instanceof Date && minDate instanceof Date) {
      return this.getDiffInSeconds(this.fromJSDate(maxDate), this.fromJSDate(minDate));
    }

    return maxDate.diff(minDate, 'seconds');
  }

  /**
   * Gets a new JS Date instance from provided date options
   * @param {number} year Represents the year for the provided date options
   * @param {number} month Represents the month for the provided date options
   * @param {number} day Represents the day for the provided date options
   * @returns {Date} The new date
   */
  createJSDate(year, month, day) {
    return this.createDate(year, month, day).toJSDate();
  }

  /**
   * Gets a new DateTime instance from provided date options
   * @param {number} year Represents the year for the provided date options
   * @param {number} month Represents the month for the provided date options
   * @param {number} day Represents the day for the provided date options
   * @returns {DateTime} The new date
   */
  createDate(year, month, day, isUtc = false) {
    if (isUtc) {
      return this.createUtcDate(year, month, day);
    }

    return DateTime.local(year, month + 1, day);
  }

  /**
   * Gets a new DateTime instance from provided date options as UTC
   * @param {number} year Represents the year for the provided date options
   * @param {number} month Represents the month for the provided date options
   * @param {number} day Represents the day for the provided date options
   * @returns {DateTime} The new date
   */
  createUtcDate(year, month, day) {
    return DateTime.utc(year, month + 1, day);
  }

  /**
   * Gets the provided date presented as UTC
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @returns {DateTime} The new date
   */
  toUtcDate(date) {
    if (date instanceof Date) {
      return this.createUtcDate(date.getFullYear(), date.getMonth(), date.getDate());
    }

    return this.createUtcDate(date.year, date.month, date.day);
  }

  /**
   * Gets a DateTime instance from the provided JS Date instance
   * @param {Date} date Represents the JS date instance
   * @returns {DateTime}
   */
  fromJSDate(date) {
    return DateTime.fromJSDate(date);
  }

  /**
   * Gets a human-readable estimation from a given date to the current date (past or future)
   *
   * @param {DateTime|Date} date Represents either a DateTime or JS Date instance
   * @returns {string} The new date in human-readable terms
   */
  fromNow(date) {
    if (date instanceof Date) {
      return this.fromNow(this.fromJSDate(date));
    }

    return date.toRelative();
  }

  /**
   * Create a date range object with a start and end date
   * @param {DateTime|Date} startDate Represents the starting date range of a DateTime or JS Date instance
   * @param {DateTime|Date} endDate Represents the ending date range of a DateTime or JS Date instance
   * @returns {Interval} An object composing a start date and end date
   */
  getDateRange(startDate, endDate) {
    if (startDate instanceof Date) {
      return this.getDateRange(this.toUtcDate(startDate), endDate);
    }

    if (endDate instanceof Date) {
      return this.getDateRange(startDate, this.toUtcDate(endDate));
    }

    const dtRange = Interval.fromDateTimes(startDate, endDate);

    return dtRange;
  }

  /**
   * Determines if a given DateTime or JS Date instance falls between two specified dates
   * @param {DateTime|Date} startDate Represents the starting date range of a DateTime or JS Date instance
   * @param {DateTime|Date} endDate Represents the ending date range of a DateTime or JS Date instance
   * @param {DateTime|Date} dateOfInterest Represents the DateTime or JS Date instance between the provided start and end date values
   * @returns {boolean} boolean
   */
  isDateBetween(startDate, endDate, dateOfInterest) {
    if (startDate instanceof Date) {
      return this.isDateBetween(this.toUtcDate(startDate), endDate, dateOfInterest);
    }

    if (endDate instanceof Date) {
      return this.isDateBetween(startDate, this.toUtcDate(endDate), dateOfInterest);
    }

    if (dateOfInterest instanceof Date) {
      return this.isDateBetween(startDate, endDate, this.toUtcDate(dateOfInterest));
    }

    const dtRange = this.getDateRange(startDate, endDate);

    return dtRange.contains(dateOfInterest);
  }

  /**
   * Converts a given day to a human-readable output.
   * @param {number} day Represents the numeric day for a given DateTime or JS Date instance
   *
   * @example 05/01/2022
   * getDayOrdinal(1) //=> 1st
   *
   * @example 05/04/2022
   * getDayOrdinal(4) //=> 4th
   *
   * @returns {string} Humanized string notation for a day in relative terms
   */
  getDayOrdinal(day) {
    if (isNaN(day)) {
      return NaN;
    }

    const plurals = ['th', 'st', 'nd', 'rd'];
    const dayModulus = day % 100;

    return day + (plurals[(dayModulus - 20) % 10] || plurals[dayModulus] || plurals[0]);
  }
}
