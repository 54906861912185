import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';

import store from 'Reducers/store';

import './styles/_index.scss';

class BooleanFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    ) {
      isOpen = true;
    }

    this.state = {
      isOpen,
      toggleClicked: false,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  toggleActive(active) {
    const { id: type, activity_request_level } = this.props;
    store.dispatch({
      type: 'ACTIVITY_TOGGLE_ACTIVE',
      filter: {
        type,
        active,
        activity_request_level,
      },
    });
  }

  render() {
    const { id, values, label, classes, collapseClass } = this.props;
    const { isOpen } = this.state;
    return (
      <FilterComponent
        id={id}
        active={values}
        addActiveCheckbox={true}
        classes={classes}
        collapseClass={collapseClass}
        collapsible={false}
        className='filter boolean'
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}
        toggleActive={this.toggleActive.bind(this)}
      />
    );
  }
}
BooleanFilterComponent.defaultProps = {
  classes: 'boolean',
  collapseClass: '',
  label: 'Boolean Filter',
  activity_request_level: '',
};

BooleanFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.bool.isRequired,
  activity_request_level: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string,
  collapseClass: PropTypes.string,
};

export default BooleanFilterComponent;
