import React, { Component } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Services
 */
import FilterService from 'Services/filters';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import SaveFilterModal from 'CommonComponents/saveFiltersModal';

/**
 * Styles
 */
import 'CommonComponents/filterSlideContainer/styles/_index.scss';

class FilterSlideContainerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelButtonLabel: this.props.cancelButtonLabel || 'Clear Filters',
      errorThrown: false,
      errorMessage: '',
      saveButtonLabel: this.props.saveButtonLabel || 'Save Filters',
      saveModalOpen: false,
    };
  }

  throwError(message) {
    this.setState({
      errorThrown: true,
      errorMessage: message,
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return nextState;
  }

  saveHandler(name) {
    if (this.props.location !== 'account') {
      this.toggleSaveModal();
    } else {
      name = this.props.listName;
    }

    const filterObj = {
      filter: Object.assign(this.props.filter),
    };

    filterObj.name = name;
    if (typeof filterObj.name === 'undefined' || filterObj.name.length === 0) {
      this.throwError('Filter must have a name');

      return;
    }

    if (typeof filterObj.players !== 'undefined' && filterObj.players.length > 0) {
      filterObj.years = [];
    }

    if (this.props.editMode === true) {
      FilterService.editFilter(filterObj.filter, filterObj.name, this.props.listId)
        .then((response) => {
          if (response.status === 200 && typeof this.props.addFilterToState !== 'undefined') {
            this.props.addFilterToState(filterObj);
          }
        })
        .catch((err) => {
          this.throwError(err.response.data.error.message);
        });

      return;
    }

    FilterService.saveFilter(filterObj, 2)
      .then((response) => {
        if (response.status === 200 && typeof this.props.addFilterToState !== 'undefined') {
          this.props.addFilterToState(response.data.data);
        }
      })
      .catch((err) => {
        this.throwError(err.response.data.error.message);
      });
  }

  toggleSaveModal() {
    if (this.props.location === 'account') {
      this.saveHandler(this.props.listName);
    } else {
      this.setState({
        saveModalOpen: !this.state.saveModalOpen,
      });
    }
  }

  disableSaveButton() {
    return (
      typeof this.props.listName === 'undefined' ||
      this.props.listName.length === 0 ||
      this.props.listName.toLowerCase() === 'for you'
    );
  }

  clearFilters() {
    store.dispatch({
      type: 'CLEAR_ACCOUNT_FILTERS',
    });
  }

  render() {
    const classes = classNames({
      filters: true,
      'filters-overlay': true,
      closed: !this.props.drawerOpened,
      opening: this.state.drawerOpening,
    });

    return (
      <Col md={2} className={classes}>
        <Row className='close-modal-container'>
          <button
            className='close-modal'
            type='button'
            onClick={() => {
              this.props.closeDrawer();
            }}>
            <IconComponent icon='x fas fa-times' label='Close Filters' />
          </button>
        </Row>
        {this.props.children}
        <Col md={2} className='button-filter-col'>
          <div className='button-filters'>
            <Button
              type='submit'
              className='save-button'
              disabled={this.disableSaveButton()}
              title={this.disableSaveButton() ? 'List Name cannot be empty' : null}
              onClick={this.toggleSaveModal.bind(this)}>
              {this.state.saveButtonLabel}
            </Button>
            <a
              href='#none'
              className='link-grey'
              onClick={this.clearFilters.bind(this)}
              tabIndex={this.props.drawerOpened ? 0 : -1}>
              {this.state.cancelButtonLabel}
            </a>
          </div>
        </Col>

        <SaveFilterModal
          modalOpen={this.state.saveModalOpen}
          toggle={this.toggleSaveModal.bind(this)}
          saveHandler={this.saveHandler.bind(this)}
        />
        <MessagingComponent
          thrown={this.state.errorThrown}
          message={this.state.errorMessage}
          type={MESSAGE_TYPE.danger}
        />
      </Col>
    );
  }
}

function mapStoreToProps(state) {
  return {
    filter: state.filter.filter,
  };
}

export default connect(mapStoreToProps)(FilterSlideContainerComponent);
