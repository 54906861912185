import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

/**
 * Components
 */
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import PageContentHeader from 'CommonComponents/accountHeader';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import AuthenticationService from 'Services/authentication';

/**
 * Styles
 */
import 'RootComponents/account/components/updatePassword/styles/_index.scss';

class ChangePasswordComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      error: false,
      isLoading: false,
      message: '',
      success: false,
    };
  }

  async handleFormSubmit(event, errors, values) {
    this.setState({
      error: false,
      success: false,
      isLoading: true,
    });
    if (errors.length > 0) {
      return;
    }

    if (this.form) {
      this.form.reset();
    }

    try {
      await AuthenticationService.changePassword(values.old_password, values.new_password);
      this.setState({
        success: true,
        message: 'Password Successfully Updated',
        isLoading: false,
      });
    } catch (e) {
      console.log('error is here', e);
      let message = e.message;
      if (e.code === 'NotAuthorizedException') {
        message = 'Incorrect old password';
      }
      this.setState({
        error: true,
        message,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <Row>
        <PageContentHeader tab='tab_password' />
        <Col className='box-container change-password' md={{ size: 8, offset: 2 }}>
          <MessagingComponent
            thrown={this.state.error}
            message={this.state.message}
            type={MESSAGE_TYPE.danger}
          />
          <MessagingComponent
            thrown={this.state.success}
            message={this.state.message}
            type={MESSAGE_TYPE.success}
          />
          <Col md={8} className='account-box'>
            <h2 className='title'>Change Password</h2>
            <AvForm
              className='account-box__content'
              onSubmit={this.handleFormSubmit}
              ref={(r) => {
                this.form = r;
              }}>
              <Label className='account-box__input-label' for='old_password' aria-hidden='true'>
                Old Password
              </Label>
              <AvField
                type='password'
                name='old_password'
                id='old_password'
                placeholder='Enter your old password'
                validate={{
                  required: { value: true, errorMessage: 'Field is required' },
                }}
              />
              <Label className='account-box__input-label' for='new_password' aria-hidden='true'>
                New Password
              </Label>
              <AvField
                type='password'
                name='new_password'
                id='new_password'
                placeholder='Enter your new password'
                validate={{
                  required: { value: true, errorMessage: 'Field is required' },
                  minLength: {
                    value: 6,
                    errorMessage: 'Password must be at least 6 characters',
                  },
                }}
              />
              <Label className='account-box__input-label' for='confirm_password' aria-hidden='true'>
                Confirm New Password
              </Label>
              <AvField
                type='password'
                name='confirm_password'
                id='confirm_password'
                placeholder='Confirm your new password'
                validate={{
                  required: { value: true, errorMessage: 'Field is required' },
                  match: {
                    value: 'new_password',
                    errorMessage: 'New password must match',
                  },
                  minLength: {
                    value: 6,
                    errorMessage: 'Password must be at least 6 characters',
                  },
                }}
              />
              <Button className='account-box__submit' disabled={this.state.isLoading}>
                Update Password
              </Button>
            </AvForm>
          </Col>
        </Col>
      </Row>
    );
  }
}

export default ChangePasswordComponent;
