import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import Checkbox from 'CommonComponents/checkbox';
import FilterComponent from 'FilterComponents/common/generic';
import NestedDropdownFilterComponent from 'FilterComponents/common/nested_dropdown';
import TagListComponent from 'FilterComponents/common/tag_list';

import DivisionService from 'Services/divisions';
import store from 'Reducers/store';

import 'react-dropdown-tree-select/dist/styles.css';
import 'FilterComponents/specific/schoolWithNullable/styles/_index.scss';

class SchoolWithNullableFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.state = {
      divisions: [],
      isLoading: true,
      toggleClicked: false,
      isOpen: this.props.isOpen,
      withoutKey: this.props.withoutKey,
    };
  }

  componentDidMount() {
    DivisionService.getDivisions().then((divisions) => {
      const formattedDivision = this._formatForDropdown(divisions);

      this.setState({
        divisions: formattedDivision,
        isLoading: false,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let divisions = Object.assign([], nextState.divisions);
    let isOpen = false;

    const unsignedOnly = nextProps.values[nextState.withoutKey];
    const selectedSchools = nextProps.values.values;

    if (Array.isArray(selectedSchools)) {
      divisions = divisions.map((division) => {
        let selectedConferenceCount = 0;

        division.checked = selectedSchools.indexOf(division.id) > -1;

        division.children = division.children.map((conference) => {
          conference.checked =
            unsignedOnly === false &&
            (division.checked === true || selectedSchools.indexOf(conference.id) > -1);

          let selectedSchoolCount = 0;
          conference.children = conference.children.map((school) => {
            if (
              unsignedOnly === false &&
              (conference.checked === true || selectedSchools.indexOf(school.id) > -1)
            ) {
              school.checked = true;

              selectedSchoolCount += 1;
            } else {
              school.checked = false;
            }

            return school;
          });

          if (selectedSchoolCount === conference.children.length && unsignedOnly === false) {
            conference.checked = true;
          }

          selectedConferenceCount =
            conference.checked === true ? selectedConferenceCount + 1 : selectedConferenceCount;

          return conference;
        });

        division.checked = division.children.length === selectedConferenceCount;
        division.expanded =
          selectedConferenceCount > 0 && division.children.length !== selectedConferenceCount;

        return division;
      });
    }

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen =
        (Array.isArray(selectedSchools) && selectedSchools.length > 0) || unsignedOnly === true;
    } else {
      isOpen = nextState.isOpen;
    }

    if (
      nextProps.closeFilter === true &&
      nextState.toggleClicked === false &&
      nextProps.defaultOpen === true
    ) {
      isOpen = true;
    }

    return {
      divisions,
      isOpen,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  _formatForDropdown(divisions) {
    const final = [];

    divisions.forEach((division) => {
      const curDivision = {
        checked: false,
        children: [],
        id: division.id,
        label: division.name,
      };

      division.conferences.forEach((conference) => {
        const conferenceEntry = {
          checked: false,
          children: [],
          id: conference.id,
          label: conference.name,
        };

        conference.colleges.forEach((college) => {
          const collegeEntry = {
            checked: false,
            id: college.college_sport_id,
            label: college.name,
          };

          conferenceEntry.children.push(collegeEntry);
        });

        curDivision.children.push(conferenceEntry);
      });

      final.push(curDivision);
    });

    return final;
  }

  /**
   * Trigger off values that should be sent to store
   *
   * values auto-open which can't happen
   *
   * @param clicked
   * @param selected
   * @param parent
   */
  onChange(clicked, selected, parent) {
    let values = [];
    let unsignedVal = this.props.values[this.state.withoutKey];

    const oldSelections = this.props.values.values.length;

    selected.forEach((option) => {
      if (typeof option.children !== 'undefined') {
        const selectedChildIdsNew = this.getCheckedIdsOfChildren(option).flat(Infinity);

        values = values.concat(selectedChildIdsNew);
      } else {
        values.push(option.id);
      }
    });

    if (unsignedVal === true && oldSelections === 0 && values.length > 0) {
      unsignedVal = false;
    }

    const valuesObj = {
      values,
    };

    valuesObj[this.state.withoutKey] = unsignedVal;

    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        active: values.length > 0,
        activity_request_level,
        type: id,
        values: valuesObj,
      },
    });
  }

  changeUnsignedOrSigned() {
    let ids = [];
    const newVal = !this.props.values[this.state.withoutKey];

    this.state.divisions.forEach((option) => {
      if (typeof option.children !== 'undefined') {
        const selectedChildIdsNew = this.getCheckedIdsOfChildren(option).flat(Infinity);

        ids = ids.concat(selectedChildIdsNew);
      } else {
        ids.push(option.id);
      }
    });

    if (ids.length > 0 && newVal === true) {
      ids = [];
    }

    const values = {
      values: ids,
    };

    values[this.state.withoutKey] = newVal;

    const { id, filter_action_type, activity_request_level } = this.props;

    store.dispatch({
      type: filter_action_type,
      filter: {
        activity_request_level,
        type: id,
        values,
      },
    });
  }

  getCheckedIdsOfChildren(option) {
    if (Array.isArray(option.children)) {
      return option.children.map((child) => {
        return this.getCheckedIdsOfChildren(child);
      });
    }
    if (option.checked === true) {
      return [option.id];
    }
    return [];
  }

  renderCheckbox() {
    const { withoutKey } = this.state;
    const { values } = this.props;
    const checkboxLabel = `${withoutKey.charAt(0).toUpperCase()}${withoutKey.slice(1)}`;

    return (
      <div className='nullable'>
        <Checkbox
          id={withoutKey}
          name={withoutKey}
          label={checkboxLabel}
          checked={values[withoutKey]}
          onChange={this.changeUnsignedOrSigned.bind(this)}
        />
      </div>
    );
  }

  render() {
    const { id, label, active, collapsible, addActiveCheckbox, classes } = this.props;
    const { divisions, isLoading, isOpen } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <FilterComponent
        id={id}
        active={active}
        addActiveCheckbox={addActiveCheckbox}
        classes={classes}
        collapsible={collapsible}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {this.renderCheckbox()}
        <NestedDropdownFilterComponent
          data={divisions}
          onChange={this.onChange.bind(this)}
          placeholder='School, Conference, or Division'
          parent={true}
          root={true}
          columns={1}
          rootExpanded={isOpen}
          depth={0}
          inputId={label}
          label={id}
        />
        <TagListComponent data={divisions} onChange={this.onChange.bind(this)} />
      </FilterComponent>
    );
  }
}

SchoolWithNullableFilterComponent.defaultProps = {
  isOpen: false,
  active: false,
  collapsible: false,
  closeFilter: false,
  addActiveCheckbox: false,
  label: 'Label',
  classes: '',
  activity_request_level: '',
  filter_action_type: 'FILTER_UPDATED',
};

SchoolWithNullableFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  withoutKey: PropTypes.string.isRequired,
  values: PropTypes.object,
  isOpen: PropTypes.bool,
  active: PropTypes.bool,
  collapsible: PropTypes.bool,
  closeFilter: PropTypes.bool,
  addActiveCheckbox: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  activity_request_level: PropTypes.string,
  filter_action_type: PropTypes.string,
};

export default SchoolWithNullableFilterComponent;
