import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import IconComponent from 'CommonComponents/icon';
import Checkbox from 'CommonComponents/checkbox';

import MinMaxRequest from 'Requests/min_max';
import store from 'Reducers/store';

class MetricFilterComponent extends CommonComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  checkVerified() {
    const values = Object.assign({}, this.props.value);

    values.allowNull = false;
    values.verified = !values.verified;

    store.dispatch({
      type: 'FILTER_UPDATED',
      filter: {
        type: this.props.id,
        values,
      },
    });
  }

  checkAllowNull() {
    const values = Object.assign({}, this.props.value);

    values.allowNull = !values.allowNull;

    store.dispatch({
      type: 'FILTER_UPDATED',
      filter: {
        type: this.props.id,
        values,
      },
    });
  }

  removeSelf() {
    store.dispatch({
      type: 'DELETE_SPECIFIC_FILTER',
      filter: {
        type: this.props.id,
      },
    });
    this.props.onChange({
      id: this.props.id,
    });
  }

  onChange(event) {
    const values = Object.assign(new MinMaxRequest(), this.props.value);

    if (this.props.id !== 'gpa') {
      values.allowNull = false;
    }

    if (this.props.unit === 'feet-inches') {
      const inches = this.secondaryRef.current.value;
      const feet = this.primaryRef.current.value;
      if (feet !== '' || inches !== '') {
        const parsedFeet = isNaN(parseFloat(feet * 12)) ? 0 : parseFloat(feet * 12);
        const parsedInches = isNaN(parseFloat(inches)) ? 0 : parseFloat(inches);
        values.min = parsedFeet + parsedInches;
      } else {
        values.max = -1;
        values.min = -1;

        values.allowNull = true;
      }
    } else if (this.props.unit === 'minutes') {
      const seconds = this.secondaryRef.current.value;
      const minutes = this.primaryRef.current.value;
      if (minutes !== '' || seconds !== '') {
        const parsedMinutes = isNaN(parseFloat(minutes * 60)) ? 0 : parseFloat(minutes * 60);
        const parsedSeconds = isNaN(parseFloat(seconds)) ? 0 : parseFloat(seconds);
        values.max = parsedMinutes + parsedSeconds;
      } else {
        values.max = -1;
        values.min = -1;

        values.allowNull = true;
      }
    } else {
      if (this.props.lessThan === true && event.currentTarget.value !== '') {
        values.max = parseFloat(event.currentTarget.value);
      } else if (event.currentTarget.value !== '') {
        values.min = parseFloat(event.currentTarget.value);
      } else {
        values.max = -1;
        values.min = -1;

        values.allowNull = true;
      }
    }

    store.dispatch({
      type: 'FILTER_UPDATED',
      filter: {
        type: this.props.id,
        values,
      },
    });
  }

  getValue() {
    const { lessThan, value } = this.props;
    const curVal = lessThan ? parseFloat(value.max) : parseFloat(value.min);

    return curVal > -1 ? curVal : '';
  }

  renderInput() {
    const lessThanClass = this.props.lessThan ? 'less-than' : 'greater-than';
    if (['feet-inches', 'minutes'].includes(this.props.unit)) {
      const conversionUnitMap = {
        'feet-inches': 12,
        minutes: 60,
      };
      const valueLabels = {
        'feet-inches': {
          small: 'in',
          large: 'ft',
        },
        minutes: {
          small: 's',
          large: 'm',
        },
      };
      const conversionUnit = conversionUnitMap[this.props.unit];
      const smallLabel = valueLabels[this.props.unit].small;
      const largeLabel = valueLabels[this.props.unit].large;
      const value = this.getValue();
      const largeValue = value !== '' ? Math.floor(value / conversionUnit) : '';
      const smallValue = value !== '' ? value % conversionUnit : '';
      if (this.primaryRef === undefined || this.secondaryRef === undefined) {
        this.primaryRef = React.createRef();
        this.secondaryRef = React.createRef();
      }
      return (
        <label
          className={`equivalence-button ${lessThanClass}`}
          htmlFor={`metric-input-${this.props.id}-feet`}>
          <input
            className={`metric-input-${this.props.id} feet`}
            id={`metric-input-${this.props.id}-feet`}
            onChange={this.onChange.bind(this)}
            type='number'
            value={largeValue === 0 ? '' : largeValue}
            ref={this.primaryRef}
          />
          <span>{largeLabel}</span>
          <input
            className={`metric-input-${this.props.id} inches`}
            id={`metric-input-${this.props.id}-inches`}
            onChange={this.onChange.bind(this)}
            type='number'
            value={smallValue === 0 ? '' : smallValue}
            ref={this.secondaryRef}
          />
          <span>{smallLabel}</span>
        </label>
      );
    }

    return (
      <label
        className={`equivalence-button ${lessThanClass}`}
        htmlFor={`metric-input-${this.props.id}`}>
        <input
          className={`metric-input-${this.props.id}`}
          id={`metric-input-${this.props.id}`}
          onChange={this.onChange.bind(this)}
          ref={(ref) => {
            this.input = ref;
          }}
          type='number'
          value={this.getValue()}
        />
        <span className=''>{this.props.unit}</span>
      </label>
    );
  }

  render() {
    const { id, value, label, verified_checkbox, allow_null_checkbox, removable } = this.props;

    return (
      <div className={`metric ${id}`}>
        {verified_checkbox && (
          <div className='verified'>
            <Checkbox
              id={`cb-${id}`}
              name={`cb-${id}`}
              onChange={this.checkVerified.bind(this)}
              checked={value.verified === true}
              label={`Verified ${label}`}
            />
          </div>
        )}

        {!verified_checkbox && (
          <div className='no-verified'>
            <label htmlFor={`cb-${id}`}>{label}</label>
          </div>
        )}

        {allow_null_checkbox && (
          <div className='verified'>
            <Checkbox
              id={`cb-${id}`}
              name={`cb-${id}`}
              onChange={this.checkAllowNull.bind(this)}
              checked={value.allowNull === true}
              label={`No ${label}`}
            />
          </div>
        )}

        {removable && (
          <IconComponent
            as_button={true}
            color='close'
            icon='x fas fa-times'
            label='Close'
            onClick={this.removeSelf.bind(this)}
            onKeyUp={this.removeSelf.bind(this)}
            tabIndex={-1}
          />
        )}
        <div className='data'>
          <div className='no-padding weight-container measureable-container'>
            {this.renderInput()}
          </div>
        </div>
      </div>
    );
  }
}

MetricFilterComponent.defaultProps = {
  verified_checkbox: false,
  allow_null_checkbox: false,
  removable: false,
  lessThan: false,
  label: 'Metric',
};

MetricFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  verified_checkbox: PropTypes.bool,
  allow_null_checkbox: PropTypes.bool,
  removable: PropTypes.bool,
  label: PropTypes.string,
  lessThan: PropTypes.bool,
};

export default MetricFilterComponent;
