import React from 'react';

function TimelineLegend({ legendHeader, children }) {
  return (
    <>
      <div className='header'>
        <h3>{legendHeader || <span className='invisible'>holder</span>}</h3>
      </div>
      {children}
    </>
  );
}

export default TimelineLegend;
