import axios from 'axios';

class StatisticsService {
  constructor() {
    this.cancelTokens = [];
  }

  getStatsForPlayer(id, format, is_tnf) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/stats/?format=${format}&is_tnf=${is_tnf === true}`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
      cancelToken: this.cancelTokens[tokenLength - 1].token,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw Error(error.message);
        }

        throw JSON.parse(JSON.stringify(error)).response.data;
      });
  }

  getStatsForPlayerAsPromise(id, format) {
    this.cancelTokens.push(axios.CancelToken.source());
    const tokenLength = this.cancelTokens.length;
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.API_URL}players/${id}/stats/?format=${format}`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
        cancelToken: this.cancelTokens[tokenLength - 1].token,
      }).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  cancelCall(message) {
    this.cancelTokens.forEach((token) => {
      token.cancel(message);
    });
  }
}

export default new StatisticsService();
