import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';
import classNames from 'classnames';

import FilterComponent from 'FilterComponents/common/generic';
import CommonComponent from 'CommonComponents/generic';
import PillsSearchResultsComponent from 'FilterComponents/common/typeahead/components/pills';
import TypeaheadSearchResultsComponent from 'FilterComponents/common/typeahead/components/results';
import store from 'Reducers/store';
import { STATUSES } from 'Constants/loadingStatuses';

import 'react-dropdown-tree-select/dist/styles.css';
import './styles/_index.scss';

class TypeaheadFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    this.search = debounce(this.search, 350);

    this.state = {
      data: [],
      selected: [],
      isOpen: true,
      status: STATUSES.idle,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (typeof nextProps.values !== 'undefined') {
      nextState.selected = nextProps.values;
    } else {
      nextState.selected = [];
    }

    return nextState;
  }

  componentDidMount() {
    if (Boolean(this.props.values?.length)) {
      this.setState({
        selected: this.props.values,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  toggle = (event) => {
    if (event.relatedTarget?.className === 'result') {
      event.preventDefault();

      return;
    }
    this.props.onValueChange('');
    this.setState({ data: [], status: STATUSES.idle });
  };

  triggerSearch = ({ target: { value } }) => {
    const { onValueChange, onCancelCall } = this.props;

    this.search.cancel();

    onCancelCall('Restarting search');
    onValueChange(value);
    this.search();
  };

  updateResults = (results) => {
    const { selected } = this.state;
    return results.map((result) => {
      if (selected.length) {
        const index = selected.findIndex((single) => {
          return result.id === single.id;
        });

        if (index > -1) {
          result.checked = true;
        }
      }

      return result;
    });
  };

  search = () => {
    const { searchCall, searchValue } = this.props;

    if (!searchCall) return;

    this.setState({ status: STATUSES.loading });

    searchCall(searchValue)
      .then((results) => {
        const updatedResults = this.updateResults(results);

        this.setState({
          status: STATUSES.completed,
          data: updatedResults,
        });
      })
      .catch(() => {
        this.setState({
          status: STATUSES.errored,
          data: [],
        });
      });
  };

  selectItem = (item) => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    const selected = JSON.parse(JSON.stringify(this.state.selected));

    const parsed = data.map((single) => {
      if (single.id === item.id) {
        single.checked = !single.checked;

        const sIndex = selected.findIndex((sel) => {
          return sel.id === item.id;
        });

        if (sIndex === -1) {
          selected.push(item);
        }
      }

      if (single.checked === false) {
        const index = selected.findIndex((selection) => {
          return selection.id === item.id && single.id === selection.id;
        });

        delete selected[index];
      }

      return single;
    });

    this.setState(
      {
        data: parsed,
        selected: selected.filter((el) => {
          return el !== null;
        }),
      },
      () => {
        store.dispatch({
          type: this.props.filter_action_type,
          filter: {
            activity_request_level: this.props.activity_request_level,
            type: this.props.id,
            values: selected,
          },
        });
      }
    );
  };

  removeItem = (item) => {
    let selected = JSON.parse(JSON.stringify(this.state.selected));

    const index = selected.findIndex((single) => {
      return single.id === item.id;
    });

    delete selected[index];

    selected = selected.filter((el) => {
      return el != null;
    });

    this.setState(
      {
        selected,
      },
      () => {
        store.dispatch({
          type: this.props.filter_action_type,
          filter: {
            activity_request_level: this.props.activity_request_level,
            type: this.props.id,
            values: selected,
          },
        });
      }
    );
  };

  render() {
    const { id, searchValue, values, label, placeholder, classes, searchingMessage } = this.props;
    const { data, selected, isOpen, status } = this.state;

    const inputClassNames = classNames({
      expanded: true,
      'search-field': true,
    });

    const activeId = label.toLowerCase().replace(/([^a-z])/g, '_');
    return (
      <FilterComponent
        id={id}
        classes={classes}
        collapsible={false}
        isOpen={isOpen}
        key={id}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        <div className='navigation-search' onBlur={this.toggle}>
          <div className='search-area'>
            <input
              autoComplete='off'
              className={inputClassNames}
              id={`active-${activeId}`}
              name='search'
              onChange={this.triggerSearch}
              onFocus={this.toggle}
              placeholder={placeholder}
              ref={(input) => {
                this.nameInput = input;
              }}
              type='text'
              value={searchValue}
            />
          </div>
          <TypeaheadSearchResultsComponent
            data={data}
            isLoading={status === STATUSES.loading}
            isCompleted={status === STATUSES.completed}
            isIdle={status === STATUSES.idle}
            isErrored={status === STATUSES.errored}
            searchValue={searchValue}
            searchingMessage={searchingMessage}
            selectItem={this.selectItem}
            values={values}
          />
          <PillsSearchResultsComponent data={selected} removeItem={this.removeItem} />
        </div>
      </FilterComponent>
    );
  }
}

TypeaheadFilterComponent.defaultProps = {
  classes: '',
  collapsible: true,
  label: 'Filter Search',
  placeholder: 'Enter a value',
  searchingMessage: 'Searching...',
  filter_action_type: 'FILTER_UPDATED',
  activity_request_level: '',
  values: [],
  searchCall: null,
};

TypeaheadFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  onCancelCall: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  searchCall: PropTypes.func,
  classes: PropTypes.string,
  collapsible: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  searchingMessage: PropTypes.string,
  filter_action_type: PropTypes.string,
  activity_request_level: PropTypes.string,
};

export default TypeaheadFilterComponent;
