import React from 'react';
import PropTypes from 'prop-types';
import defaultTwitterAvatar from 'Assets/images/fallbackImages/defaultTwitterAvatar.png';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';

export const tweetTypes = {
  follower: {
    icons: 'follower fa-level-down-alt fa-rotate-90',
    header: (str) => `Followed by ${str}`,
  },
  following: {
    icons: 'following fa-level-up-alt fa-rotate-90',
    header: (str) => `Following ${str}`,
  },
  like: {
    icons: 'like fa-heart',
    header: (str) => `Liked by ${str}`,
  },
  mention: {
    icons: 'mention fa-at',
    header: (str) => `Mentioned by ${str}`,
  },
  retweet: {
    icons: 'retweet fa-retweet',
    header: (str) => `Retweeted by ${str}`,
  },
};

function TweetLabelPerson({ name, handle, image_url = '' }) {
  return (
    <div className='person'>
      <div className='image'>
        <img alt={name} onError={(e) => (e.target.src = defaultTwitterAvatar)} src={image_url} />
      </div>
      <div className='info'>
        <p className='name'>{name}</p>
        <p>&#64;{handle}</p>
      </div>
    </div>
  );
}

TweetLabelPerson.propTypes = {
  name: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  image_url: PropTypes.string,
};

function TimelineTweetLabel({ playerName, tweet }) {
  const { id, date, text, type, name, image_url, handle } = tweet;

  const utcFormattedDate = new DateTimeService().formatUTCDate(
    new Date(date),
    DATE_DEFAULTS.format.twitter
  );

  const header = tweetTypes[type].header(playerName);
  const icons = tweetTypes[type].icons;

  return (
    <div className='twitter' key={id}>
      <div className='twitter-header'>
        <h2>
          <i className={`icon white fas ${icons}`} /> {header}
        </h2>
      </div>
      <div className='twitter-body'>
        <TweetLabelPerson image_url={image_url} name={name} handle={handle} />
        <div className='interaction'>
          {text && <p className='tweet'>{text}</p>}
          <p className='date'>{utcFormattedDate}</p>
        </div>
      </div>
    </div>
  );
}

TimelineTweetLabel.propTypes = {
  tweet: PropTypes.object.isRequired,
  playerName: PropTypes.string.isRequired,
};

export default TimelineTweetLabel;
