import axios from 'axios';

class ContactsService {
  getContactsForPlayer(id) {
    return axios({
      method: 'GET',
      url: `${process.env.API_URL}players/${id}/contacts/`,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  getContactsForPlayerAsPromise(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${process.env.API_URL}players/${id}/contacts/`,
        headers: {
          Authorization: window.localStorage.getItem('access_token'),
          'X-Org': window.localStorage.getItem('organization_id'),
          'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
          'X-Username': window.localStorage.getItem('username'),
        },
      }).then(
        (response) => {
          resolve(response.data.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default new ContactsService();
