import React from 'react';
import {
  DropdownItem,
  UncontrolledDropdown,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavbarToggler,
  DropdownToggle,
  Col,
  Row,
  NavLink,
  DropdownMenu,
  CarouselItem,
  Carousel,
  CarouselControl,
  Collapse,
} from 'reactstrap';

/**
 * Components
 */
import externalLinkScreenReaderMessage from '../a11y/screenReader/externalLink';

/**
 * Assets
 */
import zcruitLogo from 'Assets/images/zcruit-by-ncsa-logo.png';
import analyzeIcon from 'Assets/images/LandingPage/analyze-icon.png';
import discoverIcon from 'Assets/images/LandingPage/discover-icon.png';
import trackIcon from 'Assets/images/LandingPage/track-icon.png';
import devicesGraphic from 'Assets/images/LandingPage/devices-img.png';
import nbcLogo from 'Assets/images/LandingPage/nbcnews.png';
import chicagoTribuneLogo from 'Assets/images/LandingPage/chicagotribune.png';
import usaTodayLogo from 'Assets/images/LandingPage/usatoday.png';
import apLogo from 'Assets/images/LandingPage/AssociatedPress.png';
import coryImage from 'Assets/images/LandingPage/CoryNicol.png';
import jordanImage from 'Assets/images/LandingPage/jordanHeighway.png';
import taylorImage from 'Assets/images/LandingPage/taylorBuie.png';
import eliImage from 'Assets/images/LandingPage/eliMandel.png';
import aarynImage from 'Assets/images/LandingPage/aarynKearney.png';

/**
 * Parent
 */
import ZcruitComponent from '../common/zcruit';

/**
 * Styles
 */
import './styles/_index.scss';
import { opensInNewTab } from '../a11y/constants';

export const TRUSTED_BY_MSG = `Trusted by 100+ D1 Football Programs`;
class LandingPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.skipContentAnchorLink = React.createRef();
    this.content = React.createRef();

    this.state = {
      activeIndex: 0,
      isOpen: false,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js?pre=1';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5952291',
          formId: '61cfe720-193b-47b6-8438-226ed091086c',
          target: '#hubspotform',
        });
      }
    };
  }

  onSelect(id) {
    const element = document.getElementById(id);
    const rect = element.getBoundingClientRect();

    let offset = rect.top;
    let pos = document.documentElement.scrollTop || document.body.scrollTop;

    const inc = offset < 0 ? -15 : 15;

    offset = Math.abs(offset);
    const interval = setInterval(() => {
      document.scrollTo(0, pos);

      pos += inc;

      offset -= Math.abs(inc);

      if (offset <= -10) {
        clearInterval(interval);
      }
    }, 3);
  }

  toggle() {
    const isOpen = !this.state.isOpen;

    this.setState({
      isOpen,
    });
  }

  next() {
    const nextIndex = this.state.activeIndex === 8 - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const nextIndex = this.state.activeIndex === 0 ? 8 - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  processCarouselItems() {
    const carouselItems = [
      <CarouselItem key='A'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Wow – this is how you look like a mastermind. It’s like having eyes
            everywhere.&rdquo;
          </p>
          <p className='testimonial-name'>– ACC Assistant Director of Player Personnel</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='B'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Zmail is invaluable to me. I look forward to opening my inbox each
            morning!&rdquo;
          </p>
          <p className='testimonial-name'>– PAC-12 Director of Recruiting</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='C'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;This makes our lives ten times easier. Coaches are fired up about it. &rdquo;
          </p>
          <p className='testimonial-name'>– Big Ten Director of Recruiting Operations</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='D'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Zcruit has really helped to streamline our processes and we all look forward to
            those emails every morning!&rdquo;
          </p>
          <p className='testimonial-name'>– AAC Director of Player Personnel</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='E'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Daily emails have been saving me so much time over the last few months!&rdquo;
          </p>
          <p className='testimonial-name'>– Big 12 Director of Recruiting</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='F'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Every school should have Zmail. I saw the way our coaches lit up when we showed
            it to them. They love it. We use it every day.&rdquo;
          </p>
          <p className='testimonial-name'>– SEC Director of Recruiting</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='G'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;Seems to me like you’re doing something unique and different in a market full of
            copy cats. I’m fired up to be a part of what you have going&rdquo;
          </p>
          <p className='testimonial-name'>– Big 12 Director of Player Personnel</p>
        </div>
      </CarouselItem>,
      <CarouselItem key='H'>
        <div className='testimonial-item-inner'>
          <p className='testimonial-text'>
            &ldquo;I bet you’ll be at almost every school by next year. This is really cool stuff
            that no one else is doing.&rdquo;
          </p>
          <p className='testimonial-name'>– C-USA Director of Recruiting</p>
        </div>
      </CarouselItem>,
    ];

    return carouselItems;
  }

  handleOnClickForSkipNav() {
    this.content.current.focus();
  }

  render() {
    const socialMediaSVGs = (
      <svg className='svg-definitions'>
        <defs>
          <symbol id='icon-twitter' viewBox='0 0 26 28'>
            <title>twitter - {opensInNewTab}</title>
            <path d='M25.312 6.375c-0.688 1-1.547 1.891-2.531 2.609 0.016 0.219 0.016 0.438 0.016 0.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-0.828-7.75-2.266 0.406 0.047 0.797 0.063 1.219 0.063 2.359 0 4.531-0.797 6.266-2.156-2.219-0.047-4.078-1.5-4.719-3.5 0.313 0.047 0.625 0.078 0.953 0.078 0.453 0 0.906-0.063 1.328-0.172-2.312-0.469-4.047-2.5-4.047-4.953v-0.063c0.672 0.375 1.453 0.609 2.281 0.641-1.359-0.906-2.25-2.453-2.25-4.203 0-0.938 0.25-1.797 0.688-2.547 2.484 3.062 6.219 5.063 10.406 5.281-0.078-0.375-0.125-0.766-0.125-1.156 0-2.781 2.25-5.047 5.047-5.047 1.453 0 2.766 0.609 3.687 1.594 1.141-0.219 2.234-0.641 3.203-1.219-0.375 1.172-1.172 2.156-2.219 2.781 1.016-0.109 2-0.391 2.906-0.781z' />
          </symbol>
          <symbol id='icon-facebook' viewBox='0 0 16 28'>
            <title>facebook - {opensInNewTab}</title>
            <path d='M14.984 0.187v4.125h-2.453c-1.922 0-2.281 0.922-2.281 2.25v2.953h4.578l-0.609 4.625h-3.969v11.859h-4.781v-11.859h-3.984v-4.625h3.984v-3.406c0-3.953 2.422-6.109 5.953-6.109 1.687 0 3.141 0.125 3.563 0.187z' />
          </symbol>
          <symbol id='icon-linkedin' viewBox='0 0 24 28'>
            <title>linkedin - {opensInNewTab}</title>
            <path d='M5.453 9.766v15.484h-5.156v-15.484h5.156zM5.781 4.984c0.016 1.484-1.109 2.672-2.906 2.672v0h-0.031c-1.734 0-2.844-1.188-2.844-2.672 0-1.516 1.156-2.672 2.906-2.672 1.766 0 2.859 1.156 2.875 2.672zM24 16.375v8.875h-5.141v-8.281c0-2.078-0.75-3.5-2.609-3.5-1.422 0-2.266 0.953-2.641 1.875-0.125 0.344-0.172 0.797-0.172 1.266v8.641h-5.141c0.063-14.031 0-15.484 0-15.484h5.141v2.25h-0.031c0.672-1.062 1.891-2.609 4.672-2.609 3.391 0 5.922 2.219 5.922 6.969z' />
          </symbol>
        </defs>
      </svg>
    );

    const lineFiller = (
      <div className='line-filler'>
        <svg height='10' width='100'>
          <path id='' d='M 0 5 l 100 0 z' stroke='#853EB1' strokeWidth='3' fill='#853EB1' />
        </svg>
      </div>
    );

    const navInstance = (
      <Navbar className='navbar-static-top' id='loginNav' expand='lg' dark default>
        <NavbarBrand href='/' aria-label='Zcruit Logo'>
          <div className='nav-logo zcruit-by-ncsa-logo' alt='zcruit logo' />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle.bind(this)} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav id='main-nav' className='pull-right'>
            <NavItem>
              <NavLink href='#products'>ABOUT</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#testimonials'>TESTIMONIALS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#press'>PRESS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#team'>OUR TEAM</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#contact'>CONTACT</NavLink>
            </NavItem>
            <NavItem className='login-item-nodrop login-title'>
              <span className='mobile-nav-break' />
              <i className='fal fa-lock-alt login-lock-image' alt='login-logo' />
              LOGIN
            </NavItem>
            <NavItem>
              <NavLink href='/login' className='login-item-nodrop zcruit-login'>
                Zcruit
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={`${process.env.ZCRUIT_ZMAIL_URL}`} className='login-item-nodrop'>
                Zmail
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar className='login-dropdown' id='login-dropdown'>
              <DropdownToggle caret={false} id='login-dropdown-button'>
                <i className='fal fa-lock-alt login-lock-image' alt='login-logo' />
                LOGIN
              </DropdownToggle>
              <DropdownMenu id='login-dropdown-menu'>
                <DropdownItem href='/login' className='dropdown-item' active>
                  Zcruit
                </DropdownItem>
                <DropdownItem
                  href={`${process.env.ZCRUIT_ZMAIL_URL}`}
                  className='dropdown-item'
                  active>
                  Zmail
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );

    const homeSection = (
      <header className='landing-page-header'>
        <section id='home'>
          <div className='container'>
            <Col md={{ size: 6, offset: 6 }} className='intro-text text-left'>
              <div className='intro-heading'>
                <h1 className='intro-heading_top'>RECRUIT SMARTER.</h1>
                <br />
                <p className='intro-heading_bottom'>PERFORM BETTER.</p>
              </div>
              <p className='intro-text_summary'>
                Zcruit Revolutionizes Recruiting through Data, Analytics, and News Alerts
              </p>
            </Col>
          </div>
        </section>
      </header>
    );

    const featureSection = (
      <section id='features'>
        <div className='container'>
          <div className='row text-center'>
            <Col md='4'>
              <div className='feature-heading'>
                <img className='feature-heading_img' src={discoverIcon} alt='' />
                <h2 className='feature-heading_text'>DISCOVER</h2>
              </div>
              <p className='feature-text'>
                Find the right players for your school at the start of your identification process
              </p>
            </Col>
            <Col md='4'>
              <div className='feature-heading'>
                <img className='feature-heading_img' src={analyzeIcon} alt='' />
                <h2 className='feature-heading_text'>ANALYZE</h2>
              </div>
              <p className='feature-text'>
                Use data to gauge where you stand with the recruits on your board
              </p>
            </Col>
            <Col md='4'>
              <div className='feature-heading'>
                <img className='feature-heading_img' src={trackIcon} alt='' />
                <h2 className='feature-heading_text'>TRACK</h2>
              </div>
              <p className='feature-text'>
                Receive custom daily email alerts on all relevant recruiting activity
              </p>
            </Col>
          </div>
        </div>
      </section>
    );

    const productSection = (
      <section id='products'>
        <div>
          <div className='container'>
            <div className='row'>
              <Col md='6'>
                <h2 className='product-header'>The Solution That’s Right for Your Team</h2>
                <p className='product-text'>
                  Zcruit has solutions that fit any team’s needs. Contact us to find the right
                  option for you.
                </p>

                <img
                  className='products-devices mobile-size'
                  src={devicesGraphic}
                  alt='Zcruit and Zmail Demonstration Mockups'
                />

                <ul className='product-list'>
                  <li className='product-list_item'>
                    Zcruit is the full database platform that provides insights and alerts on
                    recruits’ offers, visits, and Twitter interactions. Easily find the right
                    players for your program and track every detail of their recruitment from start
                    to finish.Includes tools like Zmail and Twitter Insights.
                  </li>
                  <li className='product-list_item'>
                    Zmail is the tool that allows coaches and recruiters to track the recruiting
                    activity of individual prospects, rival schools, geographic regions, and
                    position groups in a highly customized manner. Zmail keeps coaching staffs
                    informed while saving time and resources.
                  </li>
                </ul>
              </Col>
              <Col md='6'>
                <img
                  className='products-devices full-size'
                  src={devicesGraphic}
                  alt='Zcruit and Zmail Demonstration Mockups'
                />
              </Col>
            </div>
          </div>
        </div>
      </section>
    );

    const testimonialsSection = (
      <section id='testimonials'>
        <div className='container'>
          <div className='row'>
            <Col md='5' className='testimonials-text-container'>
              <h2 className='testimonial-header'>{TRUSTED_BY_MSG}</h2>
              <Carousel
                next={this.next.bind(this)}
                previous={this.previous.bind(this)}
                activeIndex={this.state.activeIndex}
                ride='carousel'>
                {true &&
                  this.processCarouselItems().map((item) => {
                    return item;
                  })}
                <CarouselControl direction='next' onClickHandler={this.next.bind(this)} />
                <CarouselControl direction='prev' onClickHandler={this.previous.bind(this)} />
              </Carousel>
              <a href='#contact' className='signup-link'>
                <button type='button'>SIGN UP</button>
              </a>
            </Col>
          </div>
        </div>
      </section>
    );

    const pressSection = (
      <section id='press'>
        <h2>PRESS</h2>
        {lineFiller}
        <div className='container'>
          <a
            className='nbcnews'
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.nbcnews.com/feature/college-game-plan/college-football-recruiting-can-be-inefficient-zcruit-wants-change-n732856'>
            <img src={nbcLogo} alt='NBC News Logo' />
          </a>
          <a
            className='chicagotribune'
            target='_blank'
            rel='noopener noreferrer'
            href='http://www.chicagotribune.com/g00/suburbs/oak-park/sports/ct-evr-college-football-recruiting-zcruit-tl-1221-20171219-story.html?i10c.encReferrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8%3D&i10c.ua=1&i10c.dv=14'>
            <img src={chicagoTribuneLogo} alt='Chicago Tribune Logo' />
          </a>
          <a
            className='usatoday'
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.usatoday.com/story/sports/ncaaf/2017/01/23/analytical-approach-inexact-science-recruiting/96963326'>
            <img src={usaTodayLogo} alt='Usa Today Logo' />
          </a>
          <a
            className='associatedpress'
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.usatoday.com/story/sports/ncaaf/2017/08/05/numbers-game-analytics-spreading-through-college-football/104310592/'>
            <img src={apLogo} alt='Associated Press Logo' />
          </a>
        </div>
      </section>
    );

    const teamSection = (
      <section id='team'>
        <h2>OUR TEAM</h2>
        {lineFiller}
        <div className='container'>
          <div className='team-member'>
            <img src={coryImage} alt='Cory Nicol' />
            <div className='member-details'>
              <p className='member-name'>Cory Nicol</p>
              <p className='member-role'>Director of Zcruit</p>
            </div>
          </div>
          <div className='team-member'>
            <img src={jordanImage} alt='Jordan Heighway' />
            <div className='member-details'>
              <p className='member-name'>Jordan Heighway</p>
              <p className='member-role'>VP of Business Development</p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='team-member'>
            <img src={eliImage} alt='Eli Mandel' />
            <div className='member-details'>
              <p className='member-name'>Eli Mandel</p>
              <p className='member-role'>Account Manager</p>
            </div>
          </div>
          <div className='team-member'>
            <img src={aarynImage} alt='Aaryn Kearney' />
            <div className='member-details'>
              <p className='member-name'>Aaryn Kearney</p>
              <p className='member-role'>Account Manager</p>
            </div>
          </div>
          <div className='team-member'>
            <img src={taylorImage} alt='Taylor Buie' />
            <div className='member-details'>
              <p className='member-name'>Taylor Buie</p>
              <p className='member-role'>Data Coordinator</p>
            </div>
          </div>
        </div>
      </section>
    );

    const contactSection = (
      <section id='contact'>
        <h2>CONTACT</h2>
        {lineFiller}
        <Col md={{ size: 4, offset: 4 }} xs={{ size: 12, offset: 0 }} className='contact-details'>
          Get in touch for more information, to view an in-depth demo, or to signup
          <div className='container'>
            <div className='pipedriveWebForms' id='hubspotform' />
          </div>
        </Col>
      </section>
    );

    const connectSection = (
      <section id='connect'>
        <h2>CONNECT WITH US</h2>
        <div className='container'>
          <a
            aria-label={`Zcruit Twitter, ${opensInNewTab}`}
            target='_blank'
            rel='noopener noreferrer'
            href='https://twitter.com/ZcruitFootball'>
            <svg className='icon icon-twitter'>
              <use xlinkHref='#icon-twitter' />
            </svg>
          </a>
          <a
            aria-label={`Zcruit LinkedIn, ${opensInNewTab}`}
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.linkedin.com/company/zcruit'>
            <svg className='icon icon-linkedin'>
              <use xlinkHref='#icon-linkedin' />
            </svg>
          </a>
          <a
            aria-label={`Zcruit Facebook, ${opensInNewTab}`}
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.facebook.com/zcruit/'>
            <svg className='icon icon-facebook'>
              <use xlinkHref='#icon-facebook' />
            </svg>
          </a>
        </div>
      </section>
    );

    const footerInstance = (
      <footer>
        <div className='container'>
          <Row className='copy'>
            <Col md={{ size: 6, offset: 3 }}>
              <img
                className='zcruit-footer-logo'
                src={zcruitLogo}
                alt='Zcruit Logo White Variant'
              />
              <div className='zcruit-footer-policy-links'>
                <div className='zcruit-footer-policy-container'>
                  <a
                    href='https://www.imgacademy.com/privacy-policy?website=zcruit.com'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Privacy Policy
                    {externalLinkScreenReaderMessage(true)}
                  </a>
                </div>
                <div className='zcruit-footer-policy-container'>
                  <a
                    href='https://www.imgacademy.com/cookie-notice?website=zcruit.com'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Cookie Notice
                    {externalLinkScreenReaderMessage(true)}
                  </a>
                </div>
                <div className='zcruit-footer-policy-container'>
                  <a
                    href='/#'
                    onClick={() => {
                      window.semaphore.push(['showPreferences']);
                      return false;
                    }}>
                    Manage privacy settings
                    {externalLinkScreenReaderMessage(true)}
                  </a>
                </div>
                <div className='zcruit-footer-policy-container'>
                  <a
                    href='https://www.imgacademy.com/california-privacy-rights?website=zcruit.com'
                    target='_blank'
                    rel='noopener noreferrer'>
                    California Privacy Rights
                    {externalLinkScreenReaderMessage(true)}
                  </a>
                </div>
                <div className='zcruit-footer-policy-container'>
                  <a
                    href='https://www.zcruit.com/sitemap.xml'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Sitemap
                    {externalLinkScreenReaderMessage(true)}
                  </a>
                </div>
              </div>
              <p className='zcruit-footer-text'>
                Zcruit {new Date().getFullYear()} &copy; All Rights Reserved
              </p>
            </Col>
          </Row>
        </div>
      </footer>
    );

    return (
      <>
        <a
          href='#main'
          id='skip-to-main-content'
          onClick={() => {
            this.handleOnClickForSkipNav();
          }}
          ref={this.skipContentAnchorLink}
          className='skip-to-content-link'>
          Skip to main content
        </a>
        <div className='landing-page'>
          {socialMediaSVGs}
          {navInstance}
          <section ref={this.content} tabIndex='-1' className='p-0'>
            {homeSection}
            {featureSection}
            {productSection}
            {testimonialsSection}
            {pressSection}
            {teamSection}
            {contactSection}
            {connectSection}
            {footerInstance}
          </section>
        </div>
      </>
    );
  }
}

export default LandingPageComponent;
