import React, { Component } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import MyCsvLink from '../../../common/exportButton';
import MyPdfLink from '../../../common/exportPdf';

/**
 * Components
 */
import DeleteFilterConfirmModalComponent from '../deleteFilterConfirmModal';

/**
 * Services
 */
import FilterService from '../../../../services/filters';

/**
 * Store
 */
import store from '../../../../reducers/store';

/**
 * Styles
 */
import './styles/_index.scss';
import IconComponent from '../../../common/icon';

class ActivityHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFilter: {},
      dropdownOpen: false,
      filters: [],
      filtersSaved: this.props.filtersSaved || false,
      modalOpen: false,
    };
  }

  componentWillMount() {
    this.getFilters();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.filtersSaved === true) {
      this.getFilters();
    }
  }

  getFilters() {
    FilterService.getFilters(1)
      .then((response) => {
        this.setState({
          filters: response.data,
          filtersSaved: false,
        });
      })
      .catch((err) => {
        console.error(err);

        this.throwError(err.response.data.error.message);
      });
  }

  deleteFilter(filter, event) {
    event.stopPropagation();

    this.setState({
      filter,
      modalOpen: !this.state.modalOpen,
    });
  }

  setToList(filter) {
    store.dispatch({
      filter: filter.filter,
      name: filter.name,
      type: 'UPDATE_FROM_EXISTING_ACTIVITY_FILTER',
    });
  }

  _generateFilterList() {
    if (this.state.filters.length > 0) {
      return this.state.filters.map((filter) => {
        return (
          <DropdownItem
            key={filter.name}
            onClick={this.setToList.bind(this, filter)}
            onKeyDown={(e) => {
              if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
                document.activeElement === e.currentTarget
                  ? e.currentTarget.querySelector('.icon-holder').focus()
                  : e.currentTarget.focus();
              }
              if (e.key === 'ArrowUp') {
                e.currentTarget.previousElementSibling.focus();
              }
              if (e.key === 'ArrowDown') {
                e.currentTarget.nextElementSibling.focus();
              }
            }}
            aria-label={`${filter.name} List`}>
            {filter.name}
            <IconComponent
              color='red'
              icon='x fas fa-times'
              as_button={true}
              label={`Delete ${filter.name}?`}
              onClick={this.deleteFilter.bind(this, filter)}
              role='button'
              tabIndex={0}
            />
          </DropdownItem>
        );
      });
    }

    return <DropdownItem>All Players</DropdownItem>;
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  toggleDeleteConfirmModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }

  render() {
    return (
      <Col md='12' className='page-header-container'>
        <Row className='page-header'>
          <Col md={12}>
            <h1 className='page-header-text'>Activity Report</h1>
            <div className='actions'>
              <MyPdfLink
                label='Download as PDF'
                sortBy={this.props.sortBy}
                sortDirection={this.props.sortDirection}
                tracked={true}
                filter={this.props.activityRequest}
                activity={true}
              />
              <MyCsvLink
                label='Download as Excel'
                sortBy={this.props.sortBy}
                sortDirection={this.props.sortDirection}
                tracked={true}
                filter={this.props.activityRequest}
                activity={true}
              />
            </div>
          </Col>
        </Row>
        <Row className='utility-bar'>
          <Col md={12}>
            <Dropdown
              className='dropdown-filter ml-auto'
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown.bind(this)}
              id='activity-dropdown'>
              <DropdownToggle
                caret={false}
                className='dropdown-filter-button'
                onChange={() => {
                  return this.toggleDropdown();
                }}>
                {this.props.activeFilterName} {`(${this.props.totalCount})`}
                <i className='fal fa-chevron-down' />
              </DropdownToggle>
              <DropdownMenu
                className={`dropdown-checkbox-menu column-count-${this.props.columns}`}
                modifiers={{
                  offset: { offset: '0,28', enabled: true },
                  flip: { enabled: false },
                  preventOverflow: { enabled: false },
                  hide: { enabled: false },
                }}
                right>
                {this._generateFilterList()}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <DeleteFilterConfirmModalComponent
          filter={this.state.filter}
          getFilters={this.getFilters.bind(this)}
          modalOpen={this.state.modalOpen}
          toggle={this.toggleDeleteConfirmModal.bind(this)}
        />
      </Col>
    );
  }
}

export default ActivityHeader;
