import React, { Component } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';

import classNames from 'classnames';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import VerifiedIcon from 'CommonComponents/verifiedIcon';

/**
 * Constants
 */
import { VALID_METRIC_TYPES_LIST } from 'PlayerPageComponents/pages/stats/constants';

/**
 * Styles
 */
import './styles/_index.scss';

class SingleEventEventsViewStatsPlayerPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: this.props.collapse === true,
    };
  }

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  getIconClasses() {
    let classes = 'icon caret-down fal fa-chevron-down';

    if (this.state.collapse === true) {
      classes += ' flip';
    }

    return classes;
  }

  generateHeight(inches) {
    const feet = Math.floor(inches / 12);

    inches = Math.round((inches % 12) * 100) / 100;

    return `${feet}' ${inches}"`;
  }

  buildStats() {
    const sortedMetrics = this.sortMetrics(this.props.event.metrics);

    return this.filterMetrics(sortedMetrics);
  }

  sortMetrics(metrics) {
    // Immutably clone and return copy
    const sortedMetrics = [...metrics];
    return sortedMetrics.sort(
      (a, b) => VALID_METRIC_TYPES_LIST.indexOf(a.type) - VALID_METRIC_TYPES_LIST.indexOf(b.type)
    );
  }

  filterMetrics(metrics) {
    // Immutably clone and return copy
    const sortedMetrics = [...metrics];
    let counter = 1;
    let grey = true;

    return sortedMetrics
      .filter((metric) => !metric.type.toLowerCase().includes('rating'))
      .map((metric) => {
        const classes = classNames({
          grey: grey === true,
          stat: true,
        });

        if (counter % 3 === 0 && counter > 0) {
          grey = !grey;
        }

        counter++;

        return (
          <Col
            md={4}
            className='stat-holder'
            key={metric.id}
            tabIndex={0}
            role='document'
            aria-label='Statistics'>
            <div className={classes}>
              <div className='stat-icon-label'>
                <div className='stat-verified-holder'>
                  <VerifiedIcon verified={metric.verified} subSource={metric.subSource} />
                </div>
                <span className='stat-label'>{metric.label}</span>
              </div>
              <span>{metric.value}</span>
            </div>
          </Col>
        );
      });
  }

  render() {
    return (
      <div className='event filter' key={this.props.event.id}>
        <Button onClick={this.toggle.bind(this)} aria-expanded={this.state.collapse}>
          <h3>
            {this.props.event.name} -<span>{this.props.event.occurred_on}</span>
          </h3>
          <IconComponent
            color='grey'
            icon={this.props.event.source.name}
            label={this.props.event.source.label}
          />
          <i className={this.getIconClasses()} />
        </Button>
        <Collapse isOpen={this.state.collapse}>
          <Row>{this.buildStats()}</Row>
        </Collapse>
      </div>
    );
  }
}

export default SingleEventEventsViewStatsPlayerPageComponent;
