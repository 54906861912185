import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Store
 */
import store from 'Reducers/store';

/**
 * Services
 */
import PlayersService from 'Services/players';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';

/**
 * Components
 */
import TwitterInsights from 'RootComponents/TwitterInsights';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';
import ErrorPageComponent from 'PlayerPageComponents/pages/error';
import generatePageTitles from 'RootComponents/utility/pageTitles';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Styles
 */
import 'PlayerPageComponents/pages/twitter/styles/_index.scss';

class TwitterPlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);
    this.dateTimeService = new DateTimeService();
    this.state = {
      activeTab: 'twitter',
      error: true,
      isLoading: true,
      originalPlayer: null,
      player: null,
      twitterLoading: true,
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;
    const players = PlayersService.getPlayerAsPromise(playerId);

    players.then(
      (response) => {
        if (typeof response === 'undefined') {
          throw new Error('We could not find the requested player');
        }

        document.title = generatePageTitles(`${response.name} Twitter Insights`);

        return this.setState({
          currentTwitter: null,
          isLoading: false,
          player: response,
          twitter: [],
        });
      },
      (error) => {
        this.setState({
          currentTwitter: null,
          error: true,
          isLoading: false,
        });
      }
    );
  }

  setStateTo(stateObj) {}

  componentWillUpdate(nextProps, nextState) {
    if (this.state.twitterLoading && nextState.player !== null) {
      if (nextState.player.twitterHandle === null) {
        this.setStateTo({
          isLoading: false,
          error: true,
          twitterLoading: false,
        });
      }
    }
  }

  createPlaylistMembers() {
    return this.state.twitter.slice(1).map((member) => {
      return (
        <div className='playlist-body-member'>
          <h5>{member.title}</h5>
          <p>
            {this.dateTimeService.formatUTCDate(
              new Date(member.createdOn),
              DATE_DEFAULTS.format.default
            )}
          </p>
        </div>
      );
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div className='modal-wrapper' style={{ width: '90%', margin: '50px auto' }}>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </div>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <div className='modal-wrapper'>
          <ErrorPageComponent
            activeTab='twitter'
            message={NO_DATA_MESSAGE.twitterHandle}
            page={this.props.page}
            player={this.state.player}
            title='Twitter Insights'
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
          />
        </div>
      );
    }

    const likesDataLoaded = store.getState().twitter.twitterLikesDataLoaded;

    return (
      <div className='modal-wrapper'>
        <PlayerPageModalHeaderComponent
          player={this.state.player}
          page={this.props.page}
          activeTab={this.state.activeTab}
          toggleModal={this.props.toggleModal}
          setTab={this.props.setTab}
        />
        <div className='modal-body'>
          <Row className='secondary-page twitter-page'>
            <Col md={12} className='page-title-container'>
              <div className='page-title'>
                {`${this.state.player.name}'s Twitter Insights `}
                <span className='page-title-inner'>|</span>
                <a
                  href={`https://www.twitter.com/${this.state.player.twitterHandle}`}
                  className='page-title-handle'
                  target='_blank'
                  rel='noopener noreferrer'>
                  @{this.state.player.twitterHandle}
                </a>
              </div>
            </Col>
            <TwitterInsights
              twitterHandle={this.state.player.twitterHandle}
              playerId={this.state.player.id}
              masterPlayerList={store.getState().twitter.twitterPlayers}
              player={this.state.player}
              likesDataLoaded={likesDataLoaded}
            />
          </Row>
        </div>
      </div>
    );
  }
}

export default TwitterPlayerPageComponent;
