import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

/**
 * Components
 */
import IconComponent from 'CommonComponents/icon';
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

/**
 * Services
 */
import VideosService from 'Services/videos';

import './styles/_index.scss';

class PlayerPageModalVideoBoxComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      videos: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    VideosService.getVideosForPlayer(this.props.player.id).then(
      (response) => {
        this.setState({
          currentVideo: response[0],
          isLoading: false,
          videos: response.splice(0, 4),
        });
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }

  selectTab(url) {
    if (this.props.page === 'none') {
      this.props.setTab(url);
      return;
    }

    if (url === '') {
      return this.props.history.push(`/${this.props.page}/${this.props.player.id}/`);
    }

    this.props.history.push(`/${this.props.page}/${this.props.player.id}/${url}/`);
  }

  buildVideos() {
    return this.state.videos.map((video) => {
      return (
        <div className='video_holder_video_video' key={`video-${video.id}`}>
          <div role='presentation' className='tab-link'>
            <IconComponent icon='play fas fa-play' label='Play Video' />
            <p>{video.title}</p>
            <IconComponent
              color='grey'
              icon={video.source.name}
              label={video.source.label}
              shift_right={true}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <Col md={4} className='box video_holder'>
          <h2>Film</h2>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </Col>
      );
    }

    if (this.state.isLoading === false && this.state.videos.length === 0) {
      return (
        <Col md={4} className='box video_holder'>
          <h2>Film</h2>
          <div className='video_holder_video'>
            <MessagingComponent message={NO_DATA_MESSAGE.film} />
          </div>
        </Col>
      );
    }

    return (
      <Col md={4} className='box video_holder'>
        <h2>Film</h2>
        <Link
          to={`/${this.props.page}/${this.props.player.id}/videos`}
          aria-label={`View ${this.props.player.name}'s videos`}>
          <div className='video_holder_video'>{this.buildVideos()}</div>
        </Link>
      </Col>
    );
  }
}

export default withRouter(PlayerPageModalVideoBoxComponent);
