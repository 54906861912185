import ActivityRequest from '../requests/activity';

const filter = new ActivityRequest();

const initialState = {
  filter,
};

/**
 * - Generate new instance of filter on page load based on page
 *   - activity
 *   - non-activity
 * - Accept new selected filter
 */
export default function reducer(state = initialState, action) {
  const ignored_filters = ['rating_bump', 'gpa'];

  switch (action.type) {
    case 'ACTIVITY_FILTER_UPDATED':
      const newFilter = {
        filter: Object.assign(new ActivityRequest(), JSON.parse(JSON.stringify(state.filter))),
      };

      if (action.filter.type === 'name') {
        newFilter.filter = new ActivityRequest();
      }

      /**
       * Changes has a slightly different structure, so we need to hard-code `.types` into it
       */
      if (action.filter.type !== 'date_range') {
        if (action.filter.activity_request_level === 'changes') {
          newFilter.filter[action.filter.activity_request_level].types[action.filter.type].values =
            action.filter.values;

          if (ignored_filters.includes(action.filter.type)) {
            newFilter.filter[action.filter.activity_request_level].types[
              action.filter.type
            ].active = false;
          } else {
            newFilter.filter[action.filter.activity_request_level].types[
              action.filter.type
            ].active = action.filter.active || false;
          }
        } else {
          newFilter.filter[action.filter.activity_request_level][action.filter.type] =
            action.filter.values;
        }
      } else {
        newFilter.filter.changes.date_range = action.filter.values;
      }

      newFilter.closeFilter = false;
      newFilter.isClear = false;
      newFilter.activeFilterName = 'Activity';

      return newFilter;
    case 'ACTIVITY_TOGGLE_ACTIVE':
      const toggleFilter = {
        filter: Object.assign(new ActivityRequest(), JSON.parse(JSON.stringify(state.filter))),
      };

      if (ignored_filters.includes(action.filter.type)) {
        toggleFilter.filter[action.filter.activity_request_level].types[action.filter.type].active =
          false;
      } else {
        toggleFilter.filter[action.filter.activity_request_level].types[action.filter.type].active =
          action.filter.active;
      }

      toggleFilter.closeFilter = false;
      toggleFilter.isClear = false;
      toggleFilter.activeFilterName = 'Activity';

      /**
       * Clear filter if we're turning it off
       */
      if (action.filter.active === false) {
        toggleFilter.filter[action.filter.activity_request_level].types[action.filter.type].values =
          [];
        toggleFilter.closeFilter = true;
      }

      return toggleFilter;
    case 'UPDATE_FROM_EXISTING_ACTIVITY_FILTER':
      /**
       * Update filter
       *
       * initialState = passed in filter
       */
      const updateFilter = {
        filter: new ActivityRequest(),
      };

      updateFilter.activeFilterName = action.name;
      updateFilter.closeFilter = true;
      updateFilter.filter = updateFilter.filter.assignOut(new ActivityRequest(), action.filter);
      updateFilter.isClear = true;
      updateFilter.activeFilterName = action.name;

      return updateFilter;
    case 'CLEAR_FILTERS':
      return {
        activeFilterName: 'Activity',
        closeFilter: true,
        filter: initialState.filter,
        isClear: true,
      };
    default:
      return state;
  }
}
