import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import MessagingComponent, {
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';

class NavigationSearchResultsComponent extends Component {
  generateTrackedPlayers() {
    if (!this.props.trackedPlayers || this.props.trackedPlayers.length === 0) {
      return null;
    }

    this.props.trackedPlayers.splice(3, this.props.trackedPlayers.length - 1);

    const html = this.props.trackedPlayers.map((player) => {
      return this.generateRow(player);
    });

    return (
      <div className='results'>
        <h3>Tracked</h3>
        {html}
      </div>
    );
  }

  generateUntrackedPlayers() {
    if (!this.props.untrackedPlayers || this.props.untrackedPlayers.length === 0) {
      return null;
    }

    this.props.untrackedPlayers.splice(3, this.props.untrackedPlayers.length - 1);

    const html = this.props.untrackedPlayers.map((player) => {
      return this.generateRow(player);
    });

    return (
      <div className='results'>
        <h3>Discover</h3>
        {html}
      </div>
    );
  }

  generateHiddenPlayers() {
    if (!this.props.hiddenPlayers || this.props.hiddenPlayers.length === 0) {
      return null;
    }

    this.props.hiddenPlayers.splice(3, this.props.hiddenPlayers.length - 1);

    const html = this.props.hiddenPlayers.map((player) => {
      return this.generateRow(player);
    });

    return (
      <div className='results'>
        <h3>Hidden</h3>
        {html}
      </div>
    );
  }

  generateRow(player) {
    const search = this.props.searchValue;

    const split = player.name.split(search);
    const splitPos = player.name.search(search);

    let html = '';

    if (splitPos === 0) {
      html = (
        <span>
          <span className='bold'>{search}</span>
          {split[1]}
        </span>
      );
    } else if (splitPos === player.name.length - search.length) {
      html = (
        <span>
          {split[0]}
          <span className='bold'>{search}</span>
        </span>
      );
    } else {
      html = <span>{player.name}</span>;
    }

    if (this.props.lists) {
      return (
        <div className='result' key={player.id}>
          <div
            tabIndex={0}
            role='button'
            ref={(results) => {
              this.nameResults = results;
            }}
            onClick={() => {
              this.updatePlayerList(player);
            }}
            onKeyUp={() => {
              this.updatePlayerList(player);
            }}
            className='a-replacement'>
            <p className='name'>{html}</p>
            <p className='location'>{player.location.label}</p>
          </div>
        </div>
      );
    }

    const path = '/prospects';

    return (
      <div className='result' key={player.id}>
        <Link to={`${path}/${player.id}`}>
          <p className='name'>{html}</p>
          <p className='location'>{player.location.label}</p>
        </Link>
      </div>
    );
  }

  updatePlayerList(player) {
    const playerList = this.props.values;

    playerList.push({
      label: player.name,
      value: player.id,
    });

    this.props.updateFilterValue('players', playerList);

    this.nameResults.blur();
  }

  render() {
    if (
      this.props.hiddenPlayers &&
      this.props.trackedPlayers &&
      this.props.untrackedPlayers &&
      this.props.hiddenPlayers.length === 0 &&
      this.props.trackedPlayers.length === 0 &&
      this.props.untrackedPlayers.length === 0 &&
      this.props.searchValue.length > 2 &&
      this.props.isLoading === false
    ) {
      return (
        <div className='search-results'>
          <div className='results'>
            <div className='result'>
              <MessagingComponent
                id='no_search_results'
                message={NO_DATA_MESSAGE.prospects}
                type={MESSAGE_TYPE.danger}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='search-results'>
        {this.props.isLoading === false && (
          <div>
            {this.generateTrackedPlayers()}
            {this.generateUntrackedPlayers()}
            {this.generateHiddenPlayers()}
          </div>
        )}
        {this.props.isLoading === true && (
          <MessagingComponent
            id='looking_for_search_results'
            message={STATUS_MESSAGE.searching}
            type={MESSAGE_TYPE.warning}
          />
        )}
      </div>
    );
  }
}

export default withRouter(NavigationSearchResultsComponent);
