import React from 'react';
import PropTypes from 'prop-types';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';
import DropdownComponent from 'CommonComponents/dropdown';
import MetricFilterComponent from 'FilterComponents/common/metric';

import MinMaxRequest from 'Requests/min_max';
import store from 'Reducers/store';
import MetricConfig from './config';

import './styles/_index.scss';

const config = MetricConfig;

class MetricsFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);
    const { configType } = this.props;
    this.state = {
      filters: [],
      isOpen: false,
      toggleClicked: false,
      removeClicked: false,
      metrics: Object.keys(config[configType]).map((key) => {
        return config[configType][key];
      }),
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let isOpen = false;
    let hasActiveMetric = false;

    const metrics = nextState.metrics.map((metric) => {
      // special case for height and weight, since basic request includes these
      if (['height', 'weight'].includes(metric.id) && nextProps.closeFilter === true) {
        if (nextProps.values[metric.id].min > -1 || nextProps.values[metric.id].verified === true) {
          metric.active = true;
          hasActiveMetric = true;
        }
      } else if (
        typeof nextProps.values[metric.id] !== 'undefined' &&
        nextProps.closeFilter === true
      ) {
        metric.active = true;
        hasActiveMetric = true;
      }
      return metric;
    });

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      isOpen = hasActiveMetric;
    } else {
      isOpen = nextState.isOpen;
    }
    if (hasActiveMetric) {
      isOpen = true;
    }

    return {
      isOpen,
      metrics,
      toggleClicked: false,
      removeClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @param clicked
   * @param selected
   */
  onChange(selected) {
    const metrics = JSON.parse(JSON.stringify(this.state.metrics));

    let deleting = false;

    metrics.forEach((metric) => {
      if (metric.id === selected.id) {
        metric.active = !metric.active;

        if (metric.active === false) {
          deleting = true;
        }
      }
    });

    this.setState(
      {
        metrics,
        removeClicked: true,
      },
      () => {
        if (deleting === true) {
          store.dispatch({
            type: 'FILTER_UPDATED',
            filter: {
              type: selected.id,
              values: {
                allowNull: true,
                max: -1,
                min: -1,
                verified: false,
              },
            },
          });
        }
      }
    );
  }

  /**
   * Trigger off values that should be sent to store
   *
   * @param clicked
   * @param selected
   */
  changeVerified(selected) {
    const metrics = JSON.parse(JSON.stringify(this.state.metrics));

    metrics.forEach((metric) => {
      if (metric.id === selected.id) {
        metric.value.verified = selected.value.verified;
      }
    });

    this.setState({
      metrics,
    });
  }

  getProperValues(metric) {
    if (typeof this.props.values[metric] === 'undefined') {
      return new MinMaxRequest();
    }

    return this.props.values[metric];
  }

  generateFilters() {
    return this.state.metrics.map((metric) => {
      if (metric.active === true) {
        return (
          <MetricFilterComponent
            active={metric.active}
            id={metric.id}
            key={metric.id}
            label={metric.label}
            lessThan={metric.less_than}
            onChange={this.onChange.bind(this)}
            removable={true}
            unit={metric.unit}
            value={this.getProperValues(metric.id)}
            verified_checkbox={true}
          />
        );
      }

      return null;
    });
  }

  getInactiveMetrics() {
    const metrics = this.state.metrics
      .map((metric) => {
        return metric.active === false ? metric : null;
      })
      .filter((metric) => {
        return metric !== null;
      });

    return metrics;
  }

  render() {
    const { id, label, dropdownLabel } = this.props;
    const { isOpen } = this.state;
    return (
      <FilterComponent
        id={id}
        label={label}
        isOpen={isOpen}
        className='metrics'
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {this.generateFilters()}
        <DropdownComponent
          defaultLabel={dropdownLabel}
          onValueSelection={this.onChange.bind(this)}
          values={this.getInactiveMetrics()}
        />
      </FilterComponent>
    );
  }
}

MetricsFilterComponent.defaultProps = {
  closeFilter: false,
  configType: 'combine',
  label: 'Combine Results',
  dropdownLabel: 'Add Event',
};

MetricsFilterComponent.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  closeFilter: PropTypes.bool,
  configType: PropTypes.string,
  label: PropTypes.string,
  dropdownLabel: PropTypes.string,
};

export default MetricsFilterComponent;
