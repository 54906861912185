import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import MetricConfig from 'FilterComponents/specific/metrics/config';
import ConferencesService from 'Services/conferences';
import LocationService from 'Services/locations';
import PositionService from 'Services/positions';
import SourceService from 'Services/sources';
import StatusService from 'Services/statuses';

import zcruitLogo from 'Assets/images/zcruit-by-ncsa-logo-black.png';
import './styles/_index.scss';

class FilterRowComponent extends Component {
  constructor(props) {
    super(props);
    this.editModalButtonRef = React.createRef();

    this.state = {
      collapse: true,
      filterValueDetails: {},
    };
  }

  componentDidMount() {
    if (this.props.filter.filter) {
      Object.keys(this.props.filter.filter).map((filterKey) => {
        this.setFilterDetailsState(filterKey);
        return '';
      });
    }
  }

  keyToggle(e) {
    if (e.key === 'enter') {
      this.toggle();
    }
  }

  toggle() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  toggleDetailsClass() {
    let classString = 'filter-details-row';
    if (this.state.collapse) {
      classString += ' d-none';
    }
    return classString;
  }

  setFilterDetailsState(filterKey) {
    const filterValues = this.props.filter.filter[filterKey];

    if (filterValues === null || filterValues === '') {
      return;
    }

    switch (filterKey) {
      case 'years':
        this._generateYears(filterValues, filterKey);
        break;
      case 'positions':
        this._generatePosition(filterValues, filterKey);
        break;
      case 'rating':
        this._generateRating(filterValues, filterKey);
        break;
      case 'location':
        this._generateLocation(filterValues, filterKey);
        break;
      case 'offers':
        this._generateOffers(filterValues, filterKey);
        break;
      case 'status':
        this._generateStatus(filterValues, filterKey);
        break;
      case 'weight':
        this._generateWeight(filterValues, filterKey);
        break;
      case 'height':
        this._generateHeight(filterValues, filterKey);
        break;
      case 'sources':
        this._generateSources(filterValues, filterKey);
        break;
      case 'players':
        this._generatePlayers(filterValues, filterKey);
        break;
      case 'playing_level':
        this._generatePlayingLevel(filterValues, filterKey);
        break;
      case 'prospect_type':
        this._generateProspectType(filterValues, filterKey);
        break;
      case 'sort':
        this._generateSort(filterValues, filterKey);
        break;
      case 'gpa':
        this._generateGpa(filterValues, filterKey);
        break;
      case 'visits':
        this._generateVisits(filterValues, filterKey);

        break;
      case 'commits':
        this._generateVisits(filterValues, filterKey);

        break;
      case 'high_schools':
        this._generateHighSchools(filterValues, filterKey);

        break;
      case 'location_radius':
        this._generateLocationRadius(filterValues, filterKey);

        break;
      case 'events':
        this._generateEvents(filterValues, filterKey);

        break;
      default:
        const validKeys = Object.keys(MetricConfig);

        validKeys.push('act');
        validKeys.push('sat');

        if (validKeys.indexOf(filterKey) > -1) {
          this._generateMetric(filterValues, filterKey);
        } else {
          Promise.resolve('');
        }
    }
  }

  renderFilterDetails(filterKey) {
    if (typeof this.state.filterValueDetails[filterKey] !== 'undefined') {
      return (
        <p className='filter-details' key={filterKey}>
          <strong>{`${this.cleanupFilterKey(filterKey)}: `}</strong>
          {this.state.filterValueDetails[filterKey]}
        </p>
      );
    }

    return '';
  }

  _addFilterValuesToState(filterValuesString, filterKey) {
    if (typeof filterValuesString !== 'undefined' && filterValuesString !== '') {
      const newState = this.state.filterValueDetails;

      newState[filterKey] = filterValuesString;

      this.setState({
        filterValueDetails: newState,
      });
    }
  }

  _generateYears(filterValues, filterKey) {
    const filterValuesString = filterValues
      .map((year) => {
        if (typeof year === 'string') {
          return year.charAt(0).toUpperCase() + year.slice(1);
        }
        if (typeof year === 'number') {
          const parsedNum = year.toString(10);
          return parsedNum;
        }
        return year;
      })
      .join(', ');

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateStatus(filterValues, filterKey) {
    const statusPromise = StatusService.get({ ids: filterValues });

    statusPromise.then((statusModelViews) => {
      const statusString = statusModelViews
        .map((status) => {
          return status.name;
        })
        .join(', ');

      this._addFilterValuesToState(statusString, filterKey);
    });
  }

  _generateWeight(filterValues, filterKey) {
    let filterValuesString = '';

    const weightObj = filterValues;

    if (weightObj.min > 0) {
      filterValuesString += ` \u2265 ${weightObj.min} lbs`;
    }

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateHeight(filterValues, filterKey) {
    let filterValuesString = '';

    const heightObj = filterValues;

    if (heightObj.min > 0) {
      const heightFeet = `${Math.floor(heightObj.min / 12)}'`;
      const heightInches = `${heightObj.min % 12}"`;
      filterValuesString += `\u2265 ${heightFeet} ${heightInches}`;
    }

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateGpa(filterValues, filterKey) {
    let filterValuesString = '';

    const gpaMin = filterValues.min;
    const gpaMax = filterValues.max;

    if (gpaMin > -1 && gpaMax < 4 && gpaMax > -1) {
      filterValuesString += `Between ${gpaMin} - ${gpaMax}`;
    } else if (gpaMin > -1) {
      filterValuesString += `\u2265 ${gpaMin}`;
    } else if (gpaMax > -1) {
      filterValuesString += `\u2264 ${gpaMax}`;
    }

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateSources(filterValues, filterKey) {
    const sourceNameMap = {
      twoFourSeven: '247',
      rivals_camp: 'Rivals Camp',
      nike_opening: 'Nike Opening',
      rivals: 'Rivals',
    };
    let filterValuesString = '';

    const sources = filterValues;

    if (Array.isArray(sources) && sources.length > 0) {
      const sourcesPromise = SourceService.query({ ids: filterValues });
      sourcesPromise.then((result) => {
        filterValuesString = result
          .map((source) => {
            if (typeof sourceNameMap[source.name] !== 'undefined') {
              return sourceNameMap[source.name];
            }
            return source.name;
          })
          .join(', ');
        this._addFilterValuesToState(filterValuesString, filterKey);
      });
    }
  }

  _generateOffers(filterValues, filterKey) {
    if (Array.isArray(filterValues) && filterValues.length > 0) {
      const offersPromise = ConferencesService.getNamesFromIds({
        guids: filterValues,
      });

      offersPromise.then((conferencesObject) => {
        if (typeof conferencesObject !== 'undefined') {
          const conferenceNames = conferencesObject.conferences.map((conference) => {
            return `${conference.name}: All`;
          });
          const collegeNames = conferencesObject.colleges.map((college) => {
            return `${college.name}`;
          });
          const filterValuesString = conferenceNames.concat(collegeNames).join(', ');

          this._addFilterValuesToState(filterValuesString, filterKey);
        }
      });
    }
  }

  _generateVisits(filterValues, filterKey) {
    if (Array.isArray(filterValues) && filterValues.length > 0) {
      const offersPromise = ConferencesService.getNamesFromIds({
        guids: filterValues,
      });

      offersPromise.then((conferencesObject) => {
        if (typeof conferencesObject !== 'undefined') {
          const conferenceNames = conferencesObject.conferences.map((conference) => {
            return `${conference.name}: All`;
          });
          const collegeNames = conferencesObject.colleges.map((college) => {
            return `${college.name}`;
          });
          const filterValuesString = conferenceNames.concat(collegeNames).join(', ');

          this._addFilterValuesToState(filterValuesString, filterKey);
        }
      });
    }
  }

  _generatePlayers(filterValues, filterKey) {
    const filterValuesString = filterValues
      .map((player) => {
        if (typeof player === 'undefined' || player === null) {
          return null;
        }
        return player.label;
      })
      .join(', ');

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateHighSchools(filterValues, filterKey) {
    const filterValuesString = filterValues
      .filter((highSchool) => {
        return highSchool;
      })
      .map((highSchool) => {
        return highSchool.label;
      })
      .join(', ');

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generateLocation(filterValues, filterKey) {
    const locationPromise = LocationService.queryLocations(filterValues);

    if (Array.isArray(filterValues)) {
      const filterValuesString = filterValues.join(', ');
      this._addFilterValuesToState(filterValuesString, filterKey);
    } else {
      locationPromise.then((locationModelViews) => {
        if (typeof locationModelViews.states !== 'undefined') {
          const filterValuesString = locationModelViews.states
            .map((state) => {
              return `${state.name}: All`;
            })
            .concat(
              locationModelViews.counties.map((county) => {
                return `${county.state.name}: ${county.name}`;
              })
            )
            .join(', ');

          this._addFilterValuesToState(filterValuesString, filterKey);
        }
      });
    }
  }

  _generateRating(filterValues, filterKey) {
    let filterValuesString = '';

    if (
      typeof filterValues.max !== 'undefined' &&
      filterValues.max > -1 &&
      typeof filterValues.min !== 'undefined' &&
      filterValues.min > -1
    ) {
      filterValuesString = `Between ${filterValues.min} - ${filterValues.max}`;
    }

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  _generatePosition(filterValues, filterKey) {
    if (Array.isArray(filterValues) && filterValues.includes('all')) {
      return;
    }

    const positionPromise = PositionService.query({ ids: filterValues });

    positionPromise.then((positionModelViews) => {
      const positionString = positionModelViews
        .map((position) => {
          return position.name;
        })
        .join(', ');
      this._addFilterValuesToState(positionString, filterKey);
    });
  }

  _generatePlayingLevel(filterValues, filterKey) {
    if (filterValues.all === true) {
      return;
    }

    if (filterValues.high_school === true) {
      this._addFilterValuesToState('High School', filterKey);
    }

    if (filterValues.junior_college === true) {
      this._addFilterValuesToState('Juco', filterKey);
    }
  }

  _generateProspectType(filterValues, filterKey) {
    const keyLabelMap = {
      standard: 'Discover',
      tracked: 'Tracked',
      hidden: 'Hidden',
    };
    const prospectTypeString = Object.keys(filterValues)
      .filter((key) => {
        return filterValues[key] === true;
      })
      .map((keyString) => {
        return keyLabelMap[keyString] || keyString;
      })
      .join(', ');

    this._addFilterValuesToState(prospectTypeString, filterKey);
  }

  _generateSource(filterValues, filterKey) {
    if (!Array.isArray(filterValues) || filterValues.includes('all')) {
      return;
    }

    const sourcePromise = SourceService.query({ ids: filterValues });

    sourcePromise.then((sourceModelViews) => {
      const sourceString = sourceModelViews
        .map((source) => {
          return source.name;
        })
        .join(', ');
      this._addFilterValuesToState(sourceString, filterKey);
    });
  }

  _generateEvents(filterValues, filterKey) {
    if (
      Array.isArray(filterValues) &&
      filterValues.length > 0 &&
      typeof this.props.events !== 'undefined'
    ) {
      const eventNames = filterValues
        .reduce((accum, eventId) => {
          const eventName = this.props.events[eventId];
          if (eventName) {
            accum.push(eventName);
          }

          return accum;
        }, [])
        .sort((nameA, nameB) => {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

      const filterValuesString = eventNames.join(', ');

      this._addFilterValuesToState(filterValuesString, filterKey);
    }
  }

  _generateSort(filterValues, filterKey) {
    // unsure if we should display this, no way to change here
    return undefined;
  }

  _generateMetric(filterValues, filterKey) {
    const lessThan =
      typeof MetricConfig[filterKey] !== 'undefined' ? MetricConfig[filterKey].less_than : false;
    const sign =
      typeof MetricConfig[filterKey] !== 'undefined' ? ` ${MetricConfig[filterKey].unit}` : '';

    let filterValuesString = '';

    const min = filterValues.min;
    const max = filterValues.max;

    if (lessThan === true) {
      filterValuesString += `\u2264 ${max}${sign}`;
    } else {
      filterValuesString += `\u2265 ${min}${sign}`;
    }

    this._addFilterValuesToState(filterValuesString, filterKey);
  }

  cleanupFilterKey(string) {
    const keyMap = {
      playing_level: 'Highschool/Juco',
      gpa: 'GPA',
      prospect_type: 'Prospect Type',
      high_schools: 'High Schools',
      location_radius: 'Location Radius',
    };

    if (keyMap[string]) {
      string = keyMap[string];
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderButtonGroup() {
    if (this.props.filter.name === 'For You') {
      return (
        <td>
          <Row className='table-icon-container'>
            <Col md={7} className='zcruit-logo'>
              <img src={zcruitLogo} alt='Zcruit Logo' />
            </Col>
            <Col md={4}>
              <button
                className='toggler align-middle'
                tabIndex={0}
                type='button'
                onClick={this.toggle.bind(this)}
                aria-expanded={!this.state.collapse}
                aria-label={`${
                  this.state.collapse
                    ? `show the ${this.props.filter.name} list`
                    : `hide the ${this.props.filter.name} list`
                }`}>
                <i
                  className={`icon caret-down fal fa-chevron-down ${
                    this.state.collapse ? '' : 'rotate'
                  }`}
                />
              </button>
            </Col>
          </Row>
        </td>
      );
    }

    return (
      <td>
        <Row className='table-icon-container'>
          <Col md={4}>
            <button
              className='editor'
              onClick={this.props.toggleEditModal.bind(
                null,
                this.props.filter,
                this.props.filterType,
                this.editModalButtonRef
              )}
              ref={this.editModalButtonRef}
              type='button'
              aria-label={`edit ${this.props.filter.name} list`}
              onKeyPress={this.keyToggle.bind(this)}>
              <i className='icon edit fas fa-pencil' />
            </button>
          </Col>
          <Col md={4}>
            <button
              className='deleter'
              onClick={() => {
                this.props.toggleDeleteModal(this.props.filter.id);
              }}
              type='button'
              aria-label={`delete ${this.props.filter.name} list`}
              onKeyPress={this.keyToggle.bind(this)}>
              <i className='icon x far fa-times' />
            </button>
          </Col>
          <Col md={4}>
            <button
              className='toggler align-middle'
              tabIndex={0}
              type='button'
              onClick={this.toggle.bind(this)}
              aria-expanded={!this.state.collapse}
              aria-label={`${
                this.state.collapse
                  ? `show filters for ${this.props.filter.name} list`
                  : `hide filters for ${this.props.filter.name} list`
              }`}>
              <i
                className={`icon caret-down fal fa-chevron-down ${
                  this.state.collapse ? '' : 'rotate'
                }`}
              />
            </button>
          </Col>
        </Row>
      </td>
    );
  }

  _generateFilterDetails() {
    if (
      this.props.filter.filters &&
      this.props.filter.filters.players &&
      this.props.filter.filters.players.length > 0
    ) {
      return (
        <td className='filter-details-container' colSpan='2'>
          <p className='filter-details'>
            <strong>Players: </strong>
            {this.props.filter.fullPlayers
              .map((player) => {
                return player.label.charAt(0).toUpperCase() + player.label.slice(1);
              })
              .join(', ')}
          </p>
        </td>
      );
    }

    return (
      <td className='filter-details-container' colSpan='2'>
        {this.state.filterValueDetails &&
          Object.keys(this.state.filterValueDetails).map((filterKey) => {
            const filterDetails = this.renderFilterDetails(filterKey);

            if (typeof this.state.filterValueDetails === 'undefined') {
              return '';
            }
            return filterDetails;
          })}
      </td>
    );
  }

  _generateLocationRadius(values, key) {
    if (
      typeof values.latitude === 'undefined' ||
      typeof values.longitude === 'undefined' ||
      values.latitude === -1 ||
      values.longitude === -1
    ) {
      return null;
    }

    const filterValuesString = `${values.distance} miles from ${values.label}`;

    this._addFilterValuesToState(filterValuesString, key);
  }

  render() {
    const filterDetails = this._generateFilterDetails();
    return (
      <tbody>
        <tr>
          <th scope='row'>{this.props.filter.name}</th>
          {this.renderButtonGroup()}
        </tr>
        <tr className={this.toggleDetailsClass()}>{filterDetails}</tr>
      </tbody>
    );
  }
}

export default FilterRowComponent;
