import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

/**
 * Components
 */
import DropdownCheckboxFilterComponent from '../../../common/dropdownCheckbox';

/**
 * Styles
 */
import './styles/_index.scss';

class AccountHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      packages: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      organizations: nextProps.organizations || [],
      packages: nextProps.packages || [],
    });
  }

  render() {
    return (
      <Col md='12' className='page-header-container'>
        <Row className='page-header padding-left-header'>
          <Col md={{ size: 10, offset: 2 }}>
            <h1 className='page-header-text'>Users</h1>
          </Col>
        </Row>
        {this.state.organizations.length > 0 && this.state.packages.length > 0 && (
          <Row className='utility-bar'>
            <Col md={{ size: 8, offset: 2 }}>
              <div className='nav-tab-links-container rivals-camp-nav-filters'>
                <DropdownCheckboxFilterComponent
                  addAll={true}
                  columns={2}
                  filterChanged={this.props.filterChanged.bind(this, 'organizations')}
                  label='Organizations'
                  values={this.state.organizations}
                />
                <DropdownCheckboxFilterComponent
                  addAll={true}
                  columns={2}
                  filterChanged={this.props.filterChanged.bind(this, 'packages')}
                  label='Packages'
                  values={this.props.packages}
                />
              </div>
            </Col>
          </Row>
        )}
      </Col>
    );
  }
}

export default AccountHeader;
