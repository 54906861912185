import React from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoSizer, Column, InfiniteLoader, Table } from 'react-virtualized';
import { CSVLink } from 'react-csv';
import { debounce, isEqual } from 'underscore';

/**
 * Components
 */
import MessagingComponent, {
  MESSAGE_TYPE,
  NO_DATA_MESSAGE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import SchoolIconComponent from 'CommonComponents/schoolIcon';
import VerifiedIcon from 'CommonComponents/verifiedIcon';
import Checkbox from 'CommonComponents/checkbox';
import ClickableTooltip from 'CommonComponents/clickableTooltip';
import HideAllPlayersModalComponent from 'RootComponents/prospects/components/hideAllPlayersModal';
import PageContentHeader from 'RootComponents/prospects/components/pageContentHeader';
import RatingPreview from 'RootComponents/prospects/components/ratingPreview';
import SourcesPreview from 'RootComponents/prospects/components/sourcesPreview';
import TrackPlayerModal from 'RootComponents/prospects/components/trackPlayerModal';

/**
 * Filters
 */

import FilterContainer from 'CommonComponents/filters/filterContainer';
import SidebarFilters from 'CommonComponents/filters/sidebarFilters';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import AuthenticationService from 'Services/authentication';
import FiltersService from 'Services/filters';
import PlayersService from 'Services/players';

/**
 * Store
 */

import store from 'Reducers/store';
import { isTimeValidVerifiedMetric } from 'RootComponents/utility/helpers';

/**
 * Styles
 */
import 'RootComponents/prospects/styles/_index.scss';

class ProspectsComponent extends ZcruitComponent {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();

    this.getPlayersAndFilter = debounce(this.getPlayersAndFilter, 350);

    this.statusValues = [
      {
        id: 'Committed',
        label: 'Committed',
      },
      {
        id: 'Offered',
        label: 'Offered',
      },
      {
        id: 'Active',
        label: 'Active',
      },
      {
        id: 'Eval',
        label: 'Eval',
      },
    ];

    const forYouFilters = JSON.parse(
      JSON.stringify(AuthenticationService.getSchoolForYou().filters)
    );

    this.state = {
      allSelected: false,
      csvData: [],
      filterVals: forYouFilters,
      height: 1000,
      hideAllPlayersOpenModal: false,
      lists: [],
      message: 'Tracked!',
      nextPageLoading: false,
      openModal: false,
      page: 1,
      players: [],
      selectedPlayers: [],
      selectedPlayersForCSV: [],
      showMessage: false,
      sortBy: 'rating',
      sortDirection: 'DESC',
      sortMax: 100,
      sortMin: 50,
      trackedErrorThrown: false,
      trackedIsLoading: true,
    };

    this.getAllFilters();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    /**
     * Check if filters are the same. If they're not the same, we can trigger
     * the get players call and return false.
     */
    if (isEqual(nextProps.filter, this.props.filter) === false) {
      this.clearFilters(nextProps.isClear);

      return false;
    }

    return true;
  }

  clearFilters(resetSort) {
    const sortBy = resetSort === true ? 'rating' : this.state.sortBy;
    const sortDirection = resetSort === true ? 'DESC' : this.state.sortDirection;

    this.setState(
      {
        page: 1,
        players: [],
        sortBy,
        sortDirection,
        trackedErrorThrown: false,
      },
      () => {
        this.getPlayersAndFilter(true);
      }
    );
  }

  getPlayersAndFilter(reset) {
    this.getPlayersAndFilter.cancel();

    PlayersService.cancelCall('Restarting search');

    this.getVisiblePlayers(reset);
  }

  getVisiblePlayers(reset) {
    if (reset === true) {
      this.setState(
        {
          players: [],
          page: 1,
          nextPageLoading: false,
        },
        () => {
          this._getPlayerCall(true);
        }
      );
    } else {
      this._getPlayerCall();
    }
  }

  _getPlayerCall(reset = false) {
    this.setState(
      {
        trackedErrorThrown: false,
        trackedIsLoading: true,
        nextPageLoading: true,
      },
      () => {
        const filter = JSON.parse(JSON.stringify(this.props.filter));

        const pageNumber = reset ? 1 : this.state.page;
        PlayersService.getPlayers(
          {
            hidden: true,
            tracked: true,
            page: pageNumber,
            'sort[sortBy]': this.state.sortBy,
            'sort[sortDirection]': this.state.sortDirection,
          },
          filter
        )
          .then((players) => {
            const totalPlayers = this.state.players.concat(players.players);
            let height = players.total < 100 ? players.players.length * 70 + 43 : 1000;

            if (height > 1000) {
              height = 1000;
            }

            this.setState({
              height,
              trackedIsLoading: false,
              page: pageNumber + 1,
              players: totalPlayers,
              total: pageNumber === 1 ? players.total : this.state.total,
              nextPageLoading: false,
            });
          })
          .catch((e) => {
            if (e.message === 'Restarting search') {
              return;
            }

            this.setState({
              players: [],
              trackedErrorThrown: this.state.page < 2,
              trackedIsLoading: false,
              total: 0,
              nextPageLoading: false,
            });
          });
      }
    );
  }

  _sort({ sortBy, sortDirection }) {
    if (this.state.sortBy !== 'rating' && sortBy === 'rating') {
      sortDirection = 'DESC';
    }

    this.setState(
      {
        trackedErrorThrown: false,
        trackedIsLoading: true,
        page: 1,
        players: [],
        sortBy,
        sortDirection,
      },
      () => {
        this.getPlayersAndFilter(null, true);
      }
    );
  }

  getAllFilters() {
    FiltersService.getFilters(2)
      .then((response) => {
        this.setState(
          {
            lists: response.data,
          },
          () => {
            const forYou = this.state.lists.find((list) => {
              if (list.name === 'For You') {
                return list;
              }

              return false;
            });

            if (typeof forYou !== 'undefined') {
              store.dispatch({
                type: 'UPDATE_FROM_EXISTING_FILTER',
                filter: forYou.filter,
                name: forYou.name,
              });
            } else {
              this.clearFilters();
            }
          }
        );
      })
      .catch((err) => {
        this.clearFilters();
      });
  }

  addFilterToState(filterObj) {
    const filterObjCopy = JSON.parse(JSON.stringify(filterObj));
    const updatedListsState = this.state.lists.concat([filterObjCopy]);

    this.setState({
      lists: updatedListsState,
    });
  }

  _filterList(playersList) {
    let height = playersList.length < 100 ? playersList.length * 70 + 43 : 1000;

    if (height > 1000) {
      height = 1000;
    }

    this.setState(
      {
        height,
        players: playersList,
      },
      () => {
        this.getPlayersAndFilter();
      }
    );
  }

  toggleModal() {
    if (this.state.selectedPlayers.length > 0) {
      this.setState({
        openModal: !this.state.openModal,
      });
    }
  }

  selectPlayer(player) {
    const index = this.state.selectedPlayers.indexOf(player);
    const players = this.state.selectedPlayers;

    if (index === -1) {
      players.push(player);
    } else {
      players.splice(index, 1);
    }

    this.selectPlayerForCSV(player);

    this.setState({
      selectedPlayers: players,
    });
  }

  selectPlayerForCSV(player) {
    const formatted = this.formatPlayerForCSV(player);

    const index = this.state.selectedPlayersForCSV.indexOf(formatted);
    const players = this.state.selectedPlayersForCSV;

    if (index === -1) {
      players.push(formatted);
    } else {
      players.splice(index, 1);
    }

    this.setState({
      selectedPlayersForCSV: players,
    });
  }

  /**
   * @TODO: Move this into header.
   *
   * @param players
   * @return {Array}
   */
  generateCSVList(players) {
    const playersForCSV = [];

    players.forEach((player) => {
      const formatted = this.formatPlayerForCSV(player);

      playersForCSV.push(formatted);
    });

    return playersForCSV;
  }

  exportData() {
    const filter = JSON.parse(JSON.stringify(this.props.filter));

    filter.export = true;

    if (this.state.selectedPlayers) {
      filter.players = this.state.selectedPlayers.map((player) => {
        return player.id;
      });
    } else {
      return;
    }

    PlayersService.exportPlayers(
      {
        hidden: true,
        tracked: this.props.tracked,
        'sort[sortBy]': this.state.sortBy,
        'sort[sortDirection]': this.state.sortDirection,
      },
      filter
    ).then((data) => {
      this.setState({ csvData: data.data }, () => {
        // click the CSVLink component to trigger the CSV download
        this.csvLink.current.link.click();
      });
    });
  }

  /**
   * @TODO: move this to service
   *
   * @param player
   * @return formatted
   */
  formatPlayerForCSV(player) {
    const formatted = {
      Name: player.name,
      Status: player.status,
      'Committed To': player.committedTo,
      Year: player.year,
      Position: player.positionLabel,
      City:
        typeof player.location !== 'undefined' && typeof player.location.city !== 'undefined'
          ? player.location.city.name
          : '',
      State:
        typeof player.location !== 'undefined' && typeof player.location.state !== 'undefined'
          ? player.location.state.name
          : '',
      'High School':
        typeof player.location !== 'undefined' && typeof player.location.highschool !== 'undefined'
          ? player.location.highschool.name
          : '',
      Height: typeof player.height !== 'undefined' ? player.height.value : '',
      Weight: typeof player.weight !== 'undefined' ? player.weight.value : '',
      // @TODO: get offers on demand when preparing players for csv
      // Offers: this.getPlayerOffers(player.offers),
      Rating: player.rating,
      'Twitter Handle': player.twitterHandle,
    };

    return formatted;
  }

  selectAllPlayers() {
    const selected = [];

    if (this.state.allSelected === false) {
      this.state.players.forEach((player) => {
        selected.push(player);
      });

      this.setState({
        allSelected: true,
        selectedPlayers: selected,
      });
    } else {
      this.setState({
        allSelected: false,
        selectedPlayers: [],
      });
    }
  }

  openModalForSingle(player) {
    this.setState(
      {
        selectedPlayers: [player],
      },
      () => {
        this.toggleModal();
      }
    );
  }

  trackProspect(prospect) {
    const status = {
      status: {
        name: prospect.status,
      },
    };

    PlayersService.trackPlayer(prospect.id, status).then(
      (updatedProspect) => {
        this.replaceRenderedPlayer(updatedProspect, 'Player Tracked');
        this.setState({
          openModal: false,
        });
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  componentDidCatch(error, info) {
    console.warn('ERROR WAS CAUGHT');
    console.warn(error);
    console.warn(info);
  }

  replaceRenderedPlayer(player, message) {
    const playerIndex = this.state.players.findIndex((statePlayer) => {
      return player.id === statePlayer.id;
    });

    const curPlayers = this.state.players;

    if (playerIndex > -1) {
      curPlayers[playerIndex] = player;
    }

    this.setState(
      {
        players: curPlayers,
        selectedPlayers: [],
      },
      () => {
        this.showMessage(message);
      }
    );
  }

  showMessage(message) {
    this.setState({
      showMessage: true,
      message,
    });
    setTimeout(() => {
      this.setState({ showMessage: false });
    }, 1500);
  }

  hideProspect(player) {
    PlayersService.hidePlayer(player.id).then(
      (updatedPlayer) => {
        this.replaceRenderedPlayer(updatedPlayer, 'Player Hidden');
        this.setState({
          openModal: false,
        });
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  untrackProspect(player) {
    PlayersService.untrackPlayer(player.id, true).then(
      (updatedPlayer) => {
        this.replaceRenderedPlayer(updatedPlayer, 'Player Untracked');
        this.setState({
          openModal: false,
        });
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  unhideProspect(player) {
    player.hide = false;
    player.main = false;

    PlayersService.unhidePlayer(player.id, true).then(
      (updatedPlayer) => {
        this.replaceRenderedPlayer(updatedPlayer, 'Player Unhidden');
        this.setState({
          openModal: false,
        });
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  batchHidePlayers() {
    const allPlayers = this.state.selectedPlayers;

    allPlayers.forEach((player) => {
      this.hideProspect(player);
    });
  }

  batchHideSignedPlayers() {
    this.setState(
      {
        hideAllPlayersOpenModal: true,
      },
      () => {
        PlayersService.hideAllPlayers().then((result) => {
          this.getVisiblePlayers(true);

          this.setState(
            {
              hideAllPlayersOpenModal: false,
              message: 'All signed players have been successfully hidden!',
              showMessage: true,
            },
            () => {
              setTimeout(() => {
                this.setState({ showMessage: false });
              }, 3000);
            }
          );
        });
      }
    );
  }

  buildTrackPlayerArea(data) {
    return (
      <div className='adder' id={`track-player-${data.rowData.id}`}>
        <button
          className='icon add fas fa-plus'
          onClick={this.openModalForSingle.bind(this, data.rowData)}
          tabIndex={0}
          aria-label={`Track ${data.rowData.name}`}
          type='button'
        />
        <UncontrolledTooltip
          autohide={false}
          placement='right'
          target={`track-player-${data.rowData.id}`}
          className='tooltip-content'>
          <div className='tooltip-row'>
            <div className='adder'>
              <i className='icon add fas fa-plus' />
            </div>
            <span className='hide-label'>Track this Prospect</span>
          </div>
        </UncontrolledTooltip>
      </div>
    );
  }

  buildUntrackPlayerArea(data) {
    return (
      <div className='untracker' id={`untrack-player-${data.rowData.id}`}>
        <button
          className='icon checkmark fas fa-check'
          type='button'
          onClick={this.untrackProspect.bind(this, data.rowData)}
          onKeyUp={this.untrackProspect.bind(this, data.rowData)}
          tabIndex={0}
          aria-label={`Untrack ${data.rowData.name}`}
        />
        <UncontrolledTooltip
          autohide={false}
          placement='right'
          target={`untrack-player-${data.rowData.id}`}
          className='tooltip-content'>
          <div className='tooltip-row'>
            <div className='untracker'>
              <i className='icon checkmark fas fa-check' />
            </div>
            <span className='hide-label'>Untrack this Prospect</span>
          </div>
        </UncontrolledTooltip>
      </div>
    );
  }

  buildProspectsActionsColumn(data) {
    return (
      <div className='add-hide-cell'>
        {this.buildTrackPlayerArea(data)}
        <div className='hider' id={`hide-player-${data.rowData.id}`}>
          <button
            className='icon x fas fa-times'
            type='button'
            tabIndex={0}
            aria-label={`Hide ${data.rowData.name}`}
            onClick={this.hideProspect.bind(this, data.rowData)}
          />
          <UncontrolledTooltip
            autohide={false}
            placement='right'
            target={`hide-player-${data.rowData.id}`}
            className='tooltip-content'>
            <div className='tooltip-row'>
              <div className='hider'>
                <i className='icon x fas fa-times' />
              </div>
              <span className='hide-label'>Hide this Prospect</span>
            </div>
          </UncontrolledTooltip>
        </div>
      </div>
    );
  }

  buildTrackedActionsColumn(data) {
    return (
      <div className='add-hide-cell'>
        {this.buildUntrackPlayerArea(data)}
        <div className='hider' id={`hide-player-${data.rowData.id}`}>
          <button
            className='icon x fas fa-times'
            type='button'
            tabIndex={0}
            onClick={this.hideProspect.bind(this, data.rowData)}
            aria-label={`Hide ${data.rowData.name}`}
          />
          <UncontrolledTooltip
            autohide={false}
            placement='right'
            target={`hide-player-${data.rowData.id}`}
            className='tooltip-content'>
            <div className='tooltip-row'>
              <div className='hider'>
                <i className='icon x fas fa-times' />
              </div>
              <span className='hide-label'>Hide this Prospect</span>
            </div>
          </UncontrolledTooltip>
        </div>
      </div>
    );
  }

  buildHiddenActionsColumn(data) {
    return (
      <div className='add-hide-cell'>
        {this.buildTrackPlayerArea(data)}
        <div className='unhider' id={`unhide-player-${data.rowData.id}`}>
          <button
            className='icon hidden-no-circle fas fa-eye-slash'
            onClick={this.unhideProspect.bind(this, data.rowData)}
            type='button'
            tabIndex={0}
            aria-label={`Unhide ${data.rowData.name}`}
          />
          <UncontrolledTooltip
            autohide={false}
            placement='right'
            target={`unhide-player-${data.rowData.id}`}
            className='tooltip-content'>
            <div className='tooltip-row'>
              <div className='unhider'>
                <i className='icon hidden-no-circle fas fa-eye-slash' />
              </div>
              <span className='hide-label'>Unhide this Prospect</span>
            </div>
          </UncontrolledTooltip>
        </div>
      </div>
    );
  }

  _isRowLoaded({ index }) {
    return !!this.state.players[index];
  }

  _isRowLoadedHidden({ index }) {
    return !!this.state.hiddenPlayers[index];
  }

  _isDemoOrg() {
    return AuthenticationService.getOrganization()?.is_demo;
  }

  buildJuco(player) {
    if (player.is_juco === false) {
      return null;
    }

    return <div className='icon-holder purple-bg'>J</div>;
  }

  buildTransfer(player) {
    if (typeof player.transfer === 'undefined') {
      return null;
    }

    return <div className='icon-holder purple-bg'>T</div>;
  }

  buildMeasurableStat(rowData, stat) {
    const { verified, date } = rowData[stat];
    const isVerified = verified && isTimeValidVerifiedMetric(date);
    return (
      <>
        {rowData.getMeasurableStat(stat)}
        <VerifiedIcon verified={isVerified} type='checkmark' />
      </>
    );
  }

  getImage(imageUrl) {
    if (typeof imageUrl === 'undefined' || imageUrl === null || imageUrl.indexOf('http') === -1) {
      imageUrl = 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
    }

    return imageUrl;
  }

  getPlayerHSLocation(data) {
    return (
      <React.Fragment>
        {data.getCorrectHighSchool()} <br />
        {data.getCorrectLocationForHighSchool()}
      </React.Fragment>
    );
  }

  generateCorrectTable(hidden = false, players, disableHeader, height) {
    if (AuthenticationService.isRivalsOnly() === true) {
      return this.buildRivalsOnlyTable(hidden, players, disableHeader, height);
    }

    return this.buildTable(hidden, players, disableHeader, height);
  }

  loadMoreRows(hidden) {
    if (this.state.nextPageLoading === false) {
      this.getPlayersAndFilter(hidden);
    }
  }

  buildTable(hidden = false, players, disableHeader, height) {
    const { sortBy, sortDirection } = this.state;

    const total = hidden === false ? this.state.total : this.state.hiddenTotal;

    const isRowLoaded = hidden === true ? this._isRowLoadedHidden : this._isRowLoaded;

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded.bind(this)}
        loadMoreRows={this.loadMoreRows.bind(this, hidden)}
        rowCount={total}
        threshold={75}>
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer disableHeight className='zc-virtualized-table' registerChild={registerChild}>
              {({ width }) => {
                return (
                  <Table
                    className='blade-table'
                    disableHeader={disableHeader}
                    height={height}
                    headerHeight={45}
                    onRowsRendered={onRowsRendered}
                    rowCount={players.length}
                    rowGetter={({ index }) => {
                      return players[index];
                    }}
                    rowHeight={70}
                    sort={this._sort.bind(this)}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    width={width}>
                    <Column
                      dataKey='chckbx'
                      label=''
                      disableSort={true}
                      cellRenderer={(data) => {
                        return (
                          <div>
                            <Checkbox
                              id={`cb-${data.rowData.id}`}
                              name={`cb-${data.rowData.id}`}
                              className='white'
                              onChange={this.selectPlayer.bind(this, data.rowData)}
                              checked={this.state.selectedPlayers.indexOf(data.rowData) > -1}
                              labelProps={{ 'aria-label': `Select prospect ${data.rowData.name}` }}
                            />
                          </div>
                        );
                      }}
                      headerRenderer={() => {
                        return (
                          <div>
                            <Checkbox
                              id='cb-main'
                              name='cb-main'
                              className='white'
                              onChange={this.selectAllPlayers.bind(this)}
                              checked={this.state.allSelected}
                              labelProps={{ 'aria-label': 'Select all prospects' }}
                            />
                          </div>
                        );
                      }}
                      style={{ marginRight: '15px', overflow: 'none' }}
                      width={20}
                    />
                    <Column
                      dataKey='name'
                      label='Prospect Name'
                      width={275}
                      cellRenderer={(data) => {
                        return (
                          <div className='headshot'>
                            <div className='image'>
                              <img
                                src={this.getImage(data.rowData.image_url)}
                                alt={data.rowData.name}
                                title={data.rowData.name}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
                                }}
                              />
                              {this.buildJuco(data.rowData)}
                              {this.buildTransfer(data.rowData)}
                            </div>
                            <div className='sources'>
                              <Link
                                id={`sources-hover-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                to={`/prospects/${data.rowData.id}`}
                                className='prospect-name'>
                                {data.rowData.name}
                              </Link>
                              <div className='sources-hs-location'>
                                {this.getPlayerHSLocation(data.rowData)}
                              </div>
                              <UncontrolledTooltip
                                autohide={false}
                                className='tooltip-content'
                                delay={{ show: 250 }}
                                placement='left'
                                target={`sources-hover-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}>
                                <SourcesPreview playerId={data.rowData.id} />
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <Column
                      dataKey='follow'
                      disableSort={true}
                      label=''
                      width={75}
                      cellRenderer={(data) => {
                        if (data.rowData.hidden === true) {
                          return this.buildHiddenActionsColumn(data);
                        }

                        if (typeof data.rowData.status !== 'undefined') {
                          return this.buildTrackedActionsColumn(data);
                        }

                        return this.buildProspectsActionsColumn(data);
                      }}
                      headerRenderer={() => {
                        return (
                          <div>
                            <span className='header_options-dots' id='header_options'>
                              <i className='far fa-ellipsis-h' alt='Player Actions' />
                            </span>
                            <UncontrolledTooltip
                              target='header_options'
                              placement='right'
                              autohide={false}
                              delay={{ hide: 30 }}>
                              <div
                                className='selection'
                                onClick={this.batchHidePlayers.bind(this)}
                                onKeyUp={this.batchHidePlayers.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='hider'>
                                  <i className='icon x fas fa-times' />
                                </div>
                                <span>Hide All Selected Prospects</span>
                              </div>
                              <div
                                className='selection'
                                onClick={this.batchHideSignedPlayers.bind(this)}
                                onKeyUp={this.batchHideSignedPlayers.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='hider'>
                                  <i className='icon x fas fa-times' />
                                </div>
                                <span>Hide All Signed Prospects</span>
                              </div>

                              <div
                                className='selection'
                                onClick={this.toggleModal.bind(this)}
                                onKeyUp={this.toggleModal.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='adder'>
                                  <i className='icon add fas fa-plus' />
                                </div>
                                <span>Track All Selected Prospects</span>
                              </div>
                              <div>
                                <div
                                  aria-disabled={this._isDemoOrg()}
                                  className='selection'
                                  role='button'
                                  onClick={this.exportData.bind(this)}
                                  onKeyDown={() => {
                                    console.log('clicked');
                                  }}
                                  tabIndex={0}>
                                  <div className='downloader'>
                                    <i className='icon download far fa-arrow-to-bottom' />
                                  </div>
                                  <span>Export All Selected Prospects</span>
                                </div>
                              </div>
                            </UncontrolledTooltip>
                          </div>
                        );
                      }}
                    />
                    <Column
                      label='Status'
                      dataKey='status'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        if (data.rowData.commit) {
                          return (
                            <div
                              className='offer-image'
                              id={`committed-school-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}>
                              <SchoolIconComponent
                                imageOnly={true}
                                school={data.rowData.commit.college.name}
                                key={`committed-school-logo-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                addCheck={typeof data.rowData.signed !== 'undefined'}
                              />
                              <UncontrolledTooltip
                                autohide={false}
                                placement='right'
                                target={`committed-school-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                className='tooltip-content'>
                                <div className='tooltip-row'>
                                  {data.rowData.commit.college.name}
                                </div>
                              </UncontrolledTooltip>
                            </div>
                          );
                        }

                        if (data.rowData.signed) {
                          return (
                            <div
                              className='offer-image'
                              id={`committed-school-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}>
                              <SchoolIconComponent
                                imageOnly={true}
                                school={data.rowData.signed.college.name}
                                key={`committed-school-logo-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                addCheck={true}
                              />
                              <UncontrolledTooltip
                                autohide={false}
                                placement='right'
                                target={`committed-school-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                className='tooltip-content'>
                                <div className='tooltip-row'>
                                  {data.rowData.signed.college.name}
                                </div>
                              </UncontrolledTooltip>
                            </div>
                          );
                        }

                        if (data.rowData.transfer && data.rowData.transfer.to) {
                          return (
                            <div
                              className='offer-image'
                              id={`committed-school-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}>
                              <SchoolIconComponent
                                imageOnly={true}
                                school={data.rowData.transfer.to.name}
                                key={`committed-school-logo-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                addCheck={true}
                              />
                              <UncontrolledTooltip
                                autohide={false}
                                placement='right'
                                target={`committed-school-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                className='tooltip-content'>
                                <div className='tooltip-row'>{data.rowData.transfer.to.name}</div>
                              </UncontrolledTooltip>
                            </div>
                          );
                        }

                        return <div>{data.rowData.getStatus()}</div>;
                      }}
                    />
                    <Column
                      label='Year'
                      dataKey='year'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        return data.rowData.getYear();
                      }}
                    />
                    <Column
                      label='Position'
                      dataKey='positions'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      disableSort={false}
                      cellRenderer={(data) => {
                        return data.rowData.getPosition();
                      }}
                    />
                    <Column
                      label='Location'
                      dataKey='location'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        return (
                          <div className='left'>
                            <span
                              id={`location-num-${data.rowData.id.replace(/([^a-zA-Z0-9])/g, '')}`}>
                              {data.rowData.getHighSchoolState()}
                            </span>
                          </div>
                        );
                      }}
                    />
                    <Column
                      label='Height'
                      dataKey='height'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => this.buildMeasurableStat(data.rowData, 'height')}
                    />
                    <Column
                      label='Weight'
                      dataKey='weight'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => this.buildMeasurableStat(data.rowData, 'weight')}
                    />
                    <Column
                      label='GPA'
                      dataKey='gpa'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => {
                        return data.rowData.getGPA();
                      }}
                    />
                    <Column
                      label='Offers'
                      dataKey='offers'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => {
                        function Tooltip() {
                          return (
                            <ul className='tooltip-list text-left'>
                              {data.cellData.map((school) => {
                                return (
                                  <li
                                    className='school-offer-logo'
                                    key={`committed-school-logo-holder-${school.college.id}`}>
                                    <SchoolIconComponent
                                      imageOnly={true}
                                      key={`committed-school-logo-${school.college.id}`}
                                      school={school.college.name}
                                      size='small'
                                    />
                                    {school.college.name}
                                  </li>
                                );
                              })}
                            </ul>
                          );
                        }

                        return (
                          <div className='left'>
                            <ClickableTooltip
                              targetId={`offers-num-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}
                              target={<span>{data.cellData.length}</span>}
                              tooltip={<Tooltip />}
                              placement='left'
                              parentDivStyle={{ textDecoration: 'underline' }}
                            />
                          </div>
                        );
                      }}
                    />
                    <Column
                      label='Rating'
                      dataKey='rating'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        function Tooltip() {
                          return (
                            <RatingPreview
                              rating={data.rowData.getRating()}
                              playerId={data.rowData.id}
                            />
                          );
                        }

                        return (
                          <div className='left'>
                            <ClickableTooltip
                              targetId={`ratings-num-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}
                              target={<span>{data.rowData.getRating(2)}</span>}
                              tooltip={<Tooltip />}
                              placement='left'
                              parentDivStyle={{ textDecoration: 'underline' }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          );
        }}
      </InfiniteLoader>
    );
  }

  buildRivalsOnlyTable(hidden = false, players, disableHeader, height) {
    const { sortBy, sortDirection } = this.state;

    const total = hidden === false ? this.state.total : this.state.hiddenTotal;

    const isRowLoaded = hidden === true ? this._isRowLoadedHidden : this._isRowLoaded;

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded.bind(this)}
        loadMoreRows={this.getPlayersAndFilter.bind(this, hidden)}
        rowCount={total}
        threshold={75}>
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer disableHeight className='zc-virtualized-table' registerChild={registerChild}>
              {({ width }) => {
                return (
                  <Table
                    className='blade-table'
                    disableHeader={disableHeader}
                    height={height}
                    headerHeight={45}
                    onRowsRendered={onRowsRendered}
                    rowCount={players.length}
                    rowGetter={({ index }) => {
                      return players[index];
                    }}
                    rowHeight={70}
                    sort={this._sort.bind(this)}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    width={width}>
                    <Column
                      dataKey='name'
                      label='Prospect Name'
                      width={275}
                      cellRenderer={(data) => {
                        return (
                          <div className='headshot'>
                            <div className='image'>
                              <img
                                src={this.getImage(data.rowData.image_url)}
                                alt={data.rowData.name}
                                title={data.rowData.name}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
                                }}
                              />
                              {this.buildJuco(data.rowData)}
                              {this.buildTransfer(data.rowData)}
                            </div>
                            <div className='sources'>
                              <Link
                                id={`sources-hover-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                to={`/prospects/${data.rowData.id}`}>
                                {data.rowData.name}
                              </Link>
                              <div className='sources-hs-location'>
                                {this.getPlayerHSLocation(data.rowData)}
                              </div>
                              <UncontrolledTooltip
                                autohide={false}
                                placement='left'
                                target={`sources-hover-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                className='tooltip-content'>
                                <SourcesPreview playerId={data.rowData.id} />
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <Column
                      dataKey='follow'
                      disableSort={true}
                      label=''
                      width={75}
                      cellRenderer={(data) => {
                        if (hidden === true) {
                          return this.buildHiddenActionsColumn(data);
                        }

                        return this.buildProspectsActionsColumn(data);
                      }}
                      headerRenderer={() => {
                        return (
                          <div>
                            <span className='header_options-dots' id='header_options'>
                              <i className='far fa-ellipsis-h' alt='Player Actions' />
                            </span>
                            <UncontrolledTooltip
                              target='header_options'
                              placement='right'
                              autohide={false}
                              delay={{ hide: 30 }}>
                              <div
                                className='selection'
                                onClick={this.batchHidePlayers.bind(this)}
                                onKeyUp={this.batchHidePlayers.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='hider'>
                                  <i className='icon x fas fa-times' />
                                </div>
                                <span>Hide All Selected Prospects</span>
                              </div>
                              <div
                                className='selection'
                                onClick={this.batchHideSignedPlayers.bind(this)}
                                onKeyUp={this.batchHideSignedPlayers.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='hider'>
                                  <i className='icon x fas fa-times' />
                                </div>
                                <span>Hide First 25 Signed Prospects</span>
                              </div>

                              <div
                                className='selection'
                                onClick={this.toggleModal.bind(this)}
                                onKeyUp={this.toggleModal.bind(this)}
                                role='button'
                                tabIndex={0}>
                                <div className='adder'>
                                  <i className='icon add fas fa-plus' />
                                </div>
                                <span>Track All Selected Prospects</span>
                              </div>
                              <div>
                                <div
                                  aria-disabled={this._isDemoOrg()}
                                  className='selection'
                                  role='button'
                                  onClick={this.exportData.bind(this)}
                                  onKeyDown={() => {
                                    console.log('clicked');
                                  }}
                                  tabIndex={0}>
                                  <div className='downloader'>
                                    <i className='icon download far fa-arrow-to-bottom' />
                                  </div>
                                  <span>Export All Selected Prospects</span>
                                </div>
                              </div>
                            </UncontrolledTooltip>
                          </div>
                        );
                      }}
                    />
                    <Column
                      label='Status'
                      dataKey='status'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        if (data.rowData.commit) {
                          return (
                            <div
                              className='offer-image'
                              id={`committed-school-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}>
                              <SchoolIconComponent
                                imageOnly={true}
                                school={data.rowData.commit.college.name}
                                key={`committed-school-logo-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                addCheck={typeof data.rowData.signed !== 'undefined'}
                              />
                              <UncontrolledTooltip
                                autohide={false}
                                placement='right'
                                target={`committed-school-${data.rowData.id.replace(
                                  /([^a-zA-Z0-9])/g,
                                  ''
                                )}`}
                                className='tooltip-content'>
                                <div className='tooltip-row'>
                                  {data.rowData.commit.college.name}
                                </div>
                              </UncontrolledTooltip>
                            </div>
                          );
                        }

                        return <div>{data.rowData.getStatus()}</div>;
                      }}
                    />
                    <Column
                      label='Year'
                      dataKey='year'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        return data.rowData.getYear();
                      }}
                    />
                    <Column
                      label='Position'
                      dataKey='positions'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      disableSort={false}
                      cellRenderer={(data) => {
                        return data.rowData.getPosition();
                      }}
                    />
                    <Column
                      label='Location'
                      dataKey='location'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      cellRenderer={(data) => {
                        return (
                          <div className='left'>
                            <span
                              id={`location-num-${data.rowData.id.replace(/([^a-zA-Z0-9])/g, '')}`}>
                              {data.rowData.getHighSchoolState()}
                            </span>
                            <UncontrolledTooltip
                              autohide={false}
                              placement='left'
                              target={`location-num-${data.rowData.id.replace(
                                /([^a-zA-Z0-9])/g,
                                ''
                              )}`}
                              className='tooltip-content'>
                              <ul className='tooltip-list text-left' style={{ color: 'white' }}>
                                {`${data.rowData.getHighSchool()} ${data.rowData.getLocationForHighschool()}`}
                              </ul>
                            </UncontrolledTooltip>
                          </div>
                        );
                      }}
                    />
                    <Column
                      label='Height'
                      dataKey='height'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => this.buildMeasurableStat(data.rowData, 'height')}
                    />
                    <Column
                      label='Weight'
                      dataKey='weight'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => this.buildMeasurableStat(data.rowData, 'weight')}
                    />
                    <Column
                      label='GPA'
                      dataKey='gpa'
                      flexGrow={1}
                      flexShrink={1}
                      width={50}
                      defaultSortDirection='DESC'
                      cellRenderer={(data) => {
                        return data.rowData.getGPA();
                      }}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          );
        }}
      </InfiniteLoader>
    );
  }

  showHiddenTable() {
    this.setState({
      hiddenTableShowing: !this.state.hiddenTableShowing,
    });
  }

  render() {
    const headerTab =
      typeof this.props.filter !== 'undefined' &&
      typeof this.props.filter.years !== 'undefined' &&
      this.props.filter.years.length === 1
        ? this.props.filter.years[0]
        : 'all';

    const closeFilter =
      typeof this.props.closeFilter === 'undefined' ? false : this.props.closeFilter;

    return (
      <Row className='prospects'>
        <CSVLink
          data={this.state.csvData}
          filename='Zcruit Export.csv'
          className='hidden'
          ref={this.csvLink}
          target='_blank'
          uFEFF={false}
          aria-hidden={true}
        />
        <Col
          md={2}
          className='no-padding position-relative'
          onClick={() => {
            this._filterList.bind(this, this.props.players);
          }}>
          <FilterContainer
            addFilterToState={this.addFilterToState.bind(this)}
            filter={this.props.filter}
            type={2}>
            <SidebarFilters
              filter={this.props.filter}
              closeFilter={closeFilter}
              isRivalsOnly={AuthenticationService.isRivalsOnly()}
            />
          </FilterContainer>
        </Col>
        <Col md={10}>
          <PageContentHeader
            activeTab={`tab_${headerTab}`}
            filter={this.props.filter}
            lists={this.state.lists}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            totalCount={this.state.total}
          />
          <MessagingComponent
            thrown={this.state.showMessage}
            message={this.state.message}
            className='player-updated-alert'
            type={MESSAGE_TYPE.success}
          />
          <Row>
            <Col md={12} className='with-sidebar'>
              {this.state.trackedErrorThrown === false &&
                this.state.players.length > 0 &&
                this.generateCorrectTable(false, this.state.players, false, this.state.height)}
              {this.state.trackedIsLoading === false &&
                this.state.players.length === 0 &&
                this.state.trackedErrorThrown === true && (
                  <div>
                    <MessagingComponent
                      thrown={this.props.showOldYearsFilterMessage}
                      message={
                        <span>
                          Your filter <strong>{this.props.activeFilterName}</strong> has 2020 set as
                          the year. Please update your filter or you may miss out on players!
                        </span>
                      }
                      type={MESSAGE_TYPE.warning}
                    />
                    <MessagingComponent
                      message={NO_DATA_MESSAGE.prospects}
                      type={MESSAGE_TYPE.danger}
                    />
                  </div>
                )}
              {this.state.trackedIsLoading === true && (
                <MessagingComponent
                  thrown={this.state.trackedIsLoading}
                  message={STATUS_MESSAGE.loading}
                  type={MESSAGE_TYPE.warning}
                />
              )}
            </Col>
          </Row>
        </Col>
        <TrackPlayerModal
          toggleModal={this.toggleModal.bind(this)}
          modalOpen={this.state.openModal}
          players={this.state.selectedPlayers}
          dropdownValues={this.statusValues}
          trackProspect={this.trackProspect.bind(this)}
        />
        <HideAllPlayersModalComponent modalOpen={this.state.hideAllPlayersOpenModal} />
      </Row>
    );
  }
}

/**
 * This sends the store to me
 */
function mapStoreToProps(state) {
  return {
    activeFilterName: state.filter.activeFilterName,
    closeFilter: state.filter.closeFilter,
    filter: state.filter.filter,
    isClear: state.filter.isClear,
    players: state.players.players,
    getPlayersCalled: state.players.getPlayersCalled,
    playerToHide: state.players.playerToHide,
    playerToUnHide: state.players.playerToUnHide,
    playerToTrack: state.players.playerToTrack,
    showOldYearsFilterMessage: state.filter.showOldYearsFilterMessage,
  };
}

export default connect(mapStoreToProps)(ProspectsComponent);
