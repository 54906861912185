import React from 'react';
import DateTimeService, { DATE_DEFAULTS } from 'Services/date-time';

const DEFAULT_NULL_VALUE = '-';
export const PlayerServiceDefaultValues = {
  nullValue: DEFAULT_NULL_VALUE,
};
export default class PlayersModel {
  constructor(player) {
    this.dateTimeService = new DateTimeService();
    this.originalPlayer = Object.assign({}, player);

    Object.assign(this, player);

    this.setVisitsByCollege();
  }

  getYear() {
    if (this.hasTransfer()) {
      return 'Transfer';
    }

    return this.year;
  }

  getCommitDate(format) {
    if (typeof this.commit !== 'undefined') {
      return this.dateTimeService.formatUTCDate(new Date(this.commit.start_date), format);
    }

    return DEFAULT_NULL_VALUE;
  }

  getCommitSchool() {
    if (typeof this.commit !== 'undefined') {
      return this.commit.college.name;
    }
  }

  getSignedSchool() {
    if (typeof this.signed !== 'undefined') {
      return this.signed.college.name;
    }
  }

  getTransferToSchool() {
    if (this.hasTransferToSchool()) {
      return this.transfer.to.name;
    }
  }

  getTransferDate(format) {
    if (this.hasTransfer()) {
      return this.dateTimeService.formatUTCDate(new Date(this.transfer.date), format);
    }

    return DEFAULT_NULL_VALUE;
  }

  getSignedDate(format) {
    if (typeof this.signed !== 'undefined') {
      return this.dateTimeService.formatUTCDate(new Date(this.signed.start_date), format);
    }

    return DEFAULT_NULL_VALUE;
  }

  getCustomVariableByID(id) {
    if (typeof this.customVariables !== 'undefined' && this.customVariables.length > 0) {
      const found = this.customVariables.find((cv) => {
        return cv.type.id === id;
      });

      if (typeof found.value !== 'undefined') {
        return found.value;
      }
    }

    return '';
  }

  getMeasurableStat(stat) {
    const metric = this[stat];
    return metric?.value ?? DEFAULT_NULL_VALUE;
  }

  getHighSchoolState() {
    if (
      typeof this.location !== 'undefined' &&
      typeof this.location.high_school !== 'undefined' &&
      typeof this.location.high_school.state !== 'undefined'
    ) {
      return this.location.high_school.state.name;
    }

    if (
      typeof this.location !== 'undefined' &&
      typeof this.location.high_school !== 'undefined' &&
      typeof this.location.high_school.country !== 'undefined'
    ) {
      return this.location.high_school.country.name;
    }
  }

  getTransferFromHighSchool() {
    const { name } = this.transfer.from;

    if (name) {
      return name;
    }

    return DEFAULT_NULL_VALUE;
  }

  getLocationForTransferHighSchool() {
    let label = '';
    const { city, state } = this.transfer.from;

    if (city && state) {
      label = ` (${city.name}, ${state.name})`;
    } else if (city) {
      label = ` (${city.name})`;
    } else if (state) {
      label = ` (${state.name})`;
    }

    return label;
  }

  getHighSchool() {
    if (typeof this.location !== 'undefined' && typeof this.location.high_school !== 'undefined') {
      return this.location.high_school.name;
    }

    console.warn('afterwards');

    return DEFAULT_NULL_VALUE;
  }

  getLocation() {
    if (typeof this.location !== 'undefined' && typeof this.location.label !== 'undefined') {
      return this.location.label;
    }

    return DEFAULT_NULL_VALUE;
  }

  getLocationForHighschool() {
    let label = '';
    if (
      typeof this.location !== 'undefined' &&
      typeof this.location.high_school !== 'undefined' &&
      typeof this.location.high_school.city !== 'undefined' &&
      typeof this.location.high_school.city.name !== 'undefined'
    ) {
      label = ` (${this.location.high_school.city.name}`;

      if (
        typeof this.location.high_school.state !== 'undefined' &&
        typeof this.location.high_school.state.name !== 'undefined'
      ) {
        label = `${label}, ${this.location.high_school.state.name})`;
      }
    } else if (
      typeof this.location.high_school !== 'undefined' &&
      typeof this.location.high_school.state !== 'undefined' &&
      typeof this.location.high_school.state.name !== 'undefined'
    ) {
      label = ` (${this.location.high_school.state.name})`;
    } else if (
      typeof this.location.high_school !== 'undefined' &&
      typeof this.location.high_school.country !== 'undefined' &&
      typeof this.location.high_school.country.name !== 'undefined'
    ) {
      label = ` (${this.location.high_school.country.name})`;
    }

    return label;
  }

  getCorrectHighSchool() {
    if (this.hasTransfer()) {
      return this.getTransferFromHighSchool();
    }

    return this.getHighSchool();
  }

  getCorrectLocationForHighSchool() {
    if (this.hasTransfer()) {
      return this.getLocationForTransferHighSchool();
    }

    return this.getLocationForHighschool();
  }

  getOffers() {
    if (this.hasOffers() === true) {
      return this.offers;
    }

    return null;
  }

  getPosition() {
    if (typeof this.positions !== 'undefined' && this.positions.length > 0) {
      const sortedPositions = this.positions.sort((a, b) => {
        if (a.priority > b.priority) {
          return 1;
        }
        if (a.priority < b.priority) {
          return -1;
        }
        return 0;
      });
      const positionString1 = `${sortedPositions[0].name}`;
      const positionString2 =
        typeof sortedPositions[1] === 'undefined' ? '' : `, ${sortedPositions[1].name}`;
      return positionString1 + positionString2;
    }

    return DEFAULT_NULL_VALUE;
  }

  getRating(length) {
    length = length === null ? 4 : length;

    if (
      typeof this.rating !== 'undefined' &&
      this.rating.value !== '' &&
      this.rating.value !== 'none'
    ) {
      return parseFloat(this.rating.value).toFixed(length);
    }

    return DEFAULT_NULL_VALUE;
  }

  getState() {
    if (typeof this.location !== 'undefined' && typeof this.location.state !== 'undefined') {
      return this.location.state.name;
    }

    return DEFAULT_NULL_VALUE;
  }

  getStatus() {
    if (typeof this.status !== 'undefined') {
      return this.status.name;
    }

    return DEFAULT_NULL_VALUE;
  }

  getGPA() {
    if (typeof this.gpa !== 'undefined') {
      return parseFloat(this.gpa.value).toFixed(2);
    }

    return DEFAULT_NULL_VALUE;
  }

  getVisitCount() {
    if (typeof this.visits !== 'undefined') {
      return this.visits.length;
    }

    return 0;
  }

  hasCommit() {
    return typeof this.commit !== 'undefined';
  }

  hasTransfer() {
    return typeof this.transfer !== 'undefined';
  }

  hasTransferToSchool() {
    return typeof this.transfer !== 'undefined' && typeof this.transfer.to !== 'undefined';
  }

  hasSigning() {
    return typeof this.signed !== 'undefined';
  }

  hasOffers() {
    return typeof this.offers !== 'undefined' && this.offers.length > 0;
  }

  hasVisits() {
    return typeof this.visits !== 'undefined' && this.visits.length > 0;
  }

  setVisitsByCollege() {
    if (this.hasVisits() === false) {
      return;
    }

    const final = {};
    const keys = [];

    /**
     * Get all the colleges to be used as keys
     */
    this.visits.forEach((visit) => {
      if (keys.indexOf(visit.college.name) === -1) {
        keys.push(visit.college.name);
      }
    });

    /**
     * Create the HTML and nodes used in the appropriate places
     */
    keys.forEach((key) => {
      if (typeof final[key] === 'undefined') {
        final[key] = [];
      }

      this.visits.forEach((visit) => {
        if (visit.college.name !== key) {
          return;
        }

        const date = this.dateTimeService.formatUTCDate(
          new Date(visit.date),
          DATE_DEFAULTS.format.default
        );

        final[key].push({
          label: (
            <div className='visit' key={`label-${visit.type.name}`}>
              <p>{visit.type.name}</p>
              <p>{date}</p>
            </div>
          ),
          type: visit.type.name,
          value: {
            label: (
              <div className='visit' key={`value-label-${visit.type.name}`}>
                <p>{visit.type.name}</p>
                <p>{date}</p>
              </div>
            ),
            value: date,
          },
        });
      });
    });

    this.visitsByCollege = final;
  }
}
