/* eslint-disable max-len */
import React, { Component } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { DateTime } from 'luxon';
import { DATE_DEFAULTS } from '../../../../services/date-time';

class PdfDocument extends Component {
  createStylesheet() {
    return StyleSheet.create({
      page: {
        backgroundColor: 'white',
        fontFamily: 'Helvetica',
        color: '#4a4a4a',
        paddingVertical: 32,
        paddingHorizontal: 32,
      },
      pageNumber: {
        width: '100%',
        fontSize: '10pt',
        paddingLeft: '2pt',
      },
      reportHeader: {
        alignItems: 'center',
        fontSize: '18pt',
        width: '100%',
        textAlign: 'center',
        marginTop: '8pt',
      },
      activity__date: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
      },
      'activity__date--container': {
        alignItems: 'center',
        width: '98%',
        borderBottomColor: '#4a4a4a',
        borderBottomStyle: 'solid',
        borderBottomWidth: '0.25pt',
        marginBottom: '10pt',
      },
      'activity__date--text': {
        top: '10pt',
        fontSize: '10pt',
        backgroundColor: 'white',
        padding: '5pt',
      },
      'activity__single--grey': {
        backgroundColor: '#F8F8F8',
        width: '48%',
        flexDirection: 'row',
        padding: '7px 15px',
      },
      'activity__single--white': {
        width: '48%',
        flexDirection: 'row',
        // margin: '1%',
        padding: '7px 15px',
      },
      playerImage: {
        width: '30px',
        height: '30px',
        borderTopLeftRadius: '30pt',
        borderTopRightRadius: '30pt',
        borderBottomRightRadius: '30pt',
        borderBottomLeftRadius: '30pt',
        marginRight: '7px',
        objectFit: 'cover',
      },
      activity__text_container: {
        fontSize: '9pt',
      },
      'activity__text--name': {
        fontFamily: 'Helvetica-Bold',
      },
    });
  }

  getPosition(activity) {
    if (typeof activity.player.positions !== 'undefined' && activity.player.positions.length > 0) {
      return `${activity.player.positions[0].name}`;
    }
    return '';
  }

  getLocation(activity) {
    if (typeof activity.player.location !== 'undefined') {
      return activity.player.location.label;
    }
    return '';
  }

  getImageUrl(activity) {
    if (typeof activity.player.image_url === 'string' && activity.player.image_url.length > 3) {
      return activity.player.image_url;
    }
    return 'https://s3media.247sports.com/Uploads/Player/0/4_0.jpg';
  }

  getActivitySingleClass(i) {
    let tempIndex = i;
    // if index is odd, check previous even number
    if (tempIndex % 2 !== 0) {
      tempIndex--;
    }

    // check if half of temp index is even
    if ((tempIndex / 2) % 2 === 0) {
      return 'activity__single--white';
    }

    return 'activity__single--grey';
  }

  renderDate(date = null) {
    if (!date) {
      return DateTime.utc().toFormat(DATE_DEFAULTS.format.default);
    }

    const dt = DateTime.fromJSDate(new Date(date)).toUTC().toFormat(DATE_DEFAULTS.format.default);

    return dt;
  }

  renderDateSection(date, data) {
    const styles = this.createStylesheet();
    return (
      <View style={styles.activity__date} key={date}>
        <View style={styles['activity__date--container']}>
          <Text style={styles['activity__date--text']}>{this.renderDate(date)}</Text>
        </View>
        {data &&
          data.map((activity, i) => {
            return (
              <View style={styles[this.getActivitySingleClass(i)]} key={activity.id}>
                <Image src={this.getImageUrl(activity)} style={styles.playerImage} />
                <View style={styles.activity__text_container}>
                  <Text style={styles['activity__text--name']}>{activity.player.name}</Text>
                  <Text>
                    {activity.player.year} - {this.getPosition(activity)} -{' '}
                    {this.getLocation(activity)}
                  </Text>
                  <Text>{activity.label}</Text>
                </View>
              </View>
            );
          })}
      </View>
    );
  }

  render() {
    const dates = Object.keys(this.props.data);
    const activity = this.props.data;

    const styles = this.createStylesheet();
    return (
      <Document language='en'>
        <Page style={styles.page} wrap>
          <Text
            render={({ pageNumber, totalPages }) => {
              return `${pageNumber} / ${totalPages}`;
            }}
            fixed
            style={styles.pageNumber}
          />
          <Text style={styles.reportHeader}>Report Created On {this.renderDate()}</Text>
          {dates &&
            dates.map((date) => {
              return this.renderDateSection(date, activity[date]);
            })}
        </Page>
      </Document>
    );
  }
}

export default PdfDocument;
