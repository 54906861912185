import React from 'react';
import { connect } from 'react-redux';

/**
 * Components
 */
import MessagingComponent, { STATUS_MESSAGE, MESSAGE_TYPE } from 'CommonComponents/messager';
import PlayerPageModalBasicInfoComponent from 'PlayerPageComponents/modal/basicInfo';
import PlayerPageModalBoxesComponent from 'PlayerPageComponents/modal/boxes';
import PlayerPageModalCommitInfoComponent from 'PlayerPageComponents/modal/commitInfo';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import PlayersService from 'Services/players';
import TwitterService from 'Services/twitter';

import store from 'Reducers/store';
import generatePageTitles from 'RootComponents/utility/pageTitles';

class BasePlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      twitterLoading: true,
      player: null,
      activeTab: 'overview',
      twitterFollowData: {},
    };
  }

  componentDidMount() {
    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;

    PlayersService.getPlayer(playerId).then(
      (success) => {
        document.title = generatePageTitles(success.name);
        this.setState(
          {
            isLoading: false,
            originalPlayer: success,
            player: success,
          },
          () => {
            const player = this.state.player;
            TwitterService.getTwitterData(player.id, 'twitterRankings');
          }
        );
      },
      (error) => {
        console.warn('error');
        console.warn(error);
      }
    );
  }

  componentWillUnmount() {
    store.dispatch({
      type: 'TWITTER_API_CALL',
      twitterAPICallFinished: false,
    });
  }

  componentDidUpdate() {
    if (this.state.isLoading === false) {
      const modalWrapper = this.props.parentRef.current;
      modalWrapper.getFocusableChildren()[0].focus();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.twitterApiCallFinished === true &&
      nextState.player !== null &&
      nextProps.twitterPlayersLoaded === true &&
      this.state.twitterLoading === true
    ) {
      TwitterService.getTwitterData(nextState.player.id, 'twitterRankings');
    }
  }

  changePlayer(player) {
    this.setState({
      player,
    });
  }

  showInfo() {
    return (
      <div className='modal-body'>
        <PlayerPageModalBasicInfoComponent
          player={this.state.player}
          updatePlayer={this.changePlayer.bind(this)}
        />
        <PlayerPageModalCommitInfoComponent player={this.state.player} />
        <PlayerPageModalBoxesComponent
          page={this.props.page}
          player={this.state.player}
          twitterFollowData={this.state.twitterFollowData}
          setTab={this.props.setTab}
        />
      </div>
    );
  }

  render() {
    return (
      <div className='modal-wrapper'>
        {this.state.isLoading === false && (
          <PlayerPageModalHeaderComponent
            player={this.state.player}
            page={this.props.page}
            activeTab={this.state.activeTab}
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
          />
        )}
        {this.state.isLoading === false && this.showInfo()}
        {this.state.isLoading === true && (
          <div style={{ width: '90%', margin: '50px auto' }}>
            <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
          </div>
        )}
      </div>
    );
  }
}

function mapStoreToProps(storeState) {
  return {
    twitterPlayersList: storeState.twitter.twitterPlayers,
    twitterPlayersLoaded: storeState.twitter.twitterPlayersLoaded,
    twitterFollowData: storeState.twitter.twitterFollowData,
    twitterApiCallFinished: storeState.twitter.twitterAPICallFinished,
    twitterRankings: storeState.twitter.twitterRankings,
  };
}

export default connect(mapStoreToProps)(BasePlayerPageComponent);
