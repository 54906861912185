import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import CommonComponent from 'CommonComponents/generic';
import FilterComponent from 'FilterComponents/common/generic';

class RangeButtonFilterComponent extends CommonComponent {
  constructor(props) {
    super(props);

    let isOpen = false;

    if (
      typeof this.props.value !== 'undefined' &&
      this.props.value !== null &&
      this.props.value !== ''
    ) {
      isOpen = true;
    }

    this.state = {
      isOpen,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    let isOpen = false;

    if (nextProps.closeFilter === true && nextState.toggleClicked === false) {
      nextProps.values.forEach((value) => {
        if (value.selected === true && value.label !== 'All') {
          isOpen = true;
        }
      });
    } else {
      isOpen = nextState.isOpen;
    }

    return {
      isOpen,
      toggleClicked: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.shallowCompare(this, nextProps, nextState);
  }

  selectValue(rating) {
    const defaults = this.props.values;
    const newRatingVals = {
      allowNull: false,
      max: 0,
      min: 0,
      verified: false,
    };

    let highest = 0;
    let lowest = 100;
    let skipClickOthers = false;
    let unselectedValue = null;
    let valueWasUnselected = false;

    /**
     * Highlight the selection appropriately
     */
    defaults.forEach((value) => {
      /**
       * If the iteratee is All and the label clicked isn't, unset it
       */
      if (
        ((value.label === 'All' && rating.label !== 'All') ||
          (value.label === 'None' && rating.label !== 'None')) &&
        rating.selected === false
      ) {
        value.selected = false;
      } else if (rating.label === 'All' || rating.label === 'None') {
        skipClickOthers = true;

        value.selected = false;
      }

      if (value.label === rating.label) {
        value.selected = !value.selected;

        if (value.selected === false) {
          valueWasUnselected = true;
          unselectedValue = value;
        }
      }
    });

    if (skipClickOthers === false) {
      let lastWasSelected = false;

      /**
       * Find the new highest and lowest values
       */
      defaults.forEach((value) => {
        // Skip None value
        if (value.upper === null || value.lower === null) {
          return;
        }

        if (value.upper > highest && value.selected === true) {
          highest = value.upper;

          lastWasSelected = true;
        }

        if (value.lower < lowest && value.selected === true && unselectedValue === null) {
          lowest = value.lower;

          lastWasSelected = true;
        } else if (unselectedValue !== null) {
          lowest = unselectedValue.lower + 10;
        }
      });

      const ratings = defaults;

      let setRestToUnselected = false;

      ratings.forEach((rtng) => {
        if (lastWasSelected === true && valueWasUnselected === true && rtng.selected === false) {
          rtng.selected = false;

          setRestToUnselected = true;

          return;
        }

        if (rtng.label !== 'All' && rtng.label !== 'None') {
          if (setRestToUnselected === true && rtng.lower <= unselectedValue.lower) {
            rtng.selected = false;
          } else {
            rtng.selected =
              (rtng.upper !== null &&
                rtng.lower !== -1 &&
                rtng.upper <= highest &&
                rtng.lower >= lowest) === true;
          }
        } else {
          rtng.selected = false;
        }
      });

      if (
        ratings.filter((r) => {
          return r.selected === true;
        }).length === 0
      ) {
        ratings.forEach((rtn) => {
          if (rtn.label === 'All') {
            highest = 100;
            lowest = 0;

            rtn.selected = true;
          }
        });
      }

      this.setState({
        defaults: ratings,
      });
    }

    // If the value was None, set to lowest values possible
    if (rating.label === 'None' && rating.selected === true) {
      newRatingVals.allowNull = true;
    } else if (rating.label === 'All' && rating.selected === true) {
      highest = 100;
      lowest = 0;
      newRatingVals.allowNull = true;
    }

    newRatingVals.max = highest;
    newRatingVals.min = lowest;

    this.props.onChange(newRatingVals);
  }

  generateButtons() {
    return this.props.values.map((button) => {
      const state = button.selected === true ? 'primary' : 'muted';

      const contents = (
        <Button
          key={`range_button-${button.label.toLowerCase()}`}
          color={state}
          onClick={this.selectValue.bind(this, button)}
          role='checkbox'
          aria-checked={button.selected}>
          {button.label}
          {button.sub_label && <span className='btn-sub-label'>{button.sub_label}</span>}
        </Button>
      );

      return contents;
    });
  }

  render() {
    const { id, closeFilter, label, classes, collapseClass } = this.props;
    const { isOpen } = this.state;
    return (
      <FilterComponent
        id={id}
        classes={classes}
        closeFilter={closeFilter}
        collapseClass={collapseClass}
        isOpen={isOpen}
        label={label}
        passIsOpenToParent={this.passIsOpenToParent.bind(this)}>
        {this.generateButtons()}
      </FilterComponent>
    );
  }
}

RangeButtonFilterComponent.defaultProps = {
  isOpen: false,
  closeFilter: false,
  label: 'Label',
  classes: '',
  collapseClass: '',
};

RangeButtonFilterComponent.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  closeFilter: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  collapseClass: PropTypes.string,
};

export default RangeButtonFilterComponent;
