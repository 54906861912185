import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

import './_index.scss';

function ClickableTooltip({ targetId, target, tooltip, placement, parentDivStyle }) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (targetId !== event.target.id) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    if (
      targetId === event.target.id ||
      targetId === event.target.parentNode.id ||
      targetId === event.target.parentNode.parentNode.id
    ) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && targetId === event.target.id) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div
      className='clickable-tooltip-target'
      style={parentDivStyle}
      tabIndex='0'
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      id={targetId}
      key={`${targetId}-container`}
      role='button'>
      {target}

      <Tooltip
        className={'clickable-tooltip'}
        placement={placement || 'auto'}
        delay={{ show: 250, hide: 0 }}
        isOpen={isOpen}
        autohide={true}
        target={targetId}
        key={`${targetId}-tooltip`}
        boundariesElement='viewport'>
        {tooltip}
      </Tooltip>
    </div>
  );
}

ClickableTooltip.propTypes = {
  targetId: PropTypes.string.isRequired,
  target: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired,
  placement: PropTypes.string,
  parentDivStyle: PropTypes.object,
};

export default ClickableTooltip;
