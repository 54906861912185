import React, { Component } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Internal Components
 */
import NavigationSearchComponent from './components/search';

/**
 * Services
 */
import AuthenticationService from 'Services/authentication';

/**
 * Assets
 */
import zcruitLogo from 'Assets/images/zcruit-by-ncsa-logo-black.png';

import './styles/_index.scss';

export const TRIAL_LABEL = `(Trial)`;
class NavigationComponent extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.getDemoMarkup = this.getDemoMarkup.bind(this);

    this.state = {
      isOpen: false,
    };
  }

  getDemoMarkup() {
    if (AuthenticationService.isTrialOrg()) {
      return (
        <>
          <span className='separator'></span>
          <span className='nav-trial-label'>{TRIAL_LABEL}</span>
        </>
      );
    }

    return null;
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logout() {
    AuthenticationService.logout().then(() => {
      this.props.history.push('/');
    });
  }

  getRivalsCampLink() {
    if (AuthenticationService.hasRivalsData() === true) {
      return (
        <NavItem>
          <NavLink href='/rivals-camp'>Rivals Film</NavLink>
        </NavItem>
      );
    }

    return null;
  }

  getActivityReport() {
    if (AuthenticationService.isRivalsOnly() === false) {
      return (
        <NavItem>
          <NavLink href='/activity'>Activity Report</NavLink>
        </NavItem>
      );
    }

    return null;
  }

  render() {
    return (
      <Navbar light expand='md' className='main-nav'>
        <NavbarBrand href='#'>
          <img className='nav-main-logo' src={zcruitLogo} alt='zcruit logo' />
        </NavbarBrand>
        {this.getDemoMarkup()}
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className='ml-auto' navbar>
            <NavItem>
              <NavLink href='/prospects'>Prospects</NavLink>
            </NavItem>
            {this.getRivalsCampLink()}
            {this.getActivityReport()}
            <NavItem>
              <NavLink
                tabIndex={0}
                className='nav-zmail-link'
                onClick={() => {
                  AuthenticationService.redirectToZmail(true);
                }}>
                Zmail
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/account'>My Account</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag='button' className='btn-logout' onClick={this.logout.bind(this)}>
                Logout
              </NavLink>
            </NavItem>
            <NavItem>
              <NavigationSearchComponent />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

function mapStoreToProps(storeState) {
  return {
    orgChanged: storeState.authentication.orgChanged,
  };
}

export default connect(mapStoreToProps)(withRouter(NavigationComponent));
