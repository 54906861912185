/* eslint-disable import/prefer-default-export */
import { DateTime } from 'luxon';

const CURRENT_DATE = DateTime.local();

export const CURRENT_YEAR = CURRENT_DATE.year;

export function getFlipDate(year) {
  // Wednesday
  const desiredWeekDay = 3;

  // Grab the first valid day of February for the given year
  const firstDayOfFebForGivenYear = DateTime.local(year, 2).startOf('month');

  // Calculate difference in days from beginning of month to first occurrence of Wednesday
  const missingDays = (desiredWeekDay - firstDayOfFebForGivenYear.weekday + 7) % 7;

  // Target 2nd Wednesday dynamically via adding an additional week from the new date
  const secondWednesdayDate = firstDayOfFebForGivenYear.plus({
    days: missingDays,
    weeks: 1,
  });

  return secondWednesdayDate;
}

export function isReadyToFlipYear(date = CURRENT_DATE) {
  const flipDate = getFlipDate(date.year);
  const { year: flipYear, month: flipMonth, day: flipDay } = flipDate;

  return (
    flipDate.hasSame(date, 'day') ||
    (flipYear === CURRENT_YEAR && flipMonth <= date.month && flipDay <= date.day)
  );
}
