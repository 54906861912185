import axios from 'axios';

class GoogleService {
  constructor() {
    this.cancelTokens = [];
  }

  get(params) {
    this.cancelTokens.push(axios.CancelToken.source());

    const tokenLength = this.cancelTokens.length;

    return axios({
      method: 'GET',
      url: `${process.env.API_URL}locations/google/`,
      params,
      cancelToken: this.cancelTokens[tokenLength - 1].token,
      headers: {
        Authorization: window.localStorage.getItem('access_token'),
        'X-Org': window.localStorage.getItem('organization_id'),
        'X-Refresh-Token': window.localStorage.getItem('refresh_token'),
        'X-Username': window.localStorage.getItem('username'),
      },
    }).then((response) => {
      return response.data.data;
    });
  }

  cancelCall(message) {
    this.cancelTokens.forEach((token) => {
      token.cancel(message);
    });
  }
}

export default new GoogleService();
