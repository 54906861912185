import React from 'react';
import { Col, Row } from 'reactstrap';

/**
 * Components
 */
import MessagingComponent, {
  NO_DATA_MESSAGE,
  MESSAGE_TYPE,
  STATUS_MESSAGE,
} from 'CommonComponents/messager';
import PlayerPageModalHeaderComponent from 'PlayerPageComponents/modal/header';
import ErrorPageComponent from 'PlayerPageComponents/pages/error';
import EventsViewStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/events';
import MeasurablesViewStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/measurables';
import OtherSportsStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/other_sports';
import OverviewViewStatsPlayerPageComponent from 'PlayerPageComponents/pages/stats/components/overview';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

/**
 * Services
 */
import PlayersService from 'Services/players';
import StatisticsService from 'Services/stats';

import generatePageTitles from 'RootComponents/utility/pageTitles';

/**
 * Styles
 */
import 'PlayerPageComponents/pages/stats/styles/_index.scss';

class StatsPlayerPageComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: true,
      isLoading: true,
      format: 'type',
      activeTab: 'stats',
    };
  }

  componentDidMount() {
    this.setState({
      error: false,
      isLoading: true,
    });

    const playerId =
      typeof this.props.match === 'undefined' ? this.props.playerId : this.props.match.params.id;

    const players = PlayersService.getPlayerAsPromise(playerId);
    const stats = StatisticsService.getStatsForPlayerAsPromise(playerId, 'type');

    Promise.all(
      [players, stats].map((p) => {
        return p.catch((e) => {});
      })
    ).then(
      (responses) => {
        if (typeof responses[0] === 'undefined') {
          throw new Error('We could not find the requested player');
        }

        document.title = generatePageTitles(`${responses[0].name} Stats`);

        if (typeof responses[1] === 'undefined') {
          return this.setState({
            error: true,
            isLoading: false,
            player: responses[0],
            stats: [],
          });
        }

        const validStatKeys = [
          'height',
          'weight',
          'forty',
          'shuttle',
          'verticalJump',
          'broadJump',
          'threeCone',
          'wingspan',
          'armLength',
          'handSize',
          'powerBall',
          'sparq',
          'reach',
        ];

        const html = Object.keys(responses[1].metrics).filter(
          (statKey) => validStatKeys.includes(statKey) && statKey
        );

        if (html.length === 0) {
          this.setState({
            error: true,
            isLoading: false,
            player: responses[0],
          });

          return;
        }

        this.setState({
          isLoading: false,
          player: responses[0],
          stats: responses[1],
        });
      },
      (error) => {
        this.setState({
          error: true,
          isLoading: false,
        });
      }
    );
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <div className='modal-wrapper' style={{ width: '90%', margin: '50px auto' }}>
          <MessagingComponent message={STATUS_MESSAGE.loading} type={MESSAGE_TYPE.warning} />
        </div>
      );
    }

    if (this.state.isLoading === false && this.state.error === true) {
      return (
        <div className='modal-wrapper'>
          <ErrorPageComponent
            activeTab='stats'
            message={NO_DATA_MESSAGE.stats}
            page={this.props.page}
            player={this.state.player}
            toggleModal={this.props.toggleModal}
            setTab={this.props.setTab}
            title='Stats'
          />
        </div>
      );
    }

    return (
      <div className='modal-wrapper'>
        <PlayerPageModalHeaderComponent
          player={this.state.player}
          page={this.props.page}
          activeTab={this.state.activeTab}
          toggleModal={this.props.toggleModal}
          setTab={this.props.setTab}
        />
        <div className='modal-body'>
          <Row className='secondary-page stats-page'>
            <Col md={12} className='page-title-container'>
              <div className='page-title'>
                {`${this.state.player.name}'s Stats/Combine Results`}
              </div>
            </Col>
            <Col md={12} className='box-holder'>
              <Row>
                <MeasurablesViewStatsPlayerPageComponent stats={this.state.stats} />
                <OverviewViewStatsPlayerPageComponent stats={this.state.stats} />
                <EventsViewStatsPlayerPageComponent
                  format={this.state.format}
                  player={this.state.player}
                  stats={this.state.stats}
                />
                <OtherSportsStatsPlayerPageComponent player={this.state.player} />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default StatsPlayerPageComponent;
