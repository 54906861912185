import React, { Component } from 'react';
import { Col } from 'reactstrap';

class PowerDetailsRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: this.props.accountType,
      showList: false,
    };
  }

  handlePlusClick() {
    this.setState({
      showList: !this.state.showList,
    });
  }

  detailsForRole() {
    if (this.state.showList) {
      let roleText = '';
      return (
        <Col md={12} className='expanded-block is-active power-details-role-list'>
          {this.props.followList.map((user) => {
            if (
              user.socialMediaAccount.role &&
              user.socialMediaAccount.role.trim().length > 0 &&
              ['coach', 'support'].includes(user.socialMediaAccount.type)
            ) {
              roleText = `(${user.socialMediaAccount.role})`;
            } else {
              roleText = '';
            }

            return (
              <p key={`${user.id}`}>
                {user.socialMediaAccount.name} {roleText}
              </p>
            );
          })}
        </Col>
      );
    }
  }

  render() {
    const followLength = this.props.followList ? this.props.followList.length : 0;
    return (
      <Col md={2} className='power-details-role no-padding'>
        <Col md={12} className='power-details-role-summary'>
          <div className='power-details-role-text'>
            <h5>{followLength}</h5>
            <p>{this.state.accountType}</p>
            <span
              className='trigger'
              onClick={this.handlePlusClick.bind(this)}
              onKeyUp={this.handlePlusClick.bind(this)}
              role='button'
              tabIndex={0}>
              +
            </span>
          </div>
        </Col>
        {this.detailsForRole()}
      </Col>
    );
  }
}

export default PowerDetailsRole;
