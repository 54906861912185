import React, { Component } from 'react';
import { Line, defaults } from 'react-chartjs-2';
import { Col } from 'reactstrap';

defaults.global.legend.display = false;
defaults.global.hover.mode = 'point';

const sharedDatasetProperties = {
  fill: false,
  lineTension: 0,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 2,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 10,
  pointBackgroundColor: '#fff',
  intersect: false,
};

const colorsArr = [
  'rgba(35,31,32,1)',
  'rgba(217,200,145,1)',
  'rgba(1,45,97,1)',
  'rgba(220,30,52,1)',
  'rgba(75,192,192,1)',
  'rgba(44,136,56,1)',
];

class LikeTrendsChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      max: 0,
      top: 0,
      left: 0,
    };
  }

  getChartData() {
    const chartData = this.props.likeTrendsChartData;
    const schools = Object.keys(chartData);
    let colorsIndex = -1;
    let max = 0;
    const dataset = schools.map((school) => {
      const dataArr = chartData[school];
      colorsIndex += 1;
      if (colorsIndex === colorsArr.length) {
        colorsIndex = 0;
      }

      const sharedCopy = Object.assign({}, sharedDatasetProperties);

      const resultObj = Object.assign(sharedCopy, {
        label: school,
        backgroundColor: colorsArr[colorsIndex],
        borderColor: colorsArr[colorsIndex],
        pointBorderColor: colorsArr[colorsIndex],
        pointHoverBackgroundColor: colorsArr[colorsIndex],
        data: dataArr,
      });

      const currentMax = Math.max(...resultObj.data);

      max = currentMax > max ? currentMax : max;

      return resultObj;
    });

    if (this.state.max !== max) {
      this.setState({
        max,
      });
    }

    const data = {
      labels: this.props.chartLabels,
      datasets: dataset,
    };
    return data;
  }

  getMaxValue() {
    if (this.state.max < 90) {
      const tempMax = this.state.max + 10;
      return Math.ceil(tempMax / 10) * 10;
    }
    return 100;
  }

  getChartOptions() {
    const max = this.getMaxValue();

    const chartOptions = {
      tooltips: {
        mode: 'point',
        bodyFontSize: 14,
        bodyFontFamily: 'Chivo, Arial, Helvetica',
        bodyFontStyle: 'bold',
        bodySpacing: 6,
        yPadding: 12,
        xPadding: 12,
        callbacks: {
          title: (tooltipItem, data) => {
            return '';
          },
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += `${Math.round(tooltipItem.yLabel * 100) / 100}%`;
            return label;
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max,
              maxTicksLimit: 4,
              callback: (value, index, values) => {
                if (value === 0) {
                  return '';
                }
                return `${value}%`;
              },
            },
            gridLines: {
              borderDash: [15, 10],
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              maxTicksLimit: 3,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    return chartOptions;
  }

  render() {
    const options = this.getChartOptions();
    return (
      <Col md={8} className='chart-container'>
        <Line data={this.getChartData()} height={110} options={options} />
      </Col>
    );
  }
}

export default LikeTrendsChart;
