import React from 'react';
import { Link } from 'react-router-dom';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';

import AuthenticationService from 'Services/authentication';
import MessagingComponent, { MESSAGE_TYPE } from 'CommonComponents/messager';
import generatePageTitles from 'RootComponents/utility/pageTitles';

/**
 * Parent
 */
import ZcruitComponent from 'CommonComponents/zcruit';

import 'RootComponents/forceresetpassword/styles/_index.scss';

class ForceResetPasswordComponent extends ZcruitComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorThrown: false,
      isLoading: false,
      message: '',
    };
  }

  componentDidMount() {
    document.title = generatePageTitles('Force Password Reset');
  }

  componentWillMount() {
    if (AuthenticationService.loggedIn) {
      this.props.history.push('/prospects');
    }
  }

  async resetPassword(event, formValues) {
    this.setState({
      isLoading: true,
    });

    try {
      await AuthenticationService.resetPasswordForNewUser(
        formValues.username,
        formValues.password,
        formValues.password_confirm
      );

      this.props.history.push('/prospects');
    } catch (error) {
      this.setState({
        errorThrown: true,
        isLoading: false,
        message: error.message,
      });
    }
  }

  render() {
    return (
      <Container fluid={true}>
        <Row className='login-page'>
          <Col md={6} className='left'>
            <Row className='overlay'>
              <Col md={12}>
                <h1 className='zcruit-by-ncsa-logo'>
                  <Link to='/login' href='/login'>
                    Zcruit
                  </Link>
                </h1>
                <h4>Recruit Smarter</h4>
                <h3>Perform Better</h3>
              </Col>
            </Row>
          </Col>
          <Col md={6} className='right'>
            <h3>Change Password For New User</h3>
            <AvForm onValidSubmit={this.resetPassword.bind(this)}>
              <AvGroup>
                <Label for='username'>Email *</Label>
                <AvField
                  type='text'
                  id='username'
                  name='username'
                  placeholder='Enter your username'
                  errorMessage='Please provide a valid username'
                  value={this.props.location.state.username}
                  required
                />
              </AvGroup>
              <AvGroup className='temp-password'>
                <AvField
                  type='password'
                  id='password_temp'
                  name='password_temp'
                  value={this.props.location.state.password}
                  className='temp-password'
                  required
                />
              </AvGroup>
              <AvGroup>
                <Label for='password'>Current Password *</Label>
                <AvField
                  type='password'
                  id='password'
                  name='password'
                  placeholder='Enter your old current password'
                  errorMessage='Please provide your old password'
                  required
                />
              </AvGroup>
              <AvGroup>
                <Label for='password_confirm'>New Password *</Label>
                <AvField
                  type='password'
                  id='password_confirm'
                  name='password_confirm'
                  placeholder='Enter a new password'
                  errorMessage='Please provide a new password'
                  required
                />
              </AvGroup>
              <FormGroup>
                <Button color='primary' disabled={this.state.isLoading}>
                  Reset Password
                </Button>
              </FormGroup>
            </AvForm>
            <MessagingComponent
              thrown={this.state.errorThrown}
              message={this.state.message}
              type={MESSAGE_TYPE.danger}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ForceResetPasswordComponent;
